import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import regularFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import theme from "../../assets/css/theme";
import { injectIntl } from "react-intl";
import TextFilter from "../../views/Posts/TextFilter";
import postsMenuMessages from "../../../common/posts/postsMenuMessages";
import Text from "./Text";
import _ from "lodash";
import { safeFormatMessage } from "../../../common/app/funcs";
import { v4 as uuidV4 } from 'uuid';
import CheckRow from './CheckRow';
import HoverComponent from './HoverComponent';
import { EMPTY_VALUE_ID } from "../../../common/app/constants";

const DEFAULT_TITLE_PROP_PATH = ['title'];

/**
 * @typedef {{ id: string, checked: boolean, nested?: Item[] }} Item
 * @typedef MultiCheckSelectProps
 * @property {string[]} [titlePropPath] - default is ['title']
 * @property {Item[]} items
 */

class MultiCheckSelect extends React.Component {
  static defaultProps = {
    titlePropPath: DEFAULT_TITLE_PROP_PATH,
  }

  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectionChangeById = this.handleSelectionChangeById.bind(this);
    this.state = { filter: "" };
    this.uniqueComponentId = uuidV4();
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (props.items != nextProps.items) {
      let newValuesMap = {};
      _.values(nextProps.items || {}).forEach((val) => _.get(val, 'id') && (newValuesMap[val.id] = val));

      newStateChanges.values = Object.values(newValuesMap);
      newStateChanges.values = newStateChanges.values.sort((a, b) => {
        if (a.hasOwnProperty('ordinalNo') && b.hasOwnProperty('ordinalNo'))
          return (a.ordinalNo - b.ordinalNo)
        else {
          if (_.get(a, 'id') === EMPTY_VALUE_ID) return -1;
          if (_.get(b, 'id') === EMPTY_VALUE_ID) return 1;

          const titleA = String(this.convertTitle(_.get(a, nextProps.titlePropPath, '')));
          const titleB = this.convertTitle(_.get(b, nextProps.titlePropPath, ''));
          return titleA.localeCompare(titleB);
        }
      });
      
      newStateChanges.filteredValues = this.getFilteredValues(
        newStateChanges.values,
        this.state.filter
      );
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  handleFilterChange(newFilter) {
    let stateChanges = { filter: newFilter };
    if (!newFilter)
      stateChanges.filteredValues = this.state.values;
    else
      stateChanges.filteredValues = this.getFilteredValues(
        this.state.values,
        newFilter
      );
    this.setState(stateChanges);
  }

  filterNestedCheckboxesByTitle(values, lowercaseFilter) {
    return (values || []).reduce((acc, value) => {
      let titleString = value.getNested(this.props.titlePropPath, "");
      titleString = this.convertTitle(titleString);

      if (titleString.toLowerCase().includes(lowercaseFilter)) {
        acc.push({ ...value });
      } else if (value.nested?.length) {
        const nestedValues = this.filterNestedCheckboxesByTitle(value.nested, lowercaseFilter);
        if (nestedValues?.length) {
          acc.push({
            ...value,
            nested: nestedValues,
          })
        }
      }

      return acc;
    }, []);
  }

  getFilteredValues = (values, filterVal) => {
    const lowercaseFilter = (filterVal || "").toLowerCase();
    if (!lowercaseFilter.length) {
      return values;
    }

    return this.filterNestedCheckboxesByTitle(values, lowercaseFilter);
  };

  convertTitle = (title) =>
    Boolean(title && title.defaultMessage)
      ? this.props.intl.formatMessage(title)
      : title;

  handleSelectAll = (isSelected) => {
    const { filteredValues } = this.state;
    const { onChange } = this.props;

    const { allItems, allChecked, allUnchecked } = checkAllRecursive(filteredValues, isSelected);
    onChange?.(allItems, allChecked, allUnchecked);
  }

  handleSelectionChangeById(id, checked, disablePropagation) {
    const { items, onChange } = this.props;

    const allItemsNew = [...items];
    const allChecked = [];
    const allUnchecked = [];

    setCheckedRecursively(allItemsNew, allChecked, allUnchecked, id, checked, disablePropagation);

    onChange?.(allItemsNew, allChecked, allUnchecked, { id, checked });
  }

  render() {
    const { titlePropPath, height, width, style, hideSearch, hideSelectAll, aditionalSelectButtons, intl } =
      this.props;
    const { filteredValues, filter } = this.state;

    return (
      <div
        style={{
          ...(style || {}),
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: width || "100%",
          height: height || "60vh",
        }}
      >
        {Boolean(!hideSearch) && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextFilter
              containerStyle={{
                margin: theme.verticalMargin,
                borderRadius: "5px",
                backgroundColor: "transparent",
              }}
              key={"filter"}
              onChange={this.handleFilterChange}
              clearFilterVal={() => this.handleFilterChange("")}
            />

            {Boolean(!hideSelectAll) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {[
                  ...(aditionalSelectButtons || []),
                  { title: postsMenuMessages.all, onClick: () => this.handleSelectAll(true) },
                  { title: postsMenuMessages.none, onClick: () => this.handleSelectAll(false) },
                ].map(({ isDisabled, onClick, style, title }) => (
                  <HoverComponent key={'multiselect-button-' + safeFormatMessage(intl, title)}>
                    {({ isHover }) => (
                      <Text
                        style={Object.assign({
                          margin: theme.verticalMargin,
                          cursor: isDisabled ? 'not-allowed' : "pointer",
                          opacity: isDisabled ? 0.3 : 1,
                          color: isHover && !isDisabled ? theme.brandPrimary : "unset",
                        }, styles.textButtons, style)}
                        onClick={onClick}
                      >
                        {title}
                      </Text>
                    )}
                  </HoverComponent>
                ))}
              </div>
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {filteredValues.map((v, index) => (
            <CheckRow
              intl={intl}
              key={"checkrow-" + this.uniqueComponentId + (v.id || _.uniqueId()) + v.subId}
              onSelectById={this.handleSelectionChangeById}
              titlePropPath={titlePropPath}
              index={index}
              value={v}
              isMultiLevel={Boolean(v.nested?.length)}
              className={v.className}
              isDisabled={v.isDisabled}
              disabledHoverText={this.props.disabledHoverText}
            />
          ))}
        </div>
      </div>
    );
  }
}

MultiCheckSelect = injectIntl(MultiCheckSelect);
MultiCheckSelect = withStyles(regularFormsStyle)(MultiCheckSelect);
const enhance = compose(connectContext(ProjectContext.Consumer));
export default enhance(MultiCheckSelect);


const styles = {
  textButtons: {
    display: "flex",
    minWidth: 30,
    height: 35,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  }
}

const setAllCheckedRecursively = (items, allChecked, allUnchecked, checked, disablePropagation) => {
  items.forEach(item => {
    item.checked = checked;

    if (item.nested?.length && !disablePropagation) {
      setAllCheckedRecursively(item.nested, allChecked, allUnchecked, checked);
    }
    if (item.checked) {
      allChecked.push(item);
    } else {
      allUnchecked.push(item);
    }
  });
};

/**
 * @param {Item[]} items 
 * @param {boolean} isChecked 
 * @param {boolean} [disablePropagation] 
 * @returns 
 */
const checkAllRecursive = (items, isChecked, disablePropagation = false) => {
  let itemsToCheck = [...items];
  let allChecked = [];
  let allUnchecked = [];
  let allItems = [];

  while (itemsToCheck.length) {
    let item = itemsToCheck.pop();
    if (item.checked !== isChecked && !item.isDisabled) {
      item = { ...item, checked: isChecked };
    }

    if (item.checked) {
      allChecked.push(item);
    } else {
      allUnchecked.push(item);
    }

    allItems.push(item);

    if (item.nested?.length && !disablePropagation) {
      itemsToCheck.push(...item.nested);
    }
  }

  return { allItems, allChecked, allUnchecked };
};

const setCheckedRecursively = (items, allChecked, allUnchecked, id, checked, disablePropagation) => {
  items.forEach(item => {
    if (item.id === id) {
      item.checked = checked;

      if (item.nested?.length && !disablePropagation) {
        setAllCheckedRecursively(item.nested, allChecked, allUnchecked, checked, disablePropagation);
      }
    } else if (item.nested?.length && _.difference(id.split(','), item.id.split(',')).length === 0) {
      setCheckedRecursively(item.nested, allChecked, allUnchecked, id, checked, disablePropagation);
      const isEveryNestedChecked = item.nested.every(i => i.checked);
      item.checked = isEveryNestedChecked;
    }
    if (item.checked) {
      allChecked.push(item);
    } else {
      allUnchecked.push(item);
    }
  });
};





