import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ObjectsWrapperManipulationContext } from '../../ObjectsWrapper';
import FilterMenu from '../../../../web/components/CementoComponents/FilterMenu';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../../../web/app/constants';
import TextFilter from '../../../../web/views/Posts/TextFilter';
import ViewSelector from '../../../../web/components/ViewSelector/ViewSelector';
import GroupsControls from '../GroupsControls';
import ColumnVisibilityControls from '../ColumnVisibilityControls/ColumnVisibilityControls';
import AddNewButton from '../../../../web/components/CementoComponents/AddNewButton';
import theme from '../../../../web/assets/css/theme';
import ExportButton from '../ExportButton';
import safetyMessages from '../../../safety/safetyMessages';
import { VIEW_TYPES } from '../../const';
import postsMessages from '../../../posts/postsMessages';
import CreateMenuControls from '../CreateMenuControls/CreateMenuControls';

/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const FilterBar = (props) => {
  const rtl = useSelector((state) => state.app.rtl);
  const configurationMode = useSelector((state) => state.app.configurationMode);

  const {
    groupBy,
    search,
    viewType,
    filterOptions,
    groupByOptions,
    setFilters,
    setGroupBy,
    setSearch,
    setViewType,
    createNewObject,
    subjectType,
  } = useContext(ObjectsWrapperManipulationContext);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [rtl ? 'paddingRight' : 'paddingLeft']: '25%',
        [!rtl ? 'paddingRight' : 'paddingLeft']: '24px',
      }}>
      <TextFilter
        containerStyle={{ margin: '12px 0', maxWidth: '200px' }}
        defaultValue={search}
        onChange={setSearch}
        clearFilterVal={() => setSearch(null)}
      />
      <GroupsControls viewType={viewType} value={groupBy} onChange={setGroupBy} groupByOptions={groupByOptions} />
      {viewType === VIEW_TYPES.TABLE ? <ColumnVisibilityControls /> : null}
      <FilterMenu
        hideEmptyCategory={true}
        filters={filterOptions}
        filterUrlKey={FILTER_URL_KEY}
        buttonStyle={{ margin: '12px 0' }}
        pathDelimeterOverwrite={FILTER_MENU_PATH_DELIMETER}
        onChange={setFilters}
      />
      <ViewSelector
        key={'PropAnalytics_ViewSelector'}
        activeViewType={viewType}
        onChange={(viewType) => setViewType(viewType)}
      />
      {viewType === VIEW_TYPES.TABLE && configurationMode ? <CreateMenuControls /> : null}
      {props.children}
      <ExportButton />
      <AddNewButton
        onClick={createNewObject}
        values={{ contentType: safetyMessages.objectsNames[subjectType] }}
        title={postsMessages.newIssue}
        style={{
          fontWeight: theme.strongBold,
          margin: `0 ${theme.verticalMargin}px`,
        }}
      />
    </div>
  );
};

export default FilterBar;
