import { platformActions } from '../platformActions';
import { envParams, getDispatch } from '../../common/configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import { quasiStaticsEvents } from './quasiStaticsEvents';

export async function getQuasiStatics(viewer) {
  const dispatch = getDispatch();
  const scopeParams = { scope: 'global' };
  const resourceParams = {
    resourceName: 'quasiStatics',
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({ type: quasiStaticsEvents.GET_QUASISTATICS, payload: { quasiStatics: data, lastUpdateTS: 1 } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    dispatch({
      type: quasiStaticsEvents.GET_QUASISTATICS,
      payload: { quasiStatics: result },
    });
  }
}

export async function fetchQuasiStatics(type) {
  let url = `${envParams.apiServer}/v1/quasiStatics`;

  if (type) url += `?type=${type}`;
  else type = 'quasiStatics';

  let res = await platformActions.net.fetch(url);
  res = await res.getJson();

  return { [type]: res };
}
