import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
const PropertySection = Record({
  id: null,
  title: null,
  permissions: null,
  expand: null,
  noBackground: null,
  subSectionIds: null
}, 'propertySection', false);

export default PropertySection;
