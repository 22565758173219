import { subscribeToLastUpdates } from '../lib/utils/utils';
import { batchDispatch } from '../app/funcs';
import serverSDK from '@cemento-sdk/server';
import { checklistItemsEvents } from './checklistItemsEvents';
import * as formsActions from '../forms/actions';

export async function getChecklistItems(viewer, projectId) {
  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'checklistItems',
    getData: () => {
      return serverSDK.checklists.getChecklistItems({ projectId });
    },
  };

  const onData = (data) => {
    if (!data) return;
    
    batchDispatch([
      {
        type: checklistItemsEvents.GET_CHECKLIST_ITEMS,
        payload: { projectId, checklistItems: data },
      },
      formsActions.locallyPopulateFormsTemplates({ scopeId: projectId, checklistItems: data }),
    ]);
  
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);
  return { projectId };
}
