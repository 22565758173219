import _ from 'lodash';
import { checkPropSettings, instanceDataToString } from '../propertiesInstances/funcs';
import { decodeFiltersFromSearch, encodeFilterToSearch } from '../../web/app/funcs';
import {
  FILTER_MENU_PATH_DELIMETER,
  FILTER_URL_KEY,
  GROUP_BY_URL_KEY,
  VISIBLE_COLUMNS_URL_KEY,
} from '../../web/app/constants';
import * as propertyTypes from '../propertiesTypes/propertiesTypes';

/**
 *
 * @param {({ id: string, updatedTS: number } & Record<string, any>)[]} objects
 * @param {{ id: string, updatedTS: number, data: any, propId: string, propType: string }[]} instances
 * @param {{ id: string, type: string, universalId: string } & Record<string, any>} propTypes
 * @returns ({ updatedTS: number, id: string } & Record<string, any>)[]
 */
const attachSafetySpecificData = (nextGenInstance, propTypes) => {
  try {
    const propTypesByUniversalId = _.keyBy(propTypes, 'universalId');
    const activeOnlyColumn = propTypesByUniversalId['isActive'];
    const expirationColumns = Object.values(propTypes).filter(
      (prop) => prop.type === propertyTypes.CERTIFICATION && prop.settings?.isWarning && prop.settings?.isExpiration
    );

    let isActive = false;
    let isExpiring = false;

    if (activeOnlyColumn) {
      isActive = Boolean(nextGenInstance[activeOnlyColumn?.id] || nextGenInstance?.data[activeOnlyColumn?.id]);
      _.set(nextGenInstance, 'isActive', isActive);
    }

    if (expirationColumns) {
      isExpiring = (expirationColumns || []).some((column) => {
        let cellValue = nextGenInstance[column?.id] || nextGenInstance?.data[column?.id];
        if (!cellValue) return false;

        const info = checkPropSettings(column, cellValue);

        return Boolean(info.isWarning || info.isExpired);
      });
    }

    _.set(nextGenInstance, 'isActive', isActive);
    _.set(nextGenInstance, 'isExpiring', isExpiring);
  } catch (error) {
    console.error('attachSafetySpecificData', error);
  }
};

export const preProcessInstances = ({ objects, instances, propTypes, locationsTitlesMap, members }) => {
  if (!objects?.length || !propTypes) {
    return [];
  }

  const instancesByParentId = _.groupBy(instances, 'parentId');
  const propTypesById = _.keyBy(propTypes, 'id');

  const processedObjects = _.reduce(
    objects,
    (acc, object = {}) => {
      const instances = instancesByParentId?.[object.id] || [];
      let nextGenInstance = _.cloneDeep(object);

      attachLocationsTitles(nextGenInstance, locationsTitlesMap);
      attachSafetySpecificData(nextGenInstance, propTypes);

      const ownerMember = _.pick(members?.[nextGenInstance.owner?.id], ['id', 'displayName', 'companyId']);
      const assignToMember = _.pick(members?.[nextGenInstance.assignTo?.id], ['id', 'displayName', 'companyId']);
      const owner = _.merge(ownerMember, nextGenInstance.owner);
      const assignTo = _.merge(assignToMember, nextGenInstance.assignTo);

      _.set(nextGenInstance, 'props', propTypesById);
      _.set(nextGenInstance, 'owner', owner);
      _.set(nextGenInstance, 'assignTo', assignTo);

      instances.forEach(({ data, propId }) => {
        _.set(nextGenInstance, propId, data);
      });

      acc.push(nextGenInstance);
      return acc;
    },
    []
  );

  return processedObjects;
};

const attachLocationsTitles = (object, locationsTitlesMap) => {
  if (!object?.location || !locationsTitlesMap) {
    return;
  }

  ['building', 'floor', 'unit'].forEach((key) => {
    const valueId = object.location[key]?.id;
    if (valueId) {
      const value = createNextGenValue(valueId, valueId, locationsTitlesMap[valueId]?.[`${key}Title`]);
      object[key] = value;
      object.location = value;
    }
  });
  return object;
};

export const createNextGenValue = (valueId, value, title) => {
  const nextGenValue = {
    id: valueId,
    cementoValue: {
      id: valueId,
      value: value,
      title: title,
    },
  };
  return nextGenValue;
};

export const getNextGenValue = (target, propData, intl) => {
  const fullProp = _.get(target, propData.id);
  const propValue = _.get(target, propData.universalId);
  if (fullProp?.cementoValue) return fullProp;

  let nextGen = null;

  if (propData.values) {
    const richValue = propData.values?.find?.(
      (v) => v.id == propValue || (fullProp && v.id === Object.values(fullProp)[0])
    );
    nextGen = {
      id: richValue?.id,
      key: propData.id,
      cementoValue: richValue,
    };
  } else {
    nextGen = {
      id: fullProp?.id,
      key: propData.id,
      cementoValue: {
        id: fullProp?.id,
        value: fullProp,
        title: instanceDataToString(propData, propValue || fullProp, intl),
      },
    };
  }

  return nextGen;
};

export const getCementoStringValue = (nextGenValue) => {
  if (!nextGenValue) return nextGenValue;
  if (nextGenValue?.cementoValue) {
    return nextGenValue.cementoValue.getCementoTitle();
  }
  return typeof nextGenValue === 'object' ? nextGenValue?.getCementoTitle?.() : nextGenValue;
};

export const patchNextGenLocationSearchIfNeeded = (search) => {
  const decodedFilter = decodeFiltersFromSearch(search, FILTER_URL_KEY, FILTER_MENU_PATH_DELIMETER);

  let filterObject;
  if (decodedFilter.cementoQuery) {
    filterObject = Object.entries(decodedFilter.originalFilterObj).reduce((acc, [path, value]) => {
      const parsedPath = path
        .replaceAll(FILTER_MENU_PATH_DELIMETER, '')
        .replaceAll('values', '')
        .replaceAll('originalValue', '');

      _.set(acc, parsedPath, value);
      return acc;
    }, {});
  }

  let newSearchStr = '';
  if (filterObject) {
    newSearchStr = encodeFilterToSearch(filterObject, '', FILTER_URL_KEY);
  }

  const oldSearch = new URLSearchParams(search);
  const newSearch = new URLSearchParams(newSearchStr);
  oldSearch.has(VISIBLE_COLUMNS_URL_KEY) &&
    newSearch.set(VISIBLE_COLUMNS_URL_KEY, oldSearch.get(VISIBLE_COLUMNS_URL_KEY));
  oldSearch.has(GROUP_BY_URL_KEY) && newSearch.set(GROUP_BY_URL_KEY, oldSearch.get(GROUP_BY_URL_KEY));
  return newSearch.toString();
};
