import React, { useCallback, useMemo, useState } from 'react';
import Text from '../../components/CementoComponents/Text';
import theme from '../../assets/css/theme';
import plus_primary from '../../assets/img/icons/plus_primary.png';
import chroma from 'chroma-js';


const BUTTON_HEIGHT = 35;
/**
 * 
 * @typedef {{
 *  title: string | import('../../../common/app/actions').IntlMessage,
 *  style?: React.CSSProperties,
 *  iconStyle?: React.CSSProperties,
 *  textStyle?: React.CSSProperties,
 *  icon?: string,
 *  shouldInvertColors?: boolean,
 *  isDisabled?: boolean,
 *  disabledTitle?: string,
 *  disabledOnClick?: React.MouseEventHandler<HTMLDivElement>, 
 *  stopPropagation?: boolean,
 *  onClick?: React.MouseEventHandler<HTMLDivElement>,
 *  withPlus?: boolean,
 *  primaryColor?: string,
 * }} CementoButtonProps
 * @param {CementoButtonProps} props
 * @returns 
 */
const Button = (props) => {
  const { style = {}, icon, iconStyle = {}, onClick, isDisabled = false, stopPropagation = false, withPlus = false, textStyle, disabledTitle, disabledOnClick, shouldInvertColors, testid } = props;
  const [isHover, setIsHover] = useState(false);
  const handleClick = useCallback(/** @type {React.MouseEventHandler<HTMLDivElement>} */ (event) => { 
    if (stopPropagation)
      event.stopPropagation();

    if (onClick && !isDisabled)
      onClick(event);
    else if(isDisabled && disabledOnClick && typeof disabledOnClick === "function") 
      disabledOnClick(event)
  }, [onClick, isDisabled, stopPropagation]);

  const title = isDisabled && disabledTitle ? disabledTitle : props.title || props.children;
  const [primaryColor, backgroundColor] = useMemo(() => {
    const primaryColor = isDisabled ? theme.placeholderTextColor : (props.primaryColor || theme.brandPrimary);
    const backgroundColor = theme.innerCollapsibleRowBackground;

    if (shouldInvertColors) {
      return [backgroundColor, primaryColor];
    }
    return [primaryColor, backgroundColor];
  }, [shouldInvertColors, isDisabled]);
  const primaryColorLight = useMemo(() => chroma(primaryColor).alpha(0.8).hex(), [primaryColor]);

  return (
    <div 
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      data-testid={testid}
      onClick={handleClick} 
      style={{ 
        display: 'flex', 
        justifyContent: 'space-around', 
        alignItems: 'center', 
        height: BUTTON_HEIGHT, 
        borderRadius: '50px', 
        cursor: isDisabled ? 'not-allowed' : 'pointer', 
        backgroundColor: backgroundColor, 
        border: `1px solid ${theme.headerInfoColor}`, 
        borderColor: theme.headerInfoColor,
        margin: `${theme.verticalMargin}px ${theme.margin}px`, 
        transitionProperty: 'border-color, box-shadow',
        transitionDuration: '0.3s',
        minWidth: 'min-content',
        width: 40,
        ...style,
        ...(isHover && !isDisabled ? {...theme.webShadow, borderColor: primaryColorLight } : {}),
      }}
      data-qa={props['data-qa']}
    >
      {Boolean(title) && <div style={{ margin: `0 ${theme.margin}px` }} />}
      {Boolean(title) && <Text style={Object.assign({ color: primaryColor, fontSize: theme.fontSize, alignItems: 'center', margin: `0 ${theme.margin}px`, whiteSpace: 'nowrap', minWidth: 'min-content' }, textStyle)}>{title}</Text>}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: `0 ${icon ? 0 : theme.margin}px` }}>
        {Boolean(withPlus) && <Text style={{ color: primaryColor, fontSize: theme.fontSizeH4, alignItems: 'center' }}>+</Text>}
        {Boolean(icon) && <img style={{ maxHeight: '100%', ...iconStyle }} src={icon} />}
      </div>
    </div>
  );
}

export default Button;