import _ from "lodash";
import { schemasInfo, uploadObjectsDispatcher } from '../../common/lib/offline-mode/config';
import { getAppState, realmInstance } from '../../common/configureMiddleware';
import { getAllFailedToUploadChecklists, uploadFailedChecklistItemInstance } from '../../common/checklistItemsInstances/funcs';
import { retryCommentsUpload } from '../../common/comments/funcs';
import { getAllFailedToUploadPosts, retryPostsUpsert } from '../../common/posts/funcs';
import { safeJSONParse } from '../../common/app/funcs';
import * as propertyTypes from '../../common/propertiesTypes/propertiesTypes';


export const getFromPropsOrNav = (
  props,
  objPathArr,
  defaultVal,
  prioritizeProps = true
) => {
  let toRet = defaultVal;

  if (prioritizeProps) {
    const propsRes = _.get(props, objPathArr);
    if (propsRes !== undefined) toRet = propsRes;
    else {
      const paramsRes = _.get(props, ["route", "params", ...objPathArr]);
      if (paramsRes !== undefined) toRet = paramsRes;
    }
  } else {
    const paramsRes = _.get(props, ["route", "params", ...objPathArr]);
    if (paramsRes !== undefined) toRet = paramsRes;
    else {
      const propsRes = _.get(props, objPathArr);
      if (propsRes !== undefined) toRet = propsRes;
    }
  }

  return toRet;
};

export const checkAndUploadLocalObjects = (() => {
  // TODO: only to solve CEM-11622 [Client] - [App] - Upload new certificates failing sometimes
  // TODO: remove this once we fixed the certficiation input component to not use the cert as its value b*tch and use the cert.certificateMetaData.extraTypes AND all clients have the neccessary version (0.99.5.1) to work without it
  const tempFixUploadTwiceSameFile = (propInsance) => {
    if (propInsance && propInsance.propType === propertyTypes.CERTIFICATION && Array.isArray(propInsance.data)) {
      propInsance.data.forEach(cert => {
        _.values(cert.certificateMetaData?.extraTypes).forEach(extraType => {
          const certExtraTypeValue = cert[extraType.id];
          if (certExtraTypeValue && extraType.value?.uri && !extraType.value.uri.startsWith('http') && certExtraTypeValue.uri?.startsWith('http')) {
            extraType.value = cert[extraType.id];
          }
        });
      });
    }

    return propInsance;
  }

  // TODO: return what we still have locally from the retry func so we can use it from the place where this function is called!!!
  const mapObjectsFunc = o => {
    o = { ...o.realmToObject() };
    if (o.data && typeof o.data === 'string') {
      o.data = safeJSONParse(o.data, true);
      o = tempFixUploadTwiceSameFile(o);
    }

    return o;
  };

  const projectIdExtractor = (instance) => instance.projectId;
  const getLocalSchemaObjects = () => {
    let objectsBySchemaType = {}
    let projectIds = []
    for (const schemaInfo of Object.values(schemasInfo)) {
      const { enabled, schemaType, schemaName } = schemaInfo;
      if (!enabled.mobile) return;

      const allObjects = realmInstance[schemaType].objects(schemaName); // this contains all projects object
      const localObjects = allObjects.filtered(
        `isLocal == true AND lastUploadTS == 0`,
      );
      objectsBySchemaType[schemaType] = localObjects
      projectIds=[...projectIds,..._.map(localObjects?.safeToJS?.(), projectIdExtractor)]
    }
    return { objectsBySchemaType, projectIds }
  }
  
  /**
   * @type {Promise<{
   * localChecklistPayload: { localChecklistItemsInstance: any[], counter: number },
   * localPostsPayload: { localPosts: any[], counter: number },
   * localPropInstancesPayload: { localPropInstances: any[], counter: number },
   * }>}
   */
  let currentRetryPromise = null;
  return async (selectedProjectId, locationId) => {
    const retryFunc = async () => {
      const appState = getAppState();
      const isLoggedIn = appState.getNested(['auth', 'loggedIn']);
      const isConnected = appState.getNested(['app', 'isConnected']);
      const viewer = appState.getNested(['users', 'viewer']);
      const shouldDisableRetries = appState.getNested(['configurations', 'debugConfigurations', selectedProjectId, 'disableRetries']);
  
      let ret = {
        localChecklistPayload: { localChecklistItemsInstance: [], counter: 0 },
        localPostsPayload: { localPosts: [], counter: 0 },
        localPropInstancesPayload: { localPropInstances: [], counter: 0 },
      };
  
      if (!isLoggedIn || shouldDisableRetries)
        return ret;
  
      // Dont put this in promise.all because realm transactions cant be happening simultaneously
      const localChecklistPayload = await getAllFailedToUploadChecklists(locationId);
      const localPostsPayload = await getAllFailedToUploadPosts(locationId);
      const localSchemaObjects = getLocalSchemaObjects();
      
      const localPropInstances = _.values(localSchemaObjects.objectsBySchemaType[schemasInfo.propertyInstances.schemaType]).filter(o => {
        return o.projectId === selectedProjectId;
      });
  
  
      ret.localChecklistPayload = localChecklistPayload;
      ret.localPostsPayload = localPostsPayload;
      ret.localPropInstancesPayload = { localPropInstances, counter: localPropInstances.length };
      
      if (!isConnected) {
        return ret;
      }
  
      try {
        const MAX_UPLOAD_PER_RETRY = 100;
      
        if (localChecklistPayload?.localChecklistItemsInstance?.length > 0) {
          const uploadRes = await Promise.all(localChecklistPayload.localChecklistItemsInstance.map((val, index) => {
            // Limit for the first 100 so in case we have a lot to upload it won't stuck the app completely
            if (index < MAX_UPLOAD_PER_RETRY) {
              const valProjId = projectIdExtractor(val);
              if (valProjId) {
                return uploadFailedChecklistItemInstance(val);
              }
            }
          }));
  
          ret.localChecklistPayload.localChecklists = uploadRes.map(res => {
            if (res.didUpload) return null;
            if (res.willRetry) return res.checklistItemInstance;
          }).filter(Boolean);
          ret.localChecklistPayload.counter = ret.localChecklistPayload.localChecklists.length;
        }
  
        if (localPostsPayload?.counter > 0) {
          const { postsRemainedLocal } = await retryPostsUpsert(localPostsPayload.localPosts, viewer);
          ret.localPostsPayload.counter = postsRemainedLocal.length;
          ret.localPostsPayload.localPosts = postsRemainedLocal;
        }
  
        await retryCommentsUpload(viewer); 
        
        if (realmInstance) {
          for (const schemaInfo of Object.values(schemasInfo)) {
            const { enabled, schemaType } = schemaInfo;
            if (!enabled.mobile) return;
  
            const localObjects = localSchemaObjects.objectsBySchemaType[schemaType]
            
            if (localObjects.length > 0) {
              const jsLocalObjects = localObjects.map(mapObjectsFunc);
              
              const localObjectByProjectId = _.groupBy(jsLocalObjects, 'projectId');
              for (const projectId in localObjectByProjectId) {
                const localObjectsArr = localObjectByProjectId[projectId];
                
                const res = await uploadObjectsDispatcher({
                  projectId,
                  objectsToSave: localObjectsArr,
                  schemaInfo,
                  isRetry: true,
                });
  
                if (!res.success && schemaInfo.schemaType === schemasInfo.propertyInstances.schemaType) {
                  ret.localPropInstancesPayload.localPropIntances = localObjectsArr;
                  ret.localPropInstancesPayload.counter = localObjectsArr.length;
                }
              }
            }
          }
        }
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return ret;
      }
    }

    if (currentRetryPromise === null) {
      currentRetryPromise = retryFunc();
      currentRetryPromise
        .finally(() => currentRetryPromise = null)
    }
  
    return currentRetryPromise;
  }
})();