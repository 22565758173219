import { Card, GridItem } from '../../../../../web/components';

const GenericCardWrapper = ({ onClick, style, xs = 12, children, ...rest }) => {
  return (
    <GridItem data-qa={rest['data-qa']} xs={xs}>
      <Card plain onClick={onClick} style={style}>
        {children}
      </Card>
    </GridItem>
  );
};

export default GenericCardWrapper;
