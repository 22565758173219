import _ from 'lodash';
import {getFullLocationDetailsByIdNoProps} from '../locations/func';
import {Map} from 'immutable';
import {platformActions} from '../platformActions';
import {envParams, getDispatch, lokiInstance, realmInstance} from '../configureMiddleware';
import {schemasInfo} from '../lib/offline-mode/config';
import { SUBJECTS } from '../dataManager/subjects';
import { drawingsEvents } from './drawingsEvents';
import { getSnapshotData } from '../lib/utils/utils';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';

export const BUILDINGS = "buildings";
export const BUILDING = "building";
export const FLOORS = "floors";
export const FLOOR = "floor";
export const UNITS = "units";
export const UNIT = "unit";

export const startDrawingsListener = (viewer, projectId) => getDrawings(viewer, projectId);

export async function getDrawings(viewer, projectId) {
  const dispatch = getDispatch();
  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };
  const resourceParams = {
    resourceName: SUBJECTS.DRAWINGS,
    queryParams: {
      version: 2,
    },
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({ type: drawingsEvents.SET_DRAWINGS, payload: { projectId, [SUBJECTS.DRAWINGS]: data } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    onData(result);
  }

  return {
    projectId,
  };
}

export const endDrawingsListener = (projectId, drawingType) => {
	ClientServerConnectivityManagerInstance.unregisterService({
		scope: 'projects',
		scopeId: projectId,
		subject: 'drawings',
		params: { locationType: drawingType, version: 2 },
	});
	return {
		type: drawingsEvents.END_DRAWINGS_LISTENER,
		payload: {},
	}
}

export const convertDrawingsV2ToV1 = (drawings, projectId) => {
  const drawingObjects = [];
  Object
  .values(drawings || {})
  .forEach(drawing => {
    const category = _.first(_.keys(drawing.category));
    if (drawing.specificLocations) {
      Object.keys(drawing.specificLocations).forEach(locationId => {
        const locationData = getFullLocationDetailsByIdNoProps(locationId);
        locationData.id = locationId;
        drawingObjects.push({
          ...drawing,
          category,
          locationData,
        });
      });
    }
    const locationIds = [];

    if (drawing.locationType?.['-units'] && drawing['-unitsTags']) {
      locationIds.push(...getLocationsByPropIds(projectId, drawing['-unitsTags'], '-unitsTags'));
    }
    if (drawing.locationType?.['-floors'] && drawing['-floorsTags']) {
      locationIds.push(...getLocationsByPropIds(projectId, drawing['-floorsTags'], '-floorsTags'));
    }
    if (drawing.locationType?.['-buildings'] && drawing['-buildingsTags']) {
      locationIds.push(...getLocationsByPropIds(projectId, drawing['-buildingsTags'], '-buildingsTags'));
    }
    locationIds.forEach(locationId => {
      const locationData = getFullLocationDetailsByIdNoProps(locationId);
      locationData.id = locationId;
      drawingObjects.push({
        ...drawing,
        category,
        locationData,
      });
    });
  });

  return drawingObjects.reduce((map, val) => {
    if (!val.locationData.locationIds?.buildingId) {
      return map;
    }
    return map.setIn([val.locationData.locationIds?.buildingId, val.locationData.id, val.id], val)
  }, new Map());
}

export const buildDrawingV1Object = (drawings) => {
  let drawingObjects = [];
  ['buildings', 'units', 'floors'].forEach(locationType => {
    if (!drawings || !drawings[locationType])
      return

    const locationTypeDrawings = drawings[locationType];
    _.entries(locationTypeDrawings).forEach(([buildingId, buildingValue]) => {
      _.entries(buildingValue).forEach(([locationId, locationValue]) => {
        _.entries(locationValue).forEach(([drawingId, drawingValue]) => {
          const drawingCategory = _.first(_.keys(drawingValue.category));
          let locationData = getFullLocationDetailsByIdNoProps(locationId);
          locationData = _.set(locationData, ["id"], locationId);
          drawingObjects.push({
            ...drawingValue,
            drawingCategory,
            locationData,
          });
        });
      });
    });
  })

  return drawingObjects.reduce((map, val) => {
    if (!val.locationData.locationIds?.buildingId) {
      return map;
    }
    return map.setIn([val.locationData.locationIds?.buildingId, val.locationData.id, val.id], val)
  }, new Map());
}

export const isDrawingsInV2 = (drawings) => {
  return _.size(_.intersection(_.keys(drawings), ["units", "floors", "buildings"])) === 0;
}

// returns array of location ids
export const getLocationsByPropIds = (selectedProjectId, groupIds, propId = 'groups') => {
  const instancesCollectionName = schemasInfo.propertyInstances.schemaType;
  const subjectName = 'locationsInfo';
  // mobile
  if (platformActions.app.getPlatform() !== "web") {
    const filter = `projectId = "${selectedProjectId}" AND subjectName = "${subjectName}" AND propId = "${propId}"`;

    return realmInstance[instancesCollectionName]
      .objects(schemasInfo.propertyInstances.schemaName)
      .filtered(filter)
      .filter(item => {
        if (!item.data) {
          return;
        }
        return Object
        .keys(groupIds)
        .find(group => JSON.parse(item.data)?.[group])
      })
      .map(item => item.parentId);
  }
  // desktop
  const instancesQuery = {
    projectId : selectedProjectId,
    subjectName,
    propId,
  };
  return lokiInstance
    .getCollection(instancesCollectionName)
    .cementoFind(instancesQuery)
    .filter(item =>
      Object.keys(groupIds).find(group => item.data?.[group])
    )
    .map(item => item.parentId);
}

export const upsertDrawingApiCall = async (projectId, drawing) => {
  const { apiServer } = envParams;
  const body = {
    drawing,
    projectId
  };
  const res = await platformActions.net.fetch(`${apiServer}/v1/drawings`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return res.json();
};

export const deleteDrawingApiCall = async (projectId, drawingId) => {
  const { apiServer } = envParams;
  const res = await platformActions.net.fetch(`${apiServer}/v1/drawings?projectId=${projectId}&drawingId=${drawingId}`, {
    method: 'DELETE',
  });
  return res.json();
};

export const getAllLocationDrawings = async (projectId, locationIds) => {
  try {
    const url = `${envParams.apiServer}/v1/services/locations/specs?projectId=${projectId}&locationIds=${JSON.stringify(locationIds)}`;
    const res = await platformActions.net.fetch(url);
    return res.json();
  } catch (error) {
    console.error('Could not fetch location drawings')
  }
}