import _ from 'lodash';
import { platformActions } from '../platformActions';
import { removeNilAndEmpty, subscribeToLastUpdates, unsubscribeToLastUpdates } from '../lib/utils/utils';
import trackComments, { COMMENTS_EVENTS } from './trackComments';
import { getNewId } from '../lib/api';
import serverSDK from '@cemento-sdk/server';
import { encodeBase64, onError, storeImagePermanently } from '../app/funcs';
import { shouldUseBase64 } from '../app/constants';
import ExtraError, { errorCodes } from '../lib/errors/extraError';
import { uploadImage } from '../images/actions';
import { ERROR_CODES } from '../app/actions';

const handleSdkError = ({ error, projectId, comment }) => {
  let shouldSendToRetry = true;
  let shouldDelete = false;
  switch (error.metadata?.status) {
    case ERROR_CODES.BAD_REQUEST:
    case ERROR_CODES.UNAUTHORIZED:
    case ERROR_CODES.PAYMENT_REQUIRED:
    case ERROR_CODES.FORBIDDEN:
    case ERROR_CODES.NOT_FOUND:
    case ERROR_CODES.METHOD_NOT_ALLOWED:
    case ERROR_CODES.NOT_ACCEPTABLE:
    case ERROR_CODES.PROXY_AUTHENTICATION_REQUIRED:
      shouldSendToRetry = false;
      shouldDelete = true;
      break;
    case ERROR_CODES.CONFLICT:
      shouldSendToRetry = false;
      shouldDelete = false;
      break;
  }

  onError({
    errorMessage: 'Failed to upload comment',
    errorCode: error.metadata?.status,
    error,
    methodMetaData: { projectId, comment },
    errorMetaData: { originalErrorMessage: error.message, shouldSendToRetry, shouldDelete },
  });

  return { shouldSendToRetry, shouldDelete };
};

export const startCommentsListener = (viewer, projectId, parentIds) => {
  trackComments(COMMENTS_EVENTS.START_COMMENTS_LISTENER, { projectId, parentIds });
  subscribeToLastUpdates(
    viewer,
    {
      scope: 'projects',
      scopeId: projectId,
    },
    {
      subject: `comments`,
      queryParams: { parentIds },
      getData: async () => {
        trackComments(COMMENTS_EVENTS.FETCH_COMMENTS, { projectId, parentIds });
        return serverSDK.comments.getComments({ projectId, parentIds, includeDeleted: true });
      },
      isObjectUseTransactionalDB: false, // has to be false because we are listening to comments per parentId - causing comments not to fetch
    },
    (comments) => saveCommentsLocally(comments, projectId, undefined, false, false)
  );

  return () => endCommentsListener(projectId, parentIds);
};

export const endCommentsListener = (projectId, parentIds) => {
  trackComments(COMMENTS_EVENTS.END_COMMENTS_LISTENER, { projectId, parentIds });
  unsubscribeToLastUpdates(
    {
      scope: 'projects',
      scopeId: projectId,
    },
    {
      subject: `comments`,
      queryParams: { parentIds },
    }
  );
};

export async function uploadComment(viewer, projectId, parentId, _comment) {
  //TODO: test this function
  let comment = removeNilAndEmpty({
    ...(_comment || {}).realmToObject(),
    isLocal: null,
    isDeleted: _comment.isDeleted || null,
    projectId,
    parentId,
  });
  try {
  // Determine if it's a checklist comment or post comment for specific handling.
  if (comment.parentType !== 'checklistItemInstance') {
    // This block handles the additional checks and operations for post comments.
    if ((!comment.content || comment.content == '') && (!comment.images || Object.values(comment.images).length == 0)) {
      throw new ExtraError('uploadComment - missing content', { comment });
    }

    if (!projectId || !parentId || parentId == 'undefined') {
      throw new ExtraError('uploadComment - missing parentId', { comment });
    }

    // Handle image processing for post comments.
    if (comment.images && Object.keys(comment.images).length > 0) {
      const currImage = Object.assign({}, Object.values(comment.images)[0]);
      if (currImage.uri && !currImage.uri.startsWith('http')) {
        try {
          const uploadImageRes = await uploadImage(
            { data: currImage.uri, extension: 'jpeg' },
            `${projectId}_${comment.id}_${currImage.id}`,
            'comments'
          );
          if (uploadImageRes) currImage.uri = uploadImageRes;

          if (
            !currImage.uri ||
            (currImage.uri.startsWith &&
              currImage.uri.startsWith('file:/') &&
              !(await platformActions.fs.exists(currImage.uri.replace('file:/', ''))))
          ) {
            throw new ExtraError('post comment missing image', null, null, errorCodes.MISSING_FILE);
          } else if (currImage.uri.startsWith('http')) {
            delete currImage['uploading'];
            comment.images = {};
            comment.images[currImage.id] = currImage;
          }
        } catch (error) {
          if (_.get(error, ['errorCode']) == errorCodes.MISSING_FILE) {
            platformActions.sentry.notify(
              'post comment with missing image',
              { uri: currImage.uri, viewer, projectId },
              error
            );
            delete comment.images;
          } else {
            onError({
              error,
              errorMessage: 'Comments: error during uploadImage',
              errorMetaData: { images: comment.images },
              methodMetaData: {
                args: { comment, projectId, parentId, viewer },
                name: 'uploadComment',
              },
            });
            throw new ExtraError('uploadComment-uploadImage error', { viewer, projectId, parentId, comment }, error);
          }
        }
      } else if (currImage.uri?.startsWith?.('http')) {
        comment.images = {};
        comment.images[currImage.id] = currImage;
      }
    }
  }

    const event =
      comment.parentType == 'checklistItemInstance'
        ? COMMENTS_EVENTS.UPLOAD_NEW_CHECKLIST_STATUS_COMMENT
        : COMMENTS_EVENTS.UPLOAD_NEW_POST_COMMENT;
    trackComments(event, { projectId, parentId, comment });
    await serverSDK.comments.createComment(comment);

    saveCommentsLocally([comment], projectId, parentId, false, false);
    return { projectId, parentId, comment };
  } catch (error) {
    console.error('uploadComment error: ' + error);
    const { shouldSendToRetry, shouldDelete } = handleSdkError({ error, projectId, comment });
    saveCommentsLocally([comment], projectId, parentId, shouldSendToRetry, shouldDelete);
    throw new ExtraError('uploadComment error', { viewer, projectId, parentId, comment }, error);
  }
}

export const deleteComment = async (projectId, parentType, parentId, commentId) => {
  await serverSDK.comments.deleteComment({ id: commentId });
  saveCommentsLocally([{ id: commentId }], projectId, parentId, false, true);
  trackComments(COMMENTS_EVENTS.DELETE_COMMENT, { projectId, parentId, commentId });
};

export const createLocalComment = async (viewer, projectId, parentId, comment) => {
  if (
    comment.parentType !== 'checklistItemInstance' &&
    (!comment.content || comment.content == '') &&
    (!comment.images || Object.values(comment.images).length == 0)
  ) {
    throw new ExtraError('createLocalComment - missing content', { comment });
  }

  // TODO:check this function:
  if (Object.keys(comment.images || {}).length)
    await Promise.all(
      Object.values(comment.images).map(async (commImage) => {
        if (
          !commImage.uri ||
          !commImage.uri.startsWith ||
          commImage.uri.startsWith('http') ||
          commImage.uri.indexOf(';base64,') !== -1
        )
          return;

        if (shouldUseBase64()) {
          const base64Str = await encodeBase64(commImage.uri, 'image/jpeg');
          if (base64Str) commImage.uri = base64Str;
        } else if (platformActions.app.getPlatform() !== 'web') {
          const filePath = commImage.uri;
          commImage.uri = await storeImagePermanently(filePath, null, 'jpeg');
        }
      })
    );

  if (!comment.id) {
    comment.id = getNewId();
    comment.createdAt = new Date().getTime();
    comment.owner = { id: viewer.id };
  }

  trackComments(COMMENTS_EVENTS.CREATE_NEW_COMMENT, { projectId, parentId, comment });

  return {
    projectId,
    parentId,
    comment,
  };
};

export const saveCommentsLocally = (comments, projectId, parentId, isLocal, _isDeleted) => {
  const isNative = platformActions.app.isNative();
  const localDB = platformActions.localDB.getCementoDB();
  let malformedComments = [];
  const commentsToSave = _.chain(comments)
    .map((comment) => {
      const currCommentParentId = comment.parentId || parentId;
      if (!(comment.id && comment.createdAt && currCommentParentId)) {
        malformedComments.push(comment);
      }
      return removeNilAndEmpty(
        _.assign({}, comment, Boolean(comment.images) && { images: _.values(comment.images) }, {
          projectId,
          parentId: currCommentParentId,
          isDeleted: Boolean(_isDeleted || comment.isDeleted),
          isLocal: Boolean(isLocal),
          projectId_objId: `${projectId}_${comment.id}`,
          createdAt: comment.createdAt || 0,
        })
      );
    })
    .sortBy('createdAt')
    .value();

  if (malformedComments.length > 0) {
    onError({
      level: 'info',
      methodMetaData: {
        args: { projectId, parentId, isLocal, _isDeleted },
        name: 'saveCommentsLocally',
      },
      errorMessage: 'Attempted to save malformed comment(s) to realm',
      errorMetaData: { malformedComments },
    });

    const deleteQuery = isNative
      ? malformedComments.map((comment) => `projectId_objId == "${projectId}_${comment.id}"`).join(' OR ')
      : {
          project_objId: { $in: malformedComments.map((comment) => `${projectId}_${comment.id}`) },
        };

    localDB.unset('comments', deleteQuery);
  }

  localDB.set('comments', commentsToSave);
};

export const retryCommentsUpload = (viewer) => { //TODO: test this function
  const localDB = platformActions.localDB.getCementoDB();
  const localComments = localDB.get('comments', 'isLocal == TRUE');
  trackComments(COMMENTS_EVENTS.RETRY_COMMENTS_UPLOAD, { size: localComments.length });
  return Promise.all(
    _.map(localComments, (comment) => uploadComment(viewer, comment?.projectId, comment?.parentId, comment, true))
  );
};

export function getLocalComments() {
	const localDB = platformActions.localDB.getCementoDB();
	const commentsArrayFromLocalDB = localDB.get('comments', 'isLocal == TRUE');
	return commentsArrayFromLocalDB;
}