import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import ExtraError from '../lib/errors/extraError';
import { getSnapshotData, writeLogOnce } from '../lib/utils/utils';
export const GET_SAFETY_GRADE = 'GET_SAFETY_GRADE';
export const END_SAFETY_GRADE = 'END_SAFETY_GRADE';
export const GET_SAFETY_POINTING_SYSTEM = 'GET_SAFETY_POINTING_SYSTEM';


export function getSafetyGrade(projectId) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      try {
        const viewer = getState().users.viewer;

        const scopeParams = { scope: 'projects', scopeId: projectId };
        const resourceParams = {
          resourceName: 'safety/grade',
          queryParams: { projectId },
        };

        const onData = (data) => {
          writeLogOnce('info', 'getSafetyGrade - listener DB', {
            scope: 'projects',
            scopeId: projectId,
            type: 'safety_grade',
          });
          dispatch({ type: GET_SAFETY_GRADE, payload: { grades: data, projectId }, projectId });
        };

        let res = await getSnapshotData(scopeParams, resourceParams, onData, viewer, true);

        if (res) onData(res);
      } catch (error) {
        throw new ExtraError('getSafetyGrade error', { projectId }, error);
      }
    };

    return {
      type: GET_SAFETY_GRADE,
      payload: getPromise(),
    };
  };
}

export function getSafetyDefaultPointSystem() {
  return () => {
    const getPromise = async () => {
      let ret = {
        openedIssueSeverity3: 10,
        openedIssueSeverity2: 5,

        openedIssueDelaysDaySeverity3: 2,
        openedIssueDelaysDaySeverity2: 1,

        workersInvalidCertification: 10,
        equipmentInvalidCertification: 10,

        maximumDaysBetweenSurveys: 0,
        siteSafetySurveyPointsPerDayOverMaxDays: 0,
      };

      return ret;
    };

    return {
      type: GET_SAFETY_POINTING_SYSTEM,
      payload: getPromise(),
    };
  };
}

export function endSafetyGradeListener(projectId) {
  return () => {
    ClientServerConnectivityManagerInstance.unregisterService({
      scope: 'projects',
      scopeId: projectId,
      subject: 'grades',
    });

    return {
      type: END_SAFETY_GRADE,
      payload: { projectId },
    };
  };
}
