import Skeleton from "react-loading-skeleton";
import theme from "../../../common/app/theme";

const LoadingSkeleton = (props) => {
    const { count , width = '100%', height = 30, style = {} } = props;
    return (
        <div>
            <Skeleton width={width} count={count || 1} height={height} style={{ right: theme.sidebarWidth * 0.05, marginBottom: theme.padding, ...style }}/>
        </div>
    )
}

export default LoadingSkeleton;