import EventEmitter from 'EventEmitter';
import _ from 'lodash';
import { io } from 'socket.io-client';
import { flattenObject, replaceLastOccurrence } from '../../app/funcs';
import { apiListenersDeps, getUtilsConfigObject } from '../../configureMiddleware';
import { getLocalLastUpdates, saveLocalLastUpdates } from '../../lastUpdatesV2/funcs';
import { safeToJS } from '../../permissions/funcs';
import { platformActions } from '../../platformActions';
import { firebaseGet } from '../utils/utils';
import * as ioDisconnectReasons from './disconnectReasons';
import * as ioEvents from './eventTypes';
import { getUniqueServiceId } from '../ClientServerConnectivityManager';
import DataManagerInstance from '../../dataManager/DataManager';

/**
 * 
 * @param {import('socket.io-client').Socket} socket 
 * @param {string} event 
 * @param  {...any} args 
 * @returns
 */
const emitLocalEvent = (socket, event, ...args) => {
  const callbacks = socket?._callbacks?.[`$${event}`];
  callbacks?.forEach?.(callback => callback?.(...args));
}

const SOCKET_RETRY_CONNECT_TIMEOUT = 1000;
export const getServerSocket = (() => {
  /** @type {import('socket.io-client').Socket} */
  let socket = null;
  let lastViewer = null;
  let reconnectTimeOut = null;
  let didInitialConnect = false;

  /**
   * @param {{viewer: { id: string, phoneNumber: string }}} param0
   */
  return (viewer) => {
    if (!viewer) return null;

    viewer = safeToJS(viewer);
    const isDiffViewer = lastViewer?.id !== viewer?.id;
    if (!socket || isDiffViewer) {
      if (socket && isDiffViewer) {
        socket.disconnect();
        socket.removeAllListeners();
        didInitialConnect = false;
      }

      const { apiServer } = getUtilsConfigObject();
      const [token, clientId, phoneNumber] = [viewer.id, platformActions.app.getUniqueID(), viewer.phoneNumber];

      socket = io(apiServer, {
        path: '/events',
        query: { clientId, token, phoneNumber },
        transports: ['websocket'],
      });

      lastViewer = viewer;

      socket.on(ioEvents.DISCONNECT, reason => {
        console.info('Socket:', 'Disconnected. Reason:', reason)
        if (reason === ioDisconnectReasons.SERVER_DISCONNECT) {
          socket.connect();
        }
        // else for other reasons, the socket will automatically try to reconnect
      });

      socket.on(ioEvents.CONNECT, () => {
        if (!didInitialConnect) {
          console.info('Socket:', 'Connected');
          didInitialConnect = true;
        }
        else {
          console.info('Socket:', 'Reconnected');
          emitLocalEvent(socket, ioEvents.RECONNECT);
        }
      });

      socket.on(ioEvents.CONNECT_ERROR, (err) => {
        console.info('Socket Error:', err);
        if (!reconnectTimeOut) {
          reconnectTimeOut = setTimeout(() => {
            console.info('Socket:', 'Trying to reconnect...')
            socket.connect();
            reconnectTimeOut = null;
          }, SOCKET_RETRY_CONNECT_TIMEOUT);
        }
      });
    }

    return socket;
  }
})();

const startFirebaseListener = (path, eventType, callback) => {
  /** @type {{ firebaseDatabase: firebase.default.database }} */
  const { firebaseDatabase } = apiListenersDeps;
  const ref = firebaseDatabase().ref(path);
  ref.on(eventType, callback);

  return () => {
    ref.off(eventType, callback);
  }
}

export const isDiffViewer = (viewer1, viewer2) => {
  return viewer1?.id !== viewer2?.id || viewer1?.adminMode !== viewer2?.adminMode;
}

/**
 * 
 * @param {Partial<import('../ClientServerConnectivityManager').SubscriptionParams>} subscriptionParams 
 */
export const getPathInfoFromSubsParams = (viewer, subscriptionParams, isRevoke = false) => {
  const { scope, scopeId, subject, params } = subscriptionParams;
  const pathRoot = `lastUpdates/v2`;
  const type = isRevoke ? 'revokes' : 'lastUpdates';
  
  const isAdminViewer = viewer?.adminMode > 0;
  let root;
  if (scope === 'global' && isAdminViewer) {
    root = `${pathRoot}/${type}/global`;
  } else if (isAdminViewer && scope !== 'user') {
    root = `${pathRoot}/${type}/${scope}/${scopeId}`;
  } else {
    if (scope === 'companies') {
      root = `${pathRoot}/${type}/${scope}/${scopeId}`;
    } else if (scope === 'global') {
      root = `${pathRoot}/${type}/members/${viewer.id}/global`;
    } else if (scope === 'user') {
      root = `${pathRoot}/${type}/members/${viewer.id}/user`;
    } else {
      root = `${pathRoot}/${type}/members/${viewer.id}/${scope}/${scopeId}`;
    }
  }

  let collectionPathArray = [subject];
  switch (subject) {
    case ('stages'): {
      collectionPathArray = ['checklists', 'stages'];
      break;
    }

    case ('checklists'): {
      collectionPathArray = ['checklists', 'checklists'];
      break;
    }

    case ('checklistItems'): {
      collectionPathArray = ['checklists', 'items'];
      break;
    }

    case ('checklistInstances'): {
      collectionPathArray = ['checklists', 'itemInstances'];
      break;
    }

    case ('properties'): {
      collectionPathArray = ['properties', 'types', params.subjectName];
      break;
    }

    case ('properties/mappings'): {
      collectionPathArray = ['properties', 'mappings', params.subjectName];
      break;
    }

    case ('propertiesInstances'): {
      collectionPathArray = ['properties', 'instances', params.subjectName];
      break;
    }
    
    case ('forms'): {
      collectionPathArray = ['forms', params.formType];
      break;
    }

    case ('reports'): {
      collectionPathArray = ['reports', 'summary'];
      break;
    }

    case ('checklistSubscriptions'): {
      collectionPathArray = ['checklists', 'subscriptions'];
      break;
    }

    case ('permissions'): {
      collectionPathArray = ['permissions', 'v4'];
      break;
    }

    case ('safety/grade'): {
      collectionPathArray = ['safetyGrade']
      break;
    }

    case ('viewer'): {
      collectionPathArray = ['info'];
      break;
    }
    
    default: {
      if (subject.includes('properties/')) {
        collectionPathArray = ['properties', 'types', params.subjectName, subject.replace('properties/', '')];
      }
      break;
    }
  }

  const collectionRoot = collectionPathArray[0];
  const fullPath = [root, collectionRoot].join('/');

  return {
    root,
    collection: collectionPathArray.join('/'),
    collectionRoot,
    full: fullPath,
  };
}

/**
 * 
 * @param {firebase.default.database.DataSnapshot} snapshot 
 */
const getFBRefSnapshotPath = (snapshot) => {
  const fullPathURL = snapshot.ref.toString();
  const snapshotPath = new URL(fullPathURL);
  return decodeURI(snapshotPath.pathname).replace('/', '');
}

export const getClientLastUpdatesIO = (() => {
  /** @type {EventEmitter} */
  let eventHUB = null;
  let activeListeners = {};
  /** @type {Record<string, Record<string, import('../ClientServerConnectivityManager').SubscriptionParams[]>>} */
  let subscriptions = {};
  /** @type {Record<string, boolean>} */
  let didEmitConnectionEsblished = {};
  /**
   * 
   * @param {import('../ClientServerConnectivityManager').SubscriptionParams} sub 
   * @returns 
   */
  const subIsBindedToLastUpdates = (sub) => !sub.uniqueKey;
  /**
   * 
   * @param {string} snapshotRootPath 
   * @param {string} fullCollectionStr 
   * @returns 
   */
  const getSubscriptions = (snapshotRootPath, fullCollectionStr) => {
    return _.get(subscriptions, [snapshotRootPath, fullCollectionStr]);
  }
  /**
   * 
   * @param {string} snapshotRootPath
   * @param {string} fullCollectionStr
   * @param {import('../ClientServerConnectivityManager').SubscriptionParams} subscriptionParams
   * @returns
   */
  const setSubscription = (snapshotRootPath, fullCollectionStr, subscriptionParams) => {
    const currSubscriptions = getSubscriptions(snapshotRootPath, fullCollectionStr) || [];
    currSubscriptions.push(subscriptionParams);
    _.set(subscriptions, [snapshotRootPath, fullCollectionStr], currSubscriptions);
  }

  /**
   * 
   * @param {string} snapshotRootPath
   * @param {string} fullCollectionStr
   * @param {import('../ClientServerConnectivityManager').SubscriptionParams} subscriptionParams
   * @returns
   */
  const unsetSubscription = (snapshotRootPath, fullCollectionStr, subscriptionParams) => {
    const uniqueSubscriptionId = getUniqueServiceId(subscriptionParams);
    const currSubscriptions = getSubscriptions(snapshotRootPath, fullCollectionStr) || [];
    const newSubscriptions = currSubscriptions?.filter(sub => getUniqueServiceId(sub) !== uniqueSubscriptionId);
    if (newSubscriptions.length === 0) {
      delete subscriptions[snapshotRootPath]?.[fullCollectionStr];
    } else {
      _.set(
        subscriptions, 
        [snapshotRootPath, fullCollectionStr], 
        newSubscriptions
      );
    }

    return newSubscriptions;
  }
  /**
   * @type {Record<'lastUpdates' | 'revokes', Record<string, Record<string, number>>>}
   */
  let receivedLastUpdateAvailable = {};
  let debouncedHandleFBChangeByFullPathMap = {};


  const eventTypes = ['child_added', 'child_changed'];
  window.__serverSocketStuff = {
    get eventHUB() { return eventHUB; },
    activeListeners,
    subscriptions,
    didEmitConnectionEsblished,
    receivedLastUpdateAvailable,
    debouncedHandleFBChangeByFullPathMap,
  }

  return () => {
    if (!eventHUB) {
      eventHUB = new EventEmitter();
      const handleFBChange = (snapshot) => {
        const snapshotFullPath = getFBRefSnapshotPath(snapshot);
        const collectionRoot = snapshot.key;
        const snapshotRootPath = replaceLastOccurrence(snapshotFullPath, `/${collectionRoot}`, '');
        const isRevoke = snapshotRootPath.includes('revokes');
        const type = isRevoke ? 'revokes' : 'lastUpdates';
        let flatSnapshotVal = flattenObject(snapshot.val() || {});
        flatSnapshotVal = _.mapKeys(flatSnapshotVal, (v, key) => key === 'lastUpdateTS' ? '' : key.replace('/lastUpdateTS', ''));

        Object.entries(flatSnapshotVal).forEach(([restCollectionPath, nextLastUpdateAvailable]) => {
          if (!nextLastUpdateAvailable) return;

          const collection = _.compact([collectionRoot, restCollectionPath]).join('/');
          const subjectSubscriptions = getSubscriptions(snapshotRootPath, collection);
          subjectSubscriptions?.forEach(async (subscriptionParams) => {
            const { scope } = subscriptionParams;
            const isBindedToLastUpdates = subIsBindedToLastUpdates(subscriptionParams);
            const scopeId = (scope === 'global' || scope === 'user') ? 'global' : subscriptionParams.scopeId;
            let shouldEmit = !isBindedToLastUpdates || (_.get(receivedLastUpdateAvailable, [snapshotFullPath, collection]) !== nextLastUpdateAvailable);
            if (isBindedToLastUpdates && shouldEmit) {
              _.set(receivedLastUpdateAvailable, [snapshotFullPath, collection], nextLastUpdateAvailable);
            }
            
            if (isBindedToLastUpdates) {
              let lastUpdateDocScopeId = scopeId;
              let lastUpdateDocSubject = collection;
              // Users are fetched per-project but stored globally on a global map on the reducer
              // Set scopeId to 'global' for users collection to properly track last updates docs
              // Also, the subject of the last updates doc is 'members' since its saved on the members' reducer
              // Yeah I know, its confusing, but its how it is
              if (collection === 'users') {
                lastUpdateDocScopeId = 'global';
                lastUpdateDocSubject = 'members';
              }
              const doc = getLocalLastUpdates({ type, scopeId: lastUpdateDocScopeId, subject: lastUpdateDocSubject })?.[0];
              const currLastUpdateAvailable = doc?.lastUpdateAvailable;
              if (currLastUpdateAvailable !== nextLastUpdateAvailable) {
                await saveLocalLastUpdates(type, lastUpdateDocScopeId, lastUpdateDocSubject, { lastUpdateAvailable: nextLastUpdateAvailable });
              }
    
              const currDataVersion = doc?.lastUpdated;
              shouldEmit = shouldEmit && currDataVersion !== nextLastUpdateAvailable;
              
              if (!isRevoke) {
                const uniqueServiceId = getUniqueServiceId(subscriptionParams);
                if (!didEmitConnectionEsblished[uniqueServiceId]) {
                  didEmitConnectionEsblished[uniqueServiceId] = true;
                  // This check is only in the case that we should have fetched the data and saved it to local db already, but for whatever reason it didn't happen because the save in the reducer is deffered, so we need to fetch it again so we can save it
                  // The lastSavedUpdate will be changed to the currDataVersion once the save has happened
                  if (!shouldEmit && doc.lastSavedUpdate !== currDataVersion) {
                    shouldEmit = true;
                  }
                  eventHUB.emit(ioEvents.CONNECTION_ESTABLISHED, subscriptionParams, shouldEmit);
                }
              }
            } else {
              if (!isRevoke) {
                const uniqueServiceId = getUniqueServiceId(subscriptionParams);
                if (!didEmitConnectionEsblished[uniqueServiceId]) {
                  didEmitConnectionEsblished[uniqueServiceId] = true;
                  eventHUB.emit(ioEvents.CONNECTION_ESTABLISHED, subscriptionParams, shouldEmit);
                }
              }
            }

            if (shouldEmit) {
              eventHUB.emit(ioEvents.CHANGE, { attributes: subscriptionParams, data: { isRevoke } });
            } else if (isBindedToLastUpdates) {
              DataManagerInstance.setDataReady({ scope, scopeId , subject: subscriptionParams.subject, subjectParams: subscriptionParams.params });
            }
          });
        });
      }

      const handleSubscribe = (viewer, subscriptionParams) => {
        const uniqueSubscriptionId = getUniqueServiceId(subscriptionParams);
        [true, false].forEach(isRevoke => {
          const { root, collection, full: fullPath } = getPathInfoFromSubsParams(viewer, subscriptionParams, isRevoke);
          const subjectSubscriptions = getSubscriptions(root, collection);
          const isBindedToLastUpdates = subIsBindedToLastUpdates(subscriptionParams);
          if (
            (!isBindedToLastUpdates && isRevoke) ||
            subjectSubscriptions?.some(sub => getUniqueServiceId(sub) === uniqueSubscriptionId)
          ) {
            return;
          }

          setSubscription(root, collection, subscriptionParams);

          if (!activeListeners[root]) {
            const listeners = eventTypes.map(eventType => {
              return startFirebaseListener(root, eventType, handleFBChange);
            });
  
            const endListeners = () => {
              listeners.forEach(endListener => endListener());
            }
  
            activeListeners[root] = endListeners;
          } else {
            debouncedHandleFBChangeByFullPathMap[fullPath] = debouncedHandleFBChangeByFullPathMap[fullPath] || _.debounce(handleFBChange, 3000);
            firebaseGet(fullPath, true).then(debouncedHandleFBChangeByFullPathMap[fullPath]);
          }
        });
      }

      const handleUnsubscribe = (viewer, subscriptionParams) => {
        [true, false].forEach(isRevoke => {
          const { root, collection, full: fullPath } = getPathInfoFromSubsParams(viewer, subscriptionParams, isRevoke);
          if (!isRevoke) {
            delete didEmitConnectionEsblished[getUniqueServiceId(subscriptionParams)];
          }

          const newSubscriptions = unsetSubscription(root, collection, subscriptionParams);
          if (!newSubscriptions.length) {
            delete receivedLastUpdateAvailable[fullPath];
            delete debouncedHandleFBChangeByFullPathMap[fullPath];
          }

          if (_.size(subscriptions[root]) === 0) {
            activeListeners[root]?.();
            delete subscriptions[root];
            delete activeListeners[root];
          }
        });
      };

      eventHUB.on(ioEvents.SUBSCRIBE, handleSubscribe);
      eventHUB.on(ioEvents.UNSUBSCRIBE, handleUnsubscribe);
    }

    return eventHUB;
  }
})()