import { batchDispatch } from '../app/funcs';
import * as formsActions from '../forms/actions';
import { getSnapshotData } from '../lib/utils/utils';
import { configurationEvents } from './configurationEvents';

export async function getConfigurations(scope, scopeId, viewer) {
  const scopeParams = { scope, scopeId };
  const resourceParams = {
    resourceName: 'configurations',
  };

  const onData = (data) => {
    if (!data) return;
    
    batchDispatch([
      {
        type: configurationEvents.GET_CONFIGURATIONS,
        payload: { scopeType: scope, scopeId, configurations: data, immediateGlobalStorageSave: scope === 'global' },
      },
      formsActions.locallyPopulateFormsTemplates({ scopeId, formsTemplates: data?.forms }),
    ]);
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer, true);
  if (result) {
    onData(result);
  }

  return { scope, scopeId };
}
