import ArrayComponentHOC from '../../../common/propertiesTypes/ArrayComponentHOC';
import _ from 'lodash';
import NoItemsFound from './NoItemsFound';
import theme from '../../assets/css/theme';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import MultiCheckSelect from './MultiCheckSelect';
import reportsMessages from '../../../common/reports/reportsMessages';
import {isEmptyValue, optionsToText} from '../../../common/app/funcs';
import FunctionalInput from '../../../common/app/components/FunctionalInput';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import React, { useCallback } from 'react';
import CardContainer, {CardContainerRow} from './CardContainer';
import FocusManager from '../FocusManager';
import Text from './Text';
import companiesMessages from '../../../common/companies/companiesMessages';
import {injectIntl} from 'react-intl';
import ComponentActionButtons from './ComponentActionButtons';
import CementoReactFragment from '../../../common/app/components/CementoReactFragment';
import useUniqueId from '../../../common/hooks/useUniqueId';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { startToast } from '../../../common/app/actions';
import LoadingSkeleton from '../Loading/LoadingSkeleton';
import syncedIcon from '../../assets/img/icons/synced.svg';
import safetyMessages from '../../../common/safety/safetyMessages';

const SELECT_COMPANIES_INNER_VALUE_ID = '-selectCompanies';

/**
 * @typedef {import('../../../common/propertiesTypes/ComplexComponentHOC').ArrayComponentValue} Value
 * @typedef ArrayComponentProps
 * @property {string} title
 * @property {Value} value
 * @property {string} propId
 * @property {string} subjectName
 * @property {(newValue: Value | null) => void} [onChange]
 * @property {onCardClickFunc} [onCardClick]
 * @property {boolean} [isDisabled]
 * @property {(component: React.Component, this: object) => void} [onRef]
 * @property {boolean} [isExpanded]
 * @property {string} [innerValueId]
 *
 *
 * @param {ArrayComponentProps} props
 * @returns
 */

let ArrayComponent = (props) => {
  const {
    value, onChange, propId,
    subjectName, isDisabled, isExpanded,
    onCardClick, innerValueId, intl, settings,
    startToast, context: propsContext,
    apiSourceMetadata, isSynced, loadingMap = {},
    setLoading, isLoading, prevFormDataLoaded,
    isSafetyPermitted, hideCopyFromPreviousDailyButton
  } = props;
  const uniqueComponentId = useUniqueId();

  let propTitle = props.title;

  const handleCopyFromPreviousDisabledClick = useCallback(() => {
    startToast({title: reportsMessages.toast.disabledCopyFromPreviousDaily, type: "warning"});
  }, [startToast]);

  return (
    <ArrayComponentHOC onChange={onChange} propId={propId} value={value} subjectName={subjectName} isDisabled={isDisabled} isExpanded={isExpanded} settings={settings} isSynced={isSynced} >
      {(renderProps) => {
        const { prop, handleAddTypeRow, handleInnerValueChange, sortedValuesArray, innerProps, isNothingToDisplay, isArrayOfArrays, arrayNameProperty } = renderProps;
        const { lastAddedDataId, counterCount, counterLabel, handleRemoveTypeRow, getNameInputProps, isLastInnerValueHasData, getValuesFromProp, getApiSourceValue, getIsCopyFromPreviousDailyEnabled } = renderProps;
        const { setValueFromOptionalDefaultValue, getInnerInputSettings } = renderProps;

        const cardContainerId = _.get(prop, 'id', '') + propTitle;

        const valuesArray = sortedValuesArray || [];

        if (isNothingToDisplay)
          return null;

        if (isLoading) 
          return <LoadingSkeleton style={{ marginBottom: theme.margin * 2 }} count={valuesArray.length} height={150}/>

        if (isDisabled && isArrayOfArrays && !valuesArray.length)
          return <NoItemsFound compStyles={{ height: theme.headerHeight }} onClick={() => onCardClick && onCardClick()} />;

        const isInnerValueMode = Boolean(innerValueId);
        const isSelectCompaniesMode = Boolean(innerValueId === SELECT_COMPANIES_INNER_VALUE_ID);
        const isCopyFromPreviousDailyEnabled = getIsCopyFromPreviousDailyEnabled(propsContext, valuesArray, apiSourceMetadata);

        let innerInputs;
        if (isArrayOfArrays && isInnerValueMode && isSelectCompaniesMode) {
          propTitle = arrayNameProperty.getCementoTitle();
          const { handleDone, value: namePropValue } = getNameInputProps(); // TODO: handle other types than selectionList
          if (arrayNameProperty.type === propertyTypes.SELECTION_LIST && !arrayNameProperty.isCreatable) {
            const items = getValuesFromProp(arrayNameProperty, namePropValue, apiSourceMetadata)

            const aditionalSelectButtons = hideCopyFromPreviousDailyButton ? [] : [{
              onClick: setValueFromOptionalDefaultValue,
              title: reportsMessages.buttons.copyFromPreviousDaily,
              style: { fontWeight: 'bold' },
              isDisabled: !isCopyFromPreviousDailyEnabled
            }];

            innerInputs = (
              <MultiCheckSelect
                key={'NamePropertyInput' + prop.id}
                titlePropPath={['getTitle']}
                items={items}
                height={'65vh'}
                disabledHoverText={intl.formatMessage(propertiesMessages.disabledApiSource)}
                onChange={(fullMap, allChecked, allUnchecked) => handleDone(Object.values(allChecked || {}).reduce((acc, curr) => curr.checked ? _.set(acc, [curr.id], curr.id) : acc, {}))}
                aditionalSelectButtons={aditionalSelectButtons}
              />
            );
          }
          else
            innerInputs = (
              <FunctionalInput
                noTitle
                propId={arrayNameProperty.id}
                subjectName={subjectName}
                disabled={isDisabled}
                value={namePropValue}
                onChange={(propId, newValue) => handleDone(newValue)}
                apiSourceMetadata={apiSourceMetadata}
              />
            );
        }
        else {
          innerInputs = (valuesArray).map((value, index) => {
            const { id: valueId, propId, data, title: innerTitle } = value;

            const innerProp = _.get(innerProps, [propId]);
            if (!innerProp || (isInnerValueMode && valueId !== innerValueId))
              return null;

            const key = `array_complexe_${uniqueComponentId}-${propId}_${valueId}`;
            const componentTitle = arrayNameProperty.type === propertyTypes.SELECTION_LIST
              ? optionsToText({ data: innerTitle, intl, options: arrayNameProperty.values })
              : (innerTitle || intl.formatMessage(propertiesMessages.itemWithNumber, { number: index + 1 }));

            const ContainerComponent = (isArrayOfArrays || isExpanded) ? CementoReactFragment : CardContainerRow;
            const inputSettings = getInnerInputSettings(valueId);

            const context = Object.assign({}, propsContext, {
              parentPropIds: [...(propsContext.parentPropIds || []), props.propId],
              extra: { ...propsContext?.extra, valueIds: [...(propsContext.extra?.valueIds || []), valueId] },
            })

            const companyId = context.companyId || _.keys(innerTitle)[0];
            if (companyId) context.companyId = companyId;

            const arrayPath = `${context.parentPropIds?.[0]}/${context.extra?.valueIds?.[0]}`;
            const path = _.keys(loadingMap).find(p => p.includes(arrayPath))
            const isLoadingInnerProp = loadingMap[path];

            if (isLoading || isLoadingInnerProp) return <LoadingSkeleton style={{ marginBottom: theme.margin * 2 }}  key={key} height={150} />

            return (
              <FocusManager.Element
                key={key}
                focusElementOnMount={isExpanded && valueId === lastAddedDataId}
                elementId={valueId}
                parentElementId={cardContainerId}>
                {(focusProps) => (
                  <ContainerComponent mainContainerStyle={{ padding: 0 }} isSelected={focusProps.isFocus}>
                    <FunctionalInput
                      subjectName={subjectName}
                      propId={propId}
                      values={{ [propId]: data }}
                      onChange={(propId, value, status, context) => handleInnerValueChange(valueId, value, context)}
                      disabled={isDisabled}
                      isExpandSummary={isExpanded}
                      context={context}
                      settings={inputSettings}
                      noTitle={isArrayOfArrays || isExpanded || innerProp.UIStruct}
                      onCardClick={() => onCardClick?.(isArrayOfArrays ? valueId : null)}
                      containerStyle={Object.assign(
                        {},
                        (isExpanded || isArrayOfArrays) && { marginBottom: theme.margin * 2 },
                        innerProp.UIStruct && { padding: 0 }
                      )}
                      title={componentTitle}
                      onDeleteClick={() => handleRemoveTypeRow(valueId)}
                      isInFocus={focusProps.isFocus}
                      apiSourceMetadata={apiSourceMetadata}
                      isUnsyncable={index !== 0}
                      isLoading={isLoading}
                      setLoading={setLoading}
                      loadingMap={loadingMap}
                      prevFormDataLoaded={prevFormDataLoaded}
                      hideCopyFromPreviousDailyButton={hideCopyFromPreviousDailyButton}
                    />
                  </ContainerComponent>
                )}
              </FocusManager.Element>
            );
          }).filter(Boolean);
        }

        /*
        || ============================ ||
        ||            RENDER            ||
        || ============================ ||
        */

        const titleComponent = Boolean(isExpanded) && <Text style={{ fontSize: theme.fontSizeH4, fontWeight: 'bold', alignSelf: 'flex-start', marginBottom: theme.margin * 2 }}>{propTitle}</Text>;

        if (isInnerValueMode)
          return (
            <>
              {Boolean(propTitle !== props.title) && titleComponent}
              {innerInputs}
            </>
          );


        if (isExpanded || isArrayOfArrays) {
          const handleAddClick = () => {
            if (isInnerValueMode || !isArrayOfArrays) {
              if (handleAddTypeRow) handleAddTypeRow();
            }
            else if (onCardClick)
              onCardClick(SELECT_COMPANIES_INNER_VALUE_ID);
          };

          const apiSourceValue = getApiSourceValue(propsContext, apiSourceMetadata, '-companyJobNumberOfWorkers');

          const actionButtons = hideCopyFromPreviousDailyButton ? [] : [ {
            type: 'select',
            isDisabled: !isCopyFromPreviousDailyEnabled,
            title: reportsMessages.buttons.copyFromPreviousDaily,
            onClick: setValueFromOptionalDefaultValue,
            disabledOnClick: handleCopyFromPreviousDisabledClick,
          }];

          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {titleComponent}
              {!isEmptyValue(apiSourceValue) && isSafetyPermitted && isExpanded && <div style={{
                display:'flex',
                alignSelf: 'flex-start',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: theme.margin,
                marginBottom: theme.margin,
                width: '100%',
                borderBottom: `1px solid ${theme.oldHeaderInfoText}`, 
              }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={syncedIcon}/>
                  <Text style={{ paddingInline: theme.padding, fontSize: theme.fontSizeH6, fontWeight: 600 }}>{intl.formatMessage(propertiesMessages.identified)}</Text>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Text style={{ fontSize: theme.fontSizeH4 }}>{apiSourceValue}</Text>
                  <Text style={{ color: theme.grayColor }}>{intl.formatMessage(safetyMessages.employees)}</Text>
                </div>
              </div>}
              <div style={{ width: '100%' }}>
                {Boolean(valuesArray.length) && innerInputs}
                {Boolean(!isDisabled) && (
                  <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
                    <ComponentActionButtons
                      isBoxed={Boolean(!valuesArray.length)}
                      buttons={[
                        {
                          type: isArrayOfArrays ? 'select' : 'add',
                          isDisabled: Boolean(isExpanded && (sortedValuesArray.length && !isLastInnerValueHasData)),
                          title: prop.businessType === propertyTypes.BUSINESS_TYPES.companies ? companiesMessages.addContractors : propertiesMessages.add,
                          onClick: handleAddClick
                        },
                      ]}
                    />
                    {Boolean(!isArrayOfArrays && isExpanded) && (
                      <ComponentActionButtons
                        isBoxed={Boolean(!valuesArray.length)}
                        buttons={actionButtons}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }

        return (
          <FocusManager.Element elementId={cardContainerId}>
            {focusProps => (
              <CardContainer
                title={propTitle}
                onClick={() => onCardClick && onCardClick()}
                counterLabel={counterLabel}
                counterCount={counterCount}
                mainContainerStyle={{ marginBottom: 0, cursor: 'pointer' }}
                isSelected={focusProps.isFocus}
                isFocused={focusProps.isFocusWithin}
              >
                {innerInputs}
              </CardContainer>
            )}
          </FocusManager.Element>
        );
      }}
    </ArrayComponentHOC>
  );
};

const enhance = compose(
	injectIntl,
	connect(null, {startToast})
);

ArrayComponent = enhance(ArrayComponent);

export default ArrayComponent;