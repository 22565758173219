import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import withStyles from "@material-ui/core/styles/withStyles";
import { ProjectContext } from "../../../common/projects/contexts";

import User from "../../components/CementoComponents/User";
import { Card, CardHeader, CardBody } from "../../components";
import Image from "../../components/CementoComponents/Image";
import issuesMessages from "../../../common/issues/issuesMessages";
import {
  getFullPostTitle,
  getPostPrefixData,
  getPostsPrefixProp,
} from "../../../common/posts/funcs";
import Text from "../../components/CementoComponents/Text";
import theme from "../../assets/css/theme";
import PostHeader from "./PostHeader";
import PdfIcon from "../../assets/img/icons/pdf.png";
import _ from "lodash";
import { instanceDataToString } from "../../../common/propertiesInstances/funcs";

class MinPost extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.getImagesArray = this.getImagesArray.bind(this);

    let images = this.getImagesArray(props, "images");
    let attachments = this.getImagesArray(props, "attachments");
    this.state = { images, attachments };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  onClick() {
    const { onSelect, post } = this.props;
    if (onSelect && post) onSelect(post);
  }

  getImagesArray(nextProps, propType) {
    let images = [];
    if (nextProps.post) images = Object.values(nextProps.post[propType] || {});
    if (images.length == 0) return null;

    return images
      .filter(curr => !curr?.isDeleted)
      .map(curr => curr?.uri);
  }

  setComponentData(props, nextProps) {
    const { intl } = nextProps;

    let newStateChanges = {};
    newStateChanges.story = [];

    const isDifferentPost = props.post != nextProps.post;

    if (isDifferentPost) {
      newStateChanges.images = this.getImagesArray(nextProps, "images");
      newStateChanges.attachments = this.getImagesArray(
        nextProps,
        "attachments"
      );
    }

    const nextPostsPropertyTypes = _.get(
      nextProps,
      ["propertiesTypes", "postsInfo"],
      {}
    );
    const currentPostsPropertyTypes = _.get(
      props,
      ["propertiesTypes", "postsInfo"],
      {}
    );
    const isDifferentPostsPropertyTypes =
      nextPostsPropertyTypes !== currentPostsPropertyTypes;

    const nextViewsConfigurations = _.get(nextProps, [
      "viewsConfigurations",
      "base",
    ]);
    const currentViewsConfigurations = _.get(props, [
      "viewsConfigurations",
      "base",
    ]);
    const isDifferentViewsConfigurations = !_.isEqual(
      currentViewsConfigurations,
      nextViewsConfigurations
    );

    if (
      isDifferentPost ||
      isDifferentPostsPropertyTypes ||
      isDifferentViewsConfigurations
    ) {
      const postPrefixProp =
        getPostsPrefixProp(nextPostsPropertyTypes, nextViewsConfigurations) ||
        {};
      const instance = _.get(nextProps.post, ["instances", postPrefixProp.id]);
      const postPrefix = getPostPrefixData(intl, postPrefixProp, instance);
      if (postPrefix) newStateChanges.postPrefix = postPrefix;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const { post, style, classes, viewMode, rtl, isSelected, postHeight } =
      this.props;
    const { images, attachments, postPrefix } = this.state;

    if (!post) return null;

    let height = (postHeight || 195) - (viewMode != "lines" ? 20 : 0);

    const postTitle = getFullPostTitle(post.title, postPrefix, " | ");

    let defaultContainerStyle = {
      cursor: Boolean(this.props.onSelect) ? "pointer" : "unset",
      "&:hover": {
        boxShadow: "0px 0px 20px 2px rgba(0, 0, 0, 0.15)",
        backgroundColor: theme.backgroundColorSelected,
      },
      height: height,
      overflow: "hidden",
      backgroundColor: isSelected
        ? theme.backgroundColorSelected
        : theme.backgroundColorBright,
    };
    let postHeaderHeight = 40;

    if (viewMode != "lines") {
      let blocksContainerStyle = {
        border: isSelected
          ? "2px solid " + theme.brandPrimary
          : theme.borderLineNeutralLight + "30",
        borderRadius: rtl ? "6px 0px 6px 6px" : "0 6px 6px",
        boxShadow: isSelected ? "0px 0px 20px 2px rgba(0, 0, 0, 0.15)" : "none",
      };
      return (
        <Card
          data-qa="post-card"
          plain
          onClick={this.onClick}
          style={
            style ||
            Object.assign({}, defaultContainerStyle, blocksContainerStyle)
          }
        >
          <CardHeader
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: postHeaderHeight,
              padding: theme.padding,
              borderBottom: theme.borderLineNeutralLight + "30",
            }}
          >
            <PostHeader post={post} />
          </CardHeader>

          <CardBody
            style={{
              display: "flex",
              height: height - postHeaderHeight,
              padding: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: theme.paddingSize,
                paddingBottom: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <div style={{ [rtl ? "marginLeft" : "marginRight"]: 10 }}>
                  <p
                    data-qa="post-title"
                    className={classes.p}
                    style={{
                      ...theme.defaultFont,
                      fontWeight: theme.bold,
                      color: theme.brandNeutralDark,
                      maxHeight: 40,
                    }}
                  >
                    {postTitle}
                  </p>
                  {/* <Text linksMode={'convert'} tagsMode={true} >{'<<h1>>ריצוף1<<h1>><<h2>>קומת קרקע1<<h2>>לובי קומת קרקע - ריצוף  ג.פ. 90/15 סמ דגם   מק"ט-AT19042<<br>>302.542 מ"ר<<br>>מודי<<-->><<h2>>קומת קרקע2<<h2>> סטורם אפור מט GRIS מק"ט-7410012101<<br>>171.002 מ"ר<<br>>מודי<<-->><<h1>>ריצוף2<<h1>><<h2>>קומת קרקע1<<h2>> סטורם אפור מט GRIS מק"ט-7410012101<<br>>171.002 מ"ר<<br>>מודי<<-->>'}</Text> */}
                </div>
                {Boolean(post.isIssue) && (
                  <div>
                    <Text
                      className={classes.p}
                      style={{
                        ...theme.subFont,
                        marginBottom: 3,
                        marginTop: 3,
                      }}
                    >
                      {issuesMessages.assignToTitle}
                    </Text>
                    <User
                      avatarSize={25}
                      companyMode={true}
                      detailedOverflow={!images}
                      userId={post.getNested(["assignTo", "id"])}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                width: height - postHeaderHeight,
                [rtl ? "borderRight" : "borderLeft"]:
                  images && !attachments
                    ? null
                    : theme.borderLineNeutralLight + "30",
              }}
            >
              {Boolean(attachments && attachments.length) ? (
                <img
                  src={PdfIcon}
                  style={{ width: "60%", alignSelf: "center" }}
                />
              ) : (
                <Image src={images ? images[0] : null} />
              )}
            </div>
          </CardBody>
        </Card>
      );
    } else if (viewMode == "lines") {
      let linesContainerStyle = {
        borderTop: isSelected ? "1px solid " + theme.brandPrimary : "none",
        borderBottom: isSelected
          ? "1px solid " + theme.brandPrimary
          : theme.borderLineNeutralLight + "30",
        borderRadius: "0px",
        padding: isSelected
          ? theme.paddingSize + "px " + 2 * theme.paddingSize + "px"
          : theme.paddingSize + "px 0px",
        margin: isSelected ? 0 : "0px " + 2 * theme.paddingSize + "px",
      };
      return (
        <div
          onClick={this.onClick}
          style={
            style ||
            Object.assign({}, defaultContainerStyle, linesContainerStyle)
          }
        >
          <div style={{ display: "flex", padding: 0 }}>
            <div style={{ display: "flex", flex: 11 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 80,
                  border:
                    images && !attachments
                      ? null
                      : theme.borderLineNeutralLight + "30",
                }}
              >
                {Boolean(attachments && attachments.length) ? (
                  <img
                    src={PdfIcon}
                    style={{ width: "60%", alignSelf: "center" }}
                  />
                ) : (
                  <Image src={images ? images[0] : null} />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  [rtl ? "paddingRight" : "paddingLeft"]: theme.paddingSize,
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <div
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <PostHeader post={post} />
                  </div>
                  <div style={{ [rtl ? "marginLeft" : "marginRight"]: 10 }}>
                    <p
                      className={classes.p}
                      style={{
                        ...theme.defaultFont,
                        fontWeight: theme.bold,
                        color: theme.brandNeutralDark,
                        maxHeight: 60,
                      }}
                    >
                      {postTitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {Boolean(post.isIssue) && (
                <div>
                  <Text
                    className={classes.p}
                    style={{ ...theme.subFont, marginBottom: 3, marginTop: 3 }}
                  >
                    {issuesMessages.assignToTitle}
                  </Text>
                  <User
                    avatarSize={25}
                    companyMode={true}
                    detailedOverflow={!images}
                    userId={post.getNested(["assignTo", "id"])}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

let styles = {
  p: {
    overflow: "hidden",
    fontSize: "0.8vw",
    textOverflow: "ellipsis",
    margin: "0px",
    whiteSpace: "wrap",
  },
};

MinPost = injectIntl(MinPost);
MinPost = withStyles(styles)(MinPost);
const MinPostEnhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({
    rtl: state.app.rtl,
  }))
);

export default MinPostEnhance(MinPost);
