import { startLoading } from '../app/actions';
import systemMessages from '../app/systemMessages';
import { removeProjectsStorage } from '../projects/actions';
import { upsertForm } from '../forms/funcs';
import _ from 'lodash';
import { getNewId, updateUsingFirebaseProxy } from '../lib/api';

export const GET_CHECKLISTS = 'GET_CHECKLISTS';
export const GET_CHECKLISTS_SUBSCRIPTION = 'GET_CHECKLISTS_SUBSCRIPTION';
export const UPDATE_CHECKLISTS_SUBSCRIPTION = 'UPDATE_CHECKLISTS_SUBSCRIPTION';
export const SAVE_CHECKLISTS_FORM = 'SAVE_CHECKLISTS_FORM';

export const EXPORT_CHECKLIST_AS_PDF = 'EXPORT_CHECKLIST_AS_PDF';
export const GET_CHECKLIST_STATUS_JSON = 'GET_CHECKLIST_STATUS_JSON';
export const CLEAR_CHECKLIST_STATUS_JSON = 'CLEAR_CHECKLIST_STATUS_JSON';
export const DUPLICATE_CHECKLIST = 'DUPLICATE_CHECKLIST';
export const EDIT_CHECKLIST_EXTRA_DATA = 'EDIT_CHECKLIST_EXTRA_DATA';
export const CLEAN_CHECKLISTS_CACHE_DATA = 'CLEAN_CHECKLISTS_CACHE_DATA';
export const CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES = 'CLEAN_ALL_CACHED_CHECKLIST_ITEMS_AND_INSTANCES';

/**
 * @typedef {{ id: string }} LocationValue
 * @typedef Checklist
 * @property {string} id
 * @property {string} description
 * @property {{ units?: LocationValue, floor?: LocationValue, building?: LocationValue }} locations
 * @property {number} ordinalNo
 * @property {string} [contentType]
 * @property {string} [tags]
 * @property {string} [type]
 * @property {string} stage - Name of the stage
 * @property {string} [stageId]
 * @property {boolean} [duplicatable]
 * @property {string} [originChecklist] - Id of the original checklist from which it was duplicated
 * @property {number} createdTS
 * @property {boolean} [enableDistributionList]
 * @property {number} [duplicationNo]
 * @property {boolean} [isDeleted]
 */

export function escapeForbiddenKeys(email) {
  return email.replace("/", "").replace(".", "").replace("#", "").replace("$", "").replace("[", "").replace("]", "").replace("]", "");
}

export function saveChecklistForm(viewer, selectedProjectId, checklistId, location, formTemplate, signatures, formBusinessType) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      let localDate = new Date().getTime();

      let checklists = null;
      if (!formTemplate || !formTemplate.getNested(['checklists', 'preDefined', checklistId]))
        checklists = { 'random': { [checklistId]: true } };
      
      let updatedReport = {
        createdAt: localDate,
        isDocx: Boolean(_.get(formTemplate, ['uri'])),
        signatures,
        checklists,
        location: _.pickBy(location, i => i != '_'),
        formTemplateId: formTemplate.id,
        type: formBusinessType
      };

      const upsertFormRet = await upsertForm(selectedProjectId, viewer, updatedReport);
      const { form } = upsertFormRet;

      return _.clone(form);
    };

    return {
      type: SAVE_CHECKLISTS_FORM,
      payload: getPromise()
    };
  };
}

export function updateChecklistsSubscriptions(projectId, buildingId, stageId, assignToList, emailList, subscriptionKey) {
  return ({ removeEmpty }) => {
    const getPromise = async () => {
      if (projectId) {
        var pushKey = subscriptionKey || getNewId();
        let currItem = {
          id: pushKey,
          projectId,
          buildingId,
          stageId,
          members: assignToList
        };
        let validEmailList = {};
        Object.keys(emailList || {}).forEach(eKey => {
          validEmailList[escapeForbiddenKeys(eKey)] = emailList[eKey];
        });
        currItem.emails = validEmailList;

        let dbUpdates = {};
        dbUpdates[`checklists/${projectId}/subscriptions/${pushKey}`] = removeEmpty(currItem, 'updateChecklistsSubscriptions');
        await updateUsingFirebaseProxy({projectId, type: 'checklistsSubscriptions', updates: dbUpdates });
        let subscriptions = { [currItem.id]: currItem };
        return { projectId, subscriptions, updateOnly: true };
      }
    };

    return {
      type: UPDATE_CHECKLISTS_SUBSCRIPTION,
      payload: getPromise()
    };
  };
}

export function clearChecklistStatusReport(projectId) {
  return {
    type: CLEAR_CHECKLIST_STATUS_JSON,
    payload: { projectId }
  };
}

export function getChecklistStatusReport(projectId, checklistId, checklistItemId, viewer, toDate) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      var jsonToRet = null;
      var success = true;
      try {
        dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));

        var resp = await (platformActions.net.fetch(apiServer + '/v1/services/checklists/checklistProgressStatus', {
          'method': 'POST',
          'body': JSON.stringify({
            "projectId": projectId,
            "checklistId": checklistId,
            "checklistItemId": checklistItemId,
          })
        }));

        jsonToRet = await (resp.getJson());
      }
      catch (error) {
        console.error(error);
        success = false;
      }
      finally {
        dispatch({ type: GET_CHECKLIST_STATUS_JSON, payload: { success: success, projectId: projectId, viewer } });
      }

      return { reportJson: jsonToRet, projectId: projectId, checklistId, checklistItemId };
    };
    return {
      type: GET_CHECKLIST_STATUS_JSON,
      payload: getPromise()
    };
  };
}

export function duplicateChecklist(projectId, checklistId, locationType, locationId, extraInfo) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      let success = true;
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
      let newData;

      try {
        let resp = await platformActions.net.fetch(`${apiServer}/v1/checklists`, {
          method: 'POST',
          body: JSON.stringify({
            source: checklistId,
            projectId,
            locationType,
            locationId,
            extraInfo
          }),
        });

        newData = await resp.getJson();

      }
      catch (error) {
        console.error(error);
        success = false;
      }
      finally {
        dispatch({ type: DUPLICATE_CHECKLIST, payload: { success } });
        return {
          success, projectId, ...(newData || {}),
        };

      };
    };
    return {
      type: DUPLICATE_CHECKLIST,
      payload: getPromise()
    };

  };
};

export function editChecklistExtraInfo(projectId, checklistId, locationType, locationId, extraInfo) {
  return ({ dispatch, apiServer, platformActions }) => {
    const getPromise = async () => {
      let success = true;
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
      let newData;

      try {
        let resp = await platformActions.net.fetch(`${apiServer}/v1/checklists/${checklistId}?projectId=${projectId}&locationType=${locationType}&locationId=${locationId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            extraInfo
          }),
        });

        newData = await resp.getJson();

      }
      catch (error) {

        console.error(error);
        success = false;

      }
      finally {

        dispatch({ type: EDIT_CHECKLIST_EXTRA_DATA, payload: { success } });
        return {
          success, projectId, ...(newData || {}),
        };

      };
    };
    return {
      type: EDIT_CHECKLIST_EXTRA_DATA,
      payload: getPromise()
    };

  };
};

export function cleanChecklistsCachedData() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['checklists', 'subscriptions'],
        ['checklists', 'map'],
      ];

      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      await dispatch(await removeProjectsStorage(projectIdsArray, projectStateToRemove));

    };
    return {
      type: CLEAN_CHECKLISTS_CACHE_DATA,
      payload: getPromise()
    };
  };
};