const lastDeploy = ` ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
      Welcome to cemento! Last update - 27/03/2025, 17:48:50
      Version No.  0.99.5.2.279
 ------------------------------------------------------------------------- 
 ------------------------------------------------------------------------- 
`;
const date = '27/03/2025, 17:48:50';
const version = '0.99.5.2.279';

module.exports = {
    lastDeploy,
    date,
    version,
};
