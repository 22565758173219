// ##############################
// // // Modal component styles
// #############################

const modalStyle = theme => ({
  modalRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset",
    zIndex:9999,
  },
  modalRootCover: {
    alignItems: "unset",
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "auto"
    },
    borderRadius: "6px",
    marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  modalJustify: {
    [theme.breakpoints.up("sm")]: {
      margin: "auto"
    },
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    width: "600px"
  },
  modalJustifyAuto: {
    [theme.breakpoints.up("sm")]: {
      margin: "auto"
    },
    maxWidth: 'none',
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  modalCentered: {
    [theme.breakpoints.up("sm")]: {
      margin: "1.75rem auto"
    },
    borderRadius: "6px",
    alignSelf: "center",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  modalCover: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100vw"
    },
    borderRadius: "6px",
    alignSelf: "center",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
    fontWeight: 600,
    fontSize: '20px'
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.42857143"
  },
  modalCloseButton: {
    color: "#999999",
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right"
  },
  modalClose: {
    width: "16px",
    height: "16px"
  },
  modalBody: {
    marginTop: "12px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible",
    fontSize: '14px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'column',
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0",
    "& > :not(:first-child)": {
      marginLeft: "0", // Remove the default margin
    },
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  instructionNoticeModal: {
    marginBottom: "25px"
  },
  imageNoticeModal: {
    maxWidth: "150px"
  },
  modalBig: {
    width: "600px",
    height: "340px",
  },
  modalSmall: {
    width: "340px",
    height: "180px",
  },
  modalSmallBody: {
    paddingTop: "0"
  },
  modalFooterActionButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "250px",
    borderRadius: '50px',
    marginBlock: '6px',
    fontSize: '14px',
    height: '36px'
  },
});

export default modalStyle;
