// eslint-disable-next-line no-unused-vars
import { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import '../assets/scss/material-dashboard-pro-react.css?v=1.3.0';

import withStyles from '@material-ui/core/styles/withStyles';
import notificationsStyle from '../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';
import { initData } from '../../common/lib/reporting/actions';
import webActions from '../webActions';

import {
  cleanPermissionBasedData,
  checkUpdateVersion,
  fetchGlobalConfigurations,
  hideAllLoading,
} from '../../common/app/actions';
import { getMyUserInfo } from '../../common/users/actions';
import { setAppIntl } from '../../common/app/actions';
import start from '../../common/app/start';
import { getValidAuth0Token } from '../../common/auth/actions';
import { track, trackStart, trackEnd } from '../../common/lib/reporting/actions';
import { lastDeploy } from './lastDeploy';
import '../assets/css/app.css';
import PdfContainerView from '../views/PdfView/PdfViewContainer';
import { getConfigurations } from '../../common/configurations/funcs';
import generateRootRouter from './RootRouter.js';
import RootRouterComponent from './RootRouterComponent.js';
import DataManagerInstance from '../../common/dataManager/DataManager.js';
import { SUBJECTS } from '../../common/dataManager/subjects.js';
import { platformActions } from '../../common/platformActions.js';

const hist = createBrowserHistory();
const ON_DEVICE_DATA_LAST_CLEAN_TS = 'ON_DEVICE_DATA_LAST_CLEAN_TS';
class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.isLoggedInAndConnected = false;
    this.didFetchGlobalConfigurations = false;
    this.runAfterLogin = this.runAfterLogin.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { setAppIntl, intl } = this.props;
    setAppIntl(intl);
    console.log(lastDeploy);
    
    this.onDeviceStorageCleanListener();
  }

  onDeviceStorageCleanListener = () => {
    if (!localStorage.getItem(ON_DEVICE_DATA_LAST_CLEAN_TS)) {
      localStorage.setItem(ON_DEVICE_DATA_LAST_CLEAN_TS, Date.now());
    }

    window.addEventListener('beforeunload', () => {
      if (localStorage.getItem(ON_DEVICE_DATA_LAST_CLEAN_TS)) return;

      platformActions.localDB.getCementoDB().deleteAll();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { firebaseConnected, loggedIn, viewer, fetchGlobalConfigurations, checkUpdateVersion } =
      this.props;
    if (!this.isLoggedInAndConnected && firebaseConnected && loggedIn) {
      this.isLoggedInAndConnected = true;
    }

    if (this.isLoggedInAndConnected && viewer && !this.didRunAfterLogin) {
      this.didRunAfterLogin = true;
      this.runAfterLogin();
    }

		if (!this.didFetchGlobalConfigurations && this.isLoggedInAndConnected && viewer && DataManagerInstance.isGlobalStorageLoaded()) {
			this.didFetchGlobalConfigurations = true;
			checkUpdateVersion();
			fetchGlobalConfigurations(viewer);
		}
	}
	componentDidMount() {
		const { hideAllLoading } = this.props;
		window.onpopstate = e => hideAllLoading();
		webActions.LogRocket.startSessionRecording();
	}

  runAfterLogin() {
    const { getValidAuth0Token, initData, track, viewer } = this.props;
    const retryFunc = (async () => {
      if (!DataManagerInstance.isDataReady({ scope: 'global', subject: SUBJECTS.AUTH })) return;

      getValidAuth0Token();
    }).bind(this);

    track('login');
    initData(viewer && viewer.toJS ? viewer.toJS() : viewer);
    if (!this.retryMessagesInterval) this.retryMessagesInterval = setInterval(retryFunc, 1000 * 60 * 2);
  }

  handleSignOut() {
    if (this?.retryMessagesInterval)
      clearInterval(this.retryMessagesInterval);
  }

  render() {
    const { store, rtl } = this.props;
    const isPdfMode = hist.getNested(['location', 'pathname'], '').includes('/pdf');

    const router = generateRootRouter(
      <RootRouterComponent onSignOut={this.handleSignOut} />
    );

    return (
      <div style={{ direction: rtl ? 'rtl' : 'ltr', position: 'relative', overflow: 'hidden' }}>
        <Provider store={store}>
          {isPdfMode ? <PdfContainerView history={hist} /> : <RouterProvider router={router} />}
        </Provider>
      </div>
    );
  }
}

App = injectIntl(App);
App = connect(
  (state) => ({
    loggedIn: state.auth.loggedIn,
    firebaseConnected: state.auth.firebaseConnected,
    viewer: state.users.viewer,
    loading: state.app.loading,
    toast: state.app.toast,
    alert: state.app.alert,
    rtl: state.app.rtl,
    auth: state.auth,
    users: state.users,
    formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
    connectionViewerVisiblity: state.app.connectionViewerVisiblity,
    dataManagerVisibility: state.app.dataManagerVisibility
  }),
  {
    hideAllLoading,
    getValidAuth0Token,
    checkUpdateVersion,
    cleanPermissionBasedData,
    getMyUserInfo,
    track,
    trackStart,
    trackEnd,
    start,
    initData,
    fetchGlobalConfigurations,
    setAppIntl,
    getConfigurations,
  }
)(App);

App = withStyles(notificationsStyle)(App);
export default start(App);
