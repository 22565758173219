import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import ExtraError from '../lib/errors/extraError'
import { startLoading } from '../app/actions'; 
import usersMessages from '../users/usersMessages';
import { batchDispatch, onError } from '../app/funcs';
import { getDBMembers, getPhoneNumberRegionCode, isValidPhoneNumber } from './funcs';
import { membersEvents } from './membersEvents'

export function updateOtherUserProfile(uid, userDetails, userScopeDetails, scopeKey, patchOnly, callback) {
  return ({ validate, platformActions, apiServer }) => {
    const getPromise = async () => {
      await validate({uid})
      .prop('uid')
      .required()
      .promise;
      

      var userValues = {
          "uid": uid,
          "displayName": userDetails.displayName,
          "idNumber": userDetails.idNumber,
          "email": userDetails.email,
          "about": userDetails.about,
          "companyId": userScopeDetails.companyId,
          "scopeKey" : scopeKey
      };

      if (userDetails.avatar) 
        userValues.avatar = userDetails.avatar;
      
      if (userScopeDetails.projectsObject && scopeKey == 'global')
        userValues.projects = userScopeDetails.projectsObject;

      var resp = await platformActions.net.fetch(apiServer + '/v1/users/' + encodeURIComponent(uid), {
        method: patchOnly ? 'PATCH' : 'PUT', 
        body: JSON.stringify(userValues)
      });
      
      // var didSuccess = (resp.respInfo && resp.respInfo.status == 200);
      var didSuccess = resp && resp.status == 200;
      if (didSuccess) {
        var ret = await (resp.getJson());
        var DBMember = {};
        DBMember = ret.user_metadata;
        if (callback){
          callback(DBMember)
        }
          
        return { DBMember, uid };
      }
    };
    return {
      type: membersEvents.ON_OTHER_USER_PROFILE_UPDATE,
      payload: getPromise(),
    };
  };
}

export function createFromLocalContact(localContact, userDetails, scopeKey, callback, upsert) {
  return ({ dispatch, getState, platformActions, apiServer }) => {
    const getPromise = async () => {
      
      // CEM-3971 - The bug happen here, couldn't repraduce so added logs to sentry (18.5.21)
      const myPhoneNumber = getState().auth.number; // get the number of the current user
      const viewerCountryLetters = getPhoneNumberRegionCode(myPhoneNumber); // get the country code of the current user

      const phoneNumberString = isValidPhoneNumber(userDetails.phoneNumber, viewerCountryLetters);
      
      if (phoneNumberString) {
        dispatch(startLoading({title:usersMessages.createUserPleaseWait, overlay:true}));
        // clean the trades if needed
        userDetails = userDetails || {};
        let userParams = {
            "scopeKey" : scopeKey,
            "phoneNumber": phoneNumberString,
            "displayName": userDetails.displayName,
            "avatar": userDetails.avatar,
            "about": userDetails.about,
            "companyId": userDetails.companyId,
            "inviterId": getState().users.viewer.get('id')
        };

        if (scopeKey == 'global' && userDetails.projectsObject)
          userParams['projects'] = userDetails.projectsObject

        const apiUrl = apiServer + '/v1/users';
        try {
          let resp = await platformActions.net.fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({ ...userParams, forceUpsert: upsert })})
            
          const ret = await (resp.getJson());
          const user_metadata = ret.user_metadata || Object.values(ret || {})[0]?.user_metadata;
          let DBMember = {};
          DBMember.id = ret.user_id;
          DBMember.activated = ret.phone_verified;
          DBMember.phoneNumber = phoneNumberString;
          if (user_metadata) {
            user_metadata.loopEach((k, value) => { DBMember[k] = value });
            if (scopeKey != 'global')
              user_metadata.getNested(['projects', scopeKey], {}).loopEach((k, value) => { DBMember[k] = value });
          }

          if (callback)
            await callback(DBMember);
          dispatch({ type: membersEvents.SEARCH_USERS_BY_PHONE, payload: { members: [ {DBMember, localContact } ], scopeKey }});
          return DBMember;
        } catch (error) {
          onError({
            errorMessage: 'Failed to fetch user meta data',
            error,
            methodMetaData: {
              args: { localContact, userDetails, scopeKey, upsert },
              name: 'createFromLocalContact',
            },
            errorMetaData: {
              apiUrl,
              body: { ...userParams, forceUpsert: upsert }
            },
          });
        }
      }
      else {
        platformActions.sentry.notify('createFromLocalContact - Phone number is not valid', {myPhoneNumber, numberTryingToAdd: userDetails.phoneNumber});
        if (callback)
          await callback(null);
        return null
      }
    };
    return {
      type: membersEvents.MATCH_PHONES,
      payload: getPromise()
    };
  };
}

const TIMEOUT_BETWEEN_GET_USER_BY_UID_CALLS = 5 * 60 * 1000;
let alreadyRequestedUsers = {};

export function getUserByUid(uid) {
  return () => {
    let uids = {};
    uids[uid] = uid;

    const timePassed = ((alreadyRequestedUsers[uid] || 0) + TIMEOUT_BETWEEN_GET_USER_BY_UID_CALLS) < Date.now();
    if (timePassed) {
      alreadyRequestedUsers[uid] = Date.now();
      batchDispatch([getUsersByUids(uids)]);
    }

    return {
      type: membersEvents.GET_USER_BY_UID,
      payload: { uid },
    };
  };
}

export function getUsersByUids(uids) {  
    return ({ platformActions, getState, apiServer }) => {
      const getPromise = async () => {
      if (uids && Object.keys(uids).length > 0) {
        try {
          let finalUidsList = {};
          Object.keys(uids).forEach(uid => {
            if (!getState().getNested(['members','inProcessMap', uid]) && !getState().getNested(['members','map', uid]))
              finalUidsList[uid] = true;
          })
          if (!Object.keys(finalUidsList).length)
            return {};

          let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&uidsList=${encodeURIComponent(JSON.stringify(Object.keys(finalUidsList)))}`);
          let response = await (resp.getJson());
          let DBMembers = getDBMembers(response);
          return ({ DBMembers, uids:finalUidsList });
        } catch (error) {
          console.log('getUsersByUids error')
          console.warn(error)
          throw new ExtraError('getUsersByUids error', {uids}, error)
        }
      }
    } 

    return {
      type: membersEvents.SEARCH_UNLOCAL_USERS_BY_UIDS,
      payload: getPromise(),
      uids
    };      
  };
}

export function findNetworkFromContacts(contacts, viewerFullNumber, fetchWithViewer, forceFetch, callback) {
  return ({ dispatch, getState, platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!contacts)
        return {
          type: membersEvents.MATCH_PHONES,
          payload: { }
        };

      const phoneNumber = viewerFullNumber ? viewerFullNumber : getState().auth.number;
      const viewer = getState().users.viewer;
      const phoneUtil = PhoneNumberUtil.getInstance();

      
      const viewerCountryLetters = getPhoneNumberRegionCode(phoneNumber)
      let phonesList = {};
      let localContacts = {};
      contacts.loopEach((key, contact) => {
        (contact.phoneNumbers || []).loopEach((k, currPhoneNumber) => {
          try {
            const phoneNumber = phoneUtil.parse(currPhoneNumber, viewerCountryLetters);
            const phoneNumberString = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164)
            phonesList[phoneNumberString] = phoneNumberString; 
            localContacts[phoneNumberString] = contact;
          }
          catch (error) {
            onError({
              errorMessage: 'Failed to parse contacts',
              error,
              methodMetaData: {
                name: 'findNetworkFromContacts',
                args: { contacts, viewerFullNumber, fetchWithViewer },
              },
            })
          }
        });
      })

      // Send the request in batches
      const max_batch_size = 100;
      var phonesListArray = Object.keys(phonesList);
      if (fetchWithViewer && viewer && viewer.phoneNumber)
        phonesListArray.push(viewer.phoneNumber);

      for (var i=0; i < phonesListArray.length; i += max_batch_size) {
        var currList = phonesListArray.slice(i, i + max_batch_size);

        // Call the phone number on a query
        let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&phonesList=${encodeURIComponent(JSON.stringify(Object.values(currList)))}`);
        var matchNumbersResponse = await (resp.getJson());
        let myUser = null;
        let DBMembersArr = [];

        Object.values(matchNumbersResponse).map(member => {
          var DBMember = member.user_metadata;
          var updated_at = member.updated_at
          const phoneNumber = DBMember?.phoneNumber || member.phone_number || member.phoneNumber;
          // Save every matching phone nubmer found
          if (DBMember && viewer && DBMember.id == viewer.id) {
            myUser = { DBMember, updated_at };
            DBMembersArr.push(myUser);
          }
          else if (DBMember && phoneNumber) {
            var localContact = phoneNumber.toJS ? phoneNumber.toJS() : phoneNumber;
            DBMember.activated = member.phone_verified;

            DBMember.phoneNumber = phoneNumber;
            
            DBMembersArr.push({ DBMember, localContact, updated_at });
          }
        });
        if (callback)
          callback(DBMembersArr);
        if (myUser)
          dispatch({ type: membersEvents.UPDATE_MY_METADATA + "_SUCCESS", payload: { values: myUser.DBMember, updated_at: myUser.updated_at, myId: viewer.id, scopeKey: 'global' }});
        if (DBMembersArr.length > 0)
          dispatch({ type: membersEvents.SEARCH_USERS_BY_PHONE, payload: { members: DBMembersArr, scopeKey: 'global', scope: 'contacts' }});
      }
    }
    return {
      type: membersEvents.MATCH_PHONES,
      payload: getPromise()
    };
  };
}

export function getAllUsers(pageLimit, strSearchQuery) {  
  return ({ platformActions, apiServer }) => {
    const getPromise = async () => {
      try {
        let resp = await platformActions.net.fetch(`${apiServer}/v1/users?includeGroups=true&pageLimit=${pageLimit}&strSearchQuery=${encodeURIComponent(strSearchQuery)}`);
        var response = await (resp.getJson());
        var DBMembers = getDBMembers(response);

        return ({ DBMembers });
      } catch (error) {
        console.log('getAllUsers error')
        console.warn(error)
        throw new ExtraError('getAllUsers error', {}, error)
      }
    } 

    return {
      type: membersEvents.SEARCH_UNLOCAL_USERS_BY_UIDS,
      payload: getPromise()
    };      
  };
}