import CardContainer, {CardContainerRow} from './CardContainer';
import trash from '../../assets/img/icons/trash.png';
import ComplexComponentHOC from '../../../common/propertiesTypes/ComplexComponentHOC';
import FunctionalInput from '../../../common/app/components/FunctionalInput';
import _ from 'lodash';
import useUniqueId from '../../../common/hooks/useUniqueId';

/**
 * @typedef {import('../../../common/propertiesTypes/ComplexComponentHOC').ComplexComponentValue} Value
 * @typedef ComplexComponentProps
 * @property {string} title
 * @property {Value} value
 * @property {string} propId
 * @property {string} subjectName
 * @property {(newValue: Value | null) => void} [onChange]
 * @property {() => void} [onDeleteClick]
 * @property {boolean} [isDisabled]
 * @property {(component: React.Component, this: StandardInput) => void} [onRef]
 *
 *
 * @param {ComplexComponentProps} props
 * @returns
 */

const ComplexComponent = (props) => {
  const {
    title, onChange, propId, subjectName,
    value, isDisabled, context: propsContext,
    onDeleteClick, isFocus, onCardClick,
    apiSourceMetadata, isSynced, isUnsyncable: disableSync,
    isLoading, setLoading, loadingMap,
    prevFormDataLoaded
  } = props;

  const uniqueComponentId = useUniqueId();

  return (
    <CardContainer
      alwaysShowEditStrip
      editIcons={[{
        onClick: () => onDeleteClick(),
        icon: trash,
        style: { heigth: '18px' } // TODO: typo. need to check
      }]}
      isEditMode={!isDisabled}
      title={title}
      isSelected={isFocus}
      isFocused={isFocus}
    >
      <ComplexComponentHOC onChange={onChange} propId={propId} subjectName={subjectName} value={value}>
        {(renderProps) => {
          const { handleInnerValueChange, sortedInnerProps, checkIsUnsyncable } = renderProps;

          
          return (sortedInnerProps || []).map(innerProp => {
            innerProp = innerProp || {};

            const context = Object.assign({}, propsContext, {
              parentPropIds: [...(propsContext.parentPropIds || []), props.propId],
              extra: { ...propsContext?.extra, valueIds: [...(propsContext.extra?.valueIds || []), innerProp.id] }
            })

            const isUnsyncable = checkIsUnsyncable(context, apiSourceMetadata);

            return (
              <CardContainerRow key={`${uniqueComponentId}-${innerProp.id}`}>
                <FunctionalInput
                  disabled={isDisabled}
                  onChange={(propId, newData, status, context) => handleInnerValueChange(propId, newData, context)}
                  subjectName={subjectName}
                  propId={innerProp.id}
                  context={context}
                  onCardClick={onCardClick}
                  values={{ [innerProp.id]: _.get(value, [innerProp.id], null) }}
                  apiSourceMetadata={apiSourceMetadata}
                  onDeleteClick={onDeleteClick}
                  isSynced={isSynced}
                  isUnsyncable={disableSync || isUnsyncable}
                  loadingMap={loadingMap}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  prevFormDataLoaded={prevFormDataLoaded}
                />
              </CardContainerRow>
            );
          });
        }}
      </ComplexComponentHOC>
    </CardContainer>
  );
};

export default ComplexComponent;