import React, { useCallback, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import Text from '../../../components/CementoComponents/Text';
import theme from '../../../assets/css/theme';
import editPen from '../../../assets/img/icons/editPen.png';
import propertiesMessages from '../../../../common/propertiesTypes/propertiesMessages';
import trash from '../../../assets/img/icons/trash.png';
import CollapsibleSection from '../../../components/CementoComponents/CollapsibleSection';
import Button from '../../../app/standardComponents/Button';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const BUTTON_WIDTH = 40;
const MIN_HEIGHT = 16;

/** @param {PropertiesSectionProps} props */
let PropertiesSection = props => {
	const { rtl } = useSelector(state => ({ rtl: state.app.rtl }));
	const { intl } = props; // injected props
	const {
		section,
		useCollapsibleSections,
		shouldScrollToSection,
		onAddCert,
		onEditCert,
		onToggleCancelCertification,
		children,
		showSection,
		isCertificationCanceled
	} = props;

	useEffect(() => {
		if (shouldScrollToSection) {
			setTimeout(() => {
				const sectionEl = document.getElementById(section.id);
				if (sectionEl) sectionEl.scrollIntoView();
			}, 0);
		}
	}, [shouldScrollToSection]);

	const handleAddCert = useCallback(() => {
		onAddCert?.(_.first(section.properties));
	}, [onAddCert, section]);

	const handleEditCert = useCallback(() => {
		if (onEditCert) onEditCert(_.first(section.properties));
	}, [onEditCert, section]);

	const handleToggleCancelCertification = useCallback(()=>{
		if (onToggleCancelCertification) onToggleCancelCertification(_.first(section.properties), section.isCertificationCanceled)
	}, [onToggleCancelCertification, section])

	const isSupportedCertificateSection = useMemo(() => section.isCertificationSection && section.isSupportedCertBehaviour, [section]);

	if (!showSection) return children;

	if (useCollapsibleSections) {
		return (
			<CollapsibleSection
				section={{ title: section.title, id: section.id }}
				open={section.isOpen}
				stripIcons={
					isSupportedCertificateSection
						? [
								{
									icon: editPen,
									onClick: handleEditCert,
									style: { height: '18px' },
								},
							]
						: null
				}
				mainContainerStyle={{  display: 'flex', flex: 1, flexDirection: 'column' }}
				footerContainerStyle={{ display: 'flex', justifyContent: 'center' }}
				footerComponent={
					Boolean(isSupportedCertificateSection) && (
						<Button
							style={{ width: BUTTON_WIDTH, alignSelf: 'center' }}
							title={propertiesMessages.addCertification}
							onClick={handleAddCert}
						/>
					)
				}
			>
				{children}
			</CollapsibleSection>
		);
	} else {
		return (
			<div
				id={section.id}
				style={{
					...styles.cardSections,
					backgroundColor: shouldScrollToSection ? theme.backgroundColorHover : theme.backgroundColorBright,
				}}
			>
				<div style={{ ...styles.sectionHeader}}>
					<Text>{section.title}</Text>
					{Boolean(isSupportedCertificateSection) && (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							{!isCertificationCanceled &&<img style={{ cursor: 'pointer' }} src={editPen} onClick={handleEditCert} />}
						</div>
					)}
				</div>
				{Boolean(isSupportedCertificateSection) && (
					<div style={{
						display:'flex',
						gap: theme.padding,
						alignItems:'center',
						justifyContent: 'center',
						minHeight: MIN_HEIGHT
					}}> 
						{!isCertificationCanceled && <div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								[rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin,
							}}
							onClick={handleAddCert}
						>
							<Text
								style={{
									backgroundColor: theme.backgroundColorBright,
									zIndex: 10,
									cursor: 'pointer',
									border: '1px solid #00000010',
									padding: `${theme.verticalMargin}px ${theme.margin}px`,
									borderRadius: theme.padding + theme.margin,
									boxShadow: '#00000015 0px 0px 10px 0px',
								}}
							>
								{intl.formatMessage(propertiesMessages.addCertification)}
							</Text>
						</div>}
						{!_.get(_.first(section.properties), ['settings', 'isRequired']) && <div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								[rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin,
							}}
							onClick={handleToggleCancelCertification}
							>
								<Text
								style={{
									backgroundColor: theme.backgroundColorBright,
									zIndex: 10,
									cursor: 'pointer',
									border: '1px solid #00000010',
									padding: `${theme.verticalMargin}px ${theme.margin}px`,
									borderRadius: theme.padding + theme.margin,
									boxShadow: '#00000015 0px 0px 10px 0px',
								}}
							>
								{isCertificationCanceled ? propertiesMessages.activateCertification : propertiesMessages.cancelCertification}
							</Text>
						</div>}
						<div
							style={{ height: 1, backgroundColor: '#00000010', width: 1000, maxWidth: '100%', position: 'absolute' }}
						/>
					</div>
				)}
				<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>{children}</div>
			</div>
		);
	}
};

PropertiesSection = injectIntl(PropertiesSection);
export default PropertiesSection;

const styles = {
	sectionHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: `${theme.margin + theme.padding}px 0px`,
		color: theme.brandPrimary,
		fontFamily: 'Assistant - Semi Bold',
		fontSize: 16,
		fontWeight: theme.strongBold,
	},
	cardSections: {
		paddingInline: `${theme.paddingSize * 2}px`,
		paddingBottom: `${theme.paddingSize}px`,
		boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 20px 0px',
		marginBottom: theme.paddingSize,
		display: 'flex',
		flexDirection: 'column',
		transition: 'all 150ms ease 0s',
	},
};
