import {useCallback, useContext, useMemo} from 'react';
import {ProjectContext} from '../projects/contexts';
import {platformActions} from '../platformActions';
import { safeJSONParse } from '../app/funcs';
import { useForms } from './useForms';
const sortFormsByDateDesc = (a, b) => {
  return b.reportDate - a.reportDate;
}

const useDailyReports = (formType) => {
  const { selectedProjectId } = useContext(ProjectContext);
  const isNative = platformActions.app.isNative();
  const { forms } = useForms({ formType });

  const getPrevForm = useCallback((currTS = Infinity) => {
    const formsArray = Object.values(forms || {});
    if (!formsArray.length) {
      return null;
    }

    const prevForm = formsArray
      .sort(sortFormsByDateDesc)
      .filter(form => form.id)
      .find(form => !form.isDeleted && (form.reportDate < currTS));

    return prevForm;
  }, [forms]);

  const getFormInstancesMap = useCallback((formId) => {
    if (!formId) {
      return {};
    }

    const subjectName = 'formsInfo';

    let filterQuery;
    if (isNative) {
      filterQuery = `
        projectId == "${selectedProjectId}" AND 
        subjectName == "${subjectName}" AND 
        parentId == "${formId}"
      `;
    } else {
      filterQuery = {
        projectId: selectedProjectId,
        subjectName,
        parentId: formId,
      };
    }

    const localDB = platformActions.localDB.getCementoDB();
    const data = localDB.get('propertyInstances', filterQuery) || [];
    
    const propInstancesDataMap = data.reduce((acc, val) => {
      if (val?.data) {
        acc[val.propId] = {
          ...val,
          data: safeJSONParse(val.data) || val.data
        }
      }

      return acc;
    }, {});

    return propInstancesDataMap
  }, [selectedProjectId]);



  return {
    forms,
    getPrevForm,
    getFormInstancesMap,
  };
};

export default useDailyReports;
