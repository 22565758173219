import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { lokiInstance } from "../../../common/configureMiddleware";
import { ProjectContext } from "../../../common/projects/contexts";
import { onDraftModeChange } from "../../../common/ui/actions";
import { getFullLocationByLocationId } from "../Locations/funcs";
import { isPostInValid } from "../../../common/posts/funcs";
import {
  getToggleButtonStatusesArray,
  mapExtraDataBeforeUpload,
} from "../../../common/checklists/funcs";
import * as instancesStatus from "../../../common/checklistItemsInstances/clItemInstanceStatus";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages.js";
import checklistsMessages from "../../../common/checklists/checklistsMessages.js";
import * as checklistTypes from "../../../common/checklists/checklistTypes.js";
import headerLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import theme from "../../assets/css/theme";
import iconV from "../../assets/img/icons/check-v.svg";
import iconVV from "../../assets/img/icons/check-vv.svg";
import iconResolve from "../../assets/img/icons/checkResolve.svg";
import iconIrrelevant from "../../assets/img/icons/check-irrelevant.svg";
import iconPartial from "../../assets/img/icons/check-partial.svg";
import iconX from "../../assets/img/icons/check-x.svg";
import iconEmpty from "../../assets/img/icons/check-empty.svg";
import Text from "../../components/CementoComponents/Text";
import _ from "lodash";
import { startToast } from "../../../common/app/actions";
import * as issueStates from "../../../common/issues/issueStates";
import { upsertChecklistItemInstance } from "../../../common/checklistItemsInstances/funcs.js";
import { getNewId } from "../../../common/lib/api/index.js";
const statusWithReqActions = {
  [instancesStatus.CLI_STATUS_PARTIAL]: true,
  [instancesStatus.CLI_STATUS_RESOLVED]: true,
  [instancesStatus.CLI_STATUS_CONFIRMED]: true,
  [instancesStatus.CLI_STATUS_CONFIRMED_2]: true,
};

const ADD_ISSUE = "addIssue";
const ADD_DOC = "addDoc";

class CheckToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.toggleActionsDisplay = this.toggleActionsDisplay.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getToggleButtonStatusesArray =
      this.getToggleButtonStatusesArray.bind(this);

    this.setHover = this.setHover.bind(this);
    this.state = {
      open: false,
      hover: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (props.instance != nextProps.instance)
      newStateChanges.status = !nextProps.instance
        ? instancesStatus.CLI_STATUS_NONE
        : nextProps.instance.status;

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  handleBlur() {
    this.setState({ open: false, opacity: 0 });
  }

  getToggleButtonStatusesArray() {
    const {
      viewer,
      selectedProjectId,
      checklistItem,
      extraDataMode,
      isExtraDataFulfilled,
    } = this.props;
    const { status } = this.state;

    const statusObjects = {};
    statusObjects[instancesStatus.CLI_STATUS_CONFIRMED_2] = {
      img: iconVV,
      status: instancesStatus.CLI_STATUS_CONFIRMED_2,
      message:
        checklistItemMessages.statusActions[
          instancesStatus.CLI_STATUS_CONFIRMED_2
        ],
    };
    statusObjects[instancesStatus.CLI_STATUS_CONFIRMED] = {
      img: iconV,
      status: instancesStatus.CLI_STATUS_CONFIRMED,
      message:
        checklistItemMessages.statusActions[
          instancesStatus.CLI_STATUS_CONFIRMED
        ],
    };
    statusObjects[instancesStatus.CLI_STATUS_PARTIAL] = {
      img: iconPartial,
      status: instancesStatus.CLI_STATUS_PARTIAL,
      message:
        checklistItemMessages.statusActions[instancesStatus.CLI_STATUS_PARTIAL],
    };
    statusObjects[instancesStatus.CLI_STATUS_RESOLVED] = {
      img: iconResolve,
      status: instancesStatus.CLI_STATUS_RESOLVED,
      message:
        checklistItemMessages.statusActions[
          instancesStatus.CLI_STATUS_RESOLVED
        ],
    };
    statusObjects[instancesStatus.CLI_STATUS_IRRELEVANT] = {
      img: iconIrrelevant,
      status: instancesStatus.CLI_STATUS_IRRELEVANT,
      message:
        checklistItemMessages.statusActions[
          instancesStatus.CLI_STATUS_IRRELEVANT
        ],
    };
    statusObjects[instancesStatus.CLI_STATUS_NONE] = {
      img: iconEmpty,
      status: instancesStatus.CLI_STATUS_NONE,
      message:
        checklistItemMessages.statusActions[instancesStatus.CLI_STATUS_NONE],
    };
    statusObjects[instancesStatus.CLI_STATUS_REJECTED] = {
      img: iconX,
      status: instancesStatus.CLI_STATUS_REJECTED,
      message:
        checklistItemMessages.statusActions[
          instancesStatus.CLI_STATUS_REJECTED
        ],
    };

    let statusArray = getToggleButtonStatusesArray(
      viewer,
      checklistItem,
      selectedProjectId,
      status,
      true,
      extraDataMode,
      isExtraDataFulfilled
    );
    let ret = [];
    statusArray.forEach((x) => {
      if (!Boolean(x.disabled)) ret.push(statusObjects[x.status]);
    });
    return ret;
  }

  toggleActionsDisplay(newOpen) {
    const { viewer, checklistItem, locationId, disabled } = this.props;

    if (!checklistItem || !locationId || disabled) return;

    let actions = [];

    if (newOpen && viewer) {
      actions = this.getToggleButtonStatusesArray();

      if (actions.length == 0) newOpen = false;
    }

    this.setState({ actions, open: newOpen, opacity: 0 });
    if (newOpen)
      setTimeout(
        (() => {
          this.setState({ opacity: 1 });
        }).bind(this),
        1
      );
  }

  setHover() {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  }

  async onStatusChange(status, addPost) {
    const {
      selectedProjectId,
      checklistItem,
      instance,
      locationId,
      checklistType,
      onDraftModeChange,
      lang,
      intl,
      buildings,
      floors,
      units,
      startToast,
      instanceParentChecklistId = null,
      checklistId,
      onStatusChange,
      checklists,
      extraDataValues,
      isExtraDataFulfilled,
      locationsData,
      setShouldSaveExtraData,
      parent,

    } = this.props;
 
    const checklist = checklists.getNested([checklistId], {});
    let targetTS = null;
    let newInstance = instance
      ? instance.toJS
        ? instance.toJS()
        : Object.assign({}, instance)
      : null;
    let tradeId = checklistItem.trade;
    let location = getFullLocationByLocationId(
      buildings,
      floors,
      units,
      locationId
    );
    const isImageRequired = _.get(checklistItem, ["requirements", "img"]);
    const isAddPost = Boolean(addPost);
    const isIssuePost = Boolean(addPost === ADD_ISSUE);

    let mandatoryData = {
      img: Boolean(
        checklistItem.requirements && checklistItem.requirements.img
      ),
      desc: Boolean(
        checklistItem.requirements && checklistItem.requirements.desc
      ),
      file: Boolean(
        checklistItem.requirements && checklistItem.requirements.file
      ),
      drawing: Boolean(
        checklistItem.requirements && checklistItem.requirements.drawing
      ),
      signature: Boolean(
        checklistItem.requirements && checklistItem.requirements.signature
      ),
    };

    let instanceId = instance ? instance.id : getNewId()

    let postDetails = {
      trade: { id: tradeId },
      location,
      isIssue: isIssuePost,
      checklistItemInstance: { id: instanceId },
    };

    let newStatus = status;
    let extraData = {};
    
    if (checklistItem.extraData) {
      extraData = mapExtraDataBeforeUpload(selectedProjectId, checklistItem, extraDataValues, instance, locationId);
    }
    // Set default description
    const hideChecklistTitleOnIssueDesc = this.props.getNested(
      ['configurations', 'checklists', 'hideChecklistTitleOnIssueDesc'],
      false
    );
    const isPostTitlePrefixEditable = this.props.getNested(
      ['configurations', 'checklists', 'isPostTitlePrefixEditable'],
      true
    );
    
    const checklistName = !hideChecklistTitleOnIssueDesc
      ? checklists.getNested([checklistId, 'description'], '')
      : '';
    const checklistItemName = checklistItem.description;
    if (newStatus == instancesStatus.CLI_STATUS_REJECTED || (isAddPost && isIssuePost)) {
      let messageName = checklistName ? 'issueTemplate' : 'issueTemplateNoChecklistName';
      let title;
      if (lang == 'en') {
        let currString = checklistsMessages[messageName].defaultMessage;
        title = currString.replace('{description}', checklistItemName).replace('{ChecklistName}', checklistName);
      } else
        title = intl.formatMessage(checklistsMessages[messageName], {
          description: checklistItem.description,
          ChecklistName: checklistName,
        });
      postDetails = {
        ...postDetails,
        [isPostTitlePrefixEditable ? 'title' : 'titlePrefix']: title,
        isIssue: isIssuePost,
        mandatoryData,
      };
    } else if (isAddPost || isImageRequired) {
      const extraInfoText =
        locationsData && locationsData.type && locationId
          ? checklist.getNested(['locations', `${locationsData.type}s`, locationId, 'extraInfo', 'text'])
          : null;
      const messageName = hideChecklistTitleOnIssueDesc
        ? 'checklistPostTemplateNoChecklistName'
        : 'checklistPostTemplate';
      postDetails = {
        ...postDetails,
        [isPostTitlePrefixEditable ? 'title' : 'titlePrefix']: intl.formatMessage(checklistsMessages[messageName], {
          ChecklistName: extraInfoText ? `${checklistName} - ${extraInfoText}` : checklistName,
          ChecklistItem: checklistItemName,
        }),
        isIssue: false,
      };
    }
    

    let updateInstanceStatusFunc = () => {
      if (!instance) {
        newInstance = {};
        newInstance.id = instanceId
        newInstance.targetTS = checklistType == checklistTypes.ROUTINE ? targetTS : null;
        newInstance.locationId = locationId;
        newInstance.status = newStatus;
        newInstance.checklistItemId = checklistItem.id;
        newInstance.checklistId = checklistId;
        newInstance.isNew = true;
        newInstance.parent = parent;
      } else {
        newInstance.status = newStatus;
      }

      upsertChecklistItemInstance(  {
        projectId: selectedProjectId,
        inChecklistItemInstance: newInstance,
        instanceParentChecklistId,
        extraData,
        immediateSave:true
      });

      setShouldSaveExtraData(false)
      this.setState({ open: false, opacity: 0 });
      return newInstance.id;
    };

    let connectedPosts = instanceId
      ? lokiInstance.getCollection('posts').cementoFind({
          projectId: selectedProjectId,
          'checklistItemInstance.id': instanceId,
        })
      : false;

    let postsCounter = 0;
    let openedIssuesCounter = 0;
    let resolvedIssuesCounter = 0;

    if (connectedPosts) {
      connectedPosts.forEach((post) => {
        if (post.issueState == issueStates.ISSUE_STATE_OPENED) openedIssuesCounter++;
        else if (post.issueState == issueStates.ISSUE_STATE_RESOLVED) resolvedIssuesCounter++;
        else if (!post.issueState) postsCounter++;
      });
    }

    const imagesArray = _.values(connectedPosts[0]?.images)

    const hasImage = _.size(imagesArray)
    const hasFile = _.size(connectedPosts[0]?.attachments)
    const hasDesc = connectedPosts[0]?.title
    let hasDrawing = false
    imagesArray.forEach(img => hasDrawing = hasDrawing || img.type == 'drawing');

    const isExtraDataMissing = !isExtraDataFulfilled && newStatus < instancesStatus.CLI_STATUS_REJECTED;
    const isImageMissing = mandatoryData.img && !hasImage;
    const isFileMissing = mandatoryData.file && !hasFile;
    const isDescMissing = mandatoryData.desc && !hasDesc;
    const isDrawingMissing = mandatoryData.drawing && !hasDrawing;
    const isStatusReject = newStatus == instancesStatus.CLI_STATUS_REJECTED
    const shouldUpdateStatus =
      newStatus === instancesStatus.CLI_STATUS_NONE || newStatus === instancesStatus.CLI_STATUS_IRRELEVANT;
  
    if (isAddPost) {
      updateInstanceStatusFunc();
      onDraftModeChange({ type: 'post', details: postDetails });
      return;
    } else if (shouldUpdateStatus) {
      updateInstanceStatusFunc();
      return;
    } else if (isStatusReject) {
      updateInstanceStatusFunc();
      onDraftModeChange({ type: 'post', details: { ...postDetails, isIssue: true } });
      return;
    } else if (openedIssuesCounter || resolvedIssuesCounter) {
      startToast({ title: checklistItemMessages.cannotChangeMessage.message });
    } else if (isExtraDataMissing) {
      startToast({
        title: checklistItemMessages.toasts.mandatoryData,
        type: 'error',
      });
      return;
    } else if (isDrawingMissing){
        startToast({ title: checklistItemMessages.toasts.cannotUploadDrawing });
        return
    } else if (isImageMissing || isFileMissing || isDescMissing) {
      onDraftModeChange({
        type: 'post',
        details: {
          trade: tradeId ? { id: tradeId } : null,
          location,
          mandatoryData,
          checklistInstanceCallback: updateInstanceStatusFunc,
        },
      });
      startToast({
        title: checklistItemMessages.toasts.mandatoryData,
        type: 'error',
      });
      return;
    } else {
      updateInstanceStatusFunc();
    }
  }

  render() {
    const { style, rtl, disabled } = this.props;
    const { open, opacity, actions, status, hover } = this.state;
    let icon = iconEmpty;

    if (status == instancesStatus.CLI_STATUS_CONFIRMED) icon = iconV;
    else if (status == instancesStatus.CLI_STATUS_PARTIAL) icon = iconPartial;
    else if (status == instancesStatus.CLI_STATUS_CONFIRMED_2) icon = iconVV;
    else if (status == instancesStatus.CLI_STATUS_REJECTED) icon = iconX;
    else if (status == instancesStatus.CLI_STATUS_RESOLVED) icon = iconResolve;
    else if (status == instancesStatus.CLI_STATUS_IRRELEVANT)
      icon = iconIrrelevant;

    let actionHeight = 40;
    let originalIconSize = 20;
    let growSize = 3;
    let iconSize = Boolean(!disabled && (hover || this.props.hover))
      ? originalIconSize + growSize
      : originalIconSize;

    return (
      <div
        style={{ position: "relative", width: originalIconSize + growSize }}
        onMouseOver={this.setHover}
        onMouseOut={this.setHover}
      >
        {Boolean(open) && (
          <div
            onMouseLeave={() => {
              this.toggleActionsDisplay(false);
            }}
            tabIndex={0}
            onBlur={this.handleBlur}
            style={{
              zIndex: theme.zIndexes.checkToggleButton,
              paddingTop: theme.paddingSize,
              paddingBottom: theme.paddingSize,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              transition: "all 300ms ease 0s",
              opacity: opacity,
              position: "absolute",
              top: -16,
              width: 150,
              [rtl ? "right" : "left"]: -25,
              border: theme.borderLineHeaderInfo,
              backgroundColor: theme.backgroundColorBright,
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "0 6px 6px",
            }}
          >
            {actions.map((a) => (
              <ToggleAction
                onClick={this.onStatusChange}
                rtl={rtl}
                icon={a.img}
                status={a.status}
                message={a.message}
                actionHeight={actionHeight}
                iconSize={originalIconSize}
              />
            ))}
            <div
              style={{
                height: 1,
                backgroundColor: theme.separatorColor,
                width: "70%",
                alignSelf: "center",
                margin: 2,
                marginTop: 5,
              }}
            />
            <ToggleAction
              message={checklistItemMessages.addDocumentationReport}
              onClick={() =>
                this.onStatusChange(
                  status || instancesStatus.CLI_STATUS_NONE,
                  ADD_DOC
                )
              }
            />
            <ToggleAction
              message={checklistItemMessages.createTask}
              onClick={() =>
                this.onStatusChange(
                  status || instancesStatus.CLI_STATUS_NONE,
                  ADD_ISSUE
                )
              }
            />
          </div>
        )}
        <div
          style={{
            height: originalIconSize + growSize,
            width: originalIconSize + growSize,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            onClick={(e) => {
              if (!disabled) {
                e.stopPropagation();
                this.toggleActionsDisplay(true);
              }
            }}
            src={icon}
            style={{
              height: iconSize,
              width: iconSize,
              cursor: disabled ? "auto" : "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}

CheckToggleButton = withStyles(headerLinksStyle)(CheckToggleButton);
CheckToggleButton = injectIntl(CheckToggleButton);

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(null, {
    onDraftModeChange,
    startToast,
  })
);
export default enhance(CheckToggleButton);

class ToggleAction extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      hover: false,
    };
  }

  setHover() {
    this.setState((prevState) => ({ hover: !prevState.hover }));
  }

  handleClick(e) {
    e.stopPropagation();
    if (this.props.onClick) this.props.onClick(this.props.status);
  }

  render() {
    const { icon, rtl, message, actionHeight, iconSize } = this.props;
    const { hover } = this.state;
    return (
      <div
        onClick={this.handleClick}
        onMouseOver={this.setHover}
        onMouseOut={this.setHover}
        style={{
          padding: 2,
          [rtl ? "paddingRight" : "paddingLeft"]: theme.paddingSize + 10,
          [rtl ? "paddingLeft" : "paddingRight"]: theme.paddingSize,
          backgroundColor: hover
            ? theme.backgroundColorHover
            : theme.backgroundColorBright,
          display: "flex",
          flex: 1,
          height: actionHeight,
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {Boolean(icon) && (
          <img
            src={icon}
            style={{
              height: iconSize,
              width: iconSize,
              [rtl ? "marginLeft" : "marginRight"]: theme.paddingSize,
            }}
          />
        )}
        <Text
          style={{
            height: iconSize,
            [rtl ? "marginLeft" : "marginRight"]: theme.paddingSize,
          }}
        >
          {message}
        </Text>
      </div>
    );
  }
}
