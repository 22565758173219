import SignatureDialog from '../../../common/images/SignatureDialog';
import { useCallback, useMemo } from 'react';
import { getDispatch } from '../../../common/configureMiddleware';

const ChecklistSignModal = (props) => {
  const { isOpen, onClose, currentForm, handleDone, formConfigurationsSignatures } = props;
  
  const dispatch = getDispatch();
  const signatures = useMemo(() => Object.values(formConfigurationsSignatures), [formConfigurationsSignatures]);

  const unsignedSignatures = useMemo(() => {
    return signatures
      .reduce((acc, signature) => {
        if (!currentForm.signatures?.[signature.id]) acc.push(signature);
        return acc;
      }, [])
      .sort((a, b) => a.ordinalNo - b.ordinalNo);
  }, [signatures]);

  const onSign = useCallback(
    async (uploadedSignatures) => {
      const sigObjs = {
        ...uploadedSignatures,
        ...currentForm.signatures,
      };
      handleDone(sigObjs);
    },
    [unsignedSignatures, currentForm, dispatch]
  );

  return <SignatureDialog isOpen={isOpen} onClose={onClose} signatureObjs={unsignedSignatures} onSign={onSign} />;
};

export default ChecklistSignModal;
