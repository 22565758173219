import serverSDK from '@cemento-sdk/server';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import { stagesEvents } from './stagesEvents';
import { getDispatch } from '../configureMiddleware';

export async function getStages(viewer, projectId) {
  const dispatch = getDispatch();

  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'stages',
    getData: () => {
      return serverSDK.checklists.getStages({ projectId });
    },
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({ type: stagesEvents.GET_STAGES, payload: { projectId, stages: data } });
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);
  return { projectId };
}
