import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { platformActions } from '../../platformActions';
import getPlatformContainer from '../platformComponents/PlatformContainer';
import getPlatformText from '../platformComponents/PlatformText';
import getPlatformTheme from '../../platformTheme';
import { isEmptyValue } from '../../app/funcs';
import useUniqueId from '../../hooks/useUniqueId';

/**
 * Custom CSS property values should be prefixed with this token to indicate that 
 * it is a variable value and should be a dot separated path to the requested value 
 * coming from the pre-defined source object stored in the code (i.e.: $theme.borderColor). 
 */
const VARIABLE_STRING_BEGINING_TOKEN = '$';
const processUIStructStyle = (style) => {
  let newStyle = Object.assign({}, style);

  const styleSources = { theme: getPlatformTheme() };

  Object.entries(newStyle).forEach(([key, value]) => {
    if (!(typeof value === 'string' && value.trim().startsWith(VARIABLE_STRING_BEGINING_TOKEN)))
      return;

    const valuePath = value.replace(VARIABLE_STRING_BEGINING_TOKEN, '');
    const newValue = _.get(styleSources, valuePath);

    if (_.isNil(newValue))
      delete newStyle[key];
    else
      newStyle[key] = newValue;
  });

  return newStyle;
}


/**
 * @typedef {'row' | 'column'} Direction
 * @typedef UIStruct
 * @property {Direction} [direction] - default parent container's direction
 * @property {{ style?: CSSStyleDeclaration, textStyle?: CSSStyleDeclaration }} [HTMLAttributes]
 * @property {UIStruct[]} [elements]
 * @property {React.ReactNode} [valueSlot]
 * @property {string | { [lang: string]: string }} [valueString]
 * @property {string | { [lang: string]: string }} [defaultValueString]
 * 
 * @param {{ UIStruct: UIStruct[] }} props
 * @returns 
 */
const DynamicUIRenderer = (props) => {
  const { UIStruct } = props;

  const [layout, setLayout] = useState(null);

  const isNative = platformActions.app.getPlatform() !== 'web';
  const prevUIStruct = useRef(null);
  const containerComponentType = useRef(null);
  const textComponentType = useRef(null);
  const uniqueComponentId = useUniqueId();
  
  useEffect(() => {
    containerComponentType.current = getPlatformContainer();
    textComponentType.current = getPlatformText();
  }, [isNative]);
  
  useEffect(() => {
    if (_.isEqual(UIStruct, prevUIStruct.current) && _.isEqual(getPlatformContainer(), containerComponentType.current) && _.isEqual(getPlatformText(), textComponentType.current))
      return;

    prevUIStruct.current = UIStruct;

    setLayout(UIStruct ? UIStruct.map(calcStruct) : null);
  }, [UIStruct, containerComponentType.current, textComponentType.current]);

  const calcStruct = useCallback(/** @param {UIStruct} [struct] @param {number} index @returns {React.ReactNode} */ 
    (struct, index) => {
    let node = null;

    if (struct && textComponentType.current && containerComponentType.current) {
      const currStyle = Object.assign({ display: 'flex' }, struct.direction && { flexDirection: struct.direction }, (struct.HTMLAttributes || {}).style);
      const currProps = Object.assign({}, struct.HTMLAttributes, { style: currStyle });
      const currChildren = (struct.elements || []).map(calcStruct);
      
      /** @type {string} */
      let valueString =
        !isEmptyValue(struct.valueString) 
          ? struct.valueString 
          : !isEmptyValue(struct.defaultValueString) 
              ? struct.defaultValueString
              : '';

      valueString = ({ title: valueString }).getCementoTitle();
      
      if (struct.valueSlot)
        node = struct.valueSlot;
      else {
        const { style, textStyle, ...propsRest } = currProps;
        const [processedStyle, processedTextStyle] = [processUIStructStyle(style), processUIStructStyle(textStyle)];

        if (valueString) {
          if (isNative)
            node = React.createElement(containerComponentType.current, { style: processedStyle }, React.createElement(textComponentType.current, Object.assign({ style: processedTextStyle }, propsRest), valueString));
          else
            node = React.createElement(textComponentType.current, Object.assign({ style: Object.assign({}, processedStyle, processedTextStyle) }, propsRest), valueString);
        }
        else
          node = React.createElement(containerComponentType.current, Object.assign({ style: Object.assign({}, processedStyle, processedTextStyle) }, propsRest), currChildren);
      }

      node = <React.Fragment key={`${uniqueComponentId}-${struct.direction}-${valueString}-${index}`}>{node}</React.Fragment>
    }
  
    return node;
  }, [containerComponentType.current, textComponentType.current]);

  return (<>{layout}</>);
}

export default DynamicUIRenderer;

