import reportsMessages from '../../common/reports/reportsMessages.js';
import { hideLoading, startLoading } from '../app/actions';
import { getAppState } from '../configureMiddleware';
import { upsertForm } from '../forms/funcs';
import ExtraError from '../lib/errors/extraError';
import { exportFormPDF } from '../pdf/actions';

import { onError } from '../app/funcs';

import trackPosts, { POST_EVENTS } from './trackPosts.js';
import { platformActions } from '../platformActions.js';
import { retryPostsUpsert } from './funcs.js';
import pdfMessages from '../app/pdfMessages.js';

export function exportPostsPDF(posts, projectId, viewer, onLateResponse) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      let success = false;
      try {
        if (!(getAppState && getAppState() && getAppState().getNested(['app', 'isConnected'], false))) {
          onError({
            errorMessage: 'Failed to export posts pdf due to no signal',
            methodMetaData: {
              name: 'exportPostsPDF',
              args: [posts, projectId, viewer],
            },
            alertParams: {
              title: reportsMessages.exportErrors.title,
              message: reportsMessages.exportErrors.content,
            },
          });
          dispatch(hideLoading());
        }

        let project = getState().projects.map.getNested([projectId]);
        let localDate = new Date().getTime();
        let updatedReport = {
          createdAt: localDate,
          formTemplateId: '-postTestForm',
          type: 'general',
        };

        const localPosts = [];
        for (const post of Object.values(posts || {})) {
          if (post.isLocal) localPosts.push(post);
          updatedReport = updatedReport.setNested(['posts', post.isIssue ? 'issues' : 'records', post.id], true);
        }

        if (localPosts.length) {
          dispatch(startLoading({ operationId: 'general', title: pdfMessages.generating }))
          await retryPostsUpsert([localPosts], viewer);
        }
       
        let form = (await upsertForm(projectId, viewer, updatedReport)).form;
        let pdf = await dispatch(
          await exportFormPDF({
            viewer,
            project,
            formId: form.id,
            formType: 'general',
            isListenerMode: true,
            onLateResponse,
            timeout: platformActions.app.isWeb() ? null : undefined, // if its undefined, the default value defined on the function will be applied
            showEmailModalOnTimeOut: platformActions.app.isNative(),
          })
        );

        if (pdf?.error) {
          throw pdf.error;
        }

        success = true;
        return pdf;
      } catch (error) {
        console.log(error);
        throw new ExtraError('updateReport error', null, error);
      } finally {
        const multi = posts.length > 1;
        let trackPostEventParams = { success, multi, projectId };
        if (posts.length == 1) {
          trackPostEventParams.post = posts[0];
        } else {
          trackPostEventParams.posts = posts;
        }
        dispatch({ type: POST_EVENTS.EXPORT_POST_AS_PDF, payload: trackPostEventParams });
        trackPosts(POST_EVENTS.EXPORT_POST_AS_PDF, trackPostEventParams);
      }
    };
    return {
      type: POST_EVENTS.EXPORT_POST_AS_PDF, //TODO: only for hide loading
      payload: getPromise(),
    };
  };
}
