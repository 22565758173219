import Select, { components } from "react-select";

const CementoSelect = (props) => {
  const renderSelectableOption = (props) => (
    <div data-qa={`option-${props.label}`}>
      <components.Option {...props} />
    </div>
  );

  return (
    <Select
      {...props}
      components={{ Option: renderSelectableOption }}
    />
  );
};

export default CementoSelect;