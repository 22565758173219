import moment from "moment-timezone";
import theme from "../app/theme";
import safetyMessages from "../safety/safetyMessages";
import _ from 'lodash';

export const REPORT_STATE_CLOSED = 100;
export const REPORT_STATE_AWAITING_APPROVAL = 200;
export const REPORT_STATE_AWAITING_SIGNATURE = 250;
export const REPORT_STATE_OPENED = 300;

export const formStatusesArray = [
  REPORT_STATE_CLOSED,
  REPORT_STATE_AWAITING_APPROVAL,
  REPORT_STATE_AWAITING_SIGNATURE,
  REPORT_STATE_OPENED,
];

/**
 * 
 * @param {number} formStatus 
 */
export const getFormStatusColor = (formStatus) => {
  let statusColor;

  switch (formStatus) {
    case null:
    case REPORT_STATE_CLOSED:
      statusColor = theme.brandSuccess
      break;

    case REPORT_STATE_AWAITING_SIGNATURE:
      statusColor = theme.brandRealDanger;
      break;

    default:
      statusColor = theme.brandWarning;
      break;
  }

  return statusColor;
}


export const getStatusMessage = (formStatus) => {
  const statusMessageId = formStatus === REPORT_STATE_CLOSED 
                              ? 'signed'
                              : formStatus === REPORT_STATE_AWAITING_SIGNATURE
                                ? 'awaitingSignature'
                                : 'edit';

  return { 
    message: safetyMessages.forms.status[statusMessageId], 
    messageId: statusMessageId,
  };
}

/**
 * 
 * @param {{
 *  status: number,
 *  type: string,
 *  reportDate: number,
 * }} form 
 * @param {number} defaultStatus
 * @returns 
 */
export const getFormStatusParams = (form, defaultStatus) => {
  let statusParams = {
    message: null,
    subMessage: null,
    color: null,
    isClosed: false,
    isEditable: false,
  }

  const formStatus = form.status || defaultStatus;

  if ( formStatus === REPORT_STATE_CLOSED) statusParams.isClosed = true;
  if (formStatus) {
    const statusMessage = getStatusMessage(formStatus);

    statusParams.message = statusMessage.message;

    statusParams.isEditable = ![REPORT_STATE_CLOSED].includes(formStatus);
  
    statusParams.color = getFormStatusColor(formStatus);

    const formType = form.type;

    const statusMessageId = statusMessage.messageId;
    if (_.get(safetyMessages, ['forms', 'subStatus', formType, statusMessageId])) {
      statusParams.subMessage = safetyMessages.forms.subStatus[formType][statusMessageId];
    }

    if (formType === 'dailyReport' && statusMessageId === 'edit') {
      const reportDate = form.reportDate;
      const reportMoment = moment(reportDate);

      if (reportMoment.isBefore(moment(), 'days')) {
        // statusParams.color = theme.brandRealDanger;
        // statusParams.message = safetyMessages.forms.status.notSigned;
        statusParams.subMessage = safetyMessages.forms.subStatus.dailyReport.late;
      }
    }
  }

  statusParams.color = getFormStatusColor(formStatus);

  if (!form.uri && statusParams.isClosed){
    statusParams.isUploading = true;
    statusParams.message = safetyMessages.forms.status.processing;
  }
    
  return statusParams;
}