import React from "react";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import Text from "../../components/CementoComponents/Text";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import toggleDown from "../../assets/img/icons/toggleDown.png";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";

// Assets
import theme from "../../assets/css/theme";

const animationTime = 1000;

class SideBarNav extends React.Component {
  constructor(props) {
    super(props);
    this.getRef = this.getRef.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open != this.props.open) {
      setTimeout(() => {
        this.setState({ inAnimetionMode: false });
      }, animationTime);
      this.setState({ inAnimetionMode: true });
    }
  }

  getRef(r) {
    if (r) this.setState({ menuRef: r });
  }

  render() {
    const {
      caption,
      options,
      alwaysOpen,
      onChange,
      beforeChange,
      id,
      onOpenOrClose,
      selectedValue,
      open,
      rtl,
      style,
      disabled,
      viewer,
      outerClickClose,
    } = this.props;
    const { isHover, menuRef, inAnimetionMode } = this.state;

    let sideBarWidth = theme.sidebarWidth;
    let transform = "rotate(0deg)";
    let isOpen = Boolean(open == undefined || open || alwaysOpen);
    if (isOpen) transform = "rotate(180deg)";

    let rightLeftPosition = {};
    if (Boolean(onOpenOrClose)) rightLeftPosition[rtl ? "right" : "left"] = 0;

    let keysSorted = Object.keys(options || {}).sort(
      (a, b) => (options[a].num || 0) - (options[b].num || 0)
    );

    let sideBarContent = (
      <div
        style={{
          paddingRight: theme.padding * 2,
          paddingLeft: theme.padding * 2,
        }}
      >
        {Boolean(open && outerClickClose && !alwaysOpen) && (
          <div
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              right: rtl ? sideBarWidth : 0,
              left: rtl ? 0 : sideBarWidth,
            }}
            onClick={() => {
              if (onOpenOrClose) onOpenOrClose(false);
            }}
          />
        )}
        {Boolean(onOpenOrClose) && (
          <div
            onClick={
              disabled && onOpenOrClose ? null : () => onOpenOrClose(!isOpen)
            }
            style={{
              cursor: disabled ? "inherit" : "pointer",
              height: theme.headerHeightSecondary,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {false && (
              <Text
                style={{
                  alignSelf: "center",
                  marginLeft: theme.verticalMargin,
                  fontWeight: 900,
                  display: "inline-block",
                }}
              >
                {caption}
              </Text>
            )}
            {Boolean(selectedValue) && (
              <Text
                style={{
                  fontSize: 18,
                  alignSelf: "center",
                  display: "inline-block",
                }}
              >
                {selectedValue.label}
              </Text>
            )}
            {Boolean(!disabled && !alwaysOpen) && (
              <img
                src={toggleDown}
                style={{
                  transform,
                  margin: 1,
                  height: 7,
                }}
              />
            )}
          </div>
        )}

        {keysSorted.map((sectionKey) => [
          Boolean(options[sectionKey].caption) && (
            <Text
              key={sectionKey + "Text"}
              style={{
                paddingTop: theme.padding * 2,
                fontWeight: 700,
                fontSize: 20,
              }}
            >
              {options[sectionKey].caption}
            </Text>
          ),
          Object.values(options[sectionKey].options || {})
            .sort((a, b) => (a.num || 0) - (b.num || 0))
            .filter(
              (option) => !Boolean(option.adminOnly) || viewer.adminMode == 1
            )
            .map((option, index) => (
              <SideBarNavItem
                key={option.key || option.id || index}
                disabled={!Boolean(onChange)}
                option={option}
                isSelected={option == selectedValue}
                onClick={(option) => {
                  if (onOpenOrClose) onOpenOrClose(false);
                  if (beforeChange) beforeChange(option);
                  onChange(option);
                }}
              />
            )),
          <div key={sectionKey} style={{ height: theme.margin }} />,
        ])}
      </div>
    );

    return (
      <div
        ref={this.getRef}
        key={id}
        className={"options menuShadow"}
        style={Object.assign(
          {
            top:
              Boolean(onOpenOrClose) && (isOpen || inAnimetionMode)
                ? theme.headerHeight
                : "0px",
            position: Boolean(onOpenOrClose)
              ? isOpen || inAnimetionMode
                ? "fixed"
                : disabled
                ? "absolute"
                : "absolute"
              : "sticky",
            overflow: !inAnimetionMode && isOpen ? "auto" : "hidden",
            width: sideBarWidth,
            height: isOpen
              ? "calc(100vh - " +
                (theme.headerHeight + (inAnimetionMode ? 1 : 0)) +
                "px)"
              : theme.headerHeightSecondary,
            transition:
              "height " + animationTime + "ms cubic-bezier(0.4, 0, 0.2, 1)",
            backgroundColor: "white",
            zIndex: theme.zIndexes.sideBarNav,
            display: "inline-block",
            flexDirection: "column",
          },
          rightLeftPosition,
          style
        )}
      >
        {Boolean(!inAnimetionMode && isOpen) ? (
          <MenuScrollbar isSmooth={false}>{sideBarContent}</MenuScrollbar>
        ) : (
          sideBarContent
        )}
      </div>
    );
  }
}

class SideBarNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { option, isSelected, onClick, disabled } = this.props;
    const { isHover } = this.state;

    return (
      <div
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
        className={"option"}
        style={{
          paddingRight: theme.padding,
          paddingLeft: theme.padding,
          paddingTop: theme.padding * 2,
          cursor: "pointer",
          fontWeight: 400,
        }}
        disabled={disabled}
        onClick={() => onClick(option)}
        data-qa={option.label.id}
      >
        <Text
          style={{
            fontSize: 18,
            color: isSelected || isHover ? theme.brandPrimary : theme.fontColor,
          }}
        >
          {option.label}
        </Text>
      </div>
    );
  }
}

const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({}))
);
export default enhance(SideBarNav);
