export const FILTER_URL_KEY = 'q';
export const VISIBLE_COLUMNS_URL_KEY = 'visibleColumns';
export const GROUP_BY_URL_KEY = 'groupBy';

export const POSTS_VIEW_TYPES = {
	TABLE: 'table',
	TILES: 'tiles',
};

export const FILTER_MENU_PATH_DELIMETER = '--_thisIsADelimeter_--';

export const SUBJECTS_WITH__DISABLED_GROUPING = ['employees', 'equipment'];

export const DISMISSED_MODALS = 'DISMISSED_MODALS'