import _ from 'lodash';

class Form {
  constructor({
    id,
    type,
    isLocal,
    uri,
    projectId,
    updatedTS,
    readyToGenerateTS,
    isDailyReportV1,
    universalIds,
    parentId,
    attachments,
    formTemplateId,
    generator,
    reportDate,
    status,
    createdTS,
    posts,
    targetEmails,
    location,
    signatures,
    isDeleted,
    isDocx,
    inspector,
    checklists,
    subTitle
  }) {
    this.id = id;
    this.type = type;
    this.isLocal = isLocal;
    this.uri = uri;
    this.projectId = projectId;
    this.updatedTS = updatedTS;
    this.readyToGenerateTS = readyToGenerateTS;
    this.isDailyReportV1 = isDailyReportV1;
    this.universalIds = universalIds;
    this.parentId = parentId;
    this.attachments = attachments;
    this.formTemplateId = formTemplateId;
    this.generator = generator;
    this.reportDate = reportDate;
    this.status = status;
    this.createdTS = createdTS;
    this.posts = posts;
    this.targetEmails = targetEmails;
    this.location = location;
    this.signatures = signatures;
    this.isDeleted = isDeleted;
    this.isDocx = isDocx;
    this.inspector = inspector;
    this.subTitle = subTitle;
    if(checklists){
      this.checklists = {};
      _.forEach(checklists, (currTypeChecklists) => Object.assign(this.checklists, currTypeChecklists));
    }
  }
}

export default Form;
