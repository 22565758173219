import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/remote-config";

import { v4 as uuidv4 } from "uuid";
import Mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";
import Dexie from "dexie";
import loki from "lokijs";
import "../common/lib/loki/prototype.js";
import {
  envParams,
  getAppState,
  getDispatch,
  writeLog,
} from "../common/configureMiddleware";
import { version } from "./app/lastDeploy";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import _ from "lodash";
import { getAppLang, onError } from "../common/app/funcs.js";
import { getValidAuth0Token, isTokenExpired } from "../common/auth/actions.js";
import { startToast } from "../common/app/actions.js";
import { v4 as uuidV4 } from 'uuid';
import LokiLocalDBAdapter from '../common/lib/loki/LokiLocalDBAdapter.js';
import { ACCESS_HISTORY_ID } from '../common/configureStorage/index.js';

const LokiDB = new loki("lokiCemento.db");

/**
 * @typedef StartActionModalParams
 * @property {string} title
 * @property {string} message
 * @property {Action[]} actions
 * @typedef {{
 *  title: string,
 *  onClick: () => void,
 *  type?: 'success' | 'error' | 'danger'
 * }} Action
 */

// TODO: implement rest of webFunctions
const webActions = {
  init: (config) => {
    const environment = config.flavour;
    const sentryTracesSampleRate = _.toNumber(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0;
    const release = version;

    Sentry.init({
      dsn: config.webSentryDsn,
      release,
      environment,
      integrations: [
        new BrowserTracing(),
        Sentry.metrics.metricsAggregatorIntegration(),
      ],
      tracesSampleRate : sentryTracesSampleRate
    });
  },

  app: {
    getLang: getAppLang,
    getDeviceLang: () => {
      return getDeviceLang();
    },
    getDir: () => {
      let lang = getAppLang();
      let rtl = {
        he: true
      };
      return rtl[lang] ? "rtl" : "ltr";
    },
    getVersion: () => {
      return version;
    },
    getUniqueID: (() => {
      /** @type {string} */
      let uniqueId = null;
      return () => {
        if (!uniqueId)
          uniqueId = uuidV4();

        return uniqueId;
      }
    })(),
    getModel: () => {
      return "web";
    },
    getPlatform: () => {
      return "web";
    },
    getTimeZone: () => {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		},
    setRTLbyLang: () => {},
    /**
     * @param {StartActionModalParams} modalParams
     * @returns
     */
    startActionModal: (modalParams) => {
      const dispatch = getDispatch();
      if (!dispatch) return null;

      const { title, message, actions } = modalParams;
      dispatch(
        startToast({
          title,
          message,
          actions: _.values(actions).map((action) => ({
            message: action.title,
            onClick: action.onClick,
            color: action.type,
          })),
          overlay: true,
          mandatory: true,
        })
      );
    },
  },

  firebase: {
    getFirebase: () => {
      return firebase;
    },
  },

  LogRocket: {
    getLogRocket: () => {
      return LogRocket;
    },
    startSessionRecording: () => {
      const isProd = process.env.NODE_ENV == 'production';
      if (!isProd) return;
			// This function will configure and start LogRocket
			LogRocket.init('6jlpwp/cemento');
			return LogRocket.getSessionURL(sessionURL => writeLog('info', `LogRocket session started: ${sessionURL}`));
		},
    stopSessionRecording: () => {
      // This function will shutdown the SDK and end the current session gracefully, ensuring all data from the current session is successfully uploaded.
      // If init() is called afterwards the previous session will be resumed.
			LogRocket.shutdown();
		},
  },

  localDB: {
    getInstance: () => {
      return LokiDB;
    },
    getCementoDB: (() => {
      let localDB = null;
      return () => {
        if (!localDB) {
          localDB = new LokiLocalDBAdapter(LokiDB);
        }

        return localDB;
      }
    })(),
  },

  mixpanel: {
    init: (apiToken, callback) => {
      Mixpanel.init(apiToken);
      if (callback) callback();
    },
    getMixpanel: () => {
      return Mixpanel;
    },
    registerSuperProperties: (superProps) => {
      Mixpanel.register(superProps);
    },
    set: (obj) => {
      Mixpanel.people.set(obj);
    },
    track: (event) => {
      Mixpanel.track(event, null);
    },
    trackWithProperties: (event, props) => {
      Mixpanel.track(event, props);
    },
    increment: (event, val) => {
      Mixpanel.people.increment(event, val);
    },
    timeEvent: (event) => {
      Mixpanel.time_event(event);
    },
    reset: () => {
      Mixpanel.reset();
    },
    identify: (id) => {
      Mixpanel.identify(id);
    },
  },

  bugsnag: {
    getBugsnag : () => {
      return Sentry;
    },
    notify: (title) => {
      Sentry.withScope(scope => {
        //scope.setExtras({ extraData: metadata });
        Sentry.captureException(new Error(title))
      })
    },
  },
  sentry: {
    getSentry: () => {
      return Sentry;
    },
    notify: (() => {
      const _TTL_SPAN = 2 * 1000; // 2 seconds
      const _MAX_ERRORS_COUNTER = 40;

      /** @type {{ [errorMessage: string]: number }} */
      let _errorReportsTLL = {};
      let _reportedErrorsCounter = 0;

      return (err, metadata) => {
        const nowTS = Date.now();

        if (_reportedErrorsCounter >= _MAX_ERRORS_COUNTER) {
          _reportedErrorsCounter = 0;
          _errorReportsTLL = _.pickBy(
            _errorReportsTLL,
            (reportTTL) => nowTS < reportTTL
          );
        }

        let shouldReport = true;
        if (
          typeof err === "string" &&
          _errorReportsTLL[err] &&
          nowTS < _errorReportsTLL[err]
        )
          shouldReport = false;

        if (shouldReport) {
          Sentry.withScope((scope) => {
            scope.setExtras({ extraData: metadata });
            if (err instanceof Error) Sentry.captureException(err);
            else Sentry.captureException(new Error(err));
          });
          _errorReportsTLL[err] = nowTS + _TTL_SPAN;
          _reportedErrorsCounter++;
        }
      };
    })(),
  },
  storage: {
    recordAccess: async projectId => {
      return await recordStorageAccess(projectId);
    },
    getItem: async key => {
			return await getFromLocalStorage(key);
		},
		setItem: async (key, value, disableCompression) => {
			setInLocalStorage(key, value, 1, disableCompression);
		},
		removeItem: async key => {
			await deleteItemFromLocalStorage(key);
		},
		bulkRemoveItems: keys => bulkDeleteItemFromLocalStorage(keys),
		clear: async () => {
			deleteAllInLocalStorage();
		},
		getAllKeys: () => getAllKeysInLocalStorage(),
    lokiPersist: {
      getAllKeys: async () => {
        const keys = await getLokiDexieInstance().lokiPersistence.orderBy('id').keys();
        return keys;
      },
      bulkRemoveItems: async cnames => {
        return await getLokiDexieInstance().lokiPersistence.bulkDelete(cnames);
      }
    }
  },
  localStorage: {
    get: (key) => {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : {};
    },
    set: (key, value) => {
      localStorage.setItem(key, value)
    },
    remove: (key, value) => {
      localStorage.removeItem(key, value)
    },
    upsert: (key, value) => {
      const items = JSON.parse(localStorage.getItem(key)) || {};
      const newItems = { ...items, ...value };
      localStorage.setItem(key, JSON.stringify(newItems))
    },
  },
  //fs: {
  //  writeFile : async (filePath, content, encoding) => {
  //    setInLocalStorage(filePath, content, 1)
  //  },
  //  readFile : async (filePath, encoding) => {
  //    return getFromLocalStorage(filePath)
  //  },
  //  deleteFile : async (filePath) => {
  //    setInLocalStorage(filePath, null, 1)
  //  },
  //  exists : async (filePath) => {
  //    return getFromLocalStorage(filePath) != null
  //  },
  //  getCacheDirectoryPath : () => {
  //    return "RNFetchBlob.fs.dirs.CacheDir";
  //  },
  //  getPicturesDirectoryPath : () => {
  //    return "RNFetchBlob.fs.dirs.PictureDir";
  //  },
  //  getDownloadDirectoryPath : () => {
  //    return "RNFetchBlob.fs.dirs.DownloadDir";
  //  },
  //  getDocumentDirectoryPath : () => {
  //    return "RNFetchBlob.fs.dirs.DocumentDir";
  //  },
  //  //getBase64String : (uri, callback) => {
  //  //  NativeModules.RNImageToBase64.getBase64String(uri, callback)
  //  //}
  //},

  net: {
    fetch: async (url, params) => {
      if (!params) params = {};
      if (!params.method) params.method = "GET";
      const { apiServer, servicesServer } = envParams;

      params.headers = {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        ...(params.headers || {}),
      };

      let isCementoServerCall =
        url && (url.startsWith(apiServer) || url.startsWith(servicesServer));

      if (isCementoServerCall) {
        let Authorization = params.Authorization || params.authorization;
        if (!Authorization) {
          let accessToken =
            _.isFunction(getAppState) &&
            _.get(getAppState(), ["auth", "accessToken"], null);

          if (accessToken) {
            Authorization = "Bearer " + accessToken;
            let isExpired = isTokenExpired(accessToken);

            if (isExpired) {
              const dispatch = getDispatch();
              let validTokens = await dispatch(getValidAuth0Token());
              accessToken = validTokens?.access_token;
              Authorization = "Bearer " + accessToken;
            }
          }
        }
        params.headers["Authorization"] = Authorization;
        if (params.headers['authorization']) delete params.headers['authorization'];
        if (isCementoServerCall){
          params.headers["Cemento-Release"] = webActions.app.getVersion();
          params.headers['cemento-trace-id'] = uuidv4();
        }
      }

      let response = await fetch(url, params);

      let status = _.get(response, ["status"]);
      let statusText = _.get(
        response,
        ["statusText"],
      ) || `received status code ${status}`;
      if (status < 200 || status > 299) {
        onError({
          errorMessage: `Received status code ${status}`,
          methodMetaData: {
            url,
            params,
          },
        });
        throw statusText;
      }

      response.getJson = getJson.bind(null, response);
      return response;
    },
  },
};

export default webActions;

function getDeviceLang() {
  let lang = navigator.language || navigator.userLanguage;
  let index = lang.indexOf("-");
  if (index != -1) lang = lang.slice(0, index);
  return lang;
}

const getJson = async (response) => {
  return response.json();
};



const getDexieInstance = (() => {
  let dexieInstance;
  /**
   * @returns {Dexie}
   */
  return () => {
    if (!dexieInstance) {
      dexieInstance = new Dexie("MyDatabase");
      // 350 localStorage // 4500 dexie string // 1500 dexie json
      //dexieInstance.version(1).stores({ cementoStorage: "&id, data" });
      dexieInstance.version(2).stores({ cementoStorage: "&id" });
    }
    window.dexieInstance = dexieInstance
    return dexieInstance;
  }
})();

export const getLokiDexieInstance = (() => {
  let dexieInstance;
  /**
   * @returns {Dexie & {
   *  lokiPersistence: Dexie.Table
   * }}
   */
  return () => {
    if (!dexieInstance) {
      dexieInstance = new Dexie('dexieCementoPersistance');
      //dexieInstance.version(1).stores({ lokiPersistence: '&id, projectId, collectionName, data' });
      dexieInstance.version(2).stores({ lokiPersistence: '&id, projectId, collectionName' });
    }
    return dexieInstance;
  }
})();



async function recordStorageAccess(scopeId) {
	try {
    if (!scopeId) return;
		const item = await getFromLocalStorage(ACCESS_HISTORY_ID);
		await setInLocalStorage(ACCESS_HISTORY_ID, {
			...(item || {}),
			[scopeId]: Date.now(),
		});
	} catch (e) {
		onError({
			errorMessage: 'Faild to update in LOCALSTORAGE',
			error: e,
			methodMetaData: {
				name: 'recordStorageAccess',
				args: { scopeId },
			},
		});
	}
}

const getAllKeysInLocalStorage = async () => {
	const keys = await getDexieInstance().cementoStorage.orderBy('id').keys();
	return keys;
};

async function setInLocalStorage(cname, cvalue) {
  if (_.isNil(cvalue) || cvalue === 'null') {
    webActions.sentry.notify(
      "ERROR: failed to set item with empty value in local storage",
      { itemKey: cname, itemValue: cvalue }
    );
    return;
  }

  try {
    let compressed = cvalue;
    await getDexieInstance().cementoStorage.put({
			id: cname,
			createdAt: Date.now(),
      lastAccessedAt: Date.now(),
			data: compressed,
		});
  } catch (e) {
    const localStorageQuota = await navigator?.storage.estimate() || {};

    onError({
      errorMessage: "Faild to save to LOCALSTORAGE",
      error: e,
      methodMetaData: {
        name: "setInLocalStorage",
        args: { cname },
      },
      errorMetaData: {
        quota: localStorageQuota.quota,
        usage: localStorageQuota.usage,
        storagePercentageUsed: `${(
          (localStorageQuota.usage / localStorageQuota.quota) *
          100
        ).toFixed(2)}%`,
        availableStorage: `${localStorageQuota.quota / 1000000} MB`,
        usedStorage: `${localStorageQuota.usage / 1000000} MB`,
      },
    });

    if (
      e.name === "QuotaExceededError" ||
      (e.inner && e.inner.name === "QuotaExceededError")
    )
      return;
  }
}

async function getFromLocalStorage(cname) {
  let value = await getDexieInstance()
    .cementoStorage.where({ id: cname })
    .first();
    
  let ret = value ? value.data : null;
  return ret;
}

async function deleteItemFromLocalStorage(cname) {
  await getDexieInstance().cementoStorage.where("id").equals(cname).delete();
}

async function deleteAllInLocalStorage() {
  await getDexieInstance().cementoStorage.where("id").notEqual("null").delete();
}

async function bulkDeleteItemFromLocalStorage(cnames) {
	return await getDexieInstance().cementoStorage.bulkDelete(cnames);
}
