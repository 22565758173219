import * as ioEvents from '../lib/io/eventTypes';
import { checklistItemsInstanceSchema, commentSchema, employeeSchema, equipmentSchema, postsSchema, propertyInstanceSchema } from '../lib/realm/schema';
import { SUBJECTS } from './subjects';

export const dataManagerEvents = {
  SET_DATA_READY: 'SET_DATA_READY',
  SET_NEW_STATUS: 'SET_NEW_STATUS',
  UNSET_ALL_PROJECTS_DATA: 'UNSET_ALL_PROJECTS_DATA',
  SET_CONNECTION_STATUS: 'SET_CONNECTION_STATUS',
  SET_STORAGE_STATUS: 'SET_STORAGE_STATUS',
};

export const dataManagerStates = {
  STORAGE_STATUS: 'storageStatus',
  CONNECTION_STATUS: 'connectionStatus',
};

export const dataManagerStatuses = {
  STORAGE_LOADED: 'storageLoaded',
  DATA_RECEIVED: ioEvents.DATA_RECEIVED
}

export const IS_DATA_READY = 'isDataReady';

export const subjectsToStoragePaths = {
  [SUBJECTS.BUILDINGS]: [['buildings', 'map']],
  [SUBJECTS.FLOORS]: [['floors', 'map']],
  [SUBJECTS.UNITS]: [['units', 'map']],
  [SUBJECTS.CONFIGURATIONS]: [
    ['configurations', 'map'],
    ['configurations', 'debugConfigurations'],
  ],
  [SUBJECTS.PROJECT]: [['projects', 'detailsMap']],
  [SUBJECTS.PROPERTIES_TYPES]: [
    ['propertiesTypes', 'projectSections'],
    ['propertiesTypes', 'projectProperties'],
  ],
  [SUBJECTS.PROPERTIES_MAPPINGS]: [['propertiesMappings', 'map']],
  [SUBJECTS.STAGES]: [['stages', 'map']],
  [SUBJECTS.CHECKLISTS]: [
    ['checklists', 'subscriptions'],
    ['checklists', 'map'],
  ],
  [SUBJECTS.CHECKLIST_ITEMS]: [['checklistItems', 'map']],
  [SUBJECTS.DRAWINGS]: [
    ['drawings', 'map'],
    ['drawings', 'settings'],
  ],
  [SUBJECTS.CHECKLIST_ITEM_INSTANCES]: [
    ['checklistItemsInstances', 'sync'],
    ['checklistItemsInstances', 'lastSynced'],
  ],
  [SUBJECTS.PROPERTIES_INSTANCES]: [
    ['propertiesInstances', 'sync'],
    ['propertiesInstances', 'lastSynced'],
    ['propertiesInstances', 'recentlySigningUsers'],
  ],
  [SUBJECTS.POSTS]: [
    ['posts', 'sync'],
    ['posts', 'lastSynced'],
  ],
  [SUBJECTS.FORMS]: [['forms', 'map']],
  [SUBJECTS.TRADES]: [
    ['trades', 'recentlyTrades'],
    ['trades', 'recentlyUsersPerTrades'],
  ],
  [SUBJECTS.SAFETY]: [['safety', 'safetyGrades']],
  [SUBJECTS.REPORTS]: [
    ['reports', 'map'],
    ['reports', 'inspectorReports'],
  ],
};

// Some subjects have params that we want to ignore, these are the subjects we actually care about
export const subjectsWithQueryParams = {
  [SUBJECTS.PROPERTIES_TYPES]: 'subjectName',
  [SUBJECTS.PROPERTIES_MAPPINGS]: 'subjectName',
  [SUBJECTS.PROPERTIES_INSTANCES]: 'subjectName',
  [SUBJECTS.FORMS]: 'formType',
  [SUBJECTS.CHECKLIST_ITEM_INSTANCES]: 'subjectName',
};

export const objectDefinitionSubjects = [
  SUBJECTS.CONFIGURATIONS,
  SUBJECTS.STAGES,
  SUBJECTS.CHECKLISTS,
  SUBJECTS.CHECKLIST_ITEMS,
  SUBJECTS.BUILDINGS,
  SUBJECTS.FLOORS,
  SUBJECTS.UNITS,
  SUBJECTS.PROJECT,
  SUBJECTS.PROPERTIES_TYPES,
  SUBJECTS.PROPERTIES_MAPPINGS,
];

export const realmMapperToSubject = {
  [commentSchema.schema.name]: SUBJECTS.COMMENTS,
  [postsSchema.schema.name]: SUBJECTS.POSTS,
  [checklistItemsInstanceSchema.schema.name]: SUBJECTS.CHECKLIST_ITEM_INSTANCES,
  [propertyInstanceSchema.schema.name]: SUBJECTS.PROPERTIES_INSTANCES,
  [employeeSchema.schema.name]: SUBJECTS.EMPLOYEES,
  [equipmentSchema.schema.name]: SUBJECTS.EQUIPMENT,
};
