import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import ImageCarousel from '../../../components/CementoComponents/ImageCarousel';
import InputField from '../../../components/CementoComponents/InputField';
import Text from '../../../components/CementoComponents/Text';
import systemMessages from '../../../../common/app/systemMessages';
import theme from '../../../assets/css/theme';
import editPen from '../../../assets/img/icons/editPen.png';
import MenuScrollbar from '../../../components/CementoComponents/MenuScrollbar';
import propertiesMessages from '../../../../common/propertiesTypes/propertiesMessages';
import { startToast } from '../../../../common/app/actions';
import * as propertyTypes from '../../../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { getRelevantPropertyIdsByData} from '../../../../common/propertiesInstances/funcs.js';
import Button from '../../../app/standardComponents/Button';
import { isEmptyValue } from '../../../../common/app/funcs';
import reportsMessages from '../../../../common/reports/reportsMessages';
import safetyMessages from '../../../../common/safety/safetyMessages';
import projectsMessages from '../../../../common/projects/projectsMessages';
import CementoReactFragment from '../../../../common/app/components/CementoReactFragment';
import { getSectionsReadAndUpdatePermissions, safeToJS } from '../../../../common/permissions/funcs';
import PropertiesSection from './PropertiesSection';
import PropertiesPostsSelector from './PropertiesPostsSelector';
import PrevNextSwitch from './PropertiesPrevNextSwitch';
import PropertiesPropInput from './PropertiesPropInput';
import UglyPDFReadyToPrint from './UglyPDFReadyToPrint';
import useRefed from '../../../../common/hooks/useRefed';
import PrevFormHandler from '../../../../common/forms/PrevFormHandler';
import { getApiSourceObjectsFromContext, getPropTypeByTag } from '../../../../common/propertiesTypes/funcs';
import { POST_TYPES_SUB_GROUP_TAG, modals } from '../../../../common/app/constants';
import FormSignatureModal from "../../../../common/images/FormSignatureModal.js"
import useStateLoading from '../../../../common/hooks/useLoadingState.js';
import FeatureModal from '../../../components/CementoComponents/FeatureModal.js';

const getPostsSelectionSectionTitle = (intl, sectionTitle, postsType) => {
	// I know this function contains duplicate code that is also present in inspection review page but its temporary code that should be removed soon
	let sectionTitleString = '';
	if (sectionTitle && sectionTitle.defaultMessage) sectionTitleString = intl.formatMessage(sectionTitle);
	else if (typeof sectionTitle === 'string') sectionTitleString = sectionTitle;

	const postTypeTitleString = intl.formatMessage(
		reportsMessages.selectors[postsType] ? reportsMessages.selectors[postsType] : reportsMessages.selectors.tasksOrDoc,
	);
	sectionTitleString = sectionTitleString ? `${sectionTitleString} - ${postTypeTitleString}` : postTypeTitleString;

	return sectionTitleString;
};

const defaultProps = {
	mode: 'page',
	onSave: async () => true,
	isCreateMode: false,
	editMode: false,
	useMenusScrollsBar: true,
	showSections: true,
	isEditable: true,
};

/** @param {ObjectPropertiesPageUIProps} props */
let ObjectPropertiesPageUI = props => {
	const {
		mode,
		subjectName,
		instances,
		useMenusScrollsBar,
		onCardLoad,
		objectTypeName,
		objectName,
		onInnerObjectSelect,
		isFormClosed,
		isCreateMode,
		isEditable,
		onDelete,
		onCancel,
		onSave,
		onClose,
		onPrint,
		onChange,
		tableMethods = {},
		statusBadgeParams,
		setCardHeaderParams,
		types,
		sections: propSections,
		allSections: allPropSections,
		mapping,
		filteredProperties,
		intl,
		shouldShowPostsSections,
		extraSections,
		popInnerCardQueue,
		selectedPosts: propsSelectedPosts = null,
		extraHeaderComponent,
		setHeaderParams,
		onGroupIdChange,
		onDisableChange,
		onEditModeChange,
		editMode,
		startToast,
		addCertButtonTitle,
		objectId,
		onDeleteObject,
		onObjectCreate,
		hideNavigation,
		initialGroupId,
		initialSubGroupId,
		showSections,
		scrollTargetSectionId,
		isForceUpdateEnabled, // TODO: remove and refactor usage
		onRef,
		formReportDate,
		formType,
		projectId,
		hideLocalMock,
		formTemplateId,
		isPermittedToSign,
		track,
		viewer,
		nextSignature,
		hideCopyFromPreviousDailyButton
	} = props;

	const { rtl } = useSelector(state => ({ rtl: state.app.rtl }));
	const [isDisabled, setIsDisabled] = useState(!(isCreateMode || editMode));
	const [updatesToIgnore, setUpdatesToIgnore] = useState({});
	const [updatesByPropId, setUpdatesByPropId] = useState({});
	const [allDataByPropId, setAllDataByPropId] = useState({});
	const [apiSourceMetadata, setApiSourceMetadata] = useState({});
	const [instancesByPropId, setInstancesByPropId] = useState({});
	const [presentFileParams, setPresentFileParams] = useState(null);
	const [selectedPosts, setSelectedPosts] = useState(propsSelectedPosts);
	const [selectedTabId, setSelectedTabId] = useState(null);
	const [relevantPropsByType, setRelevantPropsByType] = useState({});
	const [prevFormData, setPrevFormData] = useState(null);
	const [openModals, setOpenModals] = useState({});
	const { loadingMap, setLoading } = useStateLoading();
	
	
	/** @type [PropertiesSection[], React.Dispatch<React.SetStateAction<PropertiesSection[]>>] */
	const [sections, setSections] = useState([]);

	const didInitData = useRef(false);

	const inputsRefs = useRef({});

	const isCardOrModal = useMemo(() => ['card', 'modal'].includes(mode), [mode]);

	const useCollapsibleSections = useMemo(
		() => (!_.isNil(props.useCollapsibleSections) ? props.useCollapsibleSections : mode !== 'card'),
		[mode, props.useCollapsibleSections],
	);

	const shouldHideCertifications = useMemo(
		() => (!_.isNil(props.shouldHideCertifications) ? props.shouldHideCertifications : !isDisabled),
		[isDisabled, props.shouldHideCertifications],
	);

	const selectedGroupId = useMemo(() =>
		_.first(_.keys(allDataByPropId['groups']) || ''),
		[allDataByPropId['groups']]
	);

	const hasChanges = useMemo(() =>
		_.keys(_.omit(updatesByPropId, ['groups'])).length ||
			!_.isEqual(selectedPosts, propsSelectedPosts),
		[updatesByPropId, selectedPosts, propsSelectedPosts],
	);

	// useEffect(() => {
	// 	// For when in card or modal mode when onObjectCreate is available, to open the side card
	// 	if (onObjectCreate) onObjectCreate(selectedGroupId);
	// }, [selectedGroupId]);

	const lastSideCardPushParams = useRef(null);
	const pushSideCardIntoStack = useCallback(
		/** @type {OnInnerObjectSelectFunc} */
		(type, cardProps, ComponentToRender) => {
			if (onInnerObjectSelect)
				onInnerObjectSelect(type || 'nothingToDisplay', cardProps || {}, ComponentToRender || null);

			lastSideCardPushParams.current = [type, cardProps, ComponentToRender];
		},
		[onInnerObjectSelect],
	);

	useEffect(() => {
		if (!isCreateMode) setIsDisabled(true);
		if (lastSideCardPushParams.current) lastSideCardPushParams.current = null;
	}, [objectId, objectName, isCreateMode]);

	useEffect(() => {
		setIsDisabled(!isCreateMode && !editMode);
	}, [editMode, isCreateMode]);

	useEffect(() => {
		if (isDisabled) {
			setUpdatesByPropId({});
			setSelectedPosts(null);
		}
		if (onDisableChange) onDisableChange(isDisabled);
		if (onEditModeChange) onEditModeChange(!isDisabled);
		if (hideNavigation) hideNavigation(!isDisabled);
		if (lastSideCardPushParams.current) pushSideCardIntoStack(...lastSideCardPushParams.current);
	}, [isDisabled]);

	useEffect(() => {
		if (!isDisabled && !isForceUpdateEnabled && didInitData.current) {
			return;
		}
		if (_.keys(instances).length) {
			let newAllDataByPropId = {};
			let newInstancesByPropId = {};
			_.values(instances).forEach(instance => {
				newAllDataByPropId[instance.propId] = instance?.data;
				newInstancesByPropId[instance.propId] = instance;
			});

			_.keys(updatesToIgnore).forEach(path => {
				const shouldAddToData = updatesToIgnore[path];
				if (shouldAddToData) {
					const pathArray = path.split('/');
					const oldData = _.get(newAllDataByPropId, [pathArray], {});
					const data = _.get(allDataByPropId, pathArray)
					const mergedData = typeof data === 'object' ? _.merge(oldData, data) : data;
					_.set(newAllDataByPropId, pathArray, mergedData);
				}
			})

			setAllDataByPropId(newAllDataByPropId);
			setInstancesByPropId(newInstancesByPropId);
			didInitData.current = true;
			setSelectedPosts(propsSelectedPosts);
		}
	}, [instances, isDisabled]);

	useEffect(() => {
		// Init group id;
		if (!isDisabled && !allDataByPropId['groups']) {
			let groupIdToSet = null;
			if (initialGroupId) groupIdToSet = initialGroupId;
			else if (types?.['groups']?.values?.length === 1) groupIdToSet = _.first(types['groups'].values).id;

			if (groupIdToSet) handleDataUpdate('groups', { [groupIdToSet]: groupIdToSet });
			
			if(initialSubGroupId){
				const subGroupProps = getPropTypeByTag({ projectId, subjectName, groupId: groupIdToSet, tag: POST_TYPES_SUB_GROUP_TAG });
				const subGroupPropId = _.chain(subGroupProps).values().head().get(['id']).value();
				if (subGroupPropId) handleDataUpdate(subGroupPropId, { [initialSubGroupId]: initialSubGroupId });
			}
		}
	}, [types, allDataByPropId, isDisabled, initialGroupId, initialSubGroupId, projectId, subjectName]);

	const handleClose = useCallback(() => {
		if (onClose && ['modal', 'card'].includes(mode)) onClose();
	}, [onClose, mode]);

	const handleDelete = useCallback(async () => {
		if (onDelete) {
			const didDelete = await onDelete();
			if (didDelete) handleClose();
			if (onDeleteObject) onDeleteObject(didDelete, objectId);
		}
	}, [onDelete, handleClose, onDeleteObject, objectId]);

	const handleCancel = useCallback(async () => {
		const isCancelConfirmed = await new Promise(resolve => {
			if (!hasChanges || isDisabled)
				resolve(true);
			else
				startToast({
					overlay: true,
					mandatory: true,
					title: systemMessages.manage.leaveWithoutSave,
					message: systemMessages.manage.changesHaveNotBeenSaved,
					actions: [
						{ message: systemMessages.yes, color: 'success', onClick: () => resolve(true) },
						{ message: systemMessages.no, onClick: () => resolve(false) },
					],
				});
		});

		if (isCancelConfirmed) {
			setIsDisabled(true);
			if (onCancel) onCancel();
			if (isCreateMode) handleClose();
		}

		return isCancelConfirmed;
	}, [onCancel, handleClose, hasChanges, startToast, isCreateMode, isDisabled]);

	const handleInputRef = useCallback((id, element, component) => {
		if (!element)
			_.unset(inputsRefs.current, [id]);
		else if (element?.scrollIntoView)
			_.set(inputsRefs.current, [id], { element, component });
	}, []);

	const handleCheckInputsErrors = useCallback(() => {
		let errors = {};

		_.entries(inputsRefs.current).forEach(([propId, inputRef]) => {
			let errorsArr = inputRef.component.checkErrors();
			if (errorsArr) errors[propId] = errorsArr.join(', ');
		});

		return { hasErrors: Object.keys(errors).length > 0, errors };
	}, []);

	const handleDisplayInputsErrors = useCallback(
		errorsArr => {
			startToast({
				title: systemMessages.invalidDetailsDescription,
				type: 'error',
				values: { errors: errorsArr.join('\n') },
			});
		},
		[startToast],
	);

	const filterUpdates = useCallback(() => {
		let filteredUpdates = _.cloneDeep(updatesByPropId);

		Object.keys(updatesToIgnore).forEach(path => {
			const shouldIgnore = updatesToIgnore[path];
			if (shouldIgnore) {
				const pathToRemove = path.split('/');
				_.unset(filteredUpdates, pathToRemove)
			}
		})
	
		return filteredUpdates;
	},[updatesByPropId, updatesToIgnore])

	const handleSave = useCallback(
		async (inUpdates, isSilent = false, forceSave) => {

			const filteredUpdatesByPropId = forceSave ? updatesByPropId : filterUpdates();
			
			const updates = {
				...inUpdates,
				...filteredUpdatesByPropId,
			};
			let saveSuccess = true;

			const { hasErrors, errors } = handleCheckInputsErrors();
			if (hasErrors) {
				handleDisplayInputsErrors(Object.values(errors));
				saveSuccess = false;
			} else if (onSave) {
				saveSuccess = (await onSave(updates, selectedPosts, isSilent)) !== false;
			}

			if (saveSuccess) {
				if (isCreateMode && onObjectCreate)
					onObjectCreate(selectedGroupId, objectId);
				setIsDisabled(true);
			}


			return saveSuccess;
		},
		[
			onObjectCreate,
			onSave,
			handleCheckInputsErrors,
			handleDisplayInputsErrors,
			isCreateMode,
			updatesByPropId,
			updatesToIgnore,
			selectedPosts,
			selectedGroupId,
			objectId,
		],
	);

	useEffect(() => {
		onRef?.({ handleSave });
	}, [onRef, handleSave]);

	const handlePrint = useCallback(async () => {
		if (onPrint) {
			const { src, description = '', pdfMode = false } = (await onPrint()) || {};
			if (src) setPresentFileParams({ files: [{ src, description }], pdfMode });
		}
	}, [onPrint, setPresentFileParams]);

	const headerActionsRef = useRefed({ handleSave, handleCancel, handlePrint, handleDelete, setIsDisabled }, [
		handleSave,
		handleCancel,
		handlePrint,
		handleDelete,
		setIsDisabled,
	]); // for header params to use the correct version of these functions

	const handleRecalcHeader = useCallback(() => {
		if (mode !== 'page') return;

		const headerComponent = (
			<>
				<div style={{ display: 'flex', alignItems: 'center', fontSize: theme.fontSizeH6, fontWeight: theme.strongBold }}>
					<Text style={{ marginLeft: 3 * theme.verticalMargin, marginRight: 2 * theme.verticalMargin }}>
						{objectName}
					</Text>
				</div>
				{isDisabled ? (
					<div
						style={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row-reverse',
							alignItems: 'center',
							height: '100%',
							margin: `0px ${theme.verticalMargin}px`,
						}}
					>
						<div
							style={{ display: 'flex', alignItems: 'center', [rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin }}
							onClick={() => headerActionsRef.current.setIsDisabled(false)}
						>
							<div style={{ cursor: 'pointer', margin: theme.verticalMargin }}>
								<img src={editPen} />
							</div>
						</div>
						{extraHeaderComponent}
						<UglyPDFReadyToPrint
							mode={mode}
							sections={sections}
							objectName={objectName}
							allDataByPropId={allDataByPropId}
							subjectName={subjectName}
							objectId={objectId}
						/>
					</div>
				) : (
					<div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse', height: '100%', margin: `0px ${theme.verticalMargin}px` }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								[rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin,
								color: theme.brandPrimary,
							}}
							onClick={() => headerActionsRef.current.handleSave()}
						>
							<Text style={{ cursor: 'pointer', margin: theme.verticalMargin, fontSize: theme.fontSizeH6 }}>{propertiesMessages.save}</Text>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								[rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin,
							}}
							onClick={() => headerActionsRef.current.handleCancel()}
						>
							<Text style={{ cursor: 'pointer', margin: theme.verticalMargin, fontSize: theme.fontSizeH6 }}>
								{propertiesMessages.cancel}
							</Text>
						</div>
					</div>
				)}
			</>
		);

		if (setHeaderParams)
			setHeaderParams({ headerComponent, sideBarParams: { open: false } });
	}, [
		isDisabled,
		setHeaderParams,
		mode,
		objectName,
		subjectName,
		objectId,
		allDataByPropId,
		sections,
		extraHeaderComponent,
	]);

	useEffect(() => {
		handleRecalcHeader();
	}, [extraHeaderComponent, isDisabled, objectName, objectId, sections, mode, subjectName, allDataByPropId]);

	// CARD STUFF
	const handleShowNothingToDisplaySideCard = useCallback(() => {
		if (mode === 'modal') pushSideCardIntoStack();
	}, [pushSideCardIntoStack, mode]);

	useEffect(handleShowNothingToDisplaySideCard, [objectId, objectName]); // Show default 2nd side card

	const handleCardTabSelect = useCallback(
		/** @param {string} tabId */
		tabId => {
			setSelectedTabId(tabId);
			setTimeout(() => setSelectedTabId(null), 500);
		},
		[setSelectedTabId],
	);

	useEffect(() => {
		if (scrollTargetSectionId)
			handleCardTabSelect(scrollTargetSectionId);
	}, [objectId, scrollTargetSectionId]);

	const handleBackOrCloseClick = () => {
		lastSideCardPushParams.current = null;	
	}

	const getCardHeaderParams = useCallback(() => {
		let headerParams = {
			title: mode === 'modal' ? objectTypeName : objectName,
			statusBadgeParams,
			editable: isEditable,
			onCancel: () => headerActionsRef.current.handleCancel(),
			onSave: () => headerActionsRef.current.handleSave(),
			onBackOrClose: handleBackOrCloseClick,
			middleComponent: objectName && mode === 'modal' && (
				<PrevNextSwitch
					isShowArrows={isDisabled}
					title={objectName}
					onPrevClick={tableMethods.goPreviousRow}
					onNextClick={tableMethods.goNextRow}
				/>
			),
		};
		if (isPermittedToSign) headerParams.onSign = () => {
			track('Signing form in web', {
				formId: objectId,
				formType,
				formTemplateId
			})
			setOpenModals({ [modals.SIGNATURE_MODAL]: { id: modals.SIGNATURE_MODAL } });
		}
		if (onDelete) headerParams.onDelete = () => headerActionsRef.current.handleDelete();

		if (onPrint) headerParams.onPrint = () => headerActionsRef.current.handlePrint();

		return headerParams;
	}, [tableMethods, isDisabled, handleDelete, handlePrint, objectName, mode, isEditable, statusBadgeParams, isPermittedToSign, objectId, formTemplateId, formType]);

	const handleUpdateCardParams = useCallback(() => {
		if (!onCardLoad || isDisabled) return;

		const headerParams = getCardHeaderParams();

		const tabs = sections
			.filter(
				currSection => !currSection.isCertificationSection || _.get(allDataByPropId, [currSection.properties[0]?.id]),
			)
			.map(currSection => ({ href: currSection.id, title: currSection.title }))
			.slice(0, 4);

		const tabParams = {
			onTabSelect: handleCardTabSelect,
			tabs,
		};

		onCardLoad(headerParams, tabParams, (editMode || isCreateMode), isCreateMode, objectTypeName);
	}, [sections, onCardLoad, editMode, isCreateMode, objectTypeName, getCardHeaderParams, isDisabled]);

	useEffect(() => {
		handleUpdateCardParams();
	}, [objectId, editMode, sections, isDisabled, objectTypeName, allDataByPropId, subjectName, statusBadgeParams]);

	useEffect(() => {
		if (setCardHeaderParams) setCardHeaderParams(getCardHeaderParams());
	}, [objectId, objectName]);

	// CARD STUFF END

	// INPUTS STUFF

	const handleDataUpdate = useCallback(
		/**
		 *
		 * @param {string} propId
		 * @param {any} data
		 */
		(propId, data, status, context) => {
			handleApiSource({ context, propId, newData: data });

			setUpdatesByPropId(currState => ({
				...currState,
				[propId]: data,
			}));
			setAllDataByPropId(currState => ({
				...currState,
				[propId]: data,
			}));

			if (onGroupIdChange && propId === 'groups' && _.keys(data)[0] !== selectedGroupId)
				onGroupIdChange(_.keys(data)[0]);

			onChange?.(propId, data, status);
		},
		[onGroupIdChange, selectedGroupId, updatesToIgnore, setUpdatesToIgnore, apiSourceMetadata],
	);

	const handleApiSource = useCallback(({ context, propId, newData }) => {
		let apiSourceObjects = getApiSourceObjectsFromContext(context) || [];
		const loadedFromApiSource = context?.isFiredByServer;

		if (!apiSourceObjects.length && context?.isArrayOfArrays) {
			const oldData = allDataByPropId[propId];
			const oldIds = _.keys(oldData);
			const newVal = _.values(newData).find(value => !oldIds.includes(value.id));
			
			if (newVal) {
				apiSourceObjects.push({
					pathToValue: `${propId}/${newVal.id}`,
					isUnsyncable: true,
					loadedFromApiSource: false,
				})
			}
		}
		
		if (apiSourceObjects.length) {
			let newApiSourceMetadata = {};
			let newUpdatesToIgnore = {};

			apiSourceObjects.forEach(apiSourceObject => {
				const isLoadedFromApiSource = 'loadedFromApiSource' in apiSourceObject ? apiSourceObject.loadedFromApiSource : loadedFromApiSource;
				const oldMetadata = apiSourceMetadata[apiSourceObject.pathToValue];
				newApiSourceMetadata[apiSourceObject.pathToValue] = {
					pathToValue: apiSourceObject.pathToValue,
					isUnsyncable: apiSourceObject.isUnsyncable || oldMetadata?.isUnsyncable,
					loadedFromApiSource: isLoadedFromApiSource,
					apiSourceValue: apiSourceObject.apiSourceValue || oldMetadata?.apiSourceValue
				}
				if (!apiSourceObject.forceUpdate) newUpdatesToIgnore[apiSourceObject.pathToValue] = isLoadedFromApiSource;
			})
			setUpdatesToIgnore(currState => ({
				...currState,
				...newUpdatesToIgnore
			}))
			setApiSourceMetadata(currState => ({
				...currState,
				...newApiSourceMetadata
			}))
		} else {
			setApiSourceMetadata(currState => ({
				...currState,
				[propId]: {
					pathToValue: propId,
					isUnsyncable: currState[propId]?.isUnsyncable,
					loadedFromApiSource: Boolean(loadedFromApiSource),
					apiSourceValue: currState[propId]?.apiSourceValue
				}
			}))
		}
	},[apiSourceMetadata, allDataByPropId])

	const handlePresentImage = useCallback(
		/**
		 *
		 * @param {string} description
		 * @param {string} src
		 * @param {boolean} pdfMode
		 */
		(description, src, pdfMode) => {
			setPresentFileParams({
				pdfMode,
				files: [{ src, description }],
			});
		},
		[setPresentFileParams],
	);

	const handlePresentFileClose = useCallback(() => {
		setPresentFileParams(null);
	}, [setPresentFileParams]);

	const handlePopInnerCardQueue = useCallback(() => {
		lastSideCardPushParams.current = null;
		popInnerCardQueue?.();
	}, [popInnerCardQueue]);

	const handleAddOrEditCert = useCallback(
		(props, addMode = false) => {
			const numOfProps = _.keys(props).length;
			const initialCertificationProp = numOfProps === 1 ? _.values(props)[0] : null;
			const certificationTypes = numOfProps > 1 ? props : null;

			const cardProps = {
				addMode,
				initialCertificationProp,
				certificationTypes,
				subjectName,
				editMode: true,
				instancesByPropertyId: instancesByPropId,
				onDone: async (certPropId, certData) => {
					const saveSuccess = await handleSave({ [certPropId]: certData });
					if (saveSuccess) handlePopInnerCardQueue();
					return saveSuccess;
				},
				onBackOrClose: handleBackOrCloseClick,
			};
			
			pushSideCardIntoStack('addCertification', cardProps);
		},
		[handlePopInnerCardQueue, pushSideCardIntoStack, subjectName, instancesByPropId, handleSave],
	);

	const handleToggleCancelCertification = useCallback(
		async (prop , isCertificationCanceled) => {
			let instanceData = _.get(instancesByPropId, [prop.id, 'data']);
			const clone = [ ...instanceData ];
			const lastCertIndex = instanceData.length - 1;

			const isSaveConfirmed = await new Promise(resolve => {
					startToast({
						overlay: true,
						mandatory: true,
						title: systemMessages.confirmSaveChangesAlert.title,
						message: systemMessages.confirmSaveChangesAlert.content,
						actions: [
							{ message: systemMessages.yes, color: 'success', onClick: () => resolve(true) },
							{ message: systemMessages.no, onClick: () => resolve(false) },
						],
					});
			});

			if (!isSaveConfirmed) return;

			if (isCertificationCanceled) {
				delete clone[lastCertIndex].isCanceled;
			} else {
				clone[lastCertIndex].isCanceled = true;
			}

			await handleSave({ [prop.id]: clone });
	}, [instancesByPropId])

	const handleRenderPostsComponent = useCallback(
		(postsType, isSafetyPostsOnly) => (
			<PropertiesPostsSelector
				isDisabled={isDisabled}
				postsType={postsType}
				isSafetyPostsOnly={isSafetyPostsOnly}
				onChange={newSelectedPosts => setSelectedPosts(newSelectedPosts)}
				openSideCard={pushSideCardIntoStack}
				selectedPosts={selectedPosts}
			/>
		),
		[isDisabled, setSelectedPosts, pushSideCardIntoStack, selectedPosts],
	);

	const handleRenderPostsComponentRef = useRefed(handleRenderPostsComponent, [handleRenderPostsComponent]);
	// INPUTS STUFF END

	const populateSubSections = useCallback((parentSections, subSectionsArray, updatePermissionsMap) => {
		const sectionsMap = {};

		const mockParentSection = {
			id: 'MOCK_SECTION_ID',
			title: { he: 'כללי', en: 'General' },
		};

		parentSections.push(mockParentSection);

		parentSections.forEach((section) => {
			const isUnpermittedToWrite = updatePermissionsMap.unpermitted[section?.id];
			let title = section.getCementoTitle();
			if (isUnpermittedToWrite) title += ` (${intl.formatMessage(safetyMessages.forms.subStatus.dailyReport.signed)})`
			sectionsMap[section.id] = {
				id: section.id,
				title,
				subSections: [],
			};
		});

		let ordinalNo = 0;
		subSectionsArray.forEach((subSection) => {
		const section = parentSections.find((section) =>{
			return Object.keys(section.subSectionIds || {}).includes(subSection.id)
		});

		if (!section) {
			sectionsMap['MOCK_SECTION_ID'].subSections.push(subSection);
			return;
		}

		if (!sectionsMap[section.id].ordinalNo) sectionsMap[section.id].ordinalNo = ordinalNo++;
			sectionsMap[section.id].subSections.push(subSection);
		});
		return Object.values(sectionsMap)
		.filter((section) => _.size(section.subSections))
		.sort((a, b) => a.ordinalNo - b.ordinalNo);
	}, []);

	const getReadAndWritePermissions = useCallback(() => {
		return getSectionsReadAndUpdatePermissions({
			viewer,
			projectId,
			objectId,
			subjectName,
			allPropertiesSections: _.merge(allPropSections, propSections),
			nextSignature
		});
	}, [viewer, objectId, nextSignature]);

	const getSections = useCallback(() => {
		let index = 0;
		/** @type {{ [sectionId: string]: PropertiesSection }} */
		let subSections = {};
		/** @type {{ [propId: string]: string }} */
		let objectRelevantPropsIdsMap = {};
		let relevantPropsByType = {};

		// TODO: use getRelevantProperties func(new prop mappings)
		const defaultSubjectName = subjectName || 'defaultSubject';
		const relevantPropTypesIdsArr = getRelevantPropertyIdsByData(
			{ [defaultSubjectName]: mapping },
			defaultSubjectName,
			selectedGroupId,
			allDataByPropId,
			types,
		);

		// Get all relevant columns

		relevantPropTypesIdsArr?.forEach?.(propId => {
			objectRelevantPropsIdsMap[propId] = propId;
		});

		//  Build props default ordinalNo from mapping
		let propertiesFallbackOrdinalNo = {};
		_.values(safeToJS(mapping)).forEach(groupingPropertyGroups => {
			_.keys(groupingPropertyGroups || {}).forEach(groupId => {
				groupingPropertyGroups.getNested2([groupId, 'properties'], []).forEach((mappedPropId, index) => {
					propertiesFallbackOrdinalNo[mappedPropId] = index;
				});
			});
		});

		const { readPermissionsMap , updatePermissionsMap } = getReadAndWritePermissions();

		const parentSections = Object.values(allPropSections || {}).filter(section => section.subSectionIds);
		_.forIn(relevantPropTypesIdsArr, (propId, key, collection) => {
			let currProp = types?.[propId];
			if (!currProp) return;

			const parentSection = parentSections.find((section) =>
				Object.keys(section.subSectionIds || {}).includes(currProp.sectionId)
			);
		
            const isUnpermittedToRead = readPermissionsMap.unpermitted[parentSection?.id] || readPermissionsMap.unpermitted[currProp.sectionId];
            if (isUnpermittedToRead) return;

			const isUnpermittedToWrite = updatePermissionsMap.unpermitted[currProp.sectionId];
			
			const shouldHideGroupInput = (propId === 'groups' && selectedGroupId && isCardOrModal && isCreateMode);
			if (shouldHideGroupInput || hideLocalMock && currProp.isLocalMock)
				return;

			if (currProp && objectRelevantPropsIdsMap[propId])
				_.set(relevantPropsByType, [currProp.type, currProp.id], currProp);

			if (
				!currProp ||
				!objectRelevantPropsIdsMap[propId] ||
				(filteredProperties && !filteredProperties[currProp.id]) ||
				!currProp.sectionId ||
				currProp.isExtra ||
				(currProp.id === 'groups' && _.get(currProp, ['values', 'length']) <= 1) ||
				(shouldHideCertifications && currProp.type === propertyTypes.CERTIFICATION)
			)
				return;

			index = currProp.ordinalNo || _.values(collection).length;
			if (!subSections[currProp.sectionId]) {
				let section = propSections?.[currProp.sectionId] || {};
				let sectionTitle = section.title?.id ? intl.formatMessage(section.title) : section.getNested(['getTitle'])
				subSections[section.id] = {
					id: section.id,
					title: sectionTitle,
					properties: [],
					ordinalNo: index,
					isOpen: section.isOpen,
					isCertificationSection: Boolean(currProp.type === propertyTypes.CERTIFICATION),
					isSupportedCertBehaviour: Boolean(
						!_.get(currProp, ['settings', 'signatureBehaviour']) ||
							_.get(currProp, ['settings', 'signatureBehaviour', propertyTypes.UPLOAD_FILE, 'enabled'], false) ||
							_.get(currProp, ['settings', 'signatureBehaviour', propertyTypes.SIGN_ON_TEXT, 'enabled'], false)
					),
					isRoleAppointmentSection: Boolean(
						currProp.type === propertyTypes.CERTIFICATION &&
							_.get(currProp, ['settings', 'certificationType']) === propertyTypes.CERTIFICATIONS_TYPES.roleAppointment,
					),
				};
			}

			const currentSectionOrdinalNum = _.get(subSections, [currProp.sectionId, 'ordinalNo'], 0);
			if (currentSectionOrdinalNum < index) _.set(subSections, [currProp.sectionId, 'ordinalNo'], index);

			const propValues = currProp.values
				? currProp.values.map(v => {
						return { id: v.id, title: v.getNested(['getTitle'], '') };
					})
				: null;
			subSections[currProp.sectionId].properties.push({
				id: currProp.id,
				type: currProp.type,
				extraTypes: (currProp.extraTypes || []).map(extraPropId => {
					const prop = types?.[extraPropId] || {};
					const title = prop.getNested(['getTitle']);
					const propOBJ = prop.toJS ? prop.toJS() : prop;
					return { ...(propOBJ || {}), title, value: allDataByPropId[extraPropId] };
				}),
				prop: currProp,
				isDisabled: isUnpermittedToWrite,
				settings: currProp.settings,
				businessType: currProp.businessType,
				universalId: currProp.universalId,
				isPrimary: currProp.isPrimary,
				title: currProp.getNested(['getTitle']),
				values: propValues,
				ordinalNo:
					currProp.ordinalNo || currProp.ordinalNo === 0
						? currProp.ordinalNo
						: propertiesFallbackOrdinalNo[currProp.id],
			});

			
			if (currProp.type === propertyTypes.CERTIFICATION) {
        		const instanceData = instancesByPropId[currProp.id]?.data;
        		if (instanceData && _.last(instanceData)?.isCanceled) {
          			subSections[currProp.sectionId].isCertificationCanceled = true;
        		}
     		}
		});

		let largestOrdinalNo = 0;
		// Reorder properties by ordinalNo
		let sortedSubSections = Object.values(subSections);
		sortedSubSections.forEach(section => {
			section.properties = section.properties.sort((a, b) => a.ordinalNo - b.ordinalNo);
			section.ordinalNo = (section.properties[0] || {}).ordinalNo || section.ordinalNo;
			largestOrdinalNo = section.ordinalNo > largestOrdinalNo ? section.ordinalNo : largestOrdinalNo;
		});

		sortedSubSections.sort((a, b) => a.ordinalNo - b.ordinalNo);

		let sections = null;

		if (parentSections.length) sections = populateSubSections(parentSections, sortedSubSections, updatePermissionsMap);
		else sections = [{
			id: 'MOCK_SECTION_ID',
			subSections: sortedSubSections
		}];

		if (shouldShowPostsSections) {
			sections.push({
				id: 'postsSection',
				title : { en: 'Posts and Documentations', he: 'משימות ותיעודים'},
				subSections: [
					{
						id: 'safetyTaskPostsSelect',
						title: getPostsSelectionSectionTitle(intl, safetyMessages.safetyTitle, 'tasks'),
						renderFunc: () => handleRenderPostsComponentRef.current('tasks', true),
						ordinalNo: ++largestOrdinalNo,
					},
					{
						id: 'safetyRecordsPostsSelect',
						title: getPostsSelectionSectionTitle(intl, safetyMessages.safetyTitle, 'records'),
						renderFunc: () => handleRenderPostsComponentRef.current('records', true),
						ordinalNo: ++largestOrdinalNo,
					},
					{
						id: 'taskPostsSelect',
						title: getPostsSelectionSectionTitle(intl, projectsMessages.dashboard, 'tasks'),
						renderFunc: () => handleRenderPostsComponentRef.current('tasks'),
						ordinalNo: ++largestOrdinalNo,
					},
					{
						id: 'recordsPostsSelect',
						title: getPostsSelectionSectionTitle(intl, projectsMessages.dashboard, 'records'),
						renderFunc: () => handleRenderPostsComponentRef.current('records'),
						ordinalNo: ++largestOrdinalNo,
					}
				]
			})
		}

		if (extraSections) {
			// check what tha means ffs
			extraSections.forEach(section => {
				if (section.renderFunc) sortedSubSections.push({ ...section, ordinalNo: ++largestOrdinalNo });
			});
		}

		return {
			objectRelevantPropsIdsMap,
			sections,
			relevantPropsByType,
		};
	}, [
		instancesByPropId,
		allDataByPropId,
		shouldShowPostsSections,
		shouldHideCertifications,
		extraSections,
		selectedGroupId,
		subjectName,
		mapping,
		types,
		filteredProperties,
		propSections,
	]);

	useEffect(() => {
		const { sections: newSections, relevantPropsByType: newRelevantPropsByType } = getSections();
		if (!_.isEqual(newSections, sections)) setSections(newSections);
		if (!_.isEqual(newRelevantPropsByType, relevantPropsByType)) setRelevantPropsByType(newRelevantPropsByType);
	}, [types, propSections, getSections, mapping, selectedGroupId, extraSections, isDisabled, allDataByPropId]);

	const handleOpenModal = useCallback((modalContext) => {
		let modalName = modalContext.featureId ? modals.FEATURE_MODAL : modalContext.id;
		setOpenModals({ [modalName]: modalContext })
	},[openModals])

	const modal = useMemo(() => {
		if (openModals[modals.SIGNATURE_MODAL]) {
			return (
				<FormSignatureModal
					formReportDate={formReportDate}
					formType={formType}
					formTemplateId={formTemplateId}
					signObjectId={objectId}
					projectId={projectId}
					onClose={() => { setOpenModals({}) }} 
					onDone={() => handleSave({}, true, true)}
					isOpen={true}>
				</FormSignatureModal>
			)
		} else if (openModals[modals.FEATURE_MODAL]) {
			const openModal = openModals[modals.FEATURE_MODAL];
			return (
				<FeatureModal
					id={openModal.id}
					featureId={openModal.featureId}
					onClose={() => { setOpenModals({}) }} 
                    />
			)
		}
	},[openModals])

	// RENDER

	const Wrapper = useMemo(() => (useMenusScrollsBar ? MenuScrollbar : CementoReactFragment), [useMenusScrollsBar]);

	if (isCardOrModal && isCreateMode) {
		if (!selectedGroupId) {
			// select group if there are more then one
			const groupsProp = types?.['groups'];
			if (!groupsProp) return null;
			return (
				<div style={{ padding: theme.padding + theme.margin }}>
					<Text style={{ ...styles.headerSectionStyle, [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin - 2 }}>
						{propertiesMessages.selectGroup}
					</Text>
					<InputField
						id={'newObject_groupSelect'}
						key={'newObject_groupSelect'}
						inputKey={'newObject_groupSelect'}
						propId={'groups'}
						mode={'card'}
						disabled={isDisabled}
						prop={groupsProp}
						type={groupsProp.type}
						settings={groupsProp.settings}
						businessType={groupsProp.businessType}
						universalId={groupsProp.universalId}
						extraTypes={groupsProp.extraTypes}
						values={groupsProp.values}
						onChange={newData => handleDataUpdate('groups', newData)}
					/>
				</div>
			);
		}
	}

	return (
		<Wrapper scrollbarsStyle={{ overflowX: 'hidden' }} isSmooth={true}>
			{modal}
			{subjectName === 'formsInfo' && <PrevFormHandler onPrevForm={setPrevFormData} formReportDate={formReportDate} formType={formType} />}
			{sections.map(section => {
				const subSections = section.subSections;
				return subSections.map((subSection, index) => {
					const isCertSectionWithoutData =
					subSection.isCertificationSection && isEmptyValue(allDataByPropId[subSection.properties[0]?.id]);
					if (isCertSectionWithoutData) return null;
					return ( 
						<>
							{index === 0 && section.title &&
								<div style={{ 
									height: '53px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontSize: theme.fontSizeH5,
									fontWeight: theme.strongBold,
									color: theme.brandPrimary
								}}>{section.getCementoTitle()}</div>
							}
							<PropertiesSection
								key={subSection.id}
								section={subSection}
								shouldScrollToSection={selectedTabId === subSection.id}
								onEditCert={prop => handleAddOrEditCert([prop])}
								onAddCert={prop => handleAddOrEditCert([prop], true)}
								useCollapsibleSections={useCollapsibleSections}
								showSection={showSections}
								onToggleCancelCertification={handleToggleCancelCertification}
								isCertificationCanceled={subSection.isCertificationCanceled}
							>
								{subSection.renderFunc
									? subSection.renderFunc()
									: subSection.properties?.map?.(prop => {
										return 	<PropertiesPropInput
										objectId={objectId}
										prop={prop}
										key={prop.id + subSection.id}
										onChange={handleDataUpdate}
										onImageSelect={handlePresentImage}
										isDisabled={isDisabled || prop.isDisabled}
										mode={mode}
										subjectName={subjectName}
										extraPropertiesTypes={types}
										openSideCard={pushSideCardIntoStack}
										onRef={(e, c) => handleInputRef(_.get(c, ['props', 'propId']) || prop.id, e, c)}
										value={allDataByPropId[prop.id]}
										optionalDefaultValue={prevFormData?.[prop.id]?.data}
										isCreateMode={isCreateMode}
										isCertificationCanceled={subSection.isCertificationCanceled}
										prevFormDataLoaded={Boolean(prevFormData)}
										formReportDate={formReportDate}
										apiSourceMetadata={apiSourceMetadata}
										isFormClosed={isFormClosed}
										loadingMap={loadingMap}
										setLoading={setLoading}
										onOpenModal={handleOpenModal}
										initInstanceId={prevFormData?.[prop.id]?.id}
										instanceId={instancesByPropId[prop.id]?.id}
										hideCopyFromPreviousDailyButton={hideCopyFromPreviousDailyButton}
									/>
									})}
							</PropertiesSection>
						</>
					);
				})
			})}
			{Boolean(
				addCertButtonTitle && !isDisabled && isCardOrModal && _.keys(relevantPropsByType.Certification).length,
			) && (
				<Button
					data-qa="add-cert-button"
					title={addCertButtonTitle}
					style={{ margin: `${theme.padding}px auto` }}
					onClick={() => handleAddOrEditCert(relevantPropsByType.Certification, true)}
				/>
			)}
			{Boolean(presentFileParams) && (
				<ImageCarousel
					pdfMode={presentFileParams.pdfMode}
					onClose={handlePresentFileClose}
					initialSlide={0}
					items={presentFileParams.files}
				/>
			)}
		</Wrapper>
	);
};

const enhance = compose(connect(null, { startToast }), injectIntl);
ObjectPropertiesPageUI = enhance(ObjectPropertiesPageUI);
ObjectPropertiesPageUI.defaultProps = defaultProps;

export default ObjectPropertiesPageUI;

const styles = {
	headerSectionStyle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: `${theme.padding + theme.margin}px 0px`,
		color: theme.brandPrimary,
		fontFamily: 'Assistant - Semi Bold',
		fontSize: theme.fontSizeH6,
		fontWeight: theme.strongBold,
		marginBottom: 0,
	},
};
