import { useState, useEffect, useCallback, useMemo } from 'react';

const noop = x => x;

const useCachedRequest = ({ requestFunc, requestParams, cacheId = 'v1', mapResponse = noop, onCache = noop, onResponse = noop }) => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const request = useMemo(() => {
		return requestFunc(requestParams);
	}, []);

	useEffect(() => {
		fetchData();
	}, []);


	const fetchData = useCallback(async () => {
		const cache = await caches.open(cacheId);
		await getCachedValue(cache);
		await fetchFreshData(cache);
	}, [cacheId]);

	const getCachedValue = useCallback(
		async cache => {
			const cachedRequest = await cache.match(request);

			if (cachedRequest) {
				const cachedData = await cachedRequest.clone().json();
				const mappedResult = await mapResponse(cachedData);
				setData(mappedResult);
				onCache(mappedResult);
				setIsLoading(false);
			}
		},
		[setData, onCache, mapResponse],
	);

	const fetchFreshData = useCallback(
		async cache => {
			if (!data) setIsLoading(true);
			const result = await request;
            const response = new Response(JSON.stringify(result), {
              headers: { 'Content-Type': 'application/json' },
            });
			await cache.put(request, response);
			const mappedResult = await mapResponse(result);
			setData(mappedResult);
			onResponse(mappedResult);
			setIsLoading(false);
		},
		[request, data, setData, onCache, mapResponse],
	);

	return { data, isLoading, refetch: fetchData };
};

export default useCachedRequest;
