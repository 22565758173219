import { Map, OrderedMap } from 'immutable';
import { DEFAULT_SYSTEM_LANGUAGE } from './app/constants';
import { getAppState } from './configureMiddleware';
import { platformActions } from './platformActions';
import _ from 'lodash';
require('immutable-js-debuglog');

///////////////////////////////////////////////////////
////////////////////// getNested //////////////////////
///////////////////////////////////////////////////////

Map.prototype.getNested = function (path, defaultVal) {
  return baseGetNested(this, path, defaultVal);
};
OrderedMap.prototype.getNested = function (path, defaultVal) {
  return baseGetNested(this, path, defaultVal);
};
Object.defineProperty(Object.prototype, 'getNested', {
  value: function (path, defaultVal) {
    return baseGetNested(this, path, defaultVal);
  },
  enumerable: false,
});
Object.defineProperty(Object.prototype, 'isValDiff', {
  value: function (otherObject, pathArray, defaultVal) {
    return baseGetNested(this, pathArray, defaultVal) != baseGetNested(otherObject, pathArray, defaultVal);
  },
  enumerable: false,
});
String.prototype.baseFileName = function (delimiter) {
  return this.substr(0, this.lastIndexOf(delimiter)) || this + '';
};

function baseGetNested(obj, path, defaultVal) {
  var ret = obj;

  try {
    for (var i = 0; i < path.length; i++) {
      let currPath = path[i];
      if (currPath == 'getTitle') return ret.getCementoTitle ? ret.getCementoTitle() : defaultVal;
      if (ret[currPath] || ret[currPath] === 0 || ret[currPath] === false) ret = ret[currPath];
      else if (ret.get && (ret.get(currPath) || ret.get(currPath) === 0 || ret.get(currPath) === false))
        ret = ret.get(currPath);
      else return defaultVal;
    }
  } catch (err) {
    return defaultVal;
  }

  return ret;
}

///////////////////////////////////////////////////////
////////////////////// getNested2 //////////////////////
///////////////////////////////////////////////////////

Map.prototype.getNested2 = function (path, defaultVal) {
  return this.getIn(path, defaultVal);
};
OrderedMap.prototype.getNested2 = function (path, defaultVal) {
  return this.getIn(path, defaultVal);
};
Object.defineProperty(Object.prototype, 'getNested2', {
  value: function (path, defaultVal) {
    return baseGetNested2(this, path, defaultVal);
  },
  enumerable: false,
});
Object.defineProperty(Object.prototype, 'isValDiff2', {
  value: function (otherObject, pathArray, defaultVal) {
    return baseGetNested2(this, pathArray, defaultVal) != baseGetNested2(otherObject, pathArray, defaultVal);
  },
  enumerable: false,
});

Object.defineProperty(Object.prototype, 'getCementoTitle', {
  value: function (isUseDefaultLangAsFallback = true) {
    if (!this.title) return '';

    let title = this.title;
    if (typeof title === 'object') {
      let lang =
        getAppState() && getAppState().app && getAppState().app.lang
          ? getAppState().app.lang
          : platformActions.app.getLang();
      title = title[lang] || (isUseDefaultLangAsFallback ? title[DEFAULT_SYSTEM_LANGUAGE] : '') || '';
    }

    return title;
  },
  enumerable: false,
});

// TODO: Change this to use getCemenoTitle
Object.defineProperty(Object.prototype, 'getCementoStringByLang', {
  value: function () {
    let currLang = getAppState().getNested2(['app', 'lang']);
    let ret = this.getNested2([currLang]) || this.getNested2([platformActions.app.getLang()]);
    if (ret) return ret;
    ret = Object.keys(this).length ? Object.values(this)[0] : '';
    return ret;
  },
  enumerable: false,
});

function baseGetNested2(obj, path, defaultVal) {
  if (!path || path.length == 0) return obj || defaultVal;

  if (Boolean(obj[path[0]] && obj[path[0]].getIn)) {
    let mainPath = path[0];
    path.shift();
    let ret = obj[mainPath].getIn(path, defaultVal);

    return ret;
  }

  if (path && path[path.length - 1] == 'getTitle') {
    path.pop();
    if (path.length) return _.get(obj, path, defaultVal || {}).getCementoTitle();
    else return obj.getCementoTitle();
  }

  let ret = _.get(obj, path, defaultVal);
  return ret;
}

///////////////////////////////////////////////////////
////////////////////// setNested2 //////////////////////
///////////////////////////////////////////////////////

Map.prototype.setNested2 = function (path, setValue) {
  return baseSetNested2(this, path, setValue);
};
OrderedMap.prototype.setNested2 = function (path, setValue) {
  return baseSetNested2(this, path, setValue);
};
Object.defineProperty(Object.prototype, 'setNested2', {
  value: function (path, setValue) {
    return baseSetNested2(this, path, setValue);
  },
  enumerable: false,
});

function baseSetNested2(obj, path, setValue) {
  if (!path) return null;

  let ret = _.setWith(_.clone(obj), path, setValue, _.clone);
  return ret;
}

///////////////////////////////////////////////////////
////////////////////// setNested //////////////////////
///////////////////////////////////////////////////////

Map.prototype.setNested = function (path, setValue) {
  return baseSetNested(this, path, setValue);
};
OrderedMap.prototype.setNested = function (path, setValue) {
  return baseSetNested(this, path, setValue);
};
Object.defineProperty(Object.prototype, 'setNested', {
  value: function (path, setValue, useLoadsh) {
    return baseSetNested(this, path, setValue, useLoadsh);
  },
  enumerable: false,
});

function baseSetNested(obj, path, setValue, useLoadsh) {
  if (useLoadsh) return baseSetNested2(obj, path, setValue);
  if (!path) return null;

  if (path.length > 1) {
    let a = obj.getNested([path[0]]);
    let editedValue = baseSetNested(a || {}, path.slice(1), setValue);
    if (obj.set) {
      obj = obj.set(path[0], editedValue);
    } else {
      obj = Object.assign({}, obj);
      obj[path[0]] = editedValue;
    }
    return obj;
  } else {
    let ret = null;
    if (obj.set) ret = obj.set(path[0], setValue);
    else {
      ret = Object.assign({}, obj);
      ret[path[0]] = setValue;
    }
    return ret;
  }
}

///////////////////////////////////////////////////////
//////////////////////// loop /////////////////////////
///////////////////////////////////////////////////////

Map.prototype.loopEach = function (func) {
  return baseForeach(this, func);
};
OrderedMap.prototype.loopEach = function (func) {
  return baseForeach(this, func);
};
Object.defineProperty(Object.prototype, 'loopEach', {
  value: function (func) {
    return baseForeach(this, func);
  },
  enumerable: false,
});

// If func returns FALSE during the iteration - the iteration will stop
function baseForeach(collection, func) {
  if (collection) {
    try {
      if (Array.isArray(collection)) {
        for (let i = 0; i < collection.length; i++) {
          if (func(i, collection[i], collection) === false) break;
        }
      } else if (Map.isMap(collection) || OrderedMap.isOrderedMap(collection)) {
        collection.forEach((v, k) => func(k, v, collection));
      } else {
        let keysSet = Object.keys(collection);
        for (var i = 0; i < keysSet.length; i++) {
          let key = keysSet[i];
          let value = collection[key];
          if (func(key, value, collection) === false) break;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

String.prototype.replaceAll = function (find, replace) {
  return this.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

// safeToJs
Map.prototype.safeToJS = function (func) {
  return baseSafeToJs(this);
};
OrderedMap.prototype.safeToJS = function () {
  return baseSafeToJs(this);
};
Object.defineProperty(Object.prototype, 'safeToJS', {
  value: function () {
    return baseSafeToJs(this);
  },
  enumerable: false,
});

function baseSafeToJs(obj) {
  return obj && obj.toJS && typeof obj.toJS === 'function' ? obj.toJS() : obj;
}

Object.defineProperty(Object.prototype, 'realmToObject', {
  value: function () {
    const isNative = platformActions.app.isNative();
    if (isNative && typeof this.toJSON === 'function') {
      return this.toJSON();
    } else {
      return this;
    }
  },
  writable: true,
  configurable: true,
  enumerable: false,
});