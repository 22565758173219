import { getDispatch } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import { tradesEvents } from './tradesEvents';

export async function getTrades(viewer) {
  const dispatch = getDispatch();

  const scopeParams = { scope: 'global' };
  const resourceParams = {
    resourceName: 'trades',
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({
      type: tradesEvents.GET_TRADES,
      payload: { trades: data, immediateGlobalStorageSave: true, lastUpdateTS: 1 },
    });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) {
    dispatch({
      type: tradesEvents.GET_TRADES,
      payload: { trades: result, immediateGlobalStorageSave: true },
    });
  }
}
