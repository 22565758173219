import theme from '../assets/css/theme.js';

import "../assets/scss/material-dashboard-pro-react.css?v=1.3.0";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "../components/Snackbar/Snackbar.jsx";
import Button from "../components/CustomButtons/Button.jsx";
import notificationsStyle from "../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import { PropagateLoader } from "react-spinners";
import _ from "lodash";
import { compose } from "recompose";
import { connect, useSelector } from "react-redux";
import { injectIntl, useIntl } from "react-intl";
import { useEffect, useMemo, useRef, useState } from 'react';

const toastTypeColorMap = {
  warning: 'warning',
  error: 'danger',
  success: 'success',
};

const Transition = (props) => {
  return <Grow in={props.open} {...props} />;
};

const Alerts = ({ classes }) => {
  const { rtl, toast, loading } = useSelector((state) => ({
    rtl: state.app.rtl,
    toast: state.app.toast,
    loading: state.app.loading,
  }));

  const intl = useIntl();

  const [alertVisible, setAlertVisible] = useState(false);
  const [sideToastVisible, setSideToastVisible] = useState(false);
  const toastTimeoutRef = useRef(null);

  const showToast = (nextToast) => {
    const sideToastVisible = Boolean(nextToast && !nextToast.overlay);
    const alertVisible = Boolean(nextToast && nextToast.overlay);
    const timeout = nextToast?.overwriteTimeout || 5000;

    if (sideToastVisible) {
      if (toastTimeoutRef.current) clearTimeout(toastTimeoutRef.current);

      toastTimeoutRef.current = setTimeout(() => {
        setSideToastVisible(false);
      }, timeout);
    }

    return { sideToastVisible, alertVisible };
  };

  const closeToast = () => {
    setSideToastVisible(false);
  };

  const closeAlert = () => {
    setAlertVisible(false);
  };

  useEffect(() => {
    let newAlertVisible = Boolean(loading);
    let newSideToastVisible = false;

    if (toast) {
      const toastVisibility = showToast(toast);
      newAlertVisible = toastVisibility.alertVisible || newAlertVisible;
      newSideToastVisible = toastVisibility.sideToastVisible;
    }

    setAlertVisible(newAlertVisible);
    setSideToastVisible(newSideToastVisible);

    return () => {
      if (toastTimeoutRef.current) clearTimeout(toastTimeoutRef.current);
    };
  }, [toast, loading]);

  const { color, toastTitle } = useMemo(() => {
    let _toastTitle = {};
    let values = {};

    if (toast?.values) {
      if (typeof toast.values === 'object') {
        _.keys(toast.values).forEach((key) => {
          values[key] =
            typeof toast.values[key] === 'object' ? intl.formatMessage(toast.values[key]) : toast.values[key];
        });
      } else values = toast.values;
    }

    if (toast?.title?.id) _toastTitle = intl.formatMessage(toast.title, values);
    else _toastTitle = toast?.title || '';

    return {
      color: toastTypeColorMap[toast?.type],
      toastTitle: _toastTitle,
    };
  }, [toast]);

  const modalClasses = loading ? classes.modalSmall : classes.modalBig;

  return (
    <div style={styles.container}>
      {/* SIDE TOAST */}
      <Snackbar
        close={toast?.close}
        place={rtl ? 'br' : 'bl'}
        open={sideToastVisible}
        closeNotification={closeToast}
        color={color}
        message={toastTitle}
      />

      {/* ALERT MODAL */}
      <Dialog
        classes={{
          root: `${classes.center} ${classes.modalRoot}`,
          paper: `${classes.modalJustify} ${modalClasses}`,
        }}
        style={styles.modal(rtl)}
        open={alertVisible}
        keepMounted
        TransitionComponent={Transition}
        onClose={toast?.actions || toast?.mandatory || (loading && !loading?.hideOnBackgroundPress) ? null : closeAlert}
        aria-labelledby='small-modal-slide-title'
        aria-describedby='small-modal-slide-description'>
        {toast && !loading && (
          <DialogTitle id='small-modal-slide-title' disableTypography className={classes.modalHeader}>
            {!toast.mandatory && !toast.actions && (
              <Button
                justIcon
                className={classes.modalCloseButton}
                onClick={closeAlert}
                aria-label='Close'
                color='transparent'>
                <Close className={classes.modalClose} />
              </Button>
            )}
            {toastTitle}
          </DialogTitle>
        )}
        <DialogContent
          data-qa='modal-content'
          id='small-modal-slide-description'
          className={`${classes.modalBody} ${classes.modalSmallBody}`}>
          {loading?.toast?.title && <h5>{intl.formatMessage(loading.toast.title, loading.toast.values)}</h5>}
          {loading && (
            <div data-qa='loading-spinner' style={styles.loadingSpinner}>
              <PropagateLoader size={15} color={theme.brandPrimary} loading />
            </div>
          )}
          {toast?.message && !loading && (
            <h5 style={{ direction: 'inherit' }}>
              {toast.message.defaultMessage
                ? intl.formatMessage(toast.message, toast.values)
                : toast.message.split('\n').map((x) => (
                    <>
                      {x}
                      <br />
                    </>
                  ))}
            </h5>
          )}
        </DialogContent>
        {toast?.actions && !loading && (
          <DialogActions className={`${classes.modalFooter} ${classes.modalFooterCenter}`}>
            {toast.actions.map((currAction) => (
              <Button
                key={currAction.message.id}
                data-qa={currAction.message.id}
                className={classes.modalFooterActionButton}
                onClick={() => {
                  currAction.onClick?.();
                  closeAlert();
                }}
                color={currAction.color || 'transparent'}
                simple
                style={styles.actionButton(currAction.color)}>
                {currAction.message.defaultMessage ? intl.formatMessage(currAction.message) : currAction.message}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

const styles = {
  container: { display: 'flex', flex: 1 },
  modal: (rtl) => ({
    zIndex: theme.zIndexes.alertsModal,
    direction: rtl ? 'rtl' : 'ltr',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  loadingSpinner: {
    display: 'flex',
    flex: 1,
    margin: 15,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: (color) =>
    color === 'success'
      ? { backgroundColor: theme.brandPrimary, color: 'white', fontWeight: 900 }
      : { border: '1px solid #A2A2A2' },
};

const enhance = compose(
  injectIntl,
  connect(state => ({
    loading: state.app.loading,
    toast: state.app.toast,
  }), {}),
)

export default enhance(withStyles(notificationsStyle)(Alerts))
