import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmRemoveTitle: {
    defaultMessage: "Delete permit",
    id: "properties.confirmRemoveTitle"
  },
  confirmRemoveMessage:  {
    defaultMessage: "Are you sure you want to delete permit?",
    id: "properties.confirmRemoveMessage"
  },
  confirmAndSign: {
    defaultMessage: "Confirm and sign",
    id: "properties.confirmAndSign"
  },
  confirmAndAddCertificate: {
    defaultMessage: "Confirm & add permit",
    id: "properties.confirmAndAddCertificate"
  },
  confirmAndAddReviews: {
    defaultMessage: "Confirm & add reviews",
    id: "properties.confirmAndAddReviews"
  },
  empty: {
    "defaultMessage": "-",
    "id": "properties.inputs.empty"
  },
  datePlaceholder: {
    "defaultMessage": "Select date",
    "id": "properties.placeholders.date"
  },
  attachment: {
    "defaultMessage": "attachment",
    "id": "properties.files.attachment"
  },
  attachedFiles: {
    "defaultMessage": "Attached files",
    "id": "properties.files.attachedFiles"
  },
  seeAttachedFiles: {
    "defaultMessage": "See attached files:",
    "id": "properties.files.seeAttachedFiles"
  },
  loadingAttachedFiles:{
    "defaultMessage": "Loading attached files...",
    "id": "properties.files.loadingAttachedFiles"
  },
  attacheNewDoc: {
    "defaultMessage": "Attach signed documents",
    "id": "properties.files.attacheNewDoc"
  },
  selectFiles: {
    "defaultMessage": "Select files",
    "id": "properties.files.selectFiles"
  },
  addPicture: {
    "defaultMessage": "Add Picture",
    "id": "properties.files.addPicture"
  },
  addVideo: {
    "defaultMessage": "Add Video",
    "id": "properties.files.addVideo"
  },
  addFile: {
    "defaultMessage": "Add file",
    "id": "properties.files.addFile"
  },
  scanFile: {
    "defaultMessage": "Scan file",
    "id": "properties.files.scanFile"
  },
  save: {
    "defaultMessage": "Save",
    "id": "properties.actions.save"
  },
  cancel: {
    "defaultMessage": "Cancel",
    "id": "properties.actions.cancel"
  },
  edit: {
    "defaultMessage": "Edit",
    "id": "properties.actions.edit"
  },
  select: {
    "defaultMessage": "Select",
    "id": "properties.actions.select"
  },
  ok: {
    "defaultMessage": "Ok",
    "id": "properties.actions.ok"
  },
  alerts: {
    "defaultMessage": "Alerts",
    "id": "properties.actions.alerts"
  },
  selectCectification: {
    "defaultMessage": "Select Permit",
    "id": "properties.actions.selectCectification"
  },
  details:
  {
    "defaultMessage": "details",
    "id": "properties.title.details"
  }, 
  certification: {
    employeesInfo: {
      "defaultMessage": "Permit",
      "id": "properties.certification"
    },
    equipmentInfo: {
      "defaultMessage": "Review",
      "id": "properties.review"
    }
  },
  reviewIssueDefaultText:{
    "defaultMessage": "Found issue during {reviewTitle}",
    "id": "properties.reviewIssueDefaultText"
  },
  certifications: {
    employeesInfo: {
      "defaultMessage": "Permits",
      "id": "properties.certifications"
    },
    equipmentInfo: {
      "defaultMessage": "Reviews and documentations",
      "id": "properties.reviews"
    }
  },
  roleAppointment: {
    "defaultMessage": "Role appointment",
    "id": "properties.roleAppointment"
  },
  roleAppointments: {
    "defaultMessage": "Role appointments",
    "id": "properties.roleAppointments"
  },
  addRoleAppointment: {
    "defaultMessage": "Add role appointment",
    "id": "properties.addRoleAppointment"
  },
  addCertification: {
    "defaultMessage": "Add Permit",
    "id": "properties.actions.addCertification"
  },
  addCertificationPlus: {
    "defaultMessage": "Add Permit",
    "id": "properties.actions.addCertificationPlus"
  },
  editCertification: {
    "defaultMessage": "Edit Permit",
    "id": "properties.actions.editCertification"
  },
  cancelCertification: {
    "defaultMessage": "Cancel Certification",
    "id": "properties.actions.cancelCertification"
  },
  activateCertification: {
    "defaultMessage": "Activate Certification",
    "id": "properties.actions.activateCertification"
  },
  documentATraining: {
    "defaultMessage": "Add permit",
    "id": "properties.actions.documentATraining"
  },
  renewTraining: {
    "defaultMessage": "Update permit",
    "id": "properties.actions.renewTraining"
  },
  selectATraining: {
    "defaultMessage": "Select permit",
    "id": "properties.actions.selectATraining"
  },
  selectADocument: {
    "defaultMessage": "Select document",
    "id": "properties.actions.selectADocument"
  },
  certificationDate: {
    employeesInfo: {
      "defaultMessage": "Certification Date",
      "id": "properties.certDate.employeesInfo"
    },
    equipmentInfo: {
      "defaultMessage": "Inspection Date",
      "id": "properties.certDate.equipmentInfo"
    },
  },
  roleAppointmentDate: {
    "defaultMessage": "Appointment Date",
    "id": "properties.roleAppointmentDate"
  },
  signatureDate: {
    "defaultMessage": "Signature Date",
    "id": "properties.signatureDate"
  },
  expirationDate: {
    "defaultMessage": "Expiration Date",
    "id": "properties.expirationDate"
  },
  validityDuration: {
    "defaultMessage": "Validity duration ({type})",
    "id": "properties.validityDuration"
  },
  validityDurationTypes: {
    certificationMonthsTTL: {
      "defaultMessage": "Months",
      "id": "properties.validityDuration.months"
    },
    certificationDaysTTL: {
      "defaultMessage": "Days",
      "id": "properties.validityDuration.days"
    }
  },
  expired: {
    "defaultMessage": "Expired",
    "id": "properties.dates.expired"
  },
  closeExpiration: {
    "defaultMessage": "Expiration is close",
    "id": "properties.dates.closeExpiration"
  },
  info: {
    "defaultMessage": "Info",
    "id": "properties.info.info"
  },
  history: {
    "defaultMessage": "History",
    "id": "properties.info.history"
  },
  errors: {
    withSectionName: {
      "defaultMessage": "{sectionName} - {error}",
      "id": "properties.errors.withSectionName"
    },
    mandatory: {
      "defaultMessage": "{propName} - is a mandatory field",
      "id": "properties.errors.mandatory"
    },
    stillCompressing: {
      "defaultMessage": "Video is still compressing, please wait",
      "id": "properties.errors.compressing"
    },
    numbersOnly: {
      "defaultMessage": "{propName} - Only numbers are valid",
      "id": "properties.errors.numbersOnly"
    },
    mustHaveTTL: {
      "defaultMessage": "Permit must have Signature date and duration",
      "id": "properties.errors.mustHaveTTL"
    },
    mustHaveStartAndEndTime: {
      "defaultMessage": "{propName} - must have a start and end time",
      "id": "properties.errors.mustHaveStartAndEndTime"
    },
    rangeEndMustBeBiggerThanStart: {
      "defaultMessage": "{propName} - the start time must be earlier than the end time",
      "id": "properties.errors.rangeEndMustBeBiggerThanStart"
    },
    mustHaveFile: {
      "defaultMessage": "Permit must have a file",
      "id": "properties.errors.mustHaveFile"
    },
    forbiddenChars: {
      "defaultMessage" : "{propName} - invalid input",
      "id": "properties.errors.forbiddenChars"
    }
  },
  selectGroup: {
    defaultMessage: 'Select group',
    id: 'properties.objects.selectGroup'
  },
  removeEmployee: {
    defaultMessage: 'Are you sure you want to remove this worker?',
    id: 'properties.objects.removeEmployee'
  },
  removeEquipment: {
    defaultMessage: 'Are you sure you want to remove this equipment?',
    id: 'properties.objects.removeEquipment'
  },
  removeGroup: {
    defaultMessage: 'Are you sure you want to remove this group?',
    id: 'properties.objects.removeGroup'
  },
  removeEmployeeTitle: {
    defaultMessage: 'Delete worker',
    id: 'properties.objects.removeEmployeeTitle'
  },
  removeEquipmentTitle: {
    defaultMessage: 'Delete equipment',
    id: 'properties.objects.removeEquipmentTitle'
  },
  addEquipmentReview: {
    "defaultMessage": "Add review",
    "id": "properties.equipment.addEquipmentReview"
  },
  renewAddEquipmentReview: {
    "defaultMessage": "+ Update review",
    "id": "properties.equipment.renewAddEquipmentReview"
  },
  renew: {
    "defaultMessage": "Renew",
    "id": "properties.certification.renew"
  },
  add: {
    "defaultMessage": "Add",
    "id": "properties.certification.add"
  },
  moreInformation: {
    defaultMessage: 'More Information',
    id: 'properties.objects.moreInformation'
  },
  details: {
    defaultMessage: 'Details',
    id: 'properties.objects.details'
  },
  noReport: {
    title: {
      "defaultMessage": "Welcome to the specification screen",
      "id": "properties.noReport.title"
    },
    content: {
      "defaultMessage": "In the specification screen you can manage and view tenants and units spec.\nTo start using the specification screens, please contact Cemento team at info@cemento.ai",
      "id": "properties.noReport.content"
    }
  },
  picture: {
    change: {
      "defaultMessage": "Change picture",
      "id": "properties.picture.change"
    }
  },
  print:{
    sign:{
      "defaultMessage": "Signature:",
      "id": "properties.print.sign"
    },
    date:{
      "defaultMessage": "Production date",
      "id": "properties.print.date"
    },
    producer:{
      "defaultMessage": "Producer",
      "id": "properties.print.producer"
    }
  },
  objectsNames: {
    employees: {
      "defaultMessage": "Worker",
      "id": "properties.objectsNames.employees"
    },
    equipment: {
      "defaultMessage": "Equipment",
      "id": "properties.objectsNames.equipment"
    },
    locations: {
      "defaultMessage": "Location Spec",
      "id": "properties.objectsNames.locations"
    },
  },
  signedFiles: {
    defaultMessage: 'Signed files',
    id: 'properties.signedFiles'
  },
  signedFile: {
    defaultMessage: 'Signed {title}',
    id: 'properties.signedFile'
  },
  selectPropType: { // TODO_ILANN: translate
    defaultMessage: 'Select a field',
    id: 'properties.selectPropType'
  },
  deleteInnerType: {
    title: {
      defaultMessage: 'Deleting card',
      id: 'properties.deleteInnerType.title',
    },
    message: {
      defaultMessage: 'Are you sure you wish to remove this card?',
      id: 'properties.deleteInnerType.message',
    },
  },
  enableApiSource: {
    title: {
      defaultMessage: 'Sync with Access Control',
      id: 'properties.enableApiSource.title',
    },
    message: {
      defaultMessage: 'Note, the automatic update of employee data from the access control system will overwrite the manually entered employee count.',
      id: 'properties.enableApiSource.message',
    },
  },
  itemWithNumber: {
    defaultMessage: 'Item {number}',
    id: 'properties.itemWithNumber',
  },
  selectLocations: {
    defaultMessage: 'Select locations',
    id: 'properties.selectLocations'
  },
  serialNumberToBeDetermined: {
    defaultMessage: 'Will be set once you return online',
    id: 'properties.serialNumberToBeDetermined'
  },
  missingMandatoryDetails: {
    defaultMessage: 'Missing mandatory details, are you sure you wish to continue?',
    id: 'properties.missingMandatoryDetails'
  },
  assigneeCompany: {
    defaultMessage: 'Assignee - Company',
    id: 'properties.assignee.company'
  },
  assigneeUser: {
    defaultMessage: 'Assignee - User',
    id: 'properties.assignee.user'
  },
  description: {
    defaultMessage: 'Description',
    id: 'properties.description'
  },
  status: {
    defaultMessage: 'Status',
    id: 'properties.status'
  },
  createdAt: {
    defaultMessage: 'Created At',
    id: 'properties.createdAt'
  },
  trade: {
    defaultMessage: 'Trade',
    id: 'properties.trade'
  },
  category: {
    defaultMessage: 'Category',
    id: 'properties.category'
  },
  location: {
    defaultMessage: 'Location',
    id: 'properties.location'
  },
  dueDate: {
    defaultMessage: 'Due',
    id: 'properties.dueDate'
  },
  checklistFullTitle: {
    defaultMessage: 'Checklist Title',
    id: 'properties.checklistFullTitle'
  },
  creatorUser: {
    defaultMessage: 'Creator - User',
    id: 'properties.creatorUser'
  },
  creatorCompany: {
    defaultMessage: 'Creator - Company',
    id: 'properties.creatorCompany'
  },
  severity: {
    defaultMessage: 'Severity',
    id: 'properties.severity'
  },
  disabledApiSource: {
    defaultMessage: 'The data was loaded from the access control and cannot be unchecked',
    id: 'properties.disabledApiSource'
  },
  identified: {
    defaultMessage: 'Detected by the Access Control',
    id: 'properties.identified'
  },
  ACModalTitle: {
    defaultMessage: 'Automatic Employee Attendance from access Control',
    id: 'properties.ACModal.title'
  },
  ACModalDescription: {
    defaultMessage: "Now, site access control is updated automatically using facial recognition – no manual update required!",
    id: 'properties.ACModal.description'
  },
  ACModalExtraDescription: {
    defaultMessage: "Contractors and workers will automatically appear in the work log based on data from the access control camera.\nThe contractor's trade will be updated according to the last entry in the work log.",
    id: 'properties.ACModal.extraDescription'
  },
  enableAutomaticUpdate: {
    defaultMessage: "Enable automatic update",
    id: 'properties.enableAutomaticUpdate'
  },
  formTitle: {
    defaultMessage: "Report title",
    id: 'properties.form.title'
  },
  optional: {
    defaultMessage: "Optional",
    id: 'properties.optional'
  }
});
