import SignatureDialog from './SignatureDialog';
import { useCallback, useMemo } from 'react';
import { getDispatch, lokiInstance } from '../configureMiddleware';
import { useSelector } from 'react-redux';
import { REPORT_STATE_CLOSED, REPORT_STATE_AWAITING_SIGNATURE } from '../forms/formsStatusStates';
import { upsertForm } from '../forms/funcs';
import { getRoundedDate } from '../lib/utils/utils';
import moment from 'moment-timezone';
import { startToast } from '../app/actions';
import systemMessages from '../app/systemMessages';
import { exportFormPDF } from '../pdf/actions';
import useIntl from '../intl/useIntl';
import { track } from '../lib/reporting/actions';

const FormSignatureModal = (props) => {
  const { isOpen, onClose, signObjectId, projectId, formReportDate, formType, formTemplateId, onDone } = props;

  const intl = useIntl();
  const dispatch = getDispatch();

  const { viewer, configurations } = useSelector((state) => ({
    viewer: state.users.viewer,
    configurations: state.configurations.map,
  }));

  const lokiObjectAnalytics = lokiInstance.getCollection('forms');
  const currentForm = lokiObjectAnalytics.cementoFind({ id: signObjectId })[0];

  const formConfigurationsSignatures = useMemo(() => {
    return configurations.getNested([projectId, 'forms', formTemplateId, 'signatures']);
  }, [configurations?.forms?.[formTemplateId]?.signatures]);

  const signatures = useMemo(() => Object.values(formConfigurationsSignatures || {}), [formConfigurationsSignatures]);

  const unsignedSignatures = useMemo(() => {
    return signatures
      .reduce((acc, signature) => {
        if (!currentForm.signatures?.[signature.id]) {
          acc.push(signature);
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return a.ordinalNo - b.ordinalNo;
      });
  }, [signatures]);

  const handleSign = useCallback(
    async (uploadedSignatures) => {
      const roundedDate = getRoundedDate();

      const currDate = moment(roundedDate.timestamp).utc();
      let localDate = new Date(currDate.format('YYYY-MM-DD'));
      localDate.setHours(0, new Date().getTimezoneOffset() * -1, 0, 0);
      const newFormStatus = unsignedSignatures.length > 1 ? REPORT_STATE_AWAITING_SIGNATURE : REPORT_STATE_CLOSED;
      dispatch(
        track('Signed form in web', {
          formId: signObjectId,
          formTemplateId,
          formType,
          didFinishSign: unsignedSignatures.length === 1,
        })
      );

      let updatedForm = {
        id: signObjectId,
        status: newFormStatus,
        formTemplateId: currentForm.formTemplateId,
        signatures: {
          ...uploadedSignatures,
          ...currentForm.signatures,
        },
        reportDate: formReportDate || localDate.getTime(),
        type: formType,
      };
      await upsertForm(projectId, viewer, updatedForm, formType);
      dispatch(startToast({ title: intl.formatMessage(systemMessages.signedSuccessfully), type: 'success' }));

      const isFullySigned = signatures.length === Object.keys(updatedForm.signatures).length;
      if (isFullySigned) {
        dispatch(
          exportFormPDF({
            viewer,
            project: { id: projectId },
            formId: signObjectId,
            formType,
            displayLoading: false,
          })
        );
      }

      if (onDone) onDone();
      onClose();
    },
    [signObjectId, formType, formReportDate, formTemplateId, unsignedSignatures, projectId, currentForm]
  );

  return (
    <SignatureDialog
      isOpen={isOpen}
      onClose={onClose}
      signatureObjs={unsignedSignatures}
      onSign={handleSign}
      shouldUploadImmediately={true}></SignatureDialog>
  );
};

export default FormSignatureModal;
