import { getDispatch } from '../configureMiddleware';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import serverSDK from '@cemento-sdk/server';
import { unitsEvents } from './unitsEvents';

export async function getUnits(projectId, viewer) {
  const dispatch = getDispatch();

  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'units',
    getData: () => {
      return serverSDK.locations.getUnits({ projectId });
    },
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({ type: unitsEvents.GET_UNITS, payload: { projectId, units: data } });
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);

  return { projectId };
}
