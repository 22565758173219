import useIntl from '../../../intl/useIntl';
import { useCallback, useContext } from 'react';
import { Checkbox } from '@material-ui/core';
import safetyMessages from '../../../safety/safetyMessages';
import { ObjectsWrapperManipulationContext } from '../../ObjectsWrapper';
import { Check } from '@material-ui/icons';
import theme from '../../../../web/assets/css/theme';
import { FILTER_URL_KEY } from '../../../../web/app/constants';
import { encodeFilterToSearch } from '../../../../web/app/funcs';

const SafetySpecificFilterControls = () => {
  const intl = useIntl();

  const { filters, setFilters } = useContext(ObjectsWrapperManipulationContext);

  const _setFilters = useCallback(
    (newFilters) => {
      setFilters((prevFilters) => {
        const newFilter = Object.assign({}, prevFilters?.newFilter || {}, newFilters);
        return {
          ...(prevFilters || {}),
          ...newFilters,
          newFilter,
          newSearch: encodeFilterToSearch(newFilter, location.search, FILTER_URL_KEY),
        };
      });
    },
    [setFilters]
  );

  return (
    <div style={{ ...styles.flexCenter, ...styles.horizontalPadding }}>
      <div style={{ ...styles.flexCenter, ...styles.horizontalPadding, alignItems: 'center' }}>
        <span>{intl.formatMessage(safetyMessages.activeOnly)}</span>
        <Checkbox
          onChange={() => {
            _setFilters({ isActive: !filters?.isActive });
          }}
          checked={Boolean(filters?.isActive)}
          checkedIcon={<Check className={styles.checkedIcon} />}
          icon={<Check className={styles.uncheckedIcon} />}
          style={{ cursor: 'pointer' }}
          classes={{ checked: styles.checked }}
        />
      </div>
      <>
        <div style={{ ...styles.flexCenter, ...styles.horizontalPadding, alignItems: 'center' }}>
          <span>{intl.formatMessage(safetyMessages.expiredSoon)}</span>
          <Checkbox
            onChange={() => {
              _setFilters({ isExpiring: !filters?.isExpiring });
            }}
            checked={Boolean(filters?.isExpiring)}
            icon={<Check className={styles.uncheckedIcon} />}
            checkedIcon={<Check className={styles.checkedIcon} />}
            style={{ cursor: 'pointer' }}
            classes={{ checked: styles.checked }}
          />
        </div>
      </>
    </div>
  );
};

const styles = {
  horizontalPadding: {
    padding: '0 8px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCenter: {
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justify: 'center',
  },
  checkedIcon: {
    color: theme.brandPrimary,
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    color: theme.brandPrimary,
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
};
export default SafetySpecificFilterControls;
