import serverSDK from '@cemento-sdk/server';
import { getDispatch } from '../configureMiddleware';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import { propertiesMappingsEvents } from './propertiesMappingsEvents';

export const startPropertiesMappingsListener = async (viewer, projectId, subjectName) => {
  const dispatch = getDispatch();

  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'properties/mappings',
    queryParams: { subjectName },
    getData: () => {
      return serverSDK.properties.getMappings({ ...scopeParams, subject: subjectName });
    },
  };

  const onData = (data) => {
    if (!data?.length) return;

    dispatch({
      type: propertiesMappingsEvents.GET_PROPERTIES_MAPPINGS,
      payload: { projectId, subjectName, propertiesMappings: data[0].mappedProps },
    });
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);
};
