import { getDispatch } from '../configureMiddleware';
import { subscribeToLastUpdates, unsubscribeToLastUpdates } from '../lib/utils/utils';
import { enterProject } from './actions';
import { PROJECT_EVENTS } from './trackProjects';
import serverSDK from '@cemento-sdk/server';
const excludeFields = ['createdAt', 'type', 'uniqueId', 'globalTemplates', 'checklists', 'extraCompanies', 'isDemo'];

export const getUserProjects = async (viewer, immediateFetch) => {
  const dispatch = getDispatch();
  const scopeParams = {
    scope: 'user',
  };

  const resourceParams = {
    subject: 'projects',
    getData: () => {
      return serverSDK.projects.getProjects({ excludeFields });
    },
  };

  const onData = (projects) => {
    if (projects) {
      dispatch({ type: PROJECT_EVENTS.GET_PROJECTS, payload: { projects } });
    }
  };

  const res = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData, immediateFetch);
  if (res) onData(res);
};

export const getProjectDetails = async (viewer, projectId) => {
  const dispatch = getDispatch();
  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'projects',
    getData: () => {
      return serverSDK.projects.getProject({ id: projectId });
    },
  };

  const onData = (data) => {
    dispatch({ type: PROJECT_EVENTS.GET_PROJECT_DETAILS, payload: { projectId, project: data } });
  };

  const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData, true);
  if (result) onData(result);
};

export const upsertProjectMembers = async ({ inProject, members, isAddingMembers }) => {
  const dispatch = getDispatch();
  const project = Object.assign({}, inProject.toJS?.() || inProject);

  if (isAddingMembers) {
    const newMembers = members.reduce((acc, { id }) => {
      acc[id] = { id };
      return acc;
    }, {});

    Object.assign(project.members, newMembers);

    await serverSDK.projects.addOrUpdateMembers({
      id: project.id,
      members: members.map(({ id, companyId, title }) => ({ id, companyId, title })),
    });
  } else {
    members.forEach(({ id }) => delete project.members[id]);

    await serverSDK.projects.removeMembers({
      id: project.id,
      memberIds: members.map(({ id }) => id),
    });
  }

  dispatch({ type: PROJECT_EVENTS.UPDATE_PROJECT_MEMBERS, payload: { project, members, isAddingMembers } });
};

export const getProjectById = async ({ projectId, extraFields }) => {
  if (!projectId) return;

  const projects = await serverSDK.projects.getProjects({
    projectId,
    extraFields,
    excludeFields,
  });

  return projects[0];
};

export const navigateToProject = (
  props,
  projectId,
  toLocation = 'issues/dashboard/',
  overwriteURL = false,
  newTab = false
) => {
  const { history, selectedProjectId } = props;

  if (!projectId) return null;

  let redirectUrl = overwriteURL ? overwriteURL : `/main/projectContainerPage/${projectId}/${toLocation}`;
  if (newTab && window) {
    window.open(redirectUrl, '_blank');
    return;
  }

  if (selectedProjectId !== projectId) {
    const dispatch = getDispatch();
    dispatch(enterProject(projectId, selectedProjectId));
  }

  history.push(redirectUrl);
};

export const endUserProjectsListener = () => {
  const scopeParams = { scope: 'global' };
  const resourceParams = { subject: 'projects' };
  unsubscribeToLastUpdates(scopeParams, resourceParams);

  const dispatch = getDispatch();
  dispatch({ type: PROJECT_EVENTS.END_PROJECTS_LISTENER });
};

const PROJECT_REQUEST_FIELDS = ['projectManager', 'location', 'description', 'title', 'images', 'address', 'features']

export const getProjectsByCompanyId = async ({ companyId, activeOnly, fields = PROJECT_REQUEST_FIELDS }) => {
  return await serverSDK.projects.getProjects({ companyId, activeOnly, fields });
};