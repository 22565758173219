import invariant from 'invariant';
import storage from 'redux-storage';
import storageDebounce from 'redux-storage-decorator-debounce';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { APP_STORAGE_LOAD, SAVE_APP_STORAGE } from '../app/actions';
import { isCollection } from 'immutable';
import { CementoRecordObject, cementoRecords, fromJSON, toJSON } from '../transit';
import { platformActions } from '../platformActions';
import { deepEqual, onError, toJSDeep } from '../app/funcs';
import _ from 'lodash';
import { tryCatch, debugParams, notifyLongFunctionDuration } from '../lib/utils/utils';
import { getProjectStatePaths, getGlobalStatePathes, globalScopeSaveOnProjectStateToSave } from './statePathes';
import { getLastUpdateFromFeature, saveLocalLastUpdatesById } from '../lastUpdatesV2/funcs';
import { PROJECT_EVENTS } from '../projects/trackProjects';
import DataManagerInstance from '../dataManager/DataManager';

const shouldMigrateFeatureToObject = (() => {
	/** @param {string} featureName */
	return featureName => Boolean(cementoRecords[featureName]);
})();

const invariantFeatureState = (state, feature) =>
	invariant(isCollection(state[feature]), `Storage persists only immutable iterables. '${feature}' is something else.`);

const isImmutable = value =>
	Boolean(value && (value.toJS || (typeof value === 'object' && (Object.values(value)[0] || {}).toJS)));

const updateGlobalState = (state, storageStateJson) => {
	if (!storageStateJson)
		// TODO: The workaround is ugly but it should work. If a value exist the it return a huge string. if not then it reutun only {}. For performance reason we don't want to parse the entire string for this checkup
		return state;

	let stateLoadFeatures = {};
	const globalStateToSave = getGlobalStatePathes();
	globalStateToSave.forEach(([feature, ...featurePath]) => {
		if (!stateLoadFeatures[feature]) {
			stateLoadFeatures[feature] = {};
		}
		if (featurePath && featurePath.length > 0) {
			stateLoadFeatures[feature][featurePath[0]] = true;
		}
	});

	for (const { feature, featurePath, value } of storageStateJson) {
		try {
			if (stateLoadFeatures[feature] && stateLoadFeatures[feature][featurePath]) {
				const isFeatureMigratedToObject = shouldMigrateFeatureToObject(feature);

				let parsedValue = isFeatureMigratedToObject 
                            ? (typeof value === 'string' ? tryCatch(() => value.startsWith('["~#') || value.startsWith('["^ ') ? fromJSON(value) : JSON.parse(value)).data || value : value)
                            : fromJSON(value);

				if (isFeatureMigratedToObject && parsedValue) {
					parsedValue = toJSDeep(parsedValue, CementoRecordObject);
				}
				state[feature] = state[feature].setIn(featurePath, parsedValue);
				_.set(lastFeaturesStateByScopeId, ['global', feature], state[feature]);
			} else console.warn('Feature should not be saved: ' + feature + ' path:' + featurePath);
		} catch (error) {
			// Shouldn't happen, but if the data's invalid, there's not much we can do.
			console.warn('ERROR ON LOADING STATE', { feature, featurePath });
			console.warn(error);
		}
	}

	return state;
};

var lastFeaturesStateByScopeId = {};
const updateProjectState = (state, payload) => {
	const { projectSavedJson, projectId } = payload;

	if (!projectId) {
		console.warn('MISSING projectId for load!!!!!');
		return state;
	}

	if (!projectSavedJson) return state;

	let newState = Object.assign({}, state);

	for (const { feature, featurePath, value } of projectSavedJson) {
		try {
				const isFeatureMigratedToObject = shouldMigrateFeatureToObject(feature);

				let parsedValue = isFeatureMigratedToObject 
                            ? (typeof value === 'string' ? tryCatch(() => value.startsWith('["~#') || value.startsWith('["^ ') ? fromJSON(value) : JSON.parse(value)).data || value : value)
                            : fromJSON(value);
				if (isFeatureMigratedToObject && parsedValue)
					// && (isImmutable(parsedValue) || platformActions.app.getPlatform() !== "web"))
					parsedValue = toJSDeep(parsedValue, CementoRecordObject);

				newState[feature] = newState[feature].setIn(featurePath.concat(projectId), parsedValue);
				_.set(lastFeaturesStateByScopeId, [projectId, feature], newState[feature]);
		} catch (error) {
			// Shouldn't happen, but if the data's invalid, there's not much we can do.
			console.warn('ERROR ON LOADING STATE');
			console.warn(error);
		}
	}

	return newState;
};

const saveGlobalStorage = (state, engine, forceSave) => {
	const didLoad = DataManagerInstance.isGlobalStorageLoaded();

	if (!didLoad) {
		console.warn('SAVE BEFORE LOAD!!!!!');
		return;
	}

	notifyLongFunctionDuration(
		() => saveToStorage(state, getGlobalStatePathes(), 'global', forceSave),
		`saveToStorage ~ The function took too long`,
		'saveGlobalStorage',
	);
};

const saveProjectStorage = (state, payload, deleteAfterSave) => {
	if (payload && payload.projectId) var { projectId } = payload;
	else var projectId = state.ui.currProject;

	if (process.env.NODE_ENV !== 'production' && !projectId) {
		console.log('MISSING projectId for save!!!!!');
		return;
	}
	Promise.all([
		notifyLongFunctionDuration(
			() => saveToStorage(state, getProjectStatePaths(), projectId),
			`saveToStorage ~ The function took too long`,
			'saveProjectStorage',
		),
		notifyLongFunctionDuration(
			() => saveToStorage(state, globalScopeSaveOnProjectStateToSave, 'global'),
			`saveToStorage ~ The function took too long`,
			'saveProjectStorage',
		),
		runSaveProjectStorageSideEffects({
			platform: platformActions.app.getPlatform(),
		}),
	]).then(() => {
		if (deleteAfterSave) delete lastFeaturesStateByScopeId[projectId];
	});
};

export const ACCESS_HISTORY_ID = '@accessHistory';

const runProjectsGarbageCollector = async ({ platform }) => {
	const supportedPlatforms = ['web'];
	if (!supportedPlatforms.includes(platform)) return;

	// Requested to be 5 in CEM-7914
	const PROJECTS_TO_KEEP_IN_MEMORY_AMOUNT = 15;

	const accessHistory = await platformActions.storage.getItem(ACCESS_HISTORY_ID);

	if (!accessHistory) return;

	const projectsSortedByLastAccessed = Object.entries(accessHistory)
		.reduce((acc, [cname, lastAccessedAt]) => {
			const isProjectId = cname[0] === '-';
			if (isProjectId) {
				acc.push({
					id: cname,
					lastAccessedAt,
				});
			}
			return acc;
		}, [])
		.sort((a, b) => b.lastAccessedAt - a.lastAccessedAt);

	const projectsToDelete = projectsSortedByLastAccessed.slice(PROJECTS_TO_KEEP_IN_MEMORY_AMOUNT - 1);

	if (!projectsToDelete.length) return;

	let keys = await platformActions.storage.getAllKeys();
	if (platform === 'web') {
		keys = [...keys, ...(await platformActions.storage.lokiPersist.getAllKeys())];
	}

	const localStorageKeysToDelete = projectsToDelete.reduce((acc, project) => {
		const projectId = project.id;
		const itemsToErase = keys.filter(key => key.includes(projectId));
		return [...acc, ...(itemsToErase || [])];
	}, []);

	return await Promise.all([
		localStorageKeysToDelete.length ? platformActions.storage.bulkRemoveItems(localStorageKeysToDelete) : null,
		platform === 'web' ? platformActions.storage.lokiPersist.bulkRemoveItems(localStorageKeysToDelete) : null,
	]);
};

const runSaveProjectStorageSideEffects = async ({ platform }) => {
	try {
		await runProjectsGarbageCollector({ platform });
	} catch (error) {
		onError({
			errorMessage: 'ERROR RUNNING STORAGE SIDE EFFECTS',
			error,
			methodMetaData: {
				name: 'runSaveProjectStorageSideEffects',
				args: { platform },
			},
		});
	}
};

const isLastUpdateSaveDocMissingFromFeature = (scopeId, feature, featurePath, featureValue) => {
	const getLastUpdateSaveDoc = getLastUpdateFromFeature(scopeId, feature, featurePath, featureValue);
	if (!getLastUpdateSaveDoc || getLastUpdateSaveDoc.length == 0) return false;
	return getLastUpdateSaveDoc.some(v => !v.lastSavedUpdate);
};

let saveInProgress = {};
const saveToStorage = async (state, stateFieldsToSave, scopeId, forceSave) => {
	if (saveInProgress[scopeId] && !forceSave) return;
	saveInProgress[scopeId] = true;
	
	if (debugParams.disableSaveToStorage) return;

	if (!scopeId) {
		console.warn('MISSING key for save!!!!!');
		return;
	}

	let saveState = [];
	let lastCurrState = lastFeaturesStateByScopeId[scopeId] || {};
	let changesStatesList = {};
	stateFieldsToSave.forEach(([feature, ...featurePath]) => {
		let currFeaturePath = scopeId != 'global' ? featurePath.concat(scopeId) : featurePath;
		const fullFeaturePath = [feature].concat(currFeaturePath);
		const lastFeatureState = lastCurrState.getNested(fullFeaturePath);
		const currFeatureState = state.getNested(fullFeaturePath);
		if (
			forceSave ||
			!(
				Boolean(lastCurrState) && (
					lastFeatureState == currFeatureState ||
					deepEqual(lastFeatureState, currFeatureState)
				)
			) ||
			isLastUpdateSaveDocMissingFromFeature(scopeId, feature, featurePath, currFeatureState)
		) {
			saveState.push({
				feature,
				featurePath,
				value: currFeatureState,
			});
			changesStatesList[feature] = true;
		}
	});

	Object.keys(changesStatesList).forEach(feature => {
		_.set(lastFeaturesStateByScopeId, [scopeId, feature], state.getNested([feature]));
	});

	for (const { feature, featurePath, value } of saveState) {
		const isFeatureMigratedToObject = shouldMigrateFeatureToObject(feature);

		let configKey = '@' + feature + '_' + featurePath + ':' + scopeId;
		try {
			let isDelete = false;
			let formattedJSON = null;
			const platform = platformActions.app.getPlatform();
			if (value == null || value == undefined) {
				isDelete = true;
			} else {
				if (isFeatureMigratedToObject) {
					const formattedValue = isImmutable(value) ? toJSDeep(value, CementoRecordObject) : value; // The dixie would save the CementoRecordObject as an object in anycase
					formattedJSON = !_.isNil(value) && (platform !== 'web') ? JSON.stringify(formattedValue) : formattedValue;
				} else {
					formattedJSON = toJSON(value);
				}
				if (process.env.NODE_ENV !== 'production') {
					console.log(
						'tcl save to storage ' + new Date().toString() + ' : ',
						(formattedJSON.length ?? formattedJSON.size) + ' : ' + configKey,
					);
				}
			}
			if (isDelete) {
				deleteFromStorage(configKey, platform);
			} else if (platform == 'web') {
				platformActions.storage.setItem(configKey, formattedJSON);
			} else {
				const realm = platformActions.localDB.getCementoDB();
				realm.set('reducerPersist', [
					{
						id: configKey,
						scopeId: scopeId,
						feature: feature,
						json: formattedJSON,
					},
				]);
			}

			const lastUpdateDocs = getLastUpdateFromFeature(scopeId, feature, featurePath, value);
			if (lastUpdateDocs?.length) {
				for (const lastUpdateDoc of lastUpdateDocs) {
					if (lastUpdateDoc.lastSavedUpdate !== lastUpdateDoc.lastUpdated) {
						await saveLocalLastUpdatesById(lastUpdateDoc.id, {
							lastSavedUpdate: isDelete ? null : lastUpdateDoc.lastUpdated,
						});
					}
				}
			}
		} catch (err) {
			console.error('ERROR SAVING STORAGE', configKey, value, err);
		}
	}

	saveInProgress[scopeId] = false;
};

const deleteFromStorage = (configKey, platform) => {
	if (['web'].includes(platform)) {
		return platformActions.storage.removeItem(configKey);
	}

	const realm = platformActions.localDB.getCementoDB();
	const query = `id = "${configKey}"`;
	return realm.unset('reducerPersist', query);
};

const saveBothStorages = state => {
	return new Promise((resolve, reject) => {
		saveProjectStorage(state);
		saveGlobalStorage(state);
		resolve();
	});
};

const storageFilter = engine => ({
	...engine,
	save(state) {
		return saveBothStorages(state);
	},
});

const createStorageMiddleware = storageEngine => {
	let decoratedEngine = storageFilter(storageEngine);
	decoratedEngine = storageDebounce(decoratedEngine, 30000);
	return storage.createMiddleware(decoratedEngine);
};

const cleanProjectState = (state, payload) => {
	let projectId;
	if (payload && payload.projectId) projectId = payload.projectId;
	else projectId = state.ui.currProject;

	let newState = state;
	if (projectId) {
		newState = Object.assign({}, state);
		const projectStateToClean = getProjectStatePaths();
		projectStateToClean.forEach(([feature, ...featurePath]) => {
			const projectFeaturePath = featurePath.concat(projectId);
			if (!newState[feature] || (feature === 'projects' && featurePath.includes('didLoad'))) return;

			newState[feature] = newState[feature].deleteIn(projectFeaturePath);
		});
	}

	return newState;
};

const saveGlobalStorageDebounced = AwesomeDebouncePromise(saveGlobalStorage, 1000);

export const updateStateOnStorageLoad = reducer => (state, action) => {
	if (action.type === APP_STORAGE_LOAD) {
		state = updateGlobalState(state, action.payload);
	} else if (action.type === PROJECT_EVENTS.LOAD_PROJECT_STORAGE) {
		state = updateProjectState(state, action.payload);
	}

	state = reducer(state, action);

	if (action.type === 'SAVE_PROJECT_LOKI_STORAGE' + '_SUCCESS') {
		saveProjectStorage(state, action.payload, false);
		saveGlobalStorage(state);
	}

	if (action.type === 'LEAVE_PROJECT' + '_SUCCESS') {
		saveProjectStorage(state, action.payload, true);
		saveGlobalStorage(state);
		state = cleanProjectState(state, action.payload);
	} else if (action.type == SAVE_APP_STORAGE || action?.payload?.immediateGlobalStorageSave) {
		// The "SAVE_APP_STORAGE" is on false because it happen when the app come back and stuck the UI
		saveGlobalStorageDebounced(state);
	}
	return state;
};

export default function configureStorage(initialState, createStorageEngine) {
	const storageEngine = createStorageEngine && createStorageEngine(`redux-storage:${initialState.config.appName}`);
	const storageMiddleware = storageEngine && createStorageMiddleware(storageEngine);

	return {
		STORAGE_SAVE: storage.SAVE,
		storageEngine,
		storageMiddleware,
	};
}
