import { platformActions } from "../platformActions";


export const globalScopeSaveOnProjectStateToSave = [
  ['members', 'map'],
  ['members', 'lastClientUpdatePerProject'],

  ['companies', 'map'],
  ['companies', 'lastClientUpdatePerProject'],
] 


const globalStatePathes = [
  ['members', 'map'],
  ['members', 'lastClientUpdatePerProject'],

  ['intl', 'currentLocale'],
  ['users', 'viewer'],
  ['users', 'lastVisitedProject'],

  ['projects', 'map'],

  ['trades', 'map'], 

  ['quasiStatics', 'subCategoriesMap'], 
  ['quasiStatics', 'requiredActionsMap'], 
  ['quasiStatics', 'formUniversalIdsMap'], 
  ['quasiStatics', 'requiredBuildNumber'], 
  ['quasiStatics', 'optionalBuildNumber'],   
  ['quasiStatics', 'adminUsers'],   


  ['titles', 'map'],

  ['auth', 'authToken'],
  ['auth', 'accessToken'],
  ['auth', 'userId'],
  ['auth', 'number'],
  ['auth', 'refreshToken'],
  ['auth', 'firebaseToken'],
  ['auth', 'inviterUid'],  
  ['auth', 'fCMRegistrationToken'],
  ['auth', 'loggedIn'],
  
  ['app', 'lang'],
  ['app', 'rtl'],
  ['app', 'configurationMode'],
  ['app', 'dismissedModals'],

  
  ['lastUpdates', 'appVersion'],

  ['permissions', 'map'],
  
  ['configurations', 'global'],
];

const projectStatePathes = [
  ['projects', 'detailsMap'],

  ['configurations', 'map'],
  ['configurations', 'debugConfigurations'],
  
  ['buildings', 'map'],  

  ['floors', 'map'],  
  
  ['units', 'map'],  

  ['drawings', 'map'],
  ['drawings', 'settings'],

  ['stages', 'map'],

  ['checklists', 'subscriptions'],

  ['checklists', 'map'],
  
  ['checklistItems', 'map'],

	['propertiesTypes', 'projectSections'],
	['propertiesTypes', 'projectProperties'],

  ['propertiesMappings', 'map'],
  
  ['checklistItemsInstances', 'sync'],
  ['checklistItemsInstances', 'lastSynced'],

	['propertiesInstances', 'sync'],
	['propertiesInstances', 'lastSynced'],
	['propertiesInstances', 'recentlySigningUsers'],
	
	['posts', 'sync'],
	['posts', 'lastSynced'],
  
  ['trades', 'recentlyTrades'],
  ['trades', 'recentlyUsersPerTrades'],

  ['safety', 'safetyGrades'],

  ['reports', 'map'],
  ['reports', 'inspectorReports'],

  ['forms', 'locallyPopulatedFormTemplates'],
]; 


const platformProjectStatePathes = {
  web: [
    ['checklistItemsInstances', 'map'],
    ['loki'],
  ]
}

export const getGlobalStatePathes = () => {
  return Array.from(globalStatePathes);
}

export const getProjectStatePaths = () => {
  const platform = platformActions.app.getPlatform();
  let statePathes = Array.from(projectStatePathes);
  if (platformProjectStatePathes[platform])
    statePathes = statePathes.concat(platformProjectStatePathes[platform]);

  return statePathes;
}

