import { useState, useCallback } from 'react';

const useStateLoading = () => {
  const [loadingMap, setLoadingMap] = useState({});

  const setLoading = useCallback((path, isLoading) => {
    setLoadingMap((prev) => ({
      ...prev,
      [path]: isLoading,
    }));
  }, []);

  return { loadingMap, setLoading };
}

// eslint-disable-next-line react/display-name
export const WithStateLoading = (Component) => (props) => {
  const { loadingMap, setLoading } = useStateLoading();
  return <Component {...props} loadingMap={loadingMap} setLoading={setLoading} />
}

export default useStateLoading;
