import mapFirebaseUserToAppUser from '../lib/redux-firebase/mapFirebaseUserToAppUser';
import { startToast } from '../app/actions';
import usersMessages from './usersMessages';
import ExtraError from '../lib/errors/extraError';
import _ from 'lodash';
import DataManagerInstance from '../dataManager/DataManager';
import { dataManagerStates, dataManagerStatuses } from '../dataManager/DataManagerConstants';
import { SUBJECTS } from '../dataManager/subjects';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import { envParams, getDispatch } from '../configureMiddleware';
import { platformActions } from '../platformActions';

export const UPDATE_MY_METADATA = 'UPDATE_MY_METADATA';
export const SET_ADMIN_MODE = 'SET_ADMIN_MODE';
export const ON_PROFILE_UPDATE = 'ON_PROFILE_UPDATE';
export const AUTH0_SAVE_USER = 'AUTH0_SAVE_USER';
export const SET_LAST_LOCATION_REPORTED = 'SET_LAST_LOCATION_REPORTED';
export const SET_LAST_VISITED_PROJECT_ID = 'SET_LAST_VISITED_PROJECT_ID';
export const GET_MY_USER_DETAILS_SENT = 'GET_MY_USER_DETAILS_SENT';
export const GET_MY_USER_DETAILS = 'GET_MY_USER_DETAILS';
export const SEND_NEW_USER_EMAIL = 'SEND_NEW_USER_EMAIL';

export const startUserListener = async (viewer) => {
  const dispatch = getDispatch();

  const scopeParams = { scope: 'user' };

  const resourceParams = {
    subject: SUBJECTS.VIEWER,
    getData: () => {
      return platformActions.net.fetch(envParams.apiServer + `/v1/users/${viewer.id}`);
    },
  };

  const onData = async (_data) => {
    const data = await _data.getJson();
    const newViewer = data[viewer.id];
    dispatch({ type: GET_MY_USER_DETAILS, payload: { user: newViewer.user_metadata } });
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);
};

// Get the user info from Auth0 user_metadata
export function getMyUserInfo(id_token, callerApi, viewer, auth0_data) {
  return ({ dispatch, platformActions, apiServer, errorReport, getState }) => {
    const getPromise = async () => {
      try {
        let resp = await platformActions.net.fetch(apiServer + '/v1/services/users/tokeninfo', {
          method: 'POST',
          body: JSON.stringify({ id_token }),
        });

        var response = await resp.getJson();

        if ((!response.user_metadata && !response.user_id) || response.error) {
          throw new ExtraError('user_metadata is missing.', {
            user_metadata: response.user_metadata,
            user_id: response.user_id,
            serverError: response.error,
            id_token,
            callerApi,
            viewer,
            auth0_data,
          });
        }
        var user = response.user_metadata || {}; // In case of a new user
        user.id = response.user_id;
        const userPhoneNumber = _.get(response, ['phone_number']);

        var mixpanelIgnore = Boolean(getState().users.originViewer);
        dispatch({
          type: GET_MY_USER_DETAILS,
          payload: { mixpanelIgnore, user, scopeKey: 'global', immediateGlobalStorageSave: true, userPhoneNumber },
        });
        DataManagerInstance.updateStatus({
          subject: 'viewer',
          scope: 'global',
          stateType: dataManagerStates.CONNECTION_STATUS,
          status: dataManagerStatuses.DATA_RECEIVED,
        });

        return user;
      } catch (err) {
        // TODO: Check if in case of  error, the err may contain only a string with "Unauthorized" so it will unable to parse it to JSON later
        errorReport('GET_MY_USER_DETAILS_SENT', new ExtraError('getMyUserInfo error', null, err));
      }
    };
    return {
      type: GET_MY_USER_DETAILS_SENT,
      payload: getPromise(),
    };
  };
}

export function updateMyUserMetadata(key, value) {
  return ({ getState, dispatch, platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!key) throw new Error('Missing update key for value ' + value);

      var userId = getState().auth.userId;

      let user_metadata = {};
      user_metadata[key] = value;
      var bodyString = JSON.stringify(user_metadata);

      if (!userId || !getState().users || !getState().users.viewer) return { key, newValue: value };

      try {
        await platformActions.net.fetch(encodeURI(`${apiServer}/v1/users/` + userId), {
          method: 'PATCH',
          body: bodyString,
        });

        if (usersMessages.updateSuccess[key]) dispatch(startToast({ title: usersMessages.updateSuccess[key] }));

        return { user_metadata: user_metadata, key, newValue: value, myId: getState().users.viewer.id };
      } catch (error) {
        console.log(error);
        if (usersMessages.updateFailed[key]) {
          dispatch(startToast({ title: usersMessages.updateFailed[key] }));
        }
        return {};
      }
    };

    return {
      type: UPDATE_MY_METADATA,
      payload: getPromise(),
    };
  };
}

// TODO: Merge this and the initialProfile togther
export function updateProfile(id, fields, scopeFields, scopeKey) {
  return ({ validate, dispatch, removeEmpty }) => {
    const getPromise = async () => {
      await validate({ id, ...fields })
        .prop('id')
        .required()

        .prop('displayName')
        .required().promise;

      const user = mapFirebaseUserToAppUser({
        uid: id,
        ...fields,
        ...scopeFields,
      });
      dispatch(saveUser(user, id, scopeKey));
      return { user: removeEmpty(user, 'updateProfile'), scopeKey, initial: true };
    };
    return {
      type: ON_PROFILE_UPDATE,
      payload: getPromise(),
    };
  };
}

export function saveUser(user_metadata, userId, scopeKey) {
  return ({ removeEmpty, apiServer, platformActions, getState }) => {
    const getPromise = async () => {
      let userDetails = user_metadata.toJS ? user_metadata.toJS() : Object.assign({}, user_metadata);
      userDetails = removeEmpty(userDetails, 'saveUser');
      userDetails.lang = getState().app.lang;
      delete userDetails.projects;
      try {
        let response = await platformActions.net.fetch(apiServer + '/v1/users/' + encodeURIComponent(userId), {
          method: 'PUT',
          body: JSON.stringify({
            'uid': userId,
            'scopeKey': scopeKey,
            'userDetails': userDetails,
          }),
        });

        let userRet = await response.getJson();
        return { user: userRet.user_metadata };
      } catch (error) {
        console.log(error);
      }
    };
    return {
      type: AUTH0_SAVE_USER,
      payload: getPromise(),
    };
  };
}

export function setAdminMode(newMode) {
  return {
    type: SET_ADMIN_MODE,
    payload: { newMode, immediateGlobalStorageSave: true },
  };
}

export function setLastVisitedProject(
  currentProjectIdInUse,
  newVisitedProject,
  lastVisitedProject,
  didEnterProject,
  missedLeavingSite,
  isPhysicalSite
) {
  return {
    type: SET_LAST_VISITED_PROJECT_ID,
    payload: {
      currentProjectIdInUse,
      newVisitedProject,
      lastVisitedProject,
      didEnterProject,
      missedLeavingSite,
      isPhysicalSite,
      immediateGlobalStorageSave: true,
    },
  };
}

// Send email upon new user registretion
export function sendNewUserEmail(viewer) {
  return ({ platformActions, apiServer, errorReport }) => {
    const getPromise = async () => {
      var debug = process.env.NODE_ENV !== 'production';
      var user = {
        'displayName': viewer.displayName,
        'phoneNumber': viewer.phoneNumber,
        'title': viewer.title,
        'companyType': viewer.companyType,
        'companyName': viewer.companyName,
        'info': viewer.info,
      };

      try {
        await platformActions.net.fetch(apiServer + '/v1/services/users/newUserRegistrationEmail', {
          method: 'POST',
          body: JSON.stringify({ debug, user }),
        });
      } catch (err) {
        // TODO: Check if in case of  error, the err may contain only a string with "Unauthorized" so it will unable to parse it to JSON later
        console.log('sentEmail error: ', err);
        errorReport('sendNewUserEmail error', new ExtraError('sendNewUserEmail error', null, err));
      }
    };
    return {
      type: SEND_NEW_USER_EMAIL,
      payload: getPromise(),
    };
  };
}
