import * as appActions from '../app/actions';
import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';


const InitialState = Record({
  map: OrderedMap(),
  aggregated: Map(),
  lastSelectedEmails: Map(),
  formEmailModalParams: Map(),
  locallyPopulatedFormTemplates: Map(),
}, 'forms');

const initialState = new InitialState;

export default function formsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.SET_FORM_EMAIL + '_ERROR':
    case actions.SET_FORM_EMAIL + '_SUCCESS': {
      const { scopeId, selectedEmail } = action.payload || {};
      
      if (scopeId && selectedEmail) {
        state = state.setIn(['lastSelectedEmails', scopeId], selectedEmail);
      }
      return state;
    }

    case actions.POPULATE_FORM_TEMPLATES:{
      const { scopeId, formTemplates } = action.payload || {};

      if (scopeId && Object.values(formTemplates || {}).length) {
        state = state.setIn(['locallyPopulatedFormTemplates', scopeId], formTemplates);
      }

      return state;
    }

    case actions.SET_FORM_EMAIL_MODAL_PARAMS: 
    case actions.UNSET_FORM_EMAIL_MODAL_PARAMS: {
      
      if (action.payload) {
        const { isVisible, scopeId, formType, formId } = action.payload || {};
        state = state.setIn(['formEmailModalParams'], { isVisible, scopeId, formType, formId });
      }
      return state;
    }
    

    case appActions.CLEAN_CACHE: {
      return state = initialState;
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}
