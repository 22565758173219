import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { getDispatch } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import { membersEvents } from './membersEvents';

export async function getUsersByProject(viewer, projectId) {
  const dispatch = getDispatch();
  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    resourceName: 'users',
    queryParams: { includeGroups: true, projectId },
  };
  const onData = (data) => {
    const DBMembers = getDBMembers(data);
    dispatch({ type: membersEvents.SEARCH_PROJECT_USERS, payload: { DBMembers, projectId } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) onData(result);
}

export function getDBMembers(usersMap) {
    let DBMembers = {};
    Object.values(usersMap || {}).forEach(user => { 
      if (user.user_metadata) {
        DBMembers[user.user_metadata.id] = user.user_metadata;
        DBMembers[user.user_metadata.id].activated = user.phone_verified;
        DBMembers[user.user_metadata.id].phoneNumber = user.user_metadata.phoneNumber && user.user_metadata.phoneNumber != "" ? 
        user.user_metadata.phoneNumber : user.phone_number;
      }
    });
    return DBMembers;
  }
  

/**
 * 
 * @param {string} phoneNumber 
 */
export const getPhoneNumberRegionCode = (phoneNumber) => {
  let viewerCountryLetters = 'US';        
  if (!phoneNumber) {
    console.warn('Missing phone number - defaulting to US location')
  }
  else {
    if (phoneNumber.startsWith('+972'))
      viewerCountryLetters = 'IL';
    else if (phoneNumber.startsWith('+507'))
      viewerCountryLetters = 'PA';
    else if (phoneNumber.startsWith('+49'))
      viewerCountryLetters = 'DE';
  }

  return viewerCountryLetters;
}

export const isValidPhoneNumber = (phoneNumber, countryLetters) => {
  let phoneNumberString = null;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber.replace(/[ ,-]/g, ''), countryLetters);
    const isValid = phoneUtil.isValidNumber(parsedPhoneNumber)
    if (isValid)
      phoneNumberString = phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164)
  } catch (err) {
    console.log(err);
  }

  return phoneNumberString;
}
