import configureStorage from "./configureStorage";
import { platformActions, init } from "./platformActions";
import { setPlatformStandardInput } from "./app/platformComponents/PlatformStandardInput";
import createLoggerMiddleware from "redux-logger";
import { v4 as uuidv4 } from "uuid";
import validate from "./validate";
import ExtraError from "./lib/errors/extraError";
import * as rlmSchema from "./lib/realm/schema.js";
// import * as rlmMigrations from "./lib/realm/migrations/index.js";
import { lokiCollections } from "./lib/loki/collections";
import _ from "lodash";
import { setPlatformContainer } from "./app/platformComponents/PlatformContainer";
import { setPlatformText } from "./app/platformComponents/PlatformText";
import { setPlatformTradeBadge } from "./app/platformComponents/PlatformTradeBadge";
import { setPlatformTheme } from "./platformTheme";
import { getAppLang, getOriginalErrorMessage, isEmptyValue, onError } from "./app/funcs";
import { removeEmpty} from './lib/utils/utils'
import enhanceRealm from './enhanceRealm';
import { deleteMultiLocalLastUpdatesById, getLocalLastUpdates } from './lastUpdatesV2/funcs.js';
import path from 'path';
import { SUBJECT_NAMES } from './propertiesTypes/propertiesTypes.js';

let sentry = null;
let firebase = null;
let logRocket = null;
let firebaseDeps = null;
let isMixpanelInit = false;
/** @type {{ [collectionName: string]: ReturnType<enhanceRealm> }} */
let realmInstance = null;
/** @type {import('lokijs')} @instance */
let lokiInstance = null;
export let envParams = null;

const logsHost = "listener.logz.io";
const logsToken = "dnYOrcQZciFpcYVtbdhOxAHaOgSPIBvN";
const isLocalHost = false;

//? try{action({ ...deps, dispatch, getState })}catch(err){console.log('injectMiddleware ERROR' + err)}
// Like redux-thunk but with dependency injection.
const injectMiddleware =
  (deps) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    try {
      getAppState = getState;
      getDispatch = () => dispatch;

      apiListenersDeps = {
        dispatch,
        fetchCompressedDataFromServer,
        errorReport,
        firebaseDatabase: firebaseDeps.firebaseDatabase,
      };

      action = typeof action === 'function'
        ? action({ ...deps, errorReport, dispatch, getState })
        : action;

      writeLog("info", action ? action.type : null, "ActionsTrack", {
        type: action ? action.type : null
      });
      
      return next(action);
    } catch (err) {
      var type = null;
      if (action && action.type) type = action.type;
      if (
        process.env.NODE_ENV == "production" &&
        !((envParams || {}).deploy_env == "beta")
      )
        errorReport(type, err, action);
      else {
        errorReport(type, err, action);
        console.warn(err);
        if (action.type) console.warn("type:" + action.type);
        if (action.payload)
          console.warn(
            "payload:" + JSON.stringify(action.payload).slice(0, 200)
          );
        if (
          err.metadata &&
          err.metadata.errorMessage &&
          err.metadata.errorMessage != err.toString()
        )
          console.warn(err.metadata.errorMessage);
      }
    }
  };

const errorReport = (type, error, action) => {
  if (
    process.env.NODE_ENV != "production" &&
    !((envParams || {}).deploy_env == "beta")
  )
    console.warn("Error on action - ", type, action, error);
  if (sentry) {
    //platformActions.sentry.notify(error, {function: type});

    const originalErrorMessage = getOriginalErrorMessage(error);
    const shouldReport = originalErrorMessage && originalErrorMessage.indexOf('Network request failed') === -1;
    if (shouldReport) {
      if (error instanceof ExtraError) {
        // TODO: Replace this with typeof prototype, this is a workaround
        if (error.innerError instanceof ExtraError)
          platformActions.sentry.notify(error.innerError.innerError, {
            function: type,
            ...(error.innerError.metadata || {}),
            "errorParent:": error,
          });
        else if (error.innerError instanceof Error)
          platformActions.sentry.notify(error.innerError, {
            function: type,
            ...(error.metadata || {}),
          });
        else
          platformActions.sentry.notify(
            new Error("error type is not of error - 10001")
          );
      } else if (error instanceof Error) {
        platformActions.sentry.notify(error);
      }
      else {
        platformActions.sentry.notify(
          new Error("error type is not of error - 10002")
        );
      }
    }

    type &&
      sentry.addBreadcrumb({
        message: (type + "_ERROR").slice(0, 30),
        data: error,
      });
  }

  writeLog("error", type + "_ERROR", "ActionsTrack", {
    error,
    type: action ? action.type : null,
  });
};

/**
 * 
 * @param {string} apiName 
 * @param {Record<string, any>} [queryParams] 
 * @param {number} [timeoutThreshold] 
 * @returns 
 */
const fetchCompressedDataFromServer = async (
  apiName,
  queryParams,
  timeoutThreshold = 0,
) => new Promise((resolve, reject) => {
  const url = new URL(`${envParams.apiServer}/v1/${apiName}`);
  if (queryParams) {
    _.forIn(
      queryParams,
      (val, key) => {
        if (!isEmptyValue(val)) {
          url.searchParams.set(key, _.isString(val) ? val : JSON.stringify(val))
        }
      }
    );
  }

  const urlString = url.toString();

  if (process.env.NODE_ENV !== "production")
    console.log("fetchCompressedDataFromServer -> url", urlString);

  const _onError = (err) => {
    console.warn(
      "error on apiName:",
      apiName,
      "projectId:",
      "queryParams:",
      queryParams
    );
    console.warn(err);
    onError({
      errorMessage: 'Error fetching data from API',
      error: err,
      methodMetaData: {
        name: 'fetchCompressedDataFromServer',
        args: { apiName, url: urlString, queryParams },
      },
      errorMetaData: {
        timeoutThreshold,
      }
    });
    reject(err);
  }

  let timeout;
  if (timeoutThreshold) {
    timeout = setTimeout(() => {
      _onError('Fetch exceeded timeout');
    }, timeoutThreshold);
  }

  platformActions.net.fetch(urlString)
    .then(async resp => {
      clearTimeout(timeout);
      try {
        const response = await resp.json();
        resolve(response);
      } catch (error) {
        if (error.message.includes('JSON.parse: unexpected end of data at line 1 column 1 of the JSON data')) {
          console.warn('Unexpected end of JSON input', urlString);
          return resolve(null);
        } else {
          _onError(error);
        }
      }
    })
    .catch(err => _onError(err))
    .finally(() => clearTimeout(timeout));
});


var platform = null;
var deviceAppVersion = null;
var userLogsData = null;
var userSuperProps = null;
var bulkIndex = 0;
var bulkMessageIndex = 0;

const initUserLogsData = (userData) => {
  userLogsData = userData;
};
const registerUserScopeLogs = (superProps) => {
  let props = superProps;
  if (userSuperProps) props = Object.assign({}, userSuperProps, superProps);
  userSuperProps = props;
  return userSuperProps;
};

const writeToLogzIo = async logsBulk => {
  try {
		await platformActions.net.fetch('https://us-central1-planme-1383.cloudfunctions.net/writeLogsBulk', {
			method: 'POST',
			body: JSON.stringify({ logsBulk }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
    return true
	} catch (error) {
		console.warn("Can't save logs", error);
    return false
	}
};

// Returns a unique device id
const getDeviceId = () => {
  return platformActions.app.getUniqueID()
};

const writeLog = async (level, message, messageType, extraInfo, forceSend) => {
  if ((envParams.flavour !== 'production' || process.env.NODE_ENV === 'development') && level) {
		return console[level === 'debug' || level === 'info' ? 'info' : 'error'](level, message, messageType, extraInfo);
	}
  
	if (!userLogsData) {
		return null;
	}
	let logObject = null;
	try {
		if (level && message) {
			if (level != 'error' && level != 'info' && level != 'debug') {
				level = 'error';
			}

			logObject = Object.assign({}, userSuperProps || {}, extraInfo || {});
      logObject.deviceId = getDeviceId();
			logObject.user = userLogsData;
			logObject.env = envParams.flavour == 'production' ? 'Prod' : envParams.flavour == 'staging' ? 'Staging' : 'Dev';
			logObject.level = level;
			logObject.message = message;
			logObject.messageType = messageType;
			logObject.source = 'clients';
			logObject.deviceAppVersion = deviceAppVersion;
			logObject.platform = platform;
			if (extraInfo && extraInfo.error) logObject.errorStackTrace = extraInfo.error.stack;
		}
    if (level === 'error') {
      onError({
        errorMessage: message,
        error: logObject,
        methodMetaData: {
          name: 'writeLog',
          args: [message, messageType, forceSend],
        },
        level: level === 'debug' || level === 'info' ? 'info' : 'error',
        errorMetaData: {
          ...logObject
        }
      });
    }
    return;

    const localDB = platformActions.localDB.getCementoDB()
    const logObjectsCollection = rlmSchema.logObjectsSchema.schema.name
    if (logObject) {
			logObject.bulkIndex = bulkIndex;
			logObject.bulkMessageIndex = bulkMessageIndex;
			await localDB.set(logObjectsCollection, [{
				id: `@logObjectString:${bulkMessageIndex}`,
				json: JSON.stringify(logObject),
			}]);
			bulkMessageIndex++;
		}
		if ((forceSend || bulkMessageIndex % 100 === 0) && bulkMessageIndex > 0) {
      const items = await localDB.get(logObjectsCollection);
			const logsBulk = items.map(item => item.json);
			// Write to loggers
			const success = await writeToLogzIo(logsBulk);
			// End write to loggers
			if (success) {
				bulkMessageIndex = 0;
				bulkIndex++;
        // Delete local logs that were uploaded
        await localDB.deleteCollection(logObjectsCollection)
			}
		}
	} catch (err) {
		console.warn(err);
		await writeToLogzIo([{ ...logObject, message: 'LOGGER CRASHED!' }]);
	}
};

// Like redux-promise-middleware but without the noise.
const promiseMiddleware =
  (deps) =>
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { type, payload: promise } = action;
    const isPromise = promise && typeof promise.then === "function";
    if (!isPromise)
      return next(action);
    
    const createAction = (suffix, payload) => ({
      type: `${type}_${suffix}`,
      meta: { action },
      payload,
    });
    next(createAction("START"));

    if (type) {
      let typeStart = type + "_START";
      if (sentry) sentry.addBreadcrumb({ message: typeStart.slice(0, 30) });
    }

    const onFulfilled = (value) => {
      dispatch(createAction("SUCCESS", value));
      if (type) {
        let typeSuccess = type + "_SUCCESS";
        if (sentry)
          sentry.addBreadcrumb({
						message: typeSuccess.slice(0, 30)
					});
      }

      return value;
    };
    const onRejected = (error) => {
      errorReport(type, error, action);
      dispatch(createAction("ERROR", error));
    };
    return promise.then(onFulfilled, onRejected);
  };

const checklistItemsInstanceSchema = {
  schema: [
    rlmSchema.lastUpdateTSSchema.schema,
    rlmSchema.checklistItemsInstanceSchema.schema,
    rlmSchema.extraDataFieldSchema.schema,
    rlmSchema.checklistItemsInstanceParentSchema.schema,
  ],
  schemaVersion: 17,
};

const postsSchema = {
  schema: [
    rlmSchema.postsSchema.schema,
    rlmSchema.locationIdSchema.schema,
    rlmSchema.locationSchema.schema,
    rlmSchema.taggedCompanySchema.schema,
    rlmSchema.commentDataSchema.schema,
    rlmSchema.commentSchema.schema,
    rlmSchema.tradeSchema.schema,
    rlmSchema.slimChecklistItemInstanceSchema.schema,
    rlmSchema.imagesSchema.schema,
    rlmSchema.slimUserSchema.schema,
    rlmSchema.memberSchema.schema,
    rlmSchema.lastUpdateTSSchema.schema,
    rlmSchema.subCategorySchema.schema,
    rlmSchema.requiredActionSchema.schema,
    rlmSchema.fineSchema.schema,
    rlmSchema.attachmentsSchema.schema,
    rlmSchema.postRefSchema.schema,
  ],
  schemaVersion: 22,
};

const formsSchema = {
  schema: [
    rlmSchema.formsSchema.schema,
    rlmSchema.formLocationSchema.schema,
    rlmSchema.slimUserSchema.schema,
    rlmSchema.formsPostsSchema.schema,
  ],
  schemaVersion: 4,
};

const commentsSchema = {
  schema: [
    rlmSchema.commentSchema.schema,
    rlmSchema.retryObjectsSchema.schema,
    rlmSchema.commentDataSchema.schema,
    rlmSchema.slimUserSchema.schema,
    rlmSchema.imagesSchema.schema,
  ],
  schemaVersion: rlmSchema.commentSchema.schemaVersion,
}

const schemaPaths = {
  posts: 'posts.realm',
  retry_posts: 'retry_posts.realm',
  checklistItemsInstances: 'checklistItemsInstances.realm',
  retry_checklistItemsInstances: 'retry_checklistItemsInstances.realm',
  propertyInstances: 'propertyInstances.realm',
  employees: 'employees.realm',
  equipment: 'equipment.realm',
  retryObjects: 'retryObjects.realm',
  lastUpdatesV2: 'lastUpdatesV2.realm',
  reducerPersist: 'reducerPersist.realm',
  logObjects: 'logObjects.realm',
  comments: 'comments.realm',
  companies: 'companies.realm',
  urlCache: 'urlCache.realm',
  forms: 'forms.realm'
}

const realmsToDeleteOnMigrationNeeded = {
  [schemaPaths.posts]: true,
  [schemaPaths.comments]: true,
  [schemaPaths.retry_posts]: true,
  [schemaPaths.checklistItemsInstances]: true,
  [schemaPaths.retry_checklistItemsInstances]: true,
  [schemaPaths.propertyInstances]: true,
  [schemaPaths.employees]: true,
  [schemaPaths.equipment]: true,
  [schemaPaths.retryObjects]: true,
  // [schemaPaths.lastUpdatesV2]: true,
  // [schemaPaths.reducerPersist]: true,
  [schemaPaths.logObjects]: true,
  [schemaPaths.forms]: true,
}

const pathToLastUpdatesV2Subject = {
  [schemaPaths.posts]: 'posts',
  [schemaPaths.comments]: 'comments',
  [schemaPaths.retry_posts]: 'posts',
  [schemaPaths.checklistItemsInstances]: 'checklists/itemInstances',
  [schemaPaths.retry_checklistItemsInstances]: 'checklists/itemInstances',
  [schemaPaths.propertyInstances]: 'properties/instances',
  [schemaPaths.employees]: 'employees',
  [schemaPaths.equipment]: 'equipment',
  [schemaPaths.companies]: 'companies',
  [schemaPaths.forms]: 'forms',
}
/**
 * 
 * @param {typeof Realm} Realm 
 * @returns 
 */
export async function loadRealmInstance(Realm) {
	if (platform == 'web') return;
	try {
		if (realmInstance) return;
		realmInstance = {};

    const prevSchemaVersions = Object.values(schemaPaths).reduce((acc, path) => {
      acc[path] = Realm.schemaVersion(path);
      return acc;
    }, {});

		const postRealmAction = Realm.open({
			path: schemaPaths.posts,
			schemaVersion: postsSchema.schemaVersion,
			schema: postsSchema.schema,
      deleteRealmIfMigrationNeeded: true, 
			// onMigration: rlmMigrations.postsMigrate,
		});

		const retry_postRealmAction = Realm.open({
			path: schemaPaths.retry_posts,
			schemaVersion: postsSchema.schemaVersion,
      deleteRealmIfMigrationNeeded: true,
			schema: postsSchema.schema,
		});
		const chkRealmAction = Realm.open({
			path: schemaPaths.checklistItemsInstances,
			schemaVersion: checklistItemsInstanceSchema.schemaVersion,
			schema: checklistItemsInstanceSchema.schema,
      realmsToDeleteOnMigrationNeeded: true,
		});
		const retry_chkRealmAction = Realm.open({
			path: schemaPaths.retry_checklistItemsInstances,
			schemaVersion: checklistItemsInstanceSchema.schemaVersion,
			schema: checklistItemsInstanceSchema.schema,
      realmsToDeleteOnMigrationNeeded: true,
		});
		const piRealmAction = Realm.open({
			path: schemaPaths.propertyInstances,
			schemaVersion: rlmSchema.propertyInstanceSchema.schemaVersion,
			schema: [rlmSchema.lastUpdateTSSchema.schema, rlmSchema.propertyInstanceSchema.schema],
      realmsToDeleteOnMigrationNeeded: true,
		});
		const empRealmAction = Realm.open({
			path: schemaPaths.employees,
			schemaVersion: rlmSchema.employeeSchema.schemaVersion,
			schema: [rlmSchema.lastUpdateTSSchema.schema, rlmSchema.employeeSchema.schema],
			// onMigration: rlmMigrations.employeesMigrate,
      realmsToDeleteOnMigrationNeeded: true,
		});
		const equRealmAction = Realm.open({
			path: schemaPaths.equipment,
			schemaVersion: rlmSchema.equipmentSchema.schemaVersion,
			schema: [rlmSchema.lastUpdateTSSchema.schema, rlmSchema.equipmentSchema.schema],
			// onMigration: rlmMigrations.equipmentMigrate,
      realmsToDeleteOnMigrationNeeded: true,
		});

		const retryObjectsRealmAction = Realm.open({
			path: schemaPaths.retryObjects,
			schemaVersion: rlmSchema.retryObjectsSchema.schemaVersion,
			schema: [rlmSchema.retryObjectsSchema.schema],
      realmsToDeleteOnMigrationNeeded: true,
		});

		const lastUpdatesV2RealmAction = Realm.open({
			path: schemaPaths.lastUpdatesV2,
			schemaVersion: rlmSchema.lastUpdatesV2Schema.schemaVersion,
			schema: [rlmSchema.lastUpdatesV2Schema.schema],
		});

		const commentsRealmAction = Realm.open({
			path: schemaPaths.comments,
			schemaVersion: commentsSchema.schemaVersion,
			schema: commentsSchema.schema,
			// onMigration: rlmMigrations.commentsMigrate,
      deleteRealmIfMigrationNeeded: true,
		});
          
		const reducerPersistRealmAction = Realm.open({
			path: schemaPaths.reducerPersist,
			schemaVersion: rlmSchema.reducerPersistSchema.schemaVersion,
			schema: [rlmSchema.reducerPersistSchema.schema],
		});

    const urlCacheRealmAction = Realm.open({
      path: schemaPaths.urlCache,
			schemaVersion: rlmSchema.urlCacheSchema.schemaVersion,
			schema: [rlmSchema.urlCacheSchema.schema],
    })

		const logObjectsAction = Realm.open({
			path: schemaPaths.logObjects,
			schemaVersion: rlmSchema.logObjectsSchema.schemaVersion,
			schema: [rlmSchema.logObjectsSchema.schema],
      realmsToDeleteOnMigrationNeeded: true,
		});

    const companiesAction = Realm.open({
			path: schemaPaths.companies,
			schemaVersion: rlmSchema.companiesSchema.schemaVersion,
			schema: [rlmSchema.companiesSchema.schema],
      realmsToDeleteOnMigrationNeeded: true,
		});

    const formsAction = Realm.open({
      path: schemaPaths.forms,
      schemaVersion: formsSchema.schemaVersion,
      schema: formsSchema.schema,
      realmsToDeleteOnMigrationNeeded: true,
    });

		const realmInstancesArray = await Promise.all([
			postRealmAction,
			chkRealmAction,
			piRealmAction,
			empRealmAction,
			equRealmAction,
			retry_postRealmAction,
			retry_chkRealmAction,
			retryObjectsRealmAction,
			lastUpdatesV2RealmAction,
			reducerPersistRealmAction,
			logObjectsAction,
			commentsRealmAction,
      companiesAction,
      urlCacheRealmAction,
      formsAction
		]);

		realmInstance.posts = enhanceRealm(realmInstancesArray[0]);
		realmInstance.checklistItemsInstances = enhanceRealm(realmInstancesArray[1]);
		realmInstance.propertyInstances = enhanceRealm(realmInstancesArray[2]);
		realmInstance.employees = enhanceRealm(realmInstancesArray[3]);
		realmInstance.equipment = enhanceRealm(realmInstancesArray[4]);
		realmInstance.retry_posts = enhanceRealm(realmInstancesArray[5]);
		realmInstance.retry_checklistItemsInstances = enhanceRealm(realmInstancesArray[6]);
		realmInstance.retryObjects = enhanceRealm(realmInstancesArray[7]);
		realmInstance.lastUpdatesV2 = enhanceRealm(realmInstancesArray[8]);
		realmInstance.reducerPersist = enhanceRealm(realmInstancesArray[9]);
		realmInstance.logObjects = enhanceRealm(realmInstancesArray[10]);
		realmInstance.comments = enhanceRealm(realmInstancesArray[11]);
		realmInstance.companies = enhanceRealm(realmInstancesArray[12]);
    realmInstance.urlCache = enhanceRealm(realmInstancesArray[13]);
    realmInstance.forms = enhanceRealm(realmInstancesArray[14]);

    // If we deleted the realm instead of migrating, we should delete the lastUpdatesV2 docs
    // otherwise, it would not fetch the data again
    await Promise.all(realmInstancesArray.map(async realmInstance => {
      const fileName = path.basename(realmInstance.path);
      const lastUpdatesV2Subject = pathToLastUpdatesV2Subject[fileName];
      if (!realmsToDeleteOnMigrationNeeded[fileName] || !lastUpdatesV2Subject) return;

      const prevSchemaVersion = prevSchemaVersions[fileName];
      const nextSchemaVersion = realmInstance.schemaVersion;

      if (prevSchemaVersion !== nextSchemaVersion) {
        let lastUpdateDocs = [];
        if (schemaPaths.propertyInstances === fileName) {
          Object.values(SUBJECT_NAMES).forEach(subjectName => {
            lastUpdateDocs.push(...getLocalLastUpdates({ type: 'lastUpdates', subject: path.join(lastUpdatesV2Subject, subjectName) }));
          });
        } else {
          lastUpdateDocs = getLocalLastUpdates({ type: 'lastUpdates', subject: pathToLastUpdatesV2Subject[fileName] } );
        }
        await deleteMultiLocalLastUpdatesById(lastUpdateDocs.map(doc => doc.id));
      }
    }));

	} catch (error) {
    onError({
      errorMessage: 'Error loading realm instance - ' + error?.message,
      error,
      methodMetaData: {
        name: 'loadRealmInstance',
        args: [],
      },
    });
	}
}

let utilsConfigObject = {};
export const getUtilsConfigObject = () => {
  return utilsConfigObject;
};

export default function configureMiddleware(
  initialState,
  platformDeps,
  platformMiddleware,
  standardInputComponent,
  containerComponent,
  textComponent,
  tradeComponent,
  theme
) {
  setPlatformStandardInput(standardInputComponent);
  setPlatformContainer(containerComponent);
  setPlatformText(textComponent);
  setPlatformTradeBadge(tradeComponent);
  setPlatformTheme(theme);

  //setInterval(function() { writeLog(null, null, null, null, true) }, 5000);
  deviceAppVersion = platformActions.app.getVersion();
  platform = platformActions.app.getPlatform();

  if (!logRocket) {
    logRocket = platformActions.LogRocket.getLogRocket();
  }

  if (!sentry) {
    sentry = platformActions.sentry.getSentry();

    platformActions.mixpanel.init(initialState.config.mixpanel, () => {
      isMixpanelInit = true;
    });
    userSuperProps = { platform: platform };
  }

  if (!firebase) {
    firebase = platformActions.firebase.getFirebase();
    if (platform == "web")
      firebase.initializeApp(initialState.config.firebase);
  }

  if (!firebaseDeps) {
    // Lazy init.
    firebaseDeps = {
      firebase: firebase.database().ref(),
      firebaseAuth: firebase.auth,
      firebaseAnalytics: firebase.analytics ? firebase.analytics() : null,
      firebaseDatabase: firebase.database,
      firebaseFirestore: firebase.firestore,
      firebaseStorage: firebase.storage,
      firebaseMessaging: firebase.messaging,
      firebaseRemoteConfig: firebase.remoteConfig,
    };
  }
  getFirebaseDeps = () => firebaseDeps;

  if (platform == "web" && !lokiInstance) {
    lokiInstance = platformActions.localDB.getInstance();
    window.lokiInstance = lokiInstance;
    lokiCollections.forEach((c) => lokiInstance.addCollection(c));
  }
  
  if (!envParams)
    envParams = {
      flavour: initialState.config.flavour,
      servicesServer: initialState.config.servicesServer,
      apiServer: !isLocalHost
        ? initialState.config.apiServer
        : "http://127.0.0.1:8080",
      notificationServer: initialState.config.notificationServer,
      auth0: initialState.config.auth0,
      isProduction: initialState.config.isProduction,
      deploy_env: initialState.config.deploy_env,
    };

  const { STORAGE_SAVE, storageEngine, storageMiddleware } = configureStorage(
    initialState,
    platformDeps.createStorageEngine
  );

  const middleware = [
    injectMiddleware({
      ...platformDeps,
      ...firebaseDeps,
      ...apiListenersDeps,
      getUid: () => uuidv4(),
      now: () => Date.now(),
      removeEmpty,
      initUserLogsData,
      platformActions,
      writeLog,
      apiServer: envParams.apiServer,
      notificationServer: envParams.notificationServer,
      auth0: envParams.auth0,
      storageEngine,
      validate,
      realmInstance,
      lokiInstance,
      sentry,
      logRocket,
    }),
    promiseMiddleware(platformDeps),
    ...platformMiddleware,
  ];

  if (storageMiddleware) {
    middleware.push(storageMiddleware);
  }
  const enableLogger =
    (process.env.NODE_ENV !== "production" && process.env.IS_BROWSER) ||
    initialState.device.isReactNative;

  // Logger must be the last middleware in chain.
  if (false && enableLogger) {
    const ignoredActions = [STORAGE_SAVE];
    const logger = createLoggerMiddleware({
      collapsed: true,
      predicate: (getState, action) =>
        ignoredActions.indexOf(action.type) === -1,
      // Convert immutable to JSON.
      stateTransformer: (state) => JSON.parse(JSON.stringify(state)),
    });
    middleware.push(logger);
  }

  utilsConfigObject = {
    ...firebaseDeps,
    platformActions,
    apiServer: envParams.apiServer,
  };

  // if (logRocket)
  //   middleware.push(logRocket.reduxMiddleware());

  return middleware;
}

var getAppState;
var getDispatch;
var getFirebaseDeps;
let apiListenersDeps;


export {
  realmInstance,
  getAppState,
  getDispatch,
  writeLog,
  registerUserScopeLogs,
  isMixpanelInit,
  lokiInstance,
  apiListenersDeps,
  firebaseDeps,
  getFirebaseDeps,
};
