import { startToast } from '../app/actions';
import reportsMessages from '../reports/reportsMessages';
import _ from 'lodash';
import { batchDispatch } from '../app/funcs';
import { track } from '../lib/reporting/actions';
import { getAppState } from '../configureMiddleware';
import { populateFormTemplates } from './funcs';

export const SET_FORM_EMAIL = 'SET_FORM_EMAIL';
export const SET_FORM_EMAIL_MODAL_PARAMS = 'SET_FORM_EMAIL_MODAL_PARAMS';
export const UNSET_FORM_EMAIL_MODAL_PARAMS = 'UNSET_FORM_EMAIL_MODAL_PARAMS';
export const POPULATE_FORM_TEMPLATES = 'POPULATE_FORM_TEMPLATES';

export function setFormEmail({ projectId, formType, formId, selectedEmail }) {
  return ({ platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!projectId || !formType || !formId || !selectedEmail) {
        return;
      }

      const updates = {
        targetEmails: [selectedEmail],
        emailSentDate: null,
        readyToGenerateTS: Date.now(),
      };

      let success = true;
      try {
        await platformActions.net.fetch(apiServer + `/v1/forms/${formId}?projectId=${projectId}&formType=${formType}`, {
          method: 'PATCH',
          body: JSON.stringify(updates),
        });
      } catch (error) {
        success = false;
      } finally {
        const trackText = success ? 'Set Form Email' : 'Set Form Email Failed';
        const toastText = success ? reportsMessages.savedSetFormEmail : reportsMessages.exportErrors.setFromEmailFailed;

        batchDispatch([
          track(trackText, { projectId, formType, formId, selectedEmail }),
          startToast({ title: toastText }),
        ]);
      }
      return { scopeId: projectId, formType, formId, selectedEmail };
    };

    return {
      type: SET_FORM_EMAIL,
      payload: getPromise(),
    };
  };
}

export const setFormEmailModalParams = ({ isVisible, projectId, formType, formId }) => {
  return {
    type: SET_FORM_EMAIL_MODAL_PARAMS,
    payload: {
      scopeId: projectId,
      formType,
      formId,
      isVisible: _.isNil(isVisible) ? Boolean(projectId && formType && formId) : isVisible,
    },
  };
};

export const unsetFormEmailModalParams = () => {
  return {
    type: UNSET_FORM_EMAIL_MODAL_PARAMS,
    payload: {
      scopeId: null,
      formType: null,
      formId: null,
      isVisible: false,
    },
  };
};

/**
 * Populates form templates locally by merging them with corresponding checklists and checklist items,
 * using either provided data or falling back to the current application state.
 *
 * @param {Object} params - The parameters for populating form templates.
 * @param {string} params.scopeId - The scope ID used to retrieve or map form templates, checklists, and checklist items.
 * @param {Object[]|Object} [params.formsTemplates] - Optional. Array of form templates or an object map keyed by template ID. Falls back to app state if not provided.
 * @param {Object[]|Object} [params.checklists] - Optional. Array of checklists or an object map keyed by checklist ID. Falls back to app state if not provided.
 * @param {Object[]|Object} [params.checklistItems] - Optional. Array of checklist items or an object map keyed by item ID. Falls back to app state if not provided.
 *
 * @returns {Object} An action object containing:
 *  - {string} type - The action type (`POPULATE_FORM_TEMPLATES`).
 *  - {Object} payload - Contains the `scopeId` and the populated `formTemplates`.
 */
export const locallyPopulateFormsTemplates = ({
  scopeId,
  formsTemplates: _formTemplates,
  checklists: _checklists,
  checklistItems: _checklistItems,
}) => {
  const appState = getAppState();

  const formTemplates = _formTemplates || appState.getNested(['configurations', 'map', scopeId, 'forms']);
  const checklists =
    (_.isArray(_checklists) ? _.mapKeys(_checklists, (c) => c.id) : _checklists) ||
    appState.getNested(['checklists', 'map', scopeId]);
  const checklistItems =
    (_.isArray(_checklistItems) ? _.mapKeys(_checklistItems, (c) => c.id) : _checklistItems) ||
    appState.getNested(['checklistItems', 'map', scopeId]);

  let populatedFormTemplates = null;
  if (scopeId && formTemplates && checklists && checklistItems) {
    populatedFormTemplates = populateFormTemplates({ scopeId, formTemplates, checklists, checklistItems });
  }

  return {
    type: POPULATE_FORM_TEMPLATES,
    payload: { scopeId, formTemplates: populatedFormTemplates },
  };
};