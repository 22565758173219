import { useContext } from 'react';
import PropertyAnalytics from '../../../web/views/Properties/PropertyAnalytics';
import RemoteConfig from '../../app/featureFlag/RemoteConfig';
import ObjectsWrapper from '../ObjectsWrapper';
import { ProjectContext } from '../../projects/contexts';

const SUPPORTED_CONTENT_TYPES = [
  'issues',
  'records',
  'safety',
  // 'info',
  // 'forms',
];

const ObjectsWrapperRemoteConfig = (props) => {
  const { contentType, section } = props;
  const projectProps = useContext(ProjectContext);
  return (
    <RemoteConfig>
      {(remoteConfig) => {
        // eslint-disable-next-line no-undef
        const env = process.env.REACT_APP_ENVIRONMENT;
        const isContentTypeSupported = SUPPORTED_CONTENT_TYPES.includes(contentType);
        const isObjectsWrapperForced = remoteConfig?.getValue(`${env}_forceObjectsWrapper`)?.asBoolean();
        const isProjectEnabled = remoteConfig
          ?.getValue(`${env}_objectsWrapperAllowedProjectIds`)
          ?.asString()
          ?.includes(projectProps?.selectedProjectId);

        const shouldShowObjectsWrapper = isObjectsWrapperForced || (isContentTypeSupported && isProjectEnabled);

        if (shouldShowObjectsWrapper) {
          return <ObjectsWrapper contentType={contentType} section={section} />;
        }
        return <PropertyAnalytics contentType={contentType} section={section} />;
      }}
    </RemoteConfig>
  );
};

export default ObjectsWrapperRemoteConfig;
