// eslint-disable-next-line no-unused-vars
import ConnectivitySubscriptionTable from './ConnectivitySubscriptionTable';
import UserValidator from '../UserValidator';
import ScopeContextPage from '../views/Projects/ScopeContextPage';
import DataManagerTable from './DataManagerTable';
import { useSelector } from 'react-redux';
import Alerts from './Alerts';

const RootRouterComponent = ({ onSignOut }) => {
  const { connectionViewerVisiblity, dataManagerVisibility} = useSelector((state) => ({
    connectionViewerVisiblity: state.app.connectionViewerVisiblity,
    dataManagerVisibility: state.app.dataManagerVisibility,
  }));

  return (
    <ScopeContextPage>
      <UserValidator onSignOut={onSignOut} />
      <Alerts />
      {Boolean(connectionViewerVisiblity.getNested(['visible'])) && <ConnectivitySubscriptionTable />}
      {Boolean(dataManagerVisibility.getNested(['visible'])) && <DataManagerTable />}
    </ScopeContextPage>
  );
};
export default RootRouterComponent;
