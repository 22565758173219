import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getFromPropsOrNav } from '../../native/app/funcs';
import { getLocalDBFormQuery, startFormsListener } from '../forms/funcs';
import { removeNilAndEmpty } from '../lib/utils/utils';
import { platformActions } from '../platformActions';
import { ProjectContext } from '../projects/contexts';

/**
 * Custom hook to get forms data by interacting with a local database and starting listeners.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {string} params.formType - The type of form to filter.
 * @param {string} [params.locationId] - The location ID to filter forms by.
 * @returns {Object} An object containing the `forms` state.
 */
export const useForms = ({ formType, locationId }) => {
  const { selectedProjectId: projectId, viewer } = useContext(ProjectContext);

  const localDB = platformActions.localDB.getCementoDB();

  const [forms, setForms] = useState([]);

  const filterQuery = useMemo(
    () => getLocalDBFormQuery({ projectId, formType, locationId }),
    [projectId, formType, locationId]
  );

  const updateFormState = useCallback(() => {
    const data = localDB.get('forms', filterQuery) || [];
    const formsMap = _.mapKeys(data, 'id');
    setForms(formsMap);
  }, [setForms, localDB, filterQuery]);

  const startLocalDBListener = useCallback(() => {
    return localDB.startListener(
      'forms',
      updateFormState,
      platformActions.app.isNative() ? { objectName: 'forms', query: filterQuery } : {}
    );
  }, [updateFormState, filterQuery]);

  useEffect(() => {
    const formsListenerParams = removeNilAndEmpty({ projectId, formType, locationId });
    const endListenersFuncs = [
      startLocalDBListener(),
      startFormsListener(viewer, formsListenerParams, undefined, true),
    ];
    return () => endListenersFuncs.forEach((endListener) => endListener?.());
  }, [projectId, formType]);

  useEffect(() => {
    updateFormState();
  }, [filterQuery]);

  return { forms };
};

/**
 * Higher-Order Component (HOC) to inject forms data into a wrapped component.
 *
 * @param {React.ComponentType} WrappedComponent - The component to be wrapped.
 * @returns {React.FunctionComponent} A component with forms data injected into props.
 */
export const FormsHOC = (WrappedComponent) => {
  const WithForms = (props = {}) => {
    const formType = getFromPropsOrNav(props, ['formType'], 'general');
    const locationId = getFromPropsOrNav(props, ['locationId'], null);
    const formsProps = useForms({ formType, locationId });
    return <WrappedComponent {...formsProps} {...props} />;
  };
  return WithForms;
};
