import React, { Component } from 'react';
import { connectContext } from 'react-connect-context';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { injectIntl } from 'react-intl';
import { ProjectContext } from '../../projects/contexts';
import { getApiSourceObjectsFromContext, checkError, getApiSource, getPropPermissions } from '../../propertiesTypes/funcs';
import getPlatformStandardInput from '../platformComponents/PlatformStandardInput';
import { platformActions } from '../../platformActions';
import * as propertyTypes from '../../propertiesTypes/propertiesTypes';
import { instanceDataToString } from '../../propertiesInstances/funcs';
import NavigationRef from '../../../native/app/AppNavigationRef.react';
import getPlatformTradeBadge from '../platformComponents/PlatformTradeBadge';
import getPlatformTheme from '../../platformTheme';
import postsMessages from '../../posts/postsMessages';
import { GET_NOTHING_TO_DISPLAY_UI_STRUCT } from './NothingToDisplay';
import propertiesMessages from '../../propertiesTypes/propertiesMessages';
import DynamicUIRenderer from './DynamicUIRenderer';
import * as permissionsFunc from "../../../common/permissions/funcs";
import { isEmptyValue } from '../funcs';
import { syncStatuses, apiSources } from '../constants';

const getPlatformUIStruct = uiStruct => {
	const platform = platformActions.app.getPlatform();
	const isNative = platformActions.app.isNative();
	return _.get(uiStruct, [platform])
		? _.get(uiStruct, [platform])
		: Boolean(isNative && _.get(uiStruct, ['native']))
		? _.get(uiStruct, ['native'])
		: _.get(uiStruct, ['base']);
};

const getBadgeUIStruct = (messageString = '') => [
	{
		'HTMLAttributes': {
			'style': {
				'alignItems': 'center',
				'borderColor': '$theme.textColor',
				'borderRadius': 50,
				'borderStyle': 'solid',
				'borderWidth': 1,
				'paddingBottom': 1,
				'paddingLeft': '$theme.padding',
				'paddingRight': '$theme.padding',
				'paddingTop': 1,
			},
			'textStyle': {
				'color': '$theme.placeholderTextColor',
			},
		},
		'valueString': messageString,
	},
];

/**
 * @typedef FunctionalInputProps
 * @property {boolean} disabled
 * @property {string} propId
 * @property {{ [propId: string]: any }} [values] - all values (value is extracted with propId)
 * @property {any} [value] - value of the current property - only looked at if no "values" provided
 * @property {string} subjectName
 * @property {any} [navigation] - for pdf opening (native only)
 * @property {boolean} [checkErrorsOnChange] - default: true
 * @property {(e: any, c: React.Component) => void} onRef - used for error checking - this.inputRefs[item.id].component.checkErrors() ((e, c) => { this.inputRefs[item.id] = { e, component }; })
 * @property {(propId: string, newData: any) => void} onChange
 * @property {boolean} [isMandatory]
 * @property {{} | {}[]} style
 * @property {{ [propId: string]: Property }} [extraPropertiesTypes] - Properties that are not in the store map
 * @property {(label: string, value: string) => React.ReactNode} [renderViewComponent]
 * @property {boolean} [isEditable]
 *
 * @extends {Component<FunctionalInputProps>}
 */
class FunctionalInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.handleImageResponse = this.handleImageResponse.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.handleVideoResponse = this.handleVideoResponse.bind(this);
    this.handleRemoveVideo = this.handleRemoveVideo.bind(this);
    this.loadOptionalDefaultValue = this.loadOptionalDefaultValue.bind(this);

    this.state = {
      isNative: platformActions.app.getPlatform() != 'web',
      prop: {},
      error: null,
      key: null,
      value: null,
      values: [],
      valueStrings: {},
    };

    this.platformTheme = getPlatformTheme();
    this.syncStatus = syncStatuses.UNSYNCED // This is implicitly linked to state.value therefore it ok to use it in the render
  }

  static defaultProps = {
    getInputComponent: getPlatformStandardInput
  };

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  checkErrors(nextValue, nextProp) {
    const { propertiesTypes, intl, subjectName, onError, propertiesSections, selectedProjectId } = this.props;
    const prop = _.isUndefined(nextProp) ? this.state.prop : nextProp;
    const value = _.isUndefined(nextValue) ? this.state.value : nextValue;


    let errors = checkError(value, prop, propertiesSections[subjectName] || {}, propertiesTypes[subjectName] || {}, intl, selectedProjectId);

    this.setState({ error: errors });
    if (onError)
      onError();
    return errors;
  }

  setComponentData(props, nextProps) {
    let { propertiesTypes: nextPropertiesTypes = {}, subjectName, propId, intl, getInputComponent, selectedProjectId, optionalDefaultValue, shouldLoadOptionalDefaultValue = true, isFormClosed, isConnected, onOpenFeatureToast, loadingMap } = nextProps;
    const { isNative, prop, value, didChange } = this.state;
    const getProp = (propId, isNext = false) => (!isNext ? props : nextProps).getNested(['extraPropertiesTypes', propId]) || (!isNext ? props : nextProps).getNested(['propertiesTypes', subjectName, propId], {});
    const prevProperty = getProp(propId);
    const nextProperty = getProp(propId, true);
    const nextProjLang = nextProps.getNested(['project', 'lang'], 'en');
    let nextPermissions = getPropPermissions(selectedProjectId, nextProperty);
    nextPermissions = {
      writePermission: nextPermissions.write,
      readPermission: nextPermissions.read,
      isSafetyPermitted: permissionsFunc.getActionPermissions(nextProps.viewer, selectedProjectId, 'tabs', ['safety', 'write'])
    };
    let prevPermissions = _.pick(this.state, _.keys(nextPermissions));
    let newStateChanges = {};

    if (!_.isEqual(nextPermissions, prevPermissions))
      _.assign(newStateChanges, nextPermissions);

    if (props.firstMount || prevProperty != nextProperty || props.propId != nextProps.propId || props.subjectName != nextProps.subjectName || nextProperty != this.state.prop) {
      newStateChanges.prop = nextProperty;
      newStateChanges.InputComponent = getInputComponent();
    }

    if (props.firstMount || (props.isValDiff(nextProps, ['isMulti']) || prevProperty.isValDiff(nextProperty, ['settings', 'isMulti'])))
			newStateChanges.isMulti = Boolean(typeof nextProps.isMulti !== 'undefined' ? nextProps.isMulti : _.get(nextProperty, ['settings', 'isMulti'], false));

    if (props.isValDiff(nextProps, ['values', propId]))
      newStateChanges.value = nextProps.getNested(['values', propId], null);
    else if (props.isValDiff(nextProps, ['value']))
      newStateChanges.value = nextProps.getNested(['value'], null);

    const nextValue = newStateChanges.value !== undefined ? newStateChanges.value : (this.state.value || null);
    if (!_.isEqual(this.state.value, nextValue)) {
      let dataByPropId = {};
      
      if (nextProperty.type === propertyTypes.COMPLEX)
        dataByPropId = nextValue || {};
      else
        dataByPropId[nextProperty.id] = nextValue;

      newStateChanges.dataByPropId = dataByPropId;
      newStateChanges.valueStrings = Object.entries(dataByPropId).reduce((acc, [propId, data]) => {
        const prop = nextProps.getNested(['extraPropertiesTypes', propId]) || nextPropertiesTypes.getNested([subjectName, propId], {});
        acc[propId] = instanceDataToString(prop, data, nextProps.intl);
        
        return acc;
      }, {});
    }

    const nextValueStrings = newStateChanges.valueStrings || this.state.valueStrings || {};
    const nextDataByPropId = newStateChanges.dataByPropId || this.state.dataByPropId || {};

    if (!_.isEqual(prevProperty.UIStruct, nextProperty.UIStruct) || !_.isEqual(nextDataByPropId, this.state.dataByPropId)) {
      let hasValuesToDisplay = false;
      const structMapFunc = struct => {
        let newStruct = Object.assign({}, struct);
        
        if (struct.propId) {
          if (!hasValuesToDisplay && nextDataByPropId[struct.propId])
            hasValuesToDisplay = true;

          delete newStruct.propId;
          const prop = getProp(struct.propId, true) || {};
          switch (prop.businessType) {
            case ('trades'): {
              const data = nextDataByPropId[struct.propId];
              if (data) {
                let componentProps;
                if (this.state.isNative)
                  componentProps = {
                    trades: data,
                    isColoredBadge: true,
                  };
                else
                  componentProps = {
                    textStyle: { 
                      fontSize: this.platformTheme.fontSize, 
                    },
                    tradeStyle: {
                      padding: `1px ${this.platformTheme.padding}px`,
                      margin: 0,
                      height: 'unset',
                    },
                    containerStyle: (newStruct.HTMLAttributes || {}).style,
                    ids: Object.keys(data),
                  };

                newStruct.valueSlot = React.createElement(getPlatformTradeBadge(), componentProps);
              }
              else 
                newStruct.valueSlot = <DynamicUIRenderer UIStruct={getBadgeUIStruct(intl.formatMessage(propertiesMessages.empty))} />
              break;
            }

            default:
              newStruct.valueString = nextValueStrings[struct.propId] || '';
          }

          if (newStruct.valueString === '' && !newStruct.defaultValueString) {
            let defaultString = '';

            switch (prop.type) {
              case propertyTypes.SELECTION_LIST:
              case propertyTypes.STRING:
                defaultString = intl.formatMessage(propertiesMessages.empty);
                break;

              case propertyTypes.NUMBER:
                defaultString = '0';
                break;
            }

            if (defaultString) {
              newStruct.defaultValueString = { [nextProjLang]: defaultString };
              if (struct.HTMLAttributes)              
                newStruct.HTMLAttributes = Object.assign({}, struct.HTMLAttributes);
              if (_.get(struct, ['HTMLAttributes', 'textStyle']))
                newStruct.HTMLAttributes.textStyle = Object.assign({}, struct.HTMLAttributes.textStyle);

              _.set(newStruct, ['HTMLAttributes', 'textStyle', 'color'], '$theme.placeholderTextColor')
              _.set(newStruct, ['HTMLAttributes', 'textStyle', 'fontWeight'], 'normal');
            }
          }
        }

        if ((newStruct.elements || []).length)
          newStruct.elements = newStruct.elements.map(structMapFunc);

        return newStruct;
      }
      
      const nextUIStruct = getPlatformUIStruct(_.get(nextProperty, ['UIStruct']));

      const formattedUIStruct = (nextUIStruct || []).map(structMapFunc);
      const nextFormattedUIStruct = (hasValuesToDisplay && formattedUIStruct.length) 
                                      ? formattedUIStruct 
                                      : nextUIStruct ? GET_NOTHING_TO_DISPLAY_UI_STRUCT(intl.formatMessage(postsMessages.empty)) : null;

      if (!_.isEqual(this.state.formattedUIStruct, nextFormattedUIStruct))
        newStateChanges.formattedUIStruct = nextFormattedUIStruct;
    }

    if (nextProperty.values && (props.firstMount || (nextProperty.values != prevProperty.values))) {
      newStateChanges.options = {};

      nextProperty.getNested(['values']).forEach((curr, index) => {
        const currOption = {
          isDeleted: curr.isDeleted,
          ordinalNo: curr.ordinalNo || index,
          id: curr.id,
          isDisabled: curr.isDisabled,
          section: nextProps.name,
          title: curr.getCementoTitle
            ? curr.getCementoTitle(false)
            : ((curr.title && typeof curr.title == 'object') ? curr.title[nextProjLang] : curr.title)
        };
        if (currOption.title)
          newStateChanges.options[currOption.id] = currOption;
      });
    }

    const prevShowOnNull = prevProperty.showOnNullValue || props.alwaysShowOnNullValue;
    const nextShowOnNull = nextProperty.showOnNullValue || nextProps.alwaysShowOnNullValue;
    
    if (props.firstMount || prevShowOnNull != nextShowOnNull)
      newStateChanges.showOnNullValue = _.isNil(nextShowOnNull) ? true : Boolean(nextShowOnNull);

    const prevHideOnMobile = Boolean(isNative && _.isNil(props.alwaysShowOnMobile) ? prevProperty.hideOnMobile : !props.alwaysShowOnMobile)
    const nextHideOnMobile = Boolean(isNative && _.isNil(nextProps.alwaysShowOnMobile) ? nextProperty.hideOnMobile : !nextProps.alwaysShowOnMobile)

    if (props.firstMount || prevHideOnMobile != nextHideOnMobile)
      newStateChanges.hideOnMobile = nextHideOnMobile;
    
      if (props.firstMount || nextProperty.isValDiff(prevProperty, ['settings', 'longText']))
      newStateChanges.longText = nextProperty.getNested(['settings', 'longText'], true);


    if (nextProps.checkErrorsOnInit && (props.firstMount || nextProps.checkErrorsOnInit != props.checkErrorsOnInit)) {
      this.checkErrors(newStateChanges.value, newStateChanges.prop);
    }

    const property = props.firstMount ? nextProperty : prop;

    const apiSource = property.settings?.apiSource?.value;
    const hasValue = !isEmptyValue(nextProps.value) || !isEmptyValue(nextProps.values?.[property.id]);
    const isOverride = property.settings?.apiSource?.behaviour === 'override';
    const apiSourceObjects = getApiSourceObjectsFromContext(nextProps.context) || [];
    const apiSourceObject = nextProps.apiSourceMetadata?.[apiSourceObjects[0]?.pathToValue || property.id];
    const isSyncEnabled = !this.props.isUnsyncable && !apiSourceObject?.isUnsyncable;
    const isLoadingApiSource = loadingMap?.[apiSourceObject?.pathToValue || property.id]

    // Dont remove - This is a hack so the Inner input on the left side would be aware to sync status on first render
    if (props.firstMount && isSyncEnabled && hasValue && apiSourceObject?.loadedFromApiSource) {
      this.syncStatus = syncStatuses.SYNCED;
    }

    if (props.firstMount && isSyncEnabled && apiSource) {
      newStateChanges.isSyncable = true;
    }
    
    const shouldLoadApiSource = (
      apiSource &&
      this.syncStatus !== syncStatuses.SYNCED &&
      !isLoadingApiSource && 
      isSyncEnabled &&
      (!hasValue || isOverride) &&
      !isFormClosed && 
      nextProps.prevFormDataLoaded &&
      isConnected
    );

    if (shouldLoadApiSource) {
      const featureToastParams = this.getFeatureToastParams(apiSource);
      if (onOpenFeatureToast && featureToastParams) onOpenFeatureToast(featureToastParams)

      this.loadApiSource(property, nextProps)
    } 

    const shouldCopyOver = _.get(property, ['tags', 'copyOver']);
    const isRelevantProp = !_.some([propertyTypes.COMPLEX, propertyTypes.ARRAY], property.type);
    const settingsDefaultVal = _.get(property, ['settings', 'defaultVal']);
    const didPrevFormLoad = props.prevFormDataLoaded !== nextProps.prevFormDataLoaded && nextProps.prevFormDataLoaded;

    const hasNoValue = isEmptyValue(nextValue || value);
    const isReadyToLoadDefaultValue =
      shouldLoadOptionalDefaultValue &&
      didPrevFormLoad &&
      !didChange &&
      shouldCopyOver &&
      isRelevantProp &&
      hasNoValue &&
      !isEmptyValue(optionalDefaultValue);

    if (isReadyToLoadDefaultValue) {
      this.loadOptionalDefaultValue(nextProps);
    } else if (!shouldLoadOptionalDefaultValue && hasNoValue && !isEmptyValue(settingsDefaultVal)) {
      this.handleChange(settingsDefaultVal, undefined, true);
    }

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  getFeatureToastParams = (apiSource) => {
    const { selectedProjectId } = this.props;
    let toastParams;
    switch (apiSource) {
      case apiSources.siteControlCompaniesCounter:
        toastParams = { id: `${selectedProjectId}_${apiSource}`, featureId: apiSources.siteControlCompaniesCounter };
    }
    return toastParams;
  }

  loadApiSource = async (prop, nextProps) => {
    this.syncStatus = syncStatuses.SYNCING;
    
    const { context: propsContext, selectedProjectId, setLoading, apiSourceMetadata } = nextProps;
    
    const apiSourceObjects = getApiSourceObjectsFromContext(propsContext);
    const pathToValue = apiSourceObjects?.[0]?.pathToValue || prop.id
    setLoading(pathToValue, true)

    const context = {
      ...propsContext,
      projectId: selectedProjectId,
      parentId: propsContext.objectId,
      propId: prop.id,
    }
    
    let metadata = { loadedFromApiSource: true };

    let apiSourceObject = apiSourceMetadata?.[pathToValue]
    if (!apiSourceObject) {
      // If its a new complex - check if there is parellel complex to this company that is loaded from default source - so we wont have to fetch this data again
      const apiSourceObjects = getApiSourceObjectsFromContext({
        parentPropIds: [_.first(context.parentPropIds)].filter(Boolean),
        extra: { valueIds: [_.first(context.extra?.valueIds)].filter(Boolean) },
      });
      const parentPathToValue = apiSourceObjects?.[0]?.pathToValue
      apiSourceObject = _.find(apiSourceMetadata, object => {
        return object.pathToValue.startsWith(parentPathToValue) && object.pathToValue.includes(prop.id)
      });
      if (apiSourceObject?.apiSourceValue) {
        metadata.apiSourceObjects = [{ apiSourceValue: apiSourceObject.apiSourceValue, pathToValue }]
      }
    }

    const apiSource = await getApiSource(context, apiSourceObject, metadata);

    const mergedContext = {
      ...propsContext, 
      isFiredByServer: apiSource?.metadata?.loadedFromApiSource,
      apiSourceObjects: apiSource?.metadata?.apiSourceObjects
    }
    
    this.handleChange(apiSource?.data, undefined, mergedContext);
    this.syncStatus = syncStatuses.SYNCED;
    setLoading(pathToValue, false)
  }
  
  loadOptionalDefaultValue(nextProps){
    const { optionalDefaultValue: _optionalDefaultValue } = nextProps;
    const { prop } = this.state;

    // The project context is different in web and native
    const members = platformActions.app.isWeb() ? nextProps.projectMembers : nextProps.members?.toJS();

    const optionalDefaultValue =
    _.get(prop, ['businessType']) === propertyTypes.BUSINESS_TYPES.members
    ? _.pickBy(_optionalDefaultValue, (value, key) => members[key])
    : _optionalDefaultValue;
    

    if (optionalDefaultValue && !_.isEmpty(optionalDefaultValue)) {
      this.handleChange(optionalDefaultValue);
    }
  }

  handleChange(val, status, context) {
    const { onChange, propId, context: propsContext, checkErrorsOnChange = true } = this.props;
    if (checkErrorsOnChange)
      this.checkErrors(val);
    
    this.setState({ value: val, didChange: true });

    const userRemovedValue = this.handleSyncStatus(context, val);
    if (userRemovedValue) return; // We do not want to propogate onChange because it will load the apiSource again

    if (onChange)
      onChange(propId, val, status, { ...propsContext, ...context });
  }

  handleSyncStatus = (context, val) => {
    const { propId } = this.props;
    const { prop } = this.state;

    const isOnChangeFiredByThisInput = _.last(context?.extra?.valueIds) === propId;
    // This is a hack for seeing changes live on the left side about sync icon - since he is not aware to props updating from ObjectPropertiesPageUI
    if (isOnChangeFiredByThisInput) {
      const isSynced = context?.isFiredByServer;
      const isFiredBySyncButton = context?.isFiredBySyncButton;
      let newSyncStatus = null;

      if (isFiredBySyncButton) newSyncStatus = syncStatuses.SYNCING
      else if (isSynced) newSyncStatus = syncStatuses.SYNCED
      else newSyncStatus = syncStatuses.UNSYNCED;

      this.syncStatus = newSyncStatus;
    }

    const apiSource = _.get(prop, ['settings', 'apiSource', 'value']);
    return isOnChangeFiredByThisInput && this.syncStatus === syncStatuses.UNSYNCED && isEmptyValue(val) && Boolean(apiSource);
  }

  handleImageResponse(responseArray, isSingle) {
    const { value } = this.state;
    let newImages = {};

    if (!isSingle)
      (value || []).loopEach((i, val) => newImages[val.id || val.uri] = val);
    (responseArray || []).forEach(img => {
      if (img && img.uri) {
        newImages[img.id] = _.pick(img, ['id', 'uri', 'source', 'resized', 'owner', 'uploadTS']);
        newImages[img.id].isLocal = true;
      }
    });

    newImages = Object.values(newImages);
    this.handleChange(isSingle ? newImages[0] : newImages);
  }

  handleFilesResponse = (responseArray) => {
    const { value } = this.state;
    let newFiles = {};
      
    (value || []).forEach(val => newFiles[val.id || val.uri] = val);
    (responseArray || []).forEach(file => {
      if (file && file.uri) {
        newFiles[file.id] = file
        newFiles[file.id].isLocal = true;
      }
    });

    newFiles = Object.values(newFiles);
    this.handleChange(newFiles);
  }

  handleRemoveImage(image) {
    const { value } = this.state;

    if (value && image && image.uri) {
      let newValue = Array.isArray(value) ? value.slice() : Object.assign({}, value);
      if (Array.isArray(newValue)) {
        let imageFile = null
        for (let i = newValue.length - 1; i >= 0; i--) {
          if (value[i] && (newValue[i].uri == image.uri)) {
            newValue[i] = { ...newValue[i], isDeleted: true }
            imageFile = newValue[i];
          }
        }
        if (imageFile && imageFile.fileRefId) {
          const [indexOfLatestFileVersion, latestArchiveFile] = newValue.reduce(([indexOfLatestArchiveFile, latestFileArchive], val, index) => val && !val.isDeleted && val.isArchive && val.fileRefId === imageFile.fileRefId && (!latestFileArchive || val.version > latestFileArchive.version) ? [index, val] : [indexOfLatestArchiveFile, latestFileArchive], []);
          if (latestArchiveFile)
            newValue = _fp.set([indexOfLatestFileVersion, 'isArchive'], false, newValue);
        }
      }
      else if (value.uri == image.uri)
        newValue = null;

      this.handleChange(newValue);
    }
  }

  handleVideoResponse(videos, isSingle) {
    this.handleChange(isSingle ? videos[0] : videos);
  }

  handleRemoveVideo(video) {
    const { value } = this.state;
    let newValue = null;
    if (Array.isArray(value)) {
      newValue = value.map((item) => (item.id === video.id ? { ...item, isDeleted: true } : item));
    } 
    
    this.handleChange(newValue);
  }

  render() {
    const {
      disabled, subjectName, type, title,
      isMandatory, navigation, innerValueId,
      settings, intl, isConnected, isEditable,
      alignCenter, withResize, inputKey, propId,
      isExpandSummary, noTitle, titleStyle,
      onCardClick, containerStyle, objectId,
      openPDFInWebPage, mode, onImageSelect,
      hideCheckbox, renderPreview, optionalDefaultValue,
      isUnsyncable, loadingMap, setLoading,
      disabledText, prevFormDataLoaded, hightlightOnFocus,
      showOptionalText, hideCopyFromPreviousDailyButton
    } = this.props;
    const {
      InputComponent, value: stateValue, options,
      error, prop, showOnNullValue, hideOnMobile,
      longText = true, isNative, isMulti,
      formattedUIStruct, valueStrings, dataByPropId,
      readPermission, writePermission, isSyncable,
      isSafetyPermitted
    } = this.state;

    const extraTypes = prop.getNested(['extraTypes'], []);
    const businessType = prop.getNested(['businessType'], null);

    const isSerialNumberProp = businessType === propertyTypes.AVAILABLE_BUSINESS_TYPES.serialNumber;
    const propSettingsDefaultValue = prop.getNested(['settings', 'defaultVal']);
    let value = stateValue;
    if (isSerialNumberProp) {
      if (isEmptyValue(stateValue)) {
        value = isConnected ? '-' : intl.formatMessage(propertiesMessages.serialNumberToBeDetermined);
      }
    } else if (isEmptyValue(stateValue) && !isEmptyValue(propSettingsDefaultValue)) {
      value = propSettingsDefaultValue;
    }
    
    let isReadOnly = Boolean(!_.isNil(isEditable) ? !isEditable : ((_.get(prop, 'editable', true) === false) || isSerialNumberProp));
    let isVisible = !(isNative && (hideOnMobile || (disabled && !showOnNullValue && !value)));
    
    if (!isReadOnly)
      isReadOnly = !writePermission;
    if (isVisible)
      isVisible = readPermission;

    let propSettings = settings || prop.getNested(['settings']);
    if (!isEmptyValue(optionalDefaultValue))
      propSettings = {
        ...propSettings,
        optionalDefaultValue,
      };

    const apiSourceObjects = getApiSourceObjectsFromContext(this.props.context);
    const pathToValue = apiSourceObjects?.[0]?.pathToValue || propId;
    const isLoading = loadingMap?.[pathToValue] || this.syncStatus === syncStatuses.SYNCING;

    const props = {
      ...this.props, // TODO: remove, list all props explicitly.
      hideCheckbox,
      onCardClick,
      renderPreview,
      alignCenter,
      withResize,
      disabled,
      inputKey,
      propId,
      isExpandSummary,
      noTitle,
      titleStyle,
      subjectName,
      containerStyle,
      openPDFInWebPage,
      mode,
      onImageSelect,
      isMulti: Boolean(_.get(this.props, 'isMulti', isMulti)),
      visible: isVisible,
      isReadOnly,
      extraTypes,
      extraTypesProps: extraTypes.map(propId => this.props.getNested(['extraPropertiesTypes', propId]) || this.props.getNested(['propertiesTypes', subjectName, propId])).filter(Boolean),
      settings: propSettings,
      type: prop.getNested(['type'], type),
      title: title || prop.getNested(['getTitle']),
      businessType,
      universalId: prop.getNested(['universalId'], null),
      isMandatory: Boolean(!_.isNil(isMandatory) ? isMandatory : prop.getNested(['mandatory'], false)),
      prop,
      error,
      value,
      options,
      valueStrings,
      dataByPropId,
      UIStruct: formattedUIStruct,
      onChange: this.handleChange,
      onSingleImageResponse: (res) => this.handleImageResponse(res, true),
      onMultiImageResponse: (res) => this.handleImageResponse(res, false),
      onFilesResponse: (res) => this.handleFilesResponse(res),
      onRemoveImage: this.handleRemoveImage,
      onSingleVideoResponse: (res) => this.handleVideoResponse(res, true),
      onRemoveVideo: this.handleRemoveVideo,
      isCreatable: Boolean(_.get(this.props, 'isCreatable', prop.getNested(['isCreatable']))),
      navigation: navigation ? navigation : NavigationRef.current,
      multiline: Boolean(longText),
      ...Boolean(!longText) && { textNumberOfLines: 1, textMaxLength: 38 },
      innerValueId,     
      autoSort: _.get(propSettings, 'isSortValues', true),
      context: this.props.context,
      isSynced: this.syncStatus === syncStatuses.SYNCED,
      objectId,
      isSyncable,
      isUnsyncable,
      isLoading,
      loadingMap,
      setLoading,
      disabledText,
      prevFormDataLoaded,
      hightlightOnFocus,
      showOptionalText,
      isSafetyPermitted,
      hideCopyFromPreviousDailyButton
    };

    if (prop.type != propertyTypes.CERTIFICATION && prop.extraTypes)
      props.extraComponents = prop.extraTypes.map(extraPropId => <FunctionalInput {...this.props} propId={extraPropId} key={extraPropId} />);

    return (
        <InputComponent {...props}/>
    );
  }
}

const enhance = compose(
	connect(state => ({
		isConnected: state.app.isConnected,
	})),
	injectIntl,
	connectContext(ProjectContext.Consumer),
);
FunctionalInput = enhance(FunctionalInput);
export default FunctionalInput;
