import { getDispatch } from '../configureMiddleware';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import serverSDK from '@cemento-sdk/server';
import { floorsEvents } from './floorsEvents';

export async function getFloors(projectId, viewer) {
  const dispatch = getDispatch();

  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'floors',
    getData: () => {
      return serverSDK.locations.getFloors({ projectId });
    },
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({ type: floorsEvents.GET_FLOORS, payload: { projectId, floors: data } });
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);

  return { projectId };
}
