import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import usePosts from './hooks/usePosts';
import _ from 'lodash';

/**
 * @typedef {{ [postId: string]: string } | null} PostsSelectorValue
 * @typedef PostsSelectorHOCProps
 * @property {string} id - MANDATORY -> Unique Identifier for the posts listener
 * @property {(newVal: PostsSelectorValue) => void} [onChange]
 * @property {{ isOnlySafety?: boolean, postsType?: import('./hooks/usePosts').PostsType }} settings
 * @property {PostsSelectorValue} [value]
 * @property {boolean} [isExpanded]
 * @property {(childrenProps: {
 *  onPostSelect: (post: { id: string }) => void;
 *  currViewPosts: any[],
 *  filteredPosts: any[],
 *  selectedPostsMap: { [postId: string]: {} },
 *  selectedPostsArr: {}[],
 *  postsType: import('./hooks/usePosts').PostsType,
 *  isSafety: boolean,
 * })} children
 * @param {PostsSelectorHOCProps} props
 * @returns
 */
export const usePostsSelector = ({ onChange, value, settings, isExpanded }) => {
  const [selectedPosts, setSelectedPosts] = useState(value);

  const isOnlySafety = useMemo(() => _.get(settings, ['isOnlySafety'], false), [settings]);
  const postsType = useMemo(() => _.get(settings, ['postsType']), [settings]);
  const postsLocation = useMemo(() => _.get(settings, ['postsLocation']), [settings]);
  
  const inIds = useMemo(() => {
    if (isExpanded) return undefined;

    const idsArr = _.keys(selectedPosts);
    return idsArr.length ? idsArr : ['idToReturnNoValues'];
  }, [isExpanded, selectedPosts]);

  const { currViewPosts, filteredPosts, currViewPostsMap } = usePosts({
    contentType: isOnlySafety ? 'safety' : null,
    postsType,
    inIds,
    postsLocation,

  });

  useEffect(() => {
    if (!isExpanded && !_.isEqual(value, selectedPosts)) {
      setSelectedPosts(value);
    }
  }, [value]);

  const handlePostSelect = useCallback(
    /**
     * @param {{ id: string }} post
     * @returns
     */
    (post) => {
      let newVal = Object.assign({}, selectedPosts);

      if (newVal[post.id]) {
        delete newVal[post.id];
      } else {
        newVal[post.id] = post.id;
      }

      setSelectedPosts(newVal);
      if (onChange) onChange(newVal);
    },
    [selectedPosts, onChange]
  );

  const selectedPostsMap = useMemo(
    () =>
      Object.keys(selectedPosts || {}).reduce(
        (acc, pId) => (currViewPostsMap[pId] ? _.set(acc, [pId], currViewPostsMap[pId]) : acc),
        {}
      ),
    [selectedPosts, currViewPostsMap]
  );
  const selectedPostsArr = useMemo(() => Object.values(selectedPostsMap), [selectedPostsMap]);
  
  const childrenProps = useMemo(
    () => ({
      onPostSelect: handlePostSelect,
      currViewPosts,
      filteredPosts,
      selectedPostsMap,
      selectedPostsArr,
      value: selectedPosts,
      postsType,
      isSafety: isOnlySafety,
    }),
    [handlePostSelect, currViewPosts, filteredPosts, selectedPostsArr, selectedPosts, postsType, isOnlySafety]
  );

  return childrenProps;
};

const PostsSelectorHOC = ({ onChange, value, settings, isExpanded, children }) => {
  const childrenProps = usePostsSelector({
    onChange,
    value,
    settings,
    isExpanded,
  });
  return children(childrenProps);
};

export default memo(
  PostsSelectorHOC,
  (prev, next) =>
    _.isEqual(prev.value, next.value) &&
    _.isEqual(prev.onChange, next.onChange) &&
    _.isEqual(prev.settings, next.settings) &&
    _.isEqual(prev.isExpanded, next.isExpanded)
);
