import {DATE, FILES_ARRAY, LOCATION, SELECTION_LIST, STRING} from './propertiesTypes';
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import drawingsMessages from '../drawings/drawingsMessages';

export const OBJECT_TYPES = {
  DRAWINGS: 'drawings',
}

const SECTION_IDS = {
  GENERAL_PROPS: '-generalProperties',
}

const SECTIONS = {
  [OBJECT_TYPES.DRAWINGS]: {
    [SECTION_IDS.GENERAL_PROPS]: {
      id: SECTION_IDS.GENERAL_PROPS,
      title: drawingsMessages.generalProperties,
      isOpen: true,
    },
  },
}
/**
@param {Object} object - instance from database
@param {String} objectType - OBJECT_TYPES
*/
export const objectToProperties = (object, objectType) => {
  if (!SECTIONS[objectType] || !Object.keys(SECTIONS[objectType])?.length) {
    console.error(`[instanceToPropTypes] Error: no sections specified for type "${objectType}". 
    Check if specified "type" is correct or fill the corresponding SECTIONS value`, SECTIONS);

    return {};
  }

  return {
    instances: getInstances(object, objectType),
    types: {
      ...PROPERTIES[objectType],
    },
    sections: {
      ...SECTIONS[objectType]
    },
    mapping: getMappings(objectType),
  };
};

const getInstances = (object, objectType) => {
  return Object.values(PROPERTIES[objectType]).reduce((result, prop) => {
    const id = uuidv4();
    const instance = getInstanceByType(object, objectType, prop, id);
    if (instance) {
      result[id] = instance;
    }
    return result;
  }, {});
};

const getInstanceByType = (obj, objType, prop, id) => {
  const baseInstance = {
    id,
    propId: prop.id,
    propType: prop.type,
  };

  if (prop.id === 'groups') {
    return {
      ...baseInstance,
      data: {
        [objType]: objType
      }
    }
  }

  const value = _.get(obj, prop.id);

  if (!value && (prop.id !== 'uri' || !obj.uri)) {
    return null;
  }

  switch (objType) {
    case OBJECT_TYPES.DRAWINGS:
      return {
        ...baseInstance,
        parentId: obj.id,
        updatedTS: obj.updatedTS,
        data: getPropValueByType(value, prop, obj, objType)
      };
    default:
      console.error(`[getInstanceByType] incorrect type provided: "${objType}"`);
      return null;
  }
}

const getMappings = (objectType) => {
  return {
    'locationType': {
      '-floors': {
        properties: ['-floorsTags', 'specificLocations'],
      },
      '-units': {
        properties: ['-unitsTags', 'specificLocations'],
      },
      '-buildings': {
        properties: ['-buildingsTags', 'specificLocations'],
      }
    },
    groups: {
      [objectType]: {
        // all except mapped dependencies
        properties: Object
          .keys(PROPERTIES[objectType])
          .filter(t =>
            t !== '-buildingsTags' &&
            t !== '-unitsTags' &&
            t !== '-floorsTags'  &&
            t !== 'specificLocations'
          ),
      }
    }
  }
};

const getGroups = (objectType) => {
  return {
    id: 'groups',
    ordinalNo: 99,
    sectionId: Object.keys(SECTIONS[objectType])[0],
    title: {
      en: 'groups',
    },
    type: 'SelectionList',
    values: [{
      id: objectType,
      title: {
        en: "base",
      }
    }],
  }
};

const BASE_DRAWING_PROP_FIELDS = {
  editable: true,
  sectionId: SECTION_IDS.GENERAL_PROPS,
  showOnNullValue: true,
  settings: {
    convertToDB: (instance) => instance?.data
  }
};

const PROPERTIES = {
  [OBJECT_TYPES.DRAWINGS]: {
    'uri': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'uri',
      ordinalNo: 1,
      title: drawingsMessages.selectFile,
      type: FILES_ARRAY,
      isMandatory: true,
      defaultContentType: 'drawing',
      settings: {
        multiple: false,
        immediateUpload: true,
        convertToDB: (instance) => instance?.data?.[0]?.uri,
      },
    },
    'title': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'title',
      ordinalNo: 2,
      title: drawingsMessages.drawingTitle,
      type: STRING,
    },
    'locationType': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'locationType',
      ordinalNo: 3,
      title: drawingsMessages.locationType,
      type: SELECTION_LIST,
      settings: {
        isMulti: false,
        isSortValues: false,
      },
    },
    // FEATURE POSTPONED
    // '-unitsTags': {
    //   ...BASE_DRAWING_PROP_FIELDS,
    //   id: '-unitsTags',
    //   tags: {
    //     drawingsTag: true,
    //   },
    //   ordinalNo: 5,
    //   title: {
    //     en: 'Units groups',
    //      he: 'קבוצות יחידות'
    //   },
    //   type: SELECTION_LIST,
    //   settings: {
    //     isMulti: true,
    //   }
    // },
    // '-floorsTags': {
    //   ...BASE_DRAWING_PROP_FIELDS,
    //   id: '-floorsTags',
    //   tags: {
    //     drawingsTag: true,
    //   },
    //   ordinalNo: 6,
    //   title: {
    //     en: 'Floors groups',
    //      he: 'קבוצות קומות'
    //   },
    //   type: SELECTION_LIST,
    //   settings: {
    //     isMulti: true,
    //   }
    // },
    // '-buildingsTags': {
    //   ...BASE_DRAWING_PROP_FIELDS,
    //   id: '-buildingsTags',
    //   tags: {
    //     drawingsTag: true,
    //   },
    //   ordinalNo: 7,
    //   title: {
    //     en: 'Buildings groups',
    //      he: 'קבוצות בניינים'
    //   },
    //   type: SELECTION_LIST,
    //   settings: {
    //     isMulti: true,
    //   }
    // },
    'specificLocations': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'specificLocations',
      ordinalNo: 8,
      title: drawingsMessages.specificLocations,
      type: LOCATION,
      settings: {
        multiple: true,
        filterType: null,
      },
    },
    'category': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'category',
      ordinalNo: 9,
      title: drawingsMessages.trades,
      type: SELECTION_LIST,
      isMandatory: true,
      settings: {
        convertToDB: (instance) => Object.values(instance?.data || {})[0],
      },
      businessType: 'trades',
    },
    'number': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'number',
      ordinalNo: 10,
      title: drawingsMessages.number,
      type: STRING,
    },
    'version': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'version',
      ordinalNo: 11,
      title: drawingsMessages.version,
      type: STRING,
    },
    'date': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'date',
      ordinalNo: 12,
      title: drawingsMessages.date,
      type: DATE,
      settings: {
        position: 'top',
      }
    },
    'updatedDate': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'updatedDate',
      ordinalNo: 13,
      title: drawingsMessages.updatedDate,
      type: DATE,
      settings: {
        position: 'top',
      }
    },
    'status': {
      ...BASE_DRAWING_PROP_FIELDS,
      id: 'status',
      ordinalNo: 4,
      title: drawingsMessages.status.title,
      type: SELECTION_LIST,
      settings: {
        isMulti: false,
        isSortValues: false,
      },
    },
    groups: getGroups(OBJECT_TYPES.DRAWINGS),
  },
};

const getPropValueByType = (value, prop, obj, objType) => {
  const { type: inputType, id: propId } = prop;
  if (inputType === SELECTION_LIST) {
    if (objType === OBJECT_TYPES.DRAWINGS) {
      if (propId === 'category') {
        return _.isObject(value) ? value : {
          [value]: value
        };
      }
    }
    // add more specific object types here
  }
  if (inputType === FILES_ARRAY) {
    if (objType === OBJECT_TYPES.DRAWINGS) {
      return [{
        id: value || obj.uri,
        uri: value || obj.uri,
        uploadTS: obj.date,
        type: obj.ext,
        title: obj.title
      }];
    }
    // add more specific object types here
  }
  return value;
};

/**
@param {Object} instances - property instances object
@param {Object} types - property types
*/
 export const propTypesToDBEntity = (instances, types) => {
  if (!instances || !types) {
    return;
  }
  return Object.values(instances).reduce((result, instance) => {
    const {parentId, propId} = instance;

    if (!result[parentId]) {
      result[parentId] = {};
    }
    if (!result[parentId].id) {
      result[parentId].id = parentId;
    }
    result[parentId][propId] = types[propId]?.settings?.convertToDB(instance) || instance?.data;

    return result;
  }, {});
};