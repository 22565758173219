import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useIntl from '../../intl/useIntl';
import { LOCATION_COLUMNS, VIEW_TYPES } from '../const';
import { getCementoStringValue, patchNextGenLocationSearchIfNeeded } from '../utils';
import useLocationSearchValue from '../../../web/hooks/useLocationSearchValue';
import { VISIBLE_COLUMNS_URL_KEY, GROUP_BY_URL_KEY } from '../../../web/app/constants';

const useObjectsManipulations = ({ smartObjects, propTypes, defaultGroupBy = [] }) => {
  const intl = useIntl();
  // START: URL query params handling
  // Only used for initial state and to update URL query params
  const [locationSearchGroupBy, setLocationSearchGroupBy] = useLocationSearchValue(GROUP_BY_URL_KEY, defaultGroupBy);
  const [locationSearchVisibleColumns, setLocationSearchVisibleColumns] =
    useLocationSearchValue(VISIBLE_COLUMNS_URL_KEY);
  // END: URL query params handling

  const location = useLocation();

  const [filters, setFilters] = useState({
    newSearch: patchNextGenLocationSearchIfNeeded(location.search),
  });

  const [groupBy, setGroupBy] = useState(() => locationSearchGroupBy);
  const [sortBy, setSortBy] = useState({ column: 'trade', direction: 'asc' });
  const [search, setSearch] = useState(null);
  const [viewType, setViewType] = useState(VIEW_TYPES.GRID);
  const [columnDefinitions, setColumnDefinitions] = useState([]);
  const [groupByOptions, _setGroupByOptions] = useState([]);
  const [filterOptions, _setFilterOptions] = useState({});
  const [skip, setSkip] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    updateURLQueryParams({ groupBy, columnDefinitions });
  }, [groupBy, columnDefinitions]);

  useEffect(() => {
    deriveColumns(propTypes);
  }, [propTypes]);

  const updateURLQueryParams = ({ groupBy, columnDefinitions }) => {
    setLocationSearchGroupBy(groupBy);
    const visibleColumns = columnDefinitions.reduce((acc, c) => {
      acc[c.key] = c.isVisible;
      return acc;
    }, {});
    setLocationSearchVisibleColumns(visibleColumns);
  };

  useEffect(() => {
    const { groupByOptions, filterOptions } = calculateOptions({
      objects: smartObjects,
      columnDefinitions,
      propTypes,
      intl,
    });
    _setGroupByOptions(groupByOptions);
    _setFilterOptions(filterOptions);
  }, [smartObjects, columnDefinitions]);

  // Derive unique groupByOptions and filterOptions from object properties
  const deriveColumns = useCallback(
    (propTypes) => {
      const columns = Object.keys(propTypes)
        .sort((a, b) => propTypes[a].ordinalNo - propTypes[b].ordinalNo)
        .map((key) => {
          const prop = propTypes[key];
          const column = {
            key,
            title: prop?.title,
            ordinalNo: prop?.ordinalNo,
            section: prop?.section,
            type: prop?.type,
            isVisible: locationSearchVisibleColumns ? Object.keys(locationSearchVisibleColumns).includes?.(key) : false,
            width: 125 * (prop?.settings?.widthRatio || 1),
            isPrimary: prop?.isPrimary,
          };
          return column;
        });

      const columnDefinitions = [
        ...columns,
        ...LOCATION_COLUMNS.map((col) => ({ ...col, title: intl.formatMessage(col.title) })),
      ];

      setColumnDefinitions(columnDefinitions);
      return columnDefinitions;
    },
    [propTypes, locationSearchVisibleColumns]
  );

  return {
    // Values
    groupByOptions,
    filterOptions,
    filters,
    columnDefinitions,
    groupBy,
    sortBy,
    search,
    viewType,
    skip,
    step,
    // Methods
    setFilters,
    setColumnDefinitions,
    setGroupBy,
    setSortBy,
    setSearch,
    setViewType,
    setSkip,
    setStep,
  };
};

const getLegacyGroupByOptions = ({ columnDefinitions, intl }) => {
  const menu = columnDefinitions.reduce((acc, columnDefinition) => {
    if (columnDefinition.isVisible) {
      const title = columnDefinition?.title?.id
        ? intl.formatMessage(columnDefinition.title)
        : columnDefinition?.getCementoTitle() ?? columnDefinition.key;
      acc.push({ title, key: columnDefinition.key, ordinalNo: columnDefinition.ordinalNo });
    }
    return acc;
  }, []);

  return menu;
};

const calculateOptions = ({ objects, columnDefinitions, propTypes, intl }) => {
  const groupByOptions = getLegacyGroupByOptions({ columnDefinitions, intl });
  const filterOptions = {};

  objects.forEach((obj) => {
    Object.entries(propTypes).forEach(([propName, propData]) => {
      if (!propData) return;

      let value = _.get(obj, propData.id);
      const propTitle = propData.getCementoTitle()
        ? propData.getCementoTitle()
        : propData.title?.id
        ? intl.formatMessage(propData.title)
        : propData.title?.en;

      if (propTitle) {
        if (!filterOptions[propName]) {
          filterOptions[propName] = {
            id: propData.id,
            type: propData.type === 'String' ? 'SelectionList' : propData.type,
            inputSettings: propData.settings,
            ordinalNumber: propData.ordinalNumber,
            title: propTitle,
            options: [],
          };
        }
        if (value) {
          let title = getCementoStringValue(value);
          let id = value?.cementoValue?.id || value?.id || title || value;

          const idAlreadyExistsInOptions = filterOptions[propName].options.find(option => option.id === id);
          if (title && !idAlreadyExistsInOptions) filterOptions[propName].options.push({ id, title });
        }
      }
    });
  });
  return {
    groupByOptions,
    filterOptions: [
      {
        id: 'defaultView',
        title: 'defaultView',
        categories: Object.values(filterOptions),
      },
    ],
  };
};

export default useObjectsManipulations;
