import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { OBJECT_TYPES, objectToProperties } from '../../../common/propertiesTypes/convertor';
import SplitViewPage from '../../layouts/SplitViewPage';
import useLocationGroups from '../../hooks/useLocationGroups';
import systemMessages from '../../../common/app/systemMessages';
import drawingsMessages from '../../../common/drawings/drawingsMessages';
import { hideLoading, startLoading, startToast } from "../../../common/app/actions";
import { uploadStatus } from '../../components/CementoComponents/UploadsComponent';
import newProjectMessages from '../../../common/projects/newProjectMessages';
import usePermissions from '../../hooks/usePermissions';
import useDrawings from '../../../common/drawings/useDrawings';
import useLoadingScreen from '../../../common/hooks/useLoadingScreen';
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";

const LOADING_OPERATION_ID = 'updatingDrawing';
import { track } from '../../../common/lib/reporting/actions';
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import useIntl from "../../../common/intl/useIntl";
import { compose } from 'recompose';
import { withLoading } from '../../../common/hooks/useLoading';
import { SUBJECTS } from '../../../common/dataManager/subjects';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';

const DrawingCardAdapter = ({
  drawingObject,
  closeSelectedDrawing,
  mainComponent,
  selectDrawing,
  isDataLoading,
  loadingProgress,
  didCalcData
}) => {
  const operationId = 'drawing-save';
  const dispatch = useDispatch();
  const intl = useIntl();
  const [sideStack, setSideStack] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(null);
  const [pendingSave, setPendingSave] = useState(false);
  const { locationGroupsValues } = useLocationGroups();
  const { upsertDrawing, deleteDrawing } = useDrawings();
  const { startLoadingAnimation, stopLoadingAnimation } = useLoadingScreen();
  const { rtl, trades } = useSelector(state => ({
    rtl: state.app.rtl,
    trades: state.trades?.map,
  }));

  const { checkPermissions } = usePermissions();
  const canUpdate = useMemo(() => checkPermissions('drawings', 'update'), [checkPermissions]);

  const cachedValue = useRef({});

  const startUploadingAnimation = useCallback(() => {
    dispatch(startLoading({
      title: systemMessages.uploading,
      overlay: true,
      hideOnBackgroundPress: false,
      operationId: LOADING_OPERATION_ID,
    }));
  }, []);

  const stopUploadingAnimation = useCallback(() => {
    dispatch(hideLoading(LOADING_OPERATION_ID));
  }, []);

  const onSave = useCallback(async (updates) => {
    if (fileUploadStatus === uploadStatus.IN_PROGRESS) {
      startUploadingAnimation();
      setPendingSave(true);
      cachedValue.current = {
        ...cachedValue.current,
        ...updates,
      };
      return;
    }

    startLoadingAnimation(operationId, systemMessages.savingMessage);
    const drawing = await upsertDrawing(updates, drawingObject);
    dispatch(track(`Drawing ${drawingObject?.id ? 'Edited' : 'Created'}`, { drawingId: drawingObject?.id }));
    stopLoadingAnimation(operationId);

    if (drawing && !drawing.error) {
      cachedValue.current = {};
      setPendingSave(false);
      setFileUploadStatus(null);
      dispatch(startToast({
        title: systemMessages.objectSavedSuccessfullyFemale,
        values: { objectName: drawingsMessages.objectsNames.drawing },
        type: 'success',
      }));
      selectDrawing(drawing);
    } else {
      dispatch(startToast({
        title: drawing?.error || systemMessages.error,
        type: 'error',
      }));
    }
  }, [drawingObject, upsertDrawing, closeSelectedDrawing, fileUploadStatus, startUploadingAnimation]);

  useEffect(() => {
    if (fileUploadStatus === uploadStatus.SUCCESS && pendingSave) {
      stopUploadingAnimation();
      onSave(cachedValue.current);
    }
  }, [fileUploadStatus, pendingSave, onSave]);

  const onChange = useCallback((propId, data, status) => {
    if (propId === 'uri' && data && status) {
      setFileUploadStatus(status);

      if (status === uploadStatus.SUCCESS) {
        drawingObject.uriRemote = data; // this uri will be actually saved
        cachedValue.current.uriRemote = data;
        drawingObject.uri = data;
        updateSideStack();
      } else {
        if (data.title) {
          drawingObject.title = data.title;
          cachedValue.current.title = data.title;
        }

        drawingObject.uri = data.uri; // this uri will be displayed only in preview
        drawingObject.ext = data.extension;
        cachedValue.current.ext = data.extension;
        updateSideStack();
      }
    }
    if (propId === 'uri' && !data) {
      setFileUploadStatus(null);
      cachedValue.current = {};
      drawingObject.uri = null;
      drawingObject.ext = null;
    }
    if (propId === 'category') {
      drawingObject.category = data || null;
    }
    if (propId !== 'uri' && propId !== 'category') {
      if (propId === 'locationType') {
        const prevType = _.first(_.keys(drawingObject.locationType));
        const nextType = _.first(_.keys(data));

        if (drawingObject.locationType && drawingObject.specificLocations && prevType !== nextType) {
          dispatch(startToast({
            overlay: true,
            mandatory: true,
            message: drawingsMessages.locationTypeSwitch,
            actions: [{
              message: systemMessages.yes,
              onClick: () => {
                drawingObject.locationType = data;
                drawingObject.specificLocations = undefined;
                updateSideStack();
              },
              color: "success",
            }, {
              message: systemMessages.no,
              onClick: () => {
                // do not change anything
                updateSideStack();
              },
            }],
          }));
        } else if (!drawingObject.specificLocations) {
          drawingObject.locationType = data;
          updateSideStack();
        }
      } else {
        drawingObject[propId] = data;
      }
    }
  }, [drawingObject]);

  useEffect(() => {
    updateSideStack();
  }, [drawingObject, trades, locationGroupsValues]);

  const onDelete = useCallback(() => {
    dispatch(startToast({
      overlay: true,
      mandatory: true,
      message: drawingsMessages.delete,
      actions: [{
        message: systemMessages.yes,
        onClick: async () => {
          await deleteDrawing(drawingObject?.id);
          closeSelectedDrawing();
          dispatch(track("Drawing Deleted", { drawingId: drawingObject?.id }));
        },
        color: "success",
      }, {
        message: systemMessages.no,
      }],
    }));
  }, [drawingObject, closeSelectedDrawing]);

  const updateSideStack = () => {
    if (!drawingObject) {
      setSideStack(null);

      return;
    }

    const { types, mapping, instances, sections } = objectToProperties(drawingObject, OBJECT_TYPES.DRAWINGS);

    if (trades && types?.category) {
      types.category.values = trades;
    }

    types.locationType.values = [
      {
        id: '-buildings',
        title: intl.formatMessage(newProjectMessages.locationTypes.buildings),
        ordinalNo: 3
      },
      {
        id: '-floors',
        title: intl.formatMessage(newProjectMessages.locationTypes.floors),
        ordinalNo: 2
      },
      {
        id: '-units',
        title: intl.formatMessage(newProjectMessages.locationTypes.units),
        ordinalNo: 1
      },
    ];

    types.status.values = [
      {
        id: 'toApproval',
        title: intl.formatMessage(drawingsMessages.status.toApproval)
      },
      {
        id: 'toExecute',
        title: intl.formatMessage(drawingsMessages.status.toExecute)
      },
      {
        id: 'toView',
        title: intl.formatMessage(drawingsMessages.status.toView)
      },
      {
        id: 'toMarket',
        title: intl.formatMessage(drawingsMessages.status.toMarket)
      },
    ];
    
    if (drawingObject.locationType) {
      const locationTypeString = _.first(_.keys(drawingObject.locationType));

      types.specificLocations.settings.filterType = locationTypeString.replace('-', '');
    }

    setSideStack([{
      type: "objectPropertiesPage",
      props: {
        objectId: drawingObject.id,
        objectTypeName: drawingObject?.id ? '' : drawingsMessages.add,
        objectName: drawingObject?.id ? drawingObject.title : '',
        onSave,
        onDelete,
        onChange,
        types,
        sections,
        mapping,
        instances,
      },
    }]);
  };

  const mainCardStyles = {
    width: '95vw',
    maxWidth: '95vw',
  };

  if (isDataLoading || !didCalcData) return <LoadingIndicator loadingProgress={loadingProgress}/>

  return (
    <SplitViewPage
      rtl={rtl}
      mode={'modal'}
      onSideClose={closeSelectedDrawing}
      ratio={3 / 5}
      Main={mainComponent}
      SideStack={sideStack}
      isCreateMode={!drawingObject?.id}
      isEditAllowed={canUpdate}
      mainCardStyles={mainCardStyles}
      isForceUpdateEnabled
      sideStackObjectId={drawingObject?.id || drawingObject?.title}
    />
  );
};


const enhance = compose(
  connectContext(ProjectContext.Consumer),
  withLoading((props) => {
    const { selectedProjectId } = props;
    
    const subjectPaths = [
      ['projects', selectedProjectId, SUBJECTS.BUILDINGS],
      ['projects', selectedProjectId, SUBJECTS.FLOORS],
      ['projects', selectedProjectId, SUBJECTS.UNITS],
      ['projects', selectedProjectId, SUBJECTS.DRAWINGS],
    ]
    
    return subjectPaths
  }),
);

export default enhance(DrawingCardAdapter);