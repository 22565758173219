import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import "react-telephone-input/css/default.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { LocalPhoneOutlined, VerifiedUserOutlined } from "@material-ui/icons";
//import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import loginMessages from "../../../common/auth/loginMessages.js";
import authMessages from "../../../common/auth/authMessages.js";
import systemMessages from "../../../common/app/systemMessages.js";
import { getMyUserInfo } from "../../../common/users/actions";
import {
  requestVerificationSMS,
  validateVerificationCode,
  resendSMS,
} from "../../../common/auth/actions";
import { startAuthLogin } from "../../../common/lib/redux-firebase/actions";
import * as authStatus from "../../../common/auth/reducer";
import {
  isNumberValid,
  findNetworkFromContacts,
} from "../../../common/members/actions";
import { startToast } from "../../../common/app/actions";
import webActions from "../../webActions";
import InputField from "../../components/CementoComponents/InputField.js";
import theme from "../../assets/css/theme.js";
import Text from "../../components/CementoComponents/Text.js";
import ReactTelInput from "react-telephone-input";
import flagsImagePath from "../../assets/img/flags/flags.png";
import "../../assets/css/phoneInput.css";
import { isValidPhoneNumber } from "../../../common/app/funcs.js";
import { isUserExist } from "../../../common/users/funcs.js";

const phoneNumberMaxLength = 17;
const phoneNumberMinLength = 6;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleNextAuthStep = this.handleNextAuthStep.bind(this);
    this.handleSMSButtonClick = this.handleSMSButtonClick.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleResendClick = this.handleResendClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.state = {
      step: 0,
      opacity: 0,
      phoneNumber: "",
      cardAnimaton: "cardHidden",
      countryCode: "+1",
      countryCodes: [
        "+1",
        "+33",
        "+34",
        "+39",
        "+41",
        "+43",
        "+44",
        "+49",
        "+507",
        "+972",
      ],
      isSendingSmsFlowActive: false,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ opacity: 1 });
      }.bind(this),
      750
    );

    //await this.getUserCountry();
    this.getUserCountry();
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    document.removeEventListener("keydown", this.onKeyDown);
    this.timeOutFunction = null;
  }

  onKeyDown(event) {
    const { step, isSendingSmsFlowActive } = this.state;
    const keyName = event.key;
    if (keyName == "Enter" && !isSendingSmsFlowActive) {
      event.preventDefault();
      if (step == 0) this.handleSMSButtonClick();
      else if (step == 1) this.handleLoginClick();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { auth, startAuthLogin } = this.props;
    const { step } = this.state;

    if (nextProps.viewer) {
      if (
        !this.didTryLogin &&
        nextProps.auth.number &&
        nextProps.auth.authToken &&
        !nextProps.auth.loggedIn
      ) {
        this.didTryLogin = true;
        startAuthLogin(nextProps.auth.number, nextProps.auth.authToken);
      }
    }

    if (nextProps.auth.sms && auth.sms != nextProps.auth.sms && step < 1)
      this.setState({ step: step + 1 });

    if (nextProps.auth && nextProps.auth.status != auth.status)
      await this.handleNextAuthStep(nextProps.auth.status, nextProps);

  }

  async getUserCountry() {
    let defaultCountry = "il";
    try {
      var resp = await webActions.net.fetch("https://ipapi.co/json/", {
        method: "GET",
        headers: {},
      });

      let jsonResp = await resp.json();

      if (jsonResp && jsonResp.country)
        defaultCountry = jsonResp.country.toLowerCase();
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ defaultCountry });
    }
  }

  async handleSMSButtonClick() {
    const { findNetworkFromContacts, requestVerificationSMS, startToast } =
      this.props;
    const { phoneNumber, countryCode } = this.state;
    this.setState({ isSendingSmsFlowActive: true });
    //const phoneUtil = PhoneNumberUtil.getInstance();
    let isNumberValid = false;
    let fullNumber;

    let formattedPhoneNumber = phoneNumber
      .replaceAll("-", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "");

    if (isValidPhoneNumber(formattedPhoneNumber)) {
      //  fullNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
      //  isNumberValid = phoneUtil.isValidNumber(fullNumber);
      isNumberValid = true;
    }

    formattedPhoneNumber = formattedPhoneNumber.replace("+9720", "+972");
    // formatedPhoneNumber = formatedPhoneNumber ? formatedPhoneNumber : '';
    // [/ /g, /-/g, /\)/g, /\(/g ].forEach(reg => formatedPhoneNumber = formatedPhoneNumber.replace(reg, ''));
    // let fullNumber = countryCode + formatedPhoneNumber;
    try {
      if (!isNumberValid) startToast({ title: systemMessages.invalidDetails });
      else {
        const shouldSendSMS = await isUserExist(formattedPhoneNumber);
  
        if (!shouldSendSMS) {
          startToast({ title: authMessages.pleaseCreateUser });
        }
        else {
          const SMSRequestSuccess = (await requestVerificationSMS(formattedPhoneNumber)).success;
          if (!SMSRequestSuccess)
            startToast({ title: authMessages.phoneNumberNotValid });
        }
  
      }
    } finally {
      this.setState({ isSendingSmsFlowActive: false });
    }

    
  }

  async handleLoginClick() {
    const { validateVerificationCode, auth } = this.props;
    const { verificationCode } = this.state;
    validateVerificationCode(auth.userId, auth.sms.number, verificationCode);
  }

  async handleResendClick() {
    const { resendSMS } = this.props;
    resendSMS();
    this.setState({ step: 0, isSendingSmsFlowActive: false });
  }

  async handleNextAuthStep(auth_status, props) {
    const { auth, getMyUserInfo, startToast, startAuthLogin } = props;

    let authToken =
      auth.authToken || (Boolean(auth.sms) ? auth.authToken : null);

    switch (auth_status) {
      case authStatus.SMS_VERIFICATION_VERIFIED: {
        
        startAuthLogin(props.auth.number, authToken);
        
        break;
      }
      case authStatus.SMS_VERIFICATION_VERIFIED_ERROR: {
        startToast({ title: authMessages.verificationCodeNotValid });
        break;
      }
      case authStatus.FIREBASE_AUTH: {
        if (this.didPassedToMainTabsNav) return;
        await getMyUserInfo(authToken);
        this.props.history.push("/main");
        this.didPassedToMainTabsNav = true;
        break;
      }
    }
  }

  render() {
    const { classes, intl, rtl } = this.props;
    const {
      isSendingSmsFlowActive,
      phoneNumber,
      verificationCode,
      step,
      countryCode,
      countryCodes,
      opacity,
      defaultCountry,
    } = this.state;
    let placeholder = intl.formatMessage(authMessages.phoneNumberPlaceholder);
    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <div
                dir="ltr"
                style={{
                  minWidth: 400,
                  padding: "15px 15px 15px 15px",
                  borderRadius: 10,
                  backgroundColor: "#ffffff",
                  transition: "all 500ms ease 0s",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "100px",
                  opacity,
                  boxShadow: "0px 0px 20px 10px rgba(49, 27, 3, 0.75)",
                }}
              >
                {Boolean(step == 0) && (
                  <div
                    style={{
                      marginBottom: theme.margin,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: theme.textColor,
                        fontWeight: 800,
                        fontSize: 18,
                        marginBottom: theme.verticalMargin,
                      }}
                    >
                      {loginMessages.welcomeWebTitle}
                    </Text>
                    <Text style={{ color: theme.textColor, fontWeight: 500 }}>
                      {loginMessages.welcomeWebContent}
                    </Text>
                  </div>
                )}
                <div style={{ marginBottom: theme.verticalMargin }}>
                  {Boolean(step == 0 && defaultCountry) && (
                    <ReactTelInput
                      disabled={isSendingSmsFlowActive}
                      defaultCountry={defaultCountry}
                      flagsImagePath={flagsImagePath}
                      onChange={(val) => this.setState({ phoneNumber: val })}
                      classNames="my-awesome-class"
                    />
                  )}
                  {false && (
                    <GridContainer
                      alignContent="center"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GridItem
                        xs={12}
                        style={{
                          borderRadius: 10,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Text
                          style={{
                            color: theme.brandPrimary,
                            fontWeight: theme.strongBold,
                          }}
                        >
                          {loginMessages.enterPhoneNumber}
                        </Text>
                      </GridItem>
                      <GridItem xs={5}>
                        <div
                          dir="ltr"
                          style={{
                            height: 50,
                            borderRadius: 10,
                            backgroundColor: "#ffffffdd",
                            alignItems: "center",
                            marginBottom: 10,
                            marginTop: theme.paddingSize,
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              alignItems: "center",
                              paddingTop: 10,
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            <LocalPhoneOutlined
                              className={classes.inputAdornmentIcon}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputField
                              noBorder
                              type={"SelectionList"}
                              key={"codes"}
                              value={{ [countryCode]: countryCode }}
                              onChange={(val) =>
                                this.setState({
                                  countryCode: Object.keys(val)[0],
                                })
                              }
                              values={countryCodes.map((c) => ({
                                id: c,
                                title: c,
                              }))}
                            />
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={7}>
                        <div
                          dir="ltr"
                          style={{
                            height: 50,
                            borderRadius: 10,
                            backgroundColor: "#ffffffdd",
                            alignItems: "center",
                            marginBottom: 10,
                            marginTop: theme.paddingSize,
                            paddingLeft: theme.paddingSize,
                            paddingRight: theme.paddingSize,
                            display: "flex",
                          }}
                        >
                          <InputField
                            noBorder
                            type={"String"}
                            key={"phoneNumber"}
                            placeholder={placeholder}
                            value={phoneNumber}
                            onChange={(val) =>
                              this.setState({ phoneNumber: val })
                            }
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  )}
                  {Boolean(step > 0) && (
                    <GridContainer
                      alignContent="center"
                      alignItems="center"
                      justifyContent="center"
                      style={{}}
                    >
                      <GridItem
                        xs={12}
                        style={{
                          display: "flex",
                          borderRadius: 10,
                          backgroundColor: "#ffffffdd",
                          alignItems: "center",
                        }}
                      >
                        <div className="verification-code" style={{ flex: 1 }}>
                          <InputField
                            noBorder
                            type={"String"}
                            key={"password"}
                            placeholder={intl.formatMessage(
                              authMessages.verificationPlaceholder
                            )}
                            value={verificationCode}
                            onChange={(val) =>
                              this.setState({ verificationCode: val })
                            }
                          />
                        </div>
                        <div style={{ alignItems: "center", paddingTop: 10 }}>
                          <VerifiedUserOutlined
                            className={classes.inputAdornmentIcon}
                          />
                        </div>
                      </GridItem>
                      <GridItem
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: theme.headerColorDark }}>
                          {authMessages.enterVerification}
                        </Text>
                      </GridItem>
                    </GridContainer>
                  )}
                </div>
                <div>
                  {Boolean(step == 0) && (
                    <Button
                      className="submit-button"
                      disabled={isSendingSmsFlowActive}
                      color="warning"
                      block
                      onClick={this.handleSMSButtonClick}
                    >
                      {intl.formatMessage(authMessages.sendSMS)}
                    </Button>
                  )}
                  {Boolean(step > 0) && (
                    <Button
                      color="warning"
                      simple
                      block
                      onClick={this.handleResendClick}
                    >
                      {intl.formatMessage(authMessages.resendVerificationSMS)}
                    </Button>
                  )}
                  {Boolean(step > 0) && (
                    <Button
                      className="submit-button"
                      color="warning"
                      block
                      onClick={this.handleLoginClick}
                    >
                      {intl.formatMessage(authMessages.login)}
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage = withStyles(loginPageStyle)(LoginPage);
LoginPage = injectIntl(LoginPage);
LoginPage = connect(
  (state) => ({
    viewer: state.users.viewer,
    auth: state.auth,
    appStatus: state.app.status,
    rtl: state.app.rtl,
  }),
  {
    startToast,
    startAuthLogin,
    getMyUserInfo,
    requestVerificationSMS,
    validateVerificationCode,
    resendSMS,
    findNetworkFromContacts,
  }
)(LoginPage);

export default LoginPage;
