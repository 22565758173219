import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../projects/contexts';
import { getNewId } from '../lib/api';
import { safeToJS } from '../permissions/funcs';
import * as propertyTypes from './propertiesTypes';
import * as propertyTags from './propertiesTags';
import companiesMessages from '../companies/companiesMessages';
import { platformActions } from '../platformActions';
import { injectIntl } from 'react-intl';
import systemMessages from '../app/systemMessages';
import safetyMessages from '../safety/safetyMessages';
import propertiesMessages from './propertiesMessages';
import { isEmptyValue, optionsToText } from '../app/funcs';
import { getAppState } from '../configureMiddleware';
import { handleComplexTypeChange } from '../app/standardInputFuncs';
import useMemoizedValue from '../hooks/useMemoizedValue';
import {safeJSONParse} from '../app/funcs';
import { getApiSourceObjectsFromContext } from './funcs';

const DEFAULT_ARRAY_NAME_PROPERTY = {
  title: {
    he: 'שם',
    en: 'Name',
  },
  type: propertyTypes.STRING,
}

/**
 * @typedef {{ id: string, propId: string, ordinalNo: number, data: any, title?: any, isDeleted?: boolean }} InnerValue
 * @typedef {{ [id: string]: InnerValue }} ArrayComponentValue
 * @typedef {{
 *  prop: any | null,
 *  sortedValuesArray: InnerValue[],
 *  innerProps: { [propId: string]: any } | null,
 *  isMoreThanOneInnerType: boolean,
 *  isDisplayInnerTypeSelect: boolean,
 *  lastAddedDataId: string | null,
 *  isNothingToDisplay: boolean,
 *  handleAddTypePress: () => void,
 *  handleAddTypeRow: (propId: string) => string | undefined,
 *  handleRemoveTypeRow: (valueId: string) => void,
 *  handleInnerValueChange: (valueId: string, value: any) => void,
 *  innerTypesSelectionValues: { id: string, title: { [lang: string]: string } }[],
 *  setIsDisplayInnerTypeSelect: (isDisplayInnerTypeSelect: boolean) => void,
 *  handleArrayTitleChange: (valueId: string, titleData: any) => void,
 *  isArrayOfArrays: boolean,
 *  arrayNameProperty: { id: string, title: { he: string, en: string }, type: string, values?: {id: string, title: { [lang: string]: string } }[] } | null,
 *  counterLabel: string | null,
 *  counterCount: number,
 *  getNameInputProps: () => ({ handleDone: (newVal: any) => { [valueId: string]: InnerValue }, value: any, arrayNameProperty: { id: string, title: { he: string, en: string }, type: string, values?: {id: string, title: { [lang: string]: string } }[] } }),
 *  getValuesFromProp: (propId: string, apiSourceMetadata: any) => { [key: string]: any },
 *  isDisplayArrayNameProp: boolean,
 *  setIsDisplayArrayNameProp: (isShow: boolean) => void
 *  isLastInnerValueHasData: boolean
 *  setValueFromOptionalDefaultValue: () => ArrayComponentValue // if optionalDefaultValue present in settings, will initialize value from defaultPreviousValue (different behaviors in arrayOfArrays mode)
 *  hasOptionalDefaultValue: boolean,
 *  getInnerInputSettings: (innerValueId: string) => ({} | null)
 *  getApiSourceValue: (context: any, apiSourceMetadata: any) => (number)
 *  getIsCopyFromPreviousDailyEnabled: (context: any, values: any[] , apiSourceMetadata: any) => (number)
 * }} ArrayComponentChildrenProps
 * 
 */

/**
 * @typedef ArrayComponentProps 
 * @property {ArrayComponentValue} value
 * @property {string} propId
 * @property {boolean} isDisabled
 * @property {boolean} isExpanded
 * @property {string} subjectName
 * @property {(newValue: ArrayComponentValue | null) => void} onChange
 * @property {(props: ArrayComponentChildrenProps) => React.ReactNode} children
 * @property {{ counter?: { title: { [lang: string]: string } }, arrayNamePropMeta?: { prop: any, data: any }, optionalDefaultValue?: ArrayComponentValue }} [settings]
 */

/**
 * @param {ArrayComponentProps} props
 * @returns 
 */


let ArrayComponentHOC = (props) => {
  const {
    propId, subjectName, value,
    onChange, children, intl, isDisabled,
    isExpanded, settings, isSynced
  } = props;
  const { propertiesTypes, projectCompanies, selectedProjectId } = props; // ProjectContext
  
  const [prop, setProp] = useState(null);
  const [innerProps, setInnerProps] = useState(null);
  const [isMoreThanOneInnerType, setIsMoreThanOneInnerType] = useState(false);
  const [isDisplayInnerTypeSelect, setIsDisplayInnerTypeSelect] = useState(false);
  const [isDisplayArrayNameProp, setIsDisplayArrayNameProp] = useState(false); // Mobile only
  const [lastAddedDataId, setLastAddedDataId] = useState(null);
  const [innerTypesSelectionValues, setInnerTypesSelectionValues] = useState([]);
  const [isArrayOfArrays, setIsArrayOfArrays] = useState(false);
  const [arrayNameProperty, setArrayNameProperty] = useState(null);
  const [counterCount, setCounterCount] = useState(0);
  const [counterLabel, setCounterLabel] = useState(null);
  const [initialDataValues, setInitialDataValues] = useState({});

  const sortedValuesArray = useMemo(() => _.values(value).filter(v => Boolean(v && !v.isDeleted)).sort((a, b) => (a.ordinalNo || 0) - (b.ordinalNo || 0)), [value]);
  const memoizedSettings = useMemoizedValue(settings);

  /*
  || ============================ ||
  ||      LIFE CYCLE METHODS      ||
  || ============================ ||
  */

  useEffect(() => {
    const nextProp = safeToJS(_.get(propertiesTypes, [subjectName, propId], null));
    
    let nextInnerProps = null;
    let counterLabel = null;

    if (nextProp) {
      counterLabel = nextProp.getNested(['settings', propertyTags.COUNTER, 'getTitle'], null);
      nextInnerProps = {};

      const innerTypesIds = _.keys(_.get(nextProp, ['innerTypes'], {}));
      (innerTypesIds).forEach(innerTypeId => {
        const innerProp = safeToJS(_.get(propertiesTypes, [subjectName, innerTypeId]));
        if (innerProp)
          nextInnerProps[innerProp.id] = innerProp;
      });

      const innerPropsCount = _.keys(nextInnerProps).length;
      if (innerPropsCount === 0)
        nextInnerProps = null;
      else {
        const isMoreThanOneInnerType = innerPropsCount > 1;
        setIsMoreThanOneInnerType(isMoreThanOneInnerType);
        if (isMoreThanOneInnerType)
          setInnerTypesSelectionValues(_.values(nextInnerProps).map(prop => ({ id: prop.id, title: prop.getNested(['getTitle']) })))
      }
    }

    if (!_.isEqual(prop, nextProp)) {
      setProp(nextProp);
      setInnerProps(nextInnerProps);
      setIsArrayOfArrays(isArrayOfArraysProp(_.get(propertiesTypes, subjectName), nextProp));
      setArrayNameProperty(getArrayNameProp(nextProp, _.get(propertiesTypes, subjectName)));
      setCounterLabel(counterLabel);
    }
  }, [propId, subjectName, propertiesTypes]);

  useEffect(() => {
    if (prop) {      
      const arrayNameProperty = JSON.parse(JSON.stringify(getArrayNameProp(prop, _.get(propertiesTypes, subjectName)))) 
      if(arrayNameProperty.values) {
        arrayNameProperty.values = orderSelectionOptionsAlphabetically(arrayNameProperty.values)
      }
      setArrayNameProperty(arrayNameProperty);
    }
  }, [propertiesTypes, prop, subjectName]);

  useEffect(() => {
    const { count: newCount } = getArrayTotalCount(_.get(propertiesTypes, subjectName), prop, value);

    if (newCount !== counterCount)
      setCounterCount(newCount);
  }, [value, innerProps]);


  useEffect(() => {
    if (lastAddedDataId && value && !value[lastAddedDataId])
      setLastAddedDataId(null);
  }, [value]);

  /*
  || ============================ ||
  ||           CALLBACKS          ||
  || ============================ ||
  */
  const _onChange = useCallback((newValue, context) => {
    if (onChange) 
      onChange(newValue, undefined, context);
  }, [onChange, value]);


  const handleChange = useCallback((valueId, data, context) => {
    let newInnerVal = Object.assign({}, value[valueId]);
        if (!_.isNil(data)) newInnerVal.data = data;
        else delete newInnerVal.data
    _onChange(Object.assign({}, value, { [valueId]: newInnerVal }), context);
  }, [value, _onChange]);

  const getValuesFromProp = useCallback(
    (prop, namePropValue, apiSourceMetadata) => {
      const companiesFromApiSource = _.values(apiSourceMetadata).reduce(
        (companiesMap, apiSourceObject) => {
          const parentApiSourceObjectPath = apiSourceObject.pathToValue.split("/").slice(0, 2).join("/");
          const parentApiSourceObject = apiSourceMetadata[parentApiSourceObjectPath];
          if (!apiSourceObject?.isUnsyncable && !parentApiSourceObject?.isUnsyncable) {
            const complexId = apiSourceObject.pathToValue.split("/")[1];
            const innerTitle = _.get(value, [complexId, "title"], {});
            const companyId = _.keys(innerTitle)[0];
            if (companyId) companiesMap[companyId] = true;
          }
          return companiesMap;
        },
        {}
      );

      const items = _.map(prop.values, option => ({
          ...option,
          checked: Boolean(_.get(namePropValue, [option.id], false)),
          isDisabled: companiesFromApiSource[option.id]
      }))
  
      return items;
    },
    []
  );
  
  
  const getInnerValueInitialData = useCallback(innerValue => {
    if (!innerValue || !innerValue.propId)
      return null;

    const valueProp = _.get(innerProps, innerValue.propId);

    let data = null;

    if (
      _.get(memoizedSettings, ['arrayNamePropMeta', 'prop', 'businessType']) === propertyTypes.BUSINESS_TYPES.companies && 
      _.get(memoizedSettings, ['arrayNamePropMeta', 'prop', 'type']) === propertyTypes.SELECTION_LIST
    ) {
      const companyId = _.first(_.values(_.get(memoizedSettings, ['arrayNamePropMeta', 'data'])));
      const company = projectCompanies.getNested([companyId]);
      let companyTradeValueToSet = null;
      if (company) {
        let companyProjectTrades = company.getNested(['projects', selectedProjectId, 'trades']);
        if (_.keys(companyProjectTrades).length === 0)
          companyProjectTrades = company.getNested(['trades']);

        if (_.keys(companyProjectTrades).length === 1)
          companyTradeValueToSet = companyProjectTrades;
      }

      if (companyTradeValueToSet) {
        const innerTypesMap = getNestedInnerTypesMap(_.get(propertiesTypes, subjectName), valueProp);
        const tradePropId = _.chain(innerTypesMap)
                              .values()
                              .find(prop => prop.businessType === propertyTypes.BUSINESS_TYPES.trades)
                              .get('id')
                              .value();
        if (tradePropId)
          data = handleComplexTypeChange(null, tradePropId, companyTradeValueToSet);
      }
    }

    if (data)
      setInitialDataValues(Object.assign({}, initialDataValues, { [innerValue.id]: { valueId: innerValue.id, data } }));

    return data;
  }, [innerProps, memoizedSettings, projectCompanies, selectedProjectId, propertiesTypes, subjectName]);


  const getNewInnerValue = useCallback((innerPropId = null, lastOrdinalNo = null) => {
    innerPropId = innerPropId || _.chain(innerProps).values().first().get(['id']).value();
    const innerProp = _.get(innerProps, [innerPropId]);

    if (!innerProp)
      return null;

    let innerValue = {
      id: getNewId(),
      ordinalNo: (lastOrdinalNo ? lastOrdinalNo : (_.last(sortedValuesArray) || { ordinalNo: 0 }).ordinalNo) + 1, 
      propId: innerPropId,
    };

    const initialData = getInnerValueInitialData(innerValue);
    if (!isEmptyValue(initialData))
      innerValue.data = initialData;

    return innerValue;
  }, [innerProps, sortedValuesArray, getInnerValueInitialData]);

  const handleAddTypeRow = useCallback((innerPropId = null) => {
    let newVal = Object.assign({}, value);

    const newInnerValue = getNewInnerValue(innerPropId);
    if (!newInnerValue)
      return;

    _.set(newVal, [newInnerValue.id], newInnerValue);
    
    setLastAddedDataId(newInnerValue.id);
    _onChange(newVal);
    if (isDisplayInnerTypeSelect)
      setIsDisplayInnerTypeSelect(false);
    
    return newInnerValue.id;
  }, [value, sortedValuesArray, _onChange, innerProps, isDisplayInnerTypeSelect, setLastAddedDataId, setIsDisplayInnerTypeSelect, getNewInnerValue]);


  useEffect(() => {
    if (isExpanded && isDisabled && !sortedValuesArray.length && !isArrayOfArrays)
      handleAddTypeRow();
  }, [innerProps]);

  const handleAddTypePress = useCallback(() => {
    if (isMoreThanOneInnerType)
      setIsDisplayInnerTypeSelect(true);
    else if (isArrayOfArrays)
      setIsDisplayArrayNameProp(true);
    else
      handleAddTypeRow();
  }, [innerProps, handleAddTypeRow, isMoreThanOneInnerType, isArrayOfArrays]);

  const handleRemoveTypeRow = useCallback(async (valueId, forceDelete, context) => {
    let newVal = Object.assign({}, value);

    let shouldDelete = true;
    if (!forceDelete && _.get(newVal, [valueId, 'data']))
      shouldDelete = await new Promise(resolve => platformActions.app.startActionModal({
        title: intl.formatMessage(propertiesMessages.deleteInnerType.title),
        message: intl.formatMessage(propertiesMessages.deleteInnerType.message),
        actions: [
          { title: intl.formatMessage(systemMessages.no),  onClick: () => resolve(false) },
          { title: intl.formatMessage(systemMessages.yes),  onClick: () => resolve(true), type: 'success' },
        ],
      }));
    
    if (shouldDelete) {
      delete newVal[valueId];
      _onChange(newVal, context);
    }
  }, [value, _onChange, intl]);


  const handleArrayTitleChange = useCallback((valueId, titleData) => {
    const newInnerVal = Object.assign({}, value[valueId]);
    
    newInnerVal.title = titleData;

    _onChange(Object.assign({}, value, { [valueId]: newInnerVal }));
  }, [value, _onChange]);

  const orderSelectionOptionsAlphabetically = useCallback((options) => {
    return options.sort((a, b) => {  
      const aTitle = a.getCementoTitle();
      const bTitle = b.getCementoTitle();
      return aTitle.localeCompare(bTitle);
    })
  }, [arrayNameProperty]);

  const orderValueAlphabetically = useCallback((newVal) => {
    const arrayNames = arrayNameProperty.values
    return Object.values(newVal).sort((a, b) => {
      const aTitle = arrayNames.find(nameProperty => nameProperty.id === Object.keys(a.title)[0]).getCementoTitle()
      const bTitle = arrayNames.find(nameProperty => nameProperty.id === Object.keys(b.title)[0]).getCementoTitle()
      return aTitle.localeCompare(bTitle)
    }).map((curr, index) => {
      curr.ordinalNo = index + 1
      return curr
    }).reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {})
  }, [arrayNameProperty]);

  const handleNamePropInputDone = useCallback((newNameValue, shoulReturnValueOnly = false, context) => {
    const isSelectionList = arrayNameProperty.type === propertyTypes.SELECTION_LIST;
    let newVal = Object.assign({}, value);
    if (isSelectionList) {
      /** @type {{ [nameValueId: string]: InnerValue }}*/
      let innerValuesByNamePropValueId = {};
      newVal = {};
      // Remove the inner prop value if not selected anymore in newNameValue
      _.values(value).forEach((innerValue) => {
        const nameValueId = _.chain(innerValue).get('title').keys().head().value();
        if (!nameValueId)
          return;

        _.set(innerValuesByNamePropValueId, nameValueId, innerValue);
        
        // Set values isDeleted if not in newNameValue
        if (!_.get(newNameValue, nameValueId)) {
          if (isEmptyValue(innerValue.data))
            delete newVal[innerValue.id];
          else {
            let innerValueCopy = Object.assign({}, innerValue);
                innerValueCopy.isDeleted = true;
            newVal[innerValue.id] = innerValueCopy;
          }
        }
      });

      const validValuesSet = arrayNameProperty.values?.reduce((acc, v) => {acc.add(v.id); return acc;}, new Set());
      _.keys(newNameValue).forEach(nameValueId => {
        if (!validValuesSet.has(nameValueId)) {
          return;
        }

        let innerValue = innerValuesByNamePropValueId[nameValueId];
        if (!innerValue)
          innerValue = getNewInnerValue();
        
        const innerValueCopy = Object.assign({}, innerValue);

        if (innerValueCopy.isDeleted)
          innerValueCopy.isDeleted = false;
        else
          innerValueCopy.title = { [nameValueId]: nameValueId };

        newVal[innerValueCopy.id] = innerValueCopy;
      });
      newVal = orderValueAlphabetically(newVal)
    }
    else if (newNameValue) { // TODO: support value isDeleted from strings
      let innerValue = getNewInnerValue();
      innerValue = Object.assign({}, innerValue, { title: newNameValue });
      newVal[innerValue.id] = innerValue;
    }

    if (shoulReturnValueOnly !== true) {
      _onChange(newVal, { ...context, isFiredByServer: isSynced, isArrayOfArrays });
    }
    
    setIsDisplayArrayNameProp(false);

    return newVal;
  }, [value, _onChange, getNewInnerValue, arrayNameProperty, setIsDisplayArrayNameProp]);


  const getNameInputProps = useCallback(() => {
    const isSelectionList = arrayNameProperty.type === propertyTypes.SELECTION_LIST;

    return { 
      arrayNameProperty,
      handleDone: handleNamePropInputDone, 
      value: isSelectionList 
        ? sortedValuesArray.reduce((acc, v) => Object.assign(acc, v.title), {}) 
        : null, 
    };
  }, [arrayNameProperty, sortedValuesArray, value, getNewInnerValue, _onChange, value, setIsDisplayArrayNameProp, handleNamePropInputDone]);

  const optionalDefaultValue = useMemo(() => {
    const optionalDefaultValueRaw = _.get(memoizedSettings, ['optionalDefaultValue']);
    let parsedDefaultOptValue = typeof optionalDefaultValueRaw === 'string' ? safeJSONParse(optionalDefaultValueRaw) : optionalDefaultValueRaw;
    if (typeof parsedDefaultOptValue === 'object' && isArrayOfArrays) {
      const validValuesSet = arrayNameProperty.values.reduce((acc, v) => {acc.add(v.id); return acc;}, new Set());
      parsedDefaultOptValue = _.pickBy(parsedDefaultOptValue, (val, key) => validValuesSet.has(_.first(_.keys(val.title))));
    }
    return parsedDefaultOptValue;
  }, [memoizedSettings, arrayNameProperty, isArrayOfArrays]);

  const setValueFromOptionalDefaultValue = useCallback(() => {
    /** @type {ArrayComponentValue} */
    if (isEmptyValue(optionalDefaultValue))
      return;

    let newValue = {};
    if (isArrayOfArrays) {
      if (arrayNameProperty.type === propertyTypes.SELECTION_LIST) {
        let fakeArrayNamePropInput = {};
        Object.values(optionalDefaultValue).forEach(innerVal => Object.assign(fakeArrayNamePropInput, innerVal.title));
        Object.assign(newValue, handleNamePropInputDone(fakeArrayNamePropInput));
      } else {
        Object.values(optionalDefaultValue).forEach(innerVal => {
          if (innerVal.title)
            Object.assign(newValue, handleNamePropInputDone(innerVal.title));
        });
      }
    } else {
      _.values(optionalDefaultValue).forEach(innerVal => {
        if (isEmptyValue(innerVal.data))
          return;

        let newInnerVal = getNewInnerValue();
        if (!newInnerVal)
          return;
        
        newValue[newInnerVal.id] =  { ...newInnerVal, data: innerVal.data };
      });
    }

    _onChange(newValue);

    return newValue;
  }, [optionalDefaultValue, arrayNameProperty, handleNamePropInputDone, _onChange, getNewInnerValue, isArrayOfArrays]);
  
  const optionalDefaultValueByArrayNameData = useMemo(() => {
    if (isEmptyValue(optionalDefaultValue) || !isArrayOfArrays)
      return null;

    let optionalDefaultValueByArrayNameData = {};
    Object.values(optionalDefaultValue).forEach(innerVal => {
      if (isEmptyValue(innerVal.title))
        return;

      const nameValueString = JSON.stringify(innerVal.title);
      optionalDefaultValueByArrayNameData[nameValueString] = innerVal.data;
    });

    return optionalDefaultValueByArrayNameData;
  }, [optionalDefaultValue, isArrayOfArrays]);

  const getInnerInputSettings = useCallback((innerValueId) => {
    const innerValue = _.get(value, [innerValueId]);
    let inputSettings = null;

    if (innerValue) {
      const innerPropSettings = _.get(innerProps, [innerValue.propId, 'settings']);
      if (isArrayOfArrays) {
        const { title } = innerValue;
        inputSettings = Object.assign(
          {},
          innerPropSettings,
          { arrayNamePropMeta: { prop: arrayNameProperty, data: title } },
          Boolean(optionalDefaultValueByArrayNameData && !isEmptyValue(title)) && {
            optionalDefaultValue: optionalDefaultValueByArrayNameData[JSON.stringify(title)],
          },
        );
      }
      else {
        inputSettings = innerPropSettings;
      }
    }

    return inputSettings;
  }, [value, innerProps, isArrayOfArrays, arrayNameProperty, optionalDefaultValueByArrayNameData]);

  const getApiSourceValue = useCallback((context, apiSourceMetadata, innerPropId) => {
    const arrayOfArrayPropId = _.first(context.parentPropIds);
    const hasApiSource = safeToJS(_.get(propertiesTypes, [subjectName, arrayOfArrayPropId, 'settings', 'apiSource', 'value'], null));
    if (!hasApiSource) return null;

    const arrayComplexPath = `${arrayOfArrayPropId}/${_.first(context.extra?.valueIds)}`;
    const path = `${arrayComplexPath}/data/${_.keys(value)[0]}/data/${innerPropId}`;
    let apiSourceValue = 0;
    let currApiSourceMetadata = apiSourceMetadata[path];

    if (currApiSourceMetadata?.apiSourceValue) {
      apiSourceValue = currApiSourceMetadata.apiSourceValue
    } 

    return apiSourceValue
  }, [prop]);

  const getIsCopyFromPreviousDailyEnabled = useCallback((context, values = [], apiSourceMetadata) => {
    let isCopyFromPreviousDailyEnabled = true;

    if (values.length > 1) isCopyFromPreviousDailyEnabled = false;

    const apiSourceObjects = getApiSourceObjectsFromContext(context);
    if (apiSourceObjects) {
      values.forEach(value => {
        const propIds = _.keys(value.data);
        propIds.forEach((propId) => {
          const pathToValue = `${apiSourceObjects[0].pathToValue}/data/${value.id}/data/${propId}`;
          const apiSourceObject = apiSourceMetadata[pathToValue];
          const actualValue = _.get(value, ['data', propId]);

          if ((apiSourceObject && !apiSourceObject.loadedFromApiSource) || apiSourceObject?.apiSourceValue !== actualValue) {
            isCopyFromPreviousDailyEnabled = false;
          } 

          if (apiSourceObject?.apiSourceValue === actualValue) {
            isCopyFromPreviousDailyEnabled = true;
          }
        });
  
      })
    } else {
      const prop = safeToJS(_.get(propertiesTypes, [subjectName, propId], null));
      const apiSourceObjects = apiSourceMetadata[prop.id];

      if (apiSourceObjects?.apiSourceValue) {
        const objectIdsFromApiSource = _.keys(apiSourceObjects.apiSourceValue);
        const areAllValuesFromApiSource = values.every(value => {
          const objectId = _.keys(value.title)[0]
          return objectIdsFromApiSource.includes(objectId)
        })
        if (areAllValuesFromApiSource) isCopyFromPreviousDailyEnabled = true;
      }
    }

    const isNestedValueEmpty =
      typeof optionalDefaultValue === 'object' &&
      _.size(optionalDefaultValue) === 1 &&
      _.values(optionalDefaultValue)[0].propId &&
      _.isEmpty(_.values(optionalDefaultValue)[0].data);
      
    return isCopyFromPreviousDailyEnabled && !isEmptyValue(optionalDefaultValue) && !isNestedValueEmpty
  }, [optionalDefaultValue, propId])
  
  /*
  || ============================ ||
  ||            RENDER            ||
  || ============================ ||
  */
  const childrenProps = useMemo(() => ({
    prop,
    sortedValuesArray,
    innerProps,
    isMoreThanOneInnerType,
    isDisplayInnerTypeSelect,
    lastAddedDataId,
    isNothingToDisplay: Boolean(!innerProps),
    handleAddTypePress,
    handleAddTypeRow,
    handleRemoveTypeRow,
    handleInnerValueChange: handleChange,
    innerTypesSelectionValues,
    setIsDisplayInnerTypeSelect,
    isArrayOfArrays,
    arrayNameProperty,
    handleArrayTitleChange,
    counterCount,
    counterLabel,
    isDisplayArrayNameProp,
    setIsDisplayArrayNameProp,
    getNameInputProps,
    optionalDefaultValueByArrayNameData,
    setValueFromOptionalDefaultValue,
    hasOptionalDefaultValue: !isEmptyValue(optionalDefaultValue),
    isLastInnerValueHasData: !isEmptyValue((_.last(sortedValuesArray) || {}).data),
    getInnerInputSettings,
    getValuesFromProp,
    getApiSourceValue,
    getIsCopyFromPreviousDailyEnabled
  }), [
    optionalDefaultValue,
    optionalDefaultValueByArrayNameData,
    prop,
    sortedValuesArray,
    innerProps,
    isMoreThanOneInnerType,
    isDisplayInnerTypeSelect,
    lastAddedDataId,
    handleAddTypePress,
    handleAddTypeRow,
    handleRemoveTypeRow,
    handleChange,
    innerTypesSelectionValues,
    setIsDisplayInnerTypeSelect,
    isArrayOfArrays,
    arrayNameProperty,
    handleArrayTitleChange,
    counterCount,
    counterLabel,
    isDisplayArrayNameProp,
    setIsDisplayArrayNameProp,
    setValueFromOptionalDefaultValue,
    getNameInputProps,
    getInnerInputSettings,
    getValuesFromProp,
    getApiSourceValue,
    getIsCopyFromPreviousDailyEnabled
  ]);

  return children(childrenProps);
}

ArrayComponentHOC = injectIntl(ArrayComponentHOC);
ArrayComponentHOC = connectContext(ProjectContext.Consumer)(ArrayComponentHOC);
export default ArrayComponentHOC;


















export const getNestedInnerTypesMap = (propertiesTypes, prop) => {
  let innerTypes = {};

  _.keys(_.get(prop, 'innerTypes')).forEach(innerTypeId => {
    let innerType = _.get(propertiesTypes, [innerTypeId], null);
    if (innerType) {
      innerTypes[innerTypeId] = innerType;
      if (innerType.innerTypes) {
        Object.assign(innerTypes, getNestedInnerTypesMap(propertiesTypes, innerType));
      }
    }
    else {
      platformActions.sentry.notify("Property's innerType is missing from properties! ", { innerTypeId, propId: prop.id });
    }
  });

  return innerTypes;
}


const getPropsByTagsMap = (subjectPropertiesTypes) => {
  let propsByTags = {};

  _.values(subjectPropertiesTypes).forEach(prop => {
    if (prop.tags)
      _.keys(prop.tags).forEach(tag => _.set(propsByTags, [tag, prop.id], prop));
  });

  return propsByTags;
};

export const isArrayOfArraysProp = (subjectPropTypes, prop) => _.get(safeToJS(subjectPropTypes), [_.chain(prop).get('innerTypes').keys().first().value(), 'type']) === propertyTypes.ARRAY;

export const getArrayTotalCount = (subjectPropertiesTypes, prop, instanceData, counterTag = propertyTags.COUNTER) => {
  let count = 0;
  let counterProp = null;

  if (instanceData) {
    const isArrayOfArrays = isArrayOfArraysProp(subjectPropertiesTypes, prop);
    if (isArrayOfArrays)
      _.values(instanceData).forEach(innerValue => {
        if (isEmptyValue(innerValue) || innerValue.isDeleted || isEmptyValue(innerValue.data))
          return;

        const prop = _.get(subjectPropertiesTypes, [innerValue.propId]);
        const arrayTotalCountRes = getArrayTotalCount(subjectPropertiesTypes, prop, innerValue.data, counterTag);
        count += arrayTotalCountRes.count;
        if (!counterProp)
          counterProp = arrayTotalCountRes.counterProp;
      });
    else {
      const innerTypesMap = getNestedInnerTypesMap(subjectPropertiesTypes, prop);
      const propsByTagsMap = getPropsByTagsMap(innerTypesMap);
      const counterPropId = _.first(_.keys(propsByTagsMap[counterTag]));
      if (counterPropId) {
        counterProp = innerTypesMap[counterPropId];
        _.values(instanceData).forEach(innerVal => {
          if (isEmptyValue(innerVal) || innerVal.isDeleted || isEmptyValue(innerVal.data))
            return;
  
          const isComplexType = _.get(subjectPropertiesTypes, [innerVal.propId, 'type']) === propertyTypes.COMPLEX;
          let innerCounterVal = null;
  
          if (isComplexType)
            innerCounterVal = _.get(innerVal, ['data', counterPropId]);
          else if (innerVal.propId === counterPropId)
            innerCounterVal = innerVal.data;
  
          let innerCount = 0;
          if (typeof Number(innerCounterVal) === 'number')
            innerCount = Number(innerCounterVal);
  
          if (innerCount)
            count += innerCount;
        });
      }
      else 
        count = _.values(instanceData).filter(v => !isEmptyValue(_.get(v, 'data'))).length;
    }
  }

  return { count, counterProp };
}

export const getArrayNameProp = (fullArrayProp, subjectProperties) => {
  let nameProp = null;

  if (fullArrayProp.settings?.arrayNameProp?.id && subjectProperties?.[fullArrayProp.settings.arrayNameProp.id]) {
    nameProp = subjectProperties[fullArrayProp.settings.arrayNameProp.id];
    if (nameProp.type === propertyTypes.SELECTION_LIST) {
      nameProp = Object.assign({ value: [] }, nameProp);
    }
  } else {
    switch (_.get(fullArrayProp, 'businessType')) {
      case ('companies'): {
        const appIntl = getAppState().getNested(['app', 'intl']);
        const propTitle = appIntl.formatMessage(companiesMessages.selectContractors);
        const columnDisplayTitle = appIntl.formatMessage(safetyMessages.objectsNames.companies);
        nameProp = Object.assign(
          { id: '-arrayComponentNameProperty' + fullArrayProp.id }, 
          DEFAULT_ARRAY_NAME_PROPERTY, 
          { 
            type: propertyTypes.SELECTION_LIST, 
            title: { he: propTitle, en: propTitle },
            businessType: 'companies', 
            values: fullArrayProp.values,
            settings: { 
              columnDisplayTitle: { 
                he: columnDisplayTitle, 
                en: columnDisplayTitle,
              } 
            },
          }
        );
        break;
      }
  
      default: {
        nameProp = {
          ...DEFAULT_ARRAY_NAME_PROPERTY,
          id: '-arrayComponentNameProperty' + fullArrayProp.id
        };
        break;
      }
    }
  }


  if (nameProp)
    nameProp = Object.assign({ sectionId: fullArrayProp.sectionId }, nameProp)

  return nameProp;
}

export const getArrayInnerValueTitle = (intl, arrayNameProperty, arrayValue, innerValueId) => {
  let innerValRealIndex = -1, innerValue, innerValNumber;
  _.values(arrayValue)
    .sort((a, b) => (a?.ordinalNo || 0) - (b?.ordinalNo || 0))
    .forEach(innerVal => {
    if (!innerVal || innerVal.isDeleted)
      return;

    innerValRealIndex++;
    if (innerVal.id === innerValueId) {
      innerValue = innerVal;
      innerValNumber = innerValRealIndex + 1;
    }
  });

  const { title } = innerValue || {};

  const componentTitle = arrayNameProperty.type === propertyTypes.SELECTION_LIST
    ? optionsToText({ data: title, intl, options: arrayNameProperty.values })
    : (title || intl.formatMessage(propertiesMessages.itemWithNumber, { number: innerValNumber }));

  return componentTitle;
}

