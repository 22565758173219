import {useCallback, useEffect, useMemo} from 'react';
import theme from '../../../assets/css/theme';
import FunctionalInput from '../../../../common/app/components/FunctionalInput';
import {FILES_ARRAY} from '../../../../common/propertiesTypes/propertiesTypes';
import ZoomImage from '../../../components/CementoComponents/ZoomImage';
import useRefed from '../../../../common/hooks/useRefed';
import { injectIntl } from 'react-intl';

/**
 * @param {PropertiesPropInputProps} props
 * @returns
 */
let PropertiesPropInput = props => {
	const {
		onChange,
		prop,
		isExpanded,
		innerValueId,
		mode,
		isDisabled,
		openSideCard,
		onRef,
		onImageSelect,
		subjectName,
		value,
		extraPropertiesTypes,
		optionalDefaultValue,
		isCertificationCanceled,
		intl,
		isCreateMode,
		prevFormDataLoaded,
		objectId,
		formReportDate,
		apiSourceMetadata,
		isFormClosed,
		loadingMap,
		setLoading,
		onOpenModal,
		initInstanceId,
		instanceId,
		hideCopyFromPreviousDailyButton
	} = props;
	const propId = prop?.id;
	if (!propId || propId === 'groups') return null;

	let _value = value;
	const titleStyle = isExpanded && mode === 'modal' ? { fontSize: theme.fontSizeH4 } : {};

	const handleChange = useCallback(
		(propId, data, status, context) => {
			if (onChange) onChange(propId, data, status, context);
		},
		[onChange],
	);

	const handleFileClick = useCallback((file) => {
		if (!file) {
			openSideCard?.('nothingToDisplay');
			return;
		}

		const SideCardComponent = () => (
			<div style={{ padding: `${theme.margin * 2}px`, height: '100%' }}>
				<ZoomImage
					src={file.uri}
					pdfMode={file.type === 'pdf'}
					maxHeight={'100%'}
					maxWidth={'100%'}
					toolbar
				/>
			</div>
		);
		const title = prop.getCementoTitle();
		const params = {
			headerParams: {
				title,
				noTitle: true
			},
			objectName: title,
			componentId: `innerObjectSelectComponent_${prop.id}_${file.uri}`
		};

		openSideCard?.('_blankCard', params, SideCardComponent);
	}, [prop, openSideCard]);

	const propsRef = useRefed(props, Object.values(props));
	const handleInnerInputPress = useCallback(
		/** @param {string} valueId */
		(valueId, openSideCardParams) => {
			if(openSideCardParams) {
				openSideCard?.(...openSideCardParams)
				return
			}
			const Component = () => (
				<div style={{ padding: `${theme.margin * 2}px ${theme.margin * 2}px` }}>
					<PropertiesPropInput {...propsRef.current} isExpanded innerValueId={valueId} />
				</div>
			);

			const title = prop.getCementoTitle();
			openSideCard?.(
				'_blankCard',
				{
					headerParams: { title, noTitle: true },
					objectName: title,
					componentId: `innerObjectSelectComponent_${propId}_${valueId}`,
				},
				Component,
			);
		},
		[],
	);

	const renderPreview = useMemo(() => {
		if (prop?.type === FILES_ARRAY) {
			return (file) => handleFileClick(file);
		}
	}, [prop?.type, handleFileClick])

	useEffect(() => {
		if (mode === 'modal' && renderPreview) {
			renderPreview(_value?.[0] || null);
		}
	}, [mode, _value, prop?.type, isDisabled]);

	const onCardClick = useCallback((valueId, openSideCardParams ) => {
		handleInnerInputPress(valueId, openSideCardParams)
	}, [handleInnerInputPress]);

	const handleImageSelect = useCallback(
		/**
		 *
		 * @param {string} src
		 * @param {boolean} pdfMode
		 */
		(src, pdfMode) => {
			if (onImageSelect) onImageSelect(prop.title, src, pdfMode);
		},
		[onImageSelect, prop.title],
	);

	const inputTitle = useMemo(() => {
		const propTitle = prop.prop.title;
		if (!propTitle?.id) return null;

		return intl.formatMessage(propTitle)
	},[prop])

	return (
		<FunctionalInput
			objectId={objectId}
			alignCenter
			key={propId + '_' + innerValueId + '_' + isDisabled}
			value={_value}
			withResize={false}
			onChange={handleChange} 
			autoSort={false}
			disabled={isDisabled}
			onRef={!innerValueId && onRef}
			inputKey={propId + '_' + innerValueId + '_' + isDisabled}
			propId={propId}
			context={{
				object: { objectId, reportDate: formReportDate },
				subject: subjectName,
				initInstanceId,
				instanceId
			}}
			isExpandSummary={isExpanded}
			innerValueId={innerValueId}
			onCardClick={onCardClick}
			renderPreview={renderPreview}
			noTitle={isExpanded && mode === 'card'}
			titleStyle={titleStyle}
			subjectName={subjectName}
			extraPropertiesTypes={extraPropertiesTypes}
			containerStyle={{ padding: '0px 0px 25px' }}
			openPDFInWebPage={false}
			mode={mode}
			onImageSelect={handleImageSelect}
			isMulti={prop?.isMulti}
			businessType={prop?.businessType}
			optionalDefaultValue={optionalDefaultValue}
			isCertificationCanceled={isCertificationCanceled}
			title={inputTitle}
			shouldLoadOptionalDefaultValue={Boolean(isCreateMode)}
			prevFormDataLoaded={prevFormDataLoaded}
			apiSourceMetadata={apiSourceMetadata}
			isFormClosed={isFormClosed}
			loadingMap={loadingMap}
			setLoading={setLoading}
			onOpenFeatureToast={onOpenModal}
			hideCopyFromPreviousDailyButton={hideCopyFromPreviousDailyButton}
		/>
	);
};

PropertiesPropInput = injectIntl(PropertiesPropInput)
export default PropertiesPropInput;

