import SignatureDialog from './SignatureDialog';
import { useCallback, useMemo } from 'react';
import { getDispatch } from '../configureMiddleware';
import { startToast } from '../app/actions';
import systemMessages from '../app/systemMessages';
import { SIGN_ON_TEXT, CERTIFICATIONS_TYPES, CEMENTO_SIGN_BEHAVIOUR } from '../propertiesTypes/propertiesTypes';
import safetyMessages from '../../common/safety/safetyMessages';
import _ from 'lodash';
import { instanceDataToString } from '../../common/propertiesInstances/funcs';
import * as propertyTypes from '../../common/propertiesTypes/propertiesTypes';
import useIntl from '../intl/useIntl';
import { useSelector } from 'react-redux';
import { track } from '../lib/reporting/actions';

const CertificationSignatureModal = (props) => {
  const {
    propertiesTypes,
    subjectName,
    selectedCertification,
    isOpen,
    onClose,
    signObjectId,
    projectId,
    formReportDate,
    formType,
    formTemplateId,
    onDone,
    cert,
    instancesByPropertyId,
  } = props;

  const intl = useIntl();
  const dispatch = getDispatch();

  const { lang } = useSelector((state) => ({
    lang: state.app.lang,
  }));

  const certPropType = props.getNested(['propertiesTypes', subjectName, (selectedCertification || {}).id], {});
  const certificationText = _.get(certPropType, ['settings', 'certificationText'], {});

  const { isRoleAppointment, cementoSignBehaviour } = useMemo(() => {
    return {
      isRoleAppointment:
        propertiesTypes.getNested([
          subjectName,
          _.get(selectedCertification, 'id'),
          'settings',
          'certificationType',
        ]) === CERTIFICATIONS_TYPES.roleAppointment,
      cementoSignBehaviour: propertiesTypes.getNested([
        subjectName,
        (selectedCertification || {}).id,
        'settings',
        'signatureBehaviour',
        SIGN_ON_TEXT,
        'behaviour',
      ]),
    };
  }, []);

  const isOneMandatorySignature = Boolean(cementoSignBehaviour === CEMENTO_SIGN_BEHAVIOUR.oneMandatorySignature);
  const isOneSimpleSignature = Boolean(
    isOneMandatorySignature || cementoSignBehaviour === CEMENTO_SIGN_BEHAVIOUR.oneSignature
  );

  const employee = useMemo(() => {
    let _employee = {
      avatar: '',
      displayName: '',
      idNumber: '',
    };

    Object.values((propertiesTypes || {})[subjectName] || {}).forEach((prop) => {
      if (prop.universalId) {
        const currInstance = instancesByPropertyId[prop.id] || {};
        switch (prop.universalId) {
          case 'fullName':
            _employee.displayName = instanceDataToString(prop, currInstance.data, intl, true);
            break;

          case 'avatar':
            _employee.avatar = instanceDataToString(prop, currInstance.data, intl, true);
            break;

          case 'idNumber':
            _employee.idNumber = instanceDataToString(prop, currInstance.data, intl, true);
            break;

          case 'employeeCompany':
            _employee.company = instanceDataToString(prop, currInstance.data, intl, true);
        }
      }
    });

    return _employee;
  }, [instancesByPropertyId]);

  const signatures = useMemo(() => {
    let _signatures = [];
    if (subjectName === 'employeesInfo' && !isRoleAppointment) {
      _signatures = [
        {
          isMandatory: true,
          id: 'certifiedObject',
          type: 'employee',
          employee,
          ordinalNo: 1,
          multiLangText: certificationText,
          title: {
            [lang]: safetyMessages.certification.workerSignature,
          },
        },
        {
          isMandatory: true,
          id: 'owner',
          type: 'custom',
          title: {
            [lang]: safetyMessages.certification.instructorSignature,
          },
          default: 'viewer',
          enableMemberSelection: true,
          components: [
            {
              type: 'text',
              id: 'name',
              isMandatory: true,
              pathInUserObject: ['displayName'],
              placeholder: {
                [lang]: safetyMessages.certification.instructorName,
                ordinalNo: 1,
              },
            },
            {
              type: 'text',
              id: 'idNumber',
              isMandatory: true,
              pathInUserObject: ['idNumber'],
              onChange: (_val, _user) => this.handleViewerIdNumberChanges(_user, _val),
              placeholder: {
                [lang]: safetyMessages.certification.instructorId,
                ordinalNo: 2,
              },
            },
          ],
          ordinalNo: 2,
        },
      ];
    } else if (isOneSimpleSignature || isRoleAppointment) {
      _signatures = [
        {
          isMandatory: false,
          multiLangText: isRoleAppointment && certificationText,
          id: 'owner',
          type: 'none',
          ordinalNo: 1,
        },
      ];
    }

    return _signatures;
  }, [isOneSimpleSignature, isRoleAppointment]);

  const onSign = useCallback(
    async (signatureObjs) => {
      dispatch(
        track('Signed certificate in web', {
          formId: signObjectId,
          formTemplateId,
          formType,
        })
      );
      const certifiedObj = signatureObjs.certifiedObject || {};
      const certifiedObjLang = certifiedObj.lang || 'missingLang';
      const ownerObj = signatureObjs.owner || {};
      let certProp = propertiesTypes.getNested([subjectName, (selectedCertification || {}).id], {});

      let lastCert;

      if (Array.isArray(cert)) {
        lastCert = cert[cert.length - 1];
      } else if (typeof cert === 'object') {
        lastCert = cert;
      }
      let certTitle = selectedCertification.title;
      if (typeof certTitle === 'string') {
        certTitle = {
          [lang]: certTitle,
        };
      }
      lastCert = lastCert || {};
      lastCert.signatureBehaviour = SIGN_ON_TEXT;

      let extraTypes = {};
      if (certProp.extraTypes)
        certProp.extraTypes.filter(_.identity).forEach((extraPropId) => {
          let extraProp = propertiesTypes.getNested([subjectName, extraPropId]);
          let value = lastCert[extraPropId];
          let type = extraProp.type;
          if (type == propertyTypes.SELECTION_LIST) {
            let options = _.values(_.get(extraProp.toJS ? extraProp.toJS() : extraProp, ['values'], {}));
            let optionId = _.keys(value || {})[0];
            value = options.find((option) => option.id == optionId);
          }
          extraTypes[extraPropId] = {
            id: extraPropId,
            type,
            value,
          };
        });

      if (
        isOneSimpleSignature ||
        (isRoleAppointment && !_.get(certProp, ['settings', 'certificationText', 'content']))
      ) {
        if (_.get(signatureObjs, ['owner', 'uri']))
          lastCert.certificateMetaData = {
            ...lastCert.certificateMetaData,
            extraTypes,
            certTitle,
            id: certProp.id,
            metaData: {
              signatures: {
                certifiedObject: {
                  uri: ownerObj.uri,
                },
              },
            },
          };
      } else {
        lastCert.certificateMetaData = {
          ...lastCert.certificateMetaData,
          extraTypes,
          certTitle,
          id: certProp.id,
          metaData: {
            certificationInfo: {
              employee: {
                name: certifiedObj.user.displayName,
                idNumber: certifiedObj.user.idNumber,
                lang: certifiedObjLang,
              },
              creationDate: _.get(lastCert, ['signatureTS'], Date.now()),
            },
            multiLangText: {
              content: {
                [certifiedObjLang]: _.get(certProp, ['settings', 'certificationText', 'content', certifiedObjLang]),
              },
            },
            signatures: {
              certifiedObject: {
                title: {
                  [lang]: intl.formatMessage(safetyMessages.certification.workerSignature),
                },
                uri: certifiedObj.uri || '',
                lang: certifiedObjLang,
                components: {
                  certifiedObjectName: {
                    value: _.get(certifiedObj, ['user', 'displayName'], null),
                    type: 'text',
                    ordinalNo: 1,
                  },
                  certifiedObjectIdNumber: {
                    value: _.get(certifiedObj, ['user', 'idNumber'], null),
                    type: 'text',
                    ordinalNo: 2,
                  },
                },
              },
              owner: {
                title: {
                  [lang]: intl.formatMessage(safetyMessages.certification.instructorSignature),
                },
                uri: ownerObj.uri || '',
                components: {
                  ownerName: {
                    value: _.get(ownerObj, ['components', 'name', 'value'], ''),
                    type: 'text',
                    ordinalNo: 1,
                  },
                  ownerIdNumber: {
                    value: _.get(ownerObj, ['components', 'idNumber', 'value'], ''),
                    type: 'text',
                    ordinalNo: 2,
                  },
                },
              },
            },
          },
        };
      }

      onDone([lastCert]);
      dispatch(startToast({ title: intl.formatMessage(systemMessages.signedSuccessfully), type: 'success' }));
      onClose();
    },
    [signObjectId, formType, formReportDate, formTemplateId, projectId]
  );

  return (
    <SignatureDialog
      projectId={projectId}
      isOpen={isOpen}
      onClose={onClose}
      signatureObjs={signatures}
      onSign={onSign}
      shouldUploadImmediately={false}></SignatureDialog>
  );
};

export default CertificationSignatureModal;
