import PropertiesMapping from './propertiesMapping';
import { Map } from 'immutable';
import { Record } from '../transit';
import _ from 'lodash';
import { MOCK_PROPERTY_MAPPINGS } from '../propertiesTypes/propertiesTypes';
import { CLEAR_ALL_DATA } from '../app/actions';
import { propertiesMappingsEvents } from './propertiesMappingsEvents';

const InitialState = Record(
  {
    map: Map(),
  },
  'propertiesMappings'
);

const initialState = new InitialState();

export default function propertiesMappingsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case propertiesMappingsEvents.GET_PROPERTIES_MAPPINGS: {
      if (!action.payload?.projectId) return state;

      let { projectId, subjectName, propertiesMappings } = action.payload;

      if (propertiesMappings) {
        propertiesMappings = { [subjectName]: propertiesMappings };
        let nextPropertiesMappings = state.getIn(['map', projectId]) || new Map();

        _.forIn(propertiesMappings, (subjectPropsMappings, _subjectName) => {
          nextPropertiesMappings.setIn([_subjectName], new Map());
          
          subjectPropsMappings.forEach(mapping => {
            const { id: groupId, values } = mapping;
            _.forIn(values, (currMap, id) => {
              let properties = currMap.properties;
              const mockMapping = MOCK_PROPERTY_MAPPINGS[_subjectName]?.[groupId]?.[id];
              if (mockMapping) {
                properties.push(...mockMapping.properties);
                properties = Array.from(new Set(properties));
              }
              
              nextPropertiesMappings = nextPropertiesMappings.setIn(
                [_subjectName, groupId, id],
                new PropertiesMapping({ id, properties: currMap.properties })
              );
            });
            
          })
        });
        
        state = state.setIn(['map', projectId], nextPropertiesMappings);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}
