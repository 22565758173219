import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import _ from 'lodash';
import { Map } from 'immutable';
import { Record } from '../transit';
import { DRAWINGS_KEY } from '../drawings/reducer';
import { CLEAR_ALL_DATA } from '../app/actions';
import { PROJECT_EVENTS } from '../projects/trackProjects';

const InitialState = Record(
  {
    map: Map(),
    companiesMap: Map(),
    global: Map(),
    debugConfigurations: Map(),
  },
  'configurations'
);

const initialState = new InitialState();

export default function configurationsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.GET_CONFIGURATIONS: {
      const { configurations, scopeType, scopeId } = action.payload || {};

      if (!scopeType || (scopeType !== 'global' && !scopeId)) return state;

      if (configurations) {
        if (scopeType === 'global') {
          state = state.setIn(['global'], new Map({ ...configurations }));
        } else {
          const mergedDefaultConfig = _.merge({}, configurations);
          state = state.setIn([scopeType == 'companies' ? 'companiesMap' : 'map', scopeId], mergedDefaultConfig);
        }
      }
      return state;
    }

    case lastUpdatesActions.GET_SYNCED: {
      const projects = _.get(action, ['payload', 'projects']);

      _.forIn(projects, (project, projectId) => {
        if (project.config || _.isEqual(project.config, state.getNested(['debugConfigurations', projectId])))
          state = project.config
            ? state.setIn(['debugConfigurations', projectId], project.config)
            : state.removeIn(['debugConfigurations', projectId]);
      });

      const global = _.get(action, ['payload', 'global']);
      if (global) {
        const newGlobal = new Map({ ...state.global.toJS(), ...global });
        state = state.setIn(['global'], newGlobal);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}