import userMessages from '../../../common/users/usersMessages';
import systemMessages from '../../../common/app/systemMessages';
import companiesMessages from '../../../common/companies/companiesMessages';

//////////////////////////////////////////////////////
//////////////////// Employee Fields /////////////////
//////////////////////////////////////////////////////
export class Section {
	constructor(title, fields, props = {}, id = null, show = true) {
		this.title = title;
		this.fields = fields;
		this.props = props;
		this.show = show;
		this.id = id;
	}
}

// name --> name of the input field to display
// type --> Type of input field to use
// pathToValue --> Path for 'getNested'/'setNested' to get/set the value in the state (needs to start at SAME name as in state (or you can just add them here and not on the state))
// values --> only for SelectionList input type --> values available to choose for the user (HAVE to be { id: id, value: value } ) value saved to the state will be the ID

export class Field {
	constructor(name, type, pathToValue, values = null, props = {}, defaultValue = false, creationOnly = false) {
		this.name = name;
		this.type = type;
		this.pathToValue = pathToValue;
		this.props = props;
		this.defaultValue = defaultValue;
		this.creationOnly = creationOnly;
		if (values !== null) this.values = values;
	}
}

const employeeName = new Field(userMessages.displayNamePlaceholder, 'String', ['displayName'], null, {
	fullWidth: true,
	mandatory: true,
});

const employeePhoneNumber = new Field(userMessages.mobileSelector, 'String', ['phoneNumber'], null, {
	fullWidth: true,
	mandatory: true,
	innerStyle: { direction: 'ltr' },
});

const employeeEmail = new Field(userMessages.emailSelector, 'String', ['email'], null, { fullWidth: true });

const employeeTitle = new Field(userMessages.title, 'SelectionList', ['title'], null, { fullWidth: true }, false, true);

const employeeCompanyId = new Field(userMessages.companySelector, 'SelectionList', ['companyId'], null, {
	fullWidth: true,
	mandatory: true,
}, false, true);

//////////////////////////////////////////////////////
//////////////////// Subcontractor Fields ///////////
//////////////////////////////////////////////////////

const SubcontractorName = new Field(systemMessages.manage.companyName, 'String', ['name'], null, {
	fullWidth: true,
	mandatory: true,
});

const SubcontractorLogo = new Field(systemMessages.manage.companyLogo, 'Picture', ['logo'], null, { fullWidth: true });
const SubcontractorEIN = new Field(companiesMessages.companyEIN, 'String', ['ein'], null, { fullWidth: true });

///////////////////////////////////////////////////////
///////////////// Details Sections ////////////////////
///////////////////////////////////////////////////////

const DetailsBasicSection = new Section(
	userMessages.details,
	[employeeName, employeePhoneNumber, employeeEmail, employeeCompanyId, employeeTitle],
	{},
	'basic',
);

const SubcontractorDetailsSection = new Section(
	userMessages.details,
	[SubcontractorEIN, SubcontractorName, SubcontractorLogo],
	{},
	'basic',
);

export const DetailsSection = [DetailsBasicSection];

export const SubcontractorSection = [SubcontractorDetailsSection];
