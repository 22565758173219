import { subscribeToLastUpdates } from '../lib/utils/utils';
import serverSDK from '@cemento-sdk/server';
import { buildingsEvents } from './buildingsEvents';
import { getDispatch } from '../configureMiddleware';

export async function getBuildings(projectId, viewer) {
  const dispatch = getDispatch();

  const scopeParams = {
    scope: 'projects',
    scopeId: projectId,
  };

  const resourceParams = {
    subject: 'buildings',
    getData: () => {
      return serverSDK.locations.getBuildings({ projectId });
    },
  };

  const onData = (data) => {
    if (!data) return;
    
    dispatch({ type: buildingsEvents.GET_BUILDINGS, payload: { projectId, buildings: data } });
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);

  return { projectId };
}
