import { createContext, memo, useMemo } from 'react';
import InstancesPreProcessorHOC from './helpers/InstancesPreProcessorHOC';
import useObjectsManipulations from './hooks/useObjectsManipulations';
import useSmartObjects from './hooks/useSmartObjects';
import useProcessedObjects from './hooks/useProcessedObjects';
import useSelectedObject from './hooks/useSelectedObject';

export const ObjectsWrapperDataContext = createContext({
  data: [],
});

export const ObjectsWrapperManipulationContext = createContext({
  groupByOptions: null,
  filterOptions: null,
  groupBy: null,
  sortBy: null,
  filters: null,
  skip: null,
  step: null,
});

/**
 *
 * @param {import('react').PropsWithChildren<{ objects: any[], propTypes: any, propMapping: any }>} props
 * @returns
 */
export const ObjectsWrapper = (props) => {
  const {
    //
    subjectType,
    propertiesInstances,
    contentType,
    //
    objects,
    propTypes,
    children,
    defaultGroupBy = [],
  } = props;

  const smartObjects = useSmartObjects(objects, propTypes);
  const objectsManipulationsData = useObjectsManipulations({
    smartObjects,
    propTypes,
    defaultGroupBy,
  });

  const processedObjects = useProcessedObjects(smartObjects, objectsManipulationsData);

  const { selectedObject, setSelectedObject, createNewObject, resetSelectedObject } = useSelectedObject({
    //
    contentType,
    subjectType,
    propertiesInstances,
    //
    columnDefinitions: objectsManipulationsData.columnDefinitions,
    filters: objectsManipulationsData.filters,
    objects,
  });

  const memoDataContext = useMemo(() => {
    return {
      data: processedObjects,
      rawObjects: objects,
    };
  }, [processedObjects, objects]);

  const memoManipulationContext = useMemo(() => {
    return {
      ...objectsManipulationsData,
      subjectType,
      contentType,
      selectedObject,
      setSelectedObject,
      createNewObject,
      resetSelectedObject,
    };
  }, [
    objectsManipulationsData,
    subjectType,
    contentType,
    selectedObject,
    setSelectedObject,
    createNewObject,
    resetSelectedObject,
  ]);

  return (
    <ObjectsWrapperDataContext.Provider value={memoDataContext}>
      <ObjectsWrapperManipulationContext.Provider value={memoManipulationContext}>
        {children}
      </ObjectsWrapperManipulationContext.Provider>
    </ObjectsWrapperDataContext.Provider>
  );
};

export default InstancesPreProcessorHOC(memo(ObjectsWrapper));
