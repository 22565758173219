import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import theme from '../assets/css/theme';
import close from '../assets/img/icons/close.png';
import back from '../assets/img/icons/back.png';
import MenuScrollbar from '../components/CementoComponents/MenuScrollbar';
import SideCard from './SideCard';
import _ from 'lodash';
import Modal from '../components/CementoComponents/Modal';
import { Card } from '../components';
import { deepEqual, funcSingleton } from '../../common/app/funcs';
import FocusManager from '../components/FocusManager';
import withRouterHOC from '../components/Router/util/withRouterHOC';

const BOX_SHADOW = '0 0 11px rgba(0, 0, 0, 0.3)';

class SplitViewPage extends React.Component {
	constructor(props) {
		super(props);
		this.getMainContainerRef = this.getMainContainerRef.bind(this);
		this.getMainContainerScroll = this.getMainContainerScroll.bind(this);
		this.handleCardInnerObjectSelect = this.handleCardInnerObjectSelect.bind(this);
		this.handleEditModeChange = this.handleEditModeChange.bind(this);
		this.popInnerCardQueue = this.popInnerCardQueue.bind(this);
		this.setComponentData = this.setComponentData.bind(this);
		this.hideNavigation = this.hideNavigation.bind(this);
		this.handleBackOrCloseClick = this.handleBackOrCloseClick.bind(this);

		this.state = {
			isEditMode: false,
		};

		this.mainCardRef = React.createRef();
	}

	static defaultProps = {
		mode: 'side', // | 'modal',
	};

	UNSAFE_componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	async setComponentData(props, nextProps) {
		let newStateChanges = {};

		if (props.isValDiff(nextProps, ['editMode']) || props.isValDiff(nextProps, ['isCreateMode'])) {
			this.handleEditModeChange(Boolean(nextProps.isCreateMode || nextProps.editMode));
		}

		if (props.isValDiff(nextProps, ['ratio']) || this.state.mainSize === undefined) {
			const defaultRatio = nextProps.mode === 'side' ? 1 / 3 : 1 / 2;
			const nextSideSize = 12 * (nextProps.ratio || defaultRatio);
			const nextMainSize = 12 - nextSideSize;
			if (nextSideSize !== this.state.sideSize) {
				newStateChanges.sideSize = nextSideSize;
				newStateChanges.mainSize = nextMainSize;
			}
		}

		if (nextProps.SideStack) {
			if (!deepEqual(props.SideStack, nextProps.SideStack)) {
				let shouldClose;
				if (props.sideStackObjectId && nextProps.sideStackObjectId && props.sideStackObjectId === nextProps.sideStackObjectId)
					shouldClose = true;
				else if ((props.sideStackObjectId && !nextProps.sideStackObjectId) || (!props.sideStackObjectId && nextProps.sideStackObjectId))
					shouldClose = true;
				else
					shouldClose = _.get(this.mainCardRef.current, 'handleCancel')
						? await this.mainCardRef.current.handleCancel()
						: true;
				if (shouldClose) {
					newStateChanges.sideStack = nextProps.SideStack ? nextProps.SideStack.slice() : nextProps.SideStack;
					newStateChanges.CurrentSideComponent = this.getSideComponent(
						Object.assign({}, this.state, newStateChanges),
						nextProps,
					);
				}
			}
		} else {
			newStateChanges.CurrentSideComponent = null;
			newStateChanges.sideStack = null;
		}

		if (props.isValDiff(nextProps, ['uiParams', 'unselectCellAndBackOrCloseSideCard'])) {
			newStateChanges = Object.assign(newStateChanges, this.handleBackOrCloseClick(newStateChanges.sideStack, false));
		} else if (props.isValDiff(nextProps, ['location', 'pathname'])) {
			this.handleBackOrCloseClick();
		}

		if (Object.keys(newStateChanges).length) {
			this.setState(newStateChanges);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.inDraftMode != prevProps.inDraftMode &&
			this.props.inDraftMode &&
			this.props.inDraftMode.type == 'post'
		) {
			let targetObject = Object.assign({}, this.props.inDraftMode.details, {
				mode: 'draft',
			});
			this.handleCardInnerObjectSelect(null, 'post', { targetObject });
		}
		if (prevState.isEditMode !== this.state.isEditMode)
			this.setState({
				CurrentSideComponent: this.getSideComponent(this.state, this.props),
			});
	}

	handleEditModeChange = (isEditMode = !this.state.isEditMode, callback) => {
		const { onEditModeChange } = this.props;
		const nextIsEditMode = Boolean(isEditMode);
		if (onEditModeChange) onEditModeChange(nextIsEditMode);
 		if (this.state.isEditMode !== nextIsEditMode) {
			this.setState({ isEditMode: nextIsEditMode }, () => {
				callback?.()
			});
		}
	};

	getMainContainerRef(node) {
		const { getMainContainerRef } = this.props;
		this.mainContainerRef = node;
		if (getMainContainerRef) getMainContainerRef(node);
	}

	getMainContainerScroll(view, node) {
		const { getMainContainerScroll } = this.props;
		this.mainContainerRef = view;
		if (getMainContainerScroll) getMainContainerScroll(view, node);
	}

	handleCardInnerObjectSelect(originSideObjectIndex, sideObjectType, sideObjectProps, innerCardComponent) {
		const { sideStack } = this.state;
		let newStateChanges = {};

		const newSideObject = {
			props: sideObjectProps,
			type: sideObjectType,
			Component: innerCardComponent,
		};
		originSideObjectIndex = !_.isNil(originSideObjectIndex) ? originSideObjectIndex : sideStack.length - 1;
		let newStack = sideStack ? sideStack.slice(0, originSideObjectIndex + 1) : [];
		newStack.push(newSideObject);

		newStateChanges.sideStack = newStack;
		newStateChanges.CurrentSideComponent = this.getSideComponent(
			Object.assign({}, this.state, newStateChanges),
			this.props,
		);
		this.setState(newStateChanges);
	}

	popInnerCardQueue() {
		this.handleBackOrCloseClick();
	}

	async handleBackOrCloseClick(inSideStack, skipSetState) {
		const { onSideClose, mode } = this.props;
		const { sideStack } = this.state;
		
		const stack = inSideStack || sideStack;
		let stateChanges = {};
		
		if (mode === 'side' && stack && stack.length > 1) {
			stateChanges.sideStack = stack.slice(0, stack.length - 1);
			stateChanges.CurrentSideComponent = this.getSideComponent(
				Object.assign({}, this.state, stateChanges),
				this.props,
			);
		} else {
			const shouldClose = _.get(this.mainCardRef.current, 'handleCancel')
				? await this.mainCardRef.current.handleCancel()
				: true;
			if (shouldClose) {
				if (onSideClose) onSideClose();
				stateChanges.sideStack = null;
				stateChanges.CurrentSideComponent = null;
				stateChanges.isEditMode = false;
			}
		}

		if (!skipSetState) this.setState(stateChanges);

		return stateChanges;
	}

	injectCardObjectProps = (sideObjectIndex, sideObject) => {
		const { mode, location } = this.props;
		if (!sideObject?.type) {
			return {
				type: 'nothingToDisplay',
				props: {},
			};
		}
		const enhancedHandleCardInnerObjectSelect = funcSingleton(
			this,
			`handleCardInnerObjectSelect-${sideObjectIndex}`,
			(...args) => this.handleCardInnerObjectSelect(sideObjectIndex, ...args),
		);

		let cardProps = Object.assign({}, sideObject.props);
		switch (sideObject.type) {
			case 'post':
				Object.assign(cardProps, {
					hideNavigation: this.hideNavigation,
					onClose: () => this.handleBackOrCloseClick(),
					post: cardProps.targetObject ? cardProps.targetObject : cardProps.post,
					style: postCardStyle,
					location
				});
				const cardPropsOnPostSaveRef = cardProps.onPostSave;
				cardProps.onPostSave = newPost => {
					if (cardPropsOnPostSaveRef) cardPropsOnPostSaveRef(newPost);
				};
				break;

			case 'forms':
			case 'connectedObjectProperties':
				Object.assign(cardProps, {
					mode: sideObject.type === 'forms' ? 'modal' : 'card',
					popInnerCardQueue: this.popInnerCardQueue,
					onInnerObjectSelect: enhancedHandleCardInnerObjectSelect,
					hideNavigation: this.hideNavigation,
				});
				break;

			case 'objectPropertiesPage': {
				Object.assign(cardProps, {
					mode: mode === 'side' ? 'card' : 'modal',
					popInnerCardQueue: this.popInnerCardQueue,
					onInnerObjectSelect: enhancedHandleCardInnerObjectSelect,
					hideNavigation: this.hideNavigation,
				});
				break;
			}
			case 'addCertification':
				Object.assign(cardProps, { hideNavigation: this.hideNavigation });
				break;
			case 'member':
			case 'company':
			case 'locationsGroup':
				Object.assign(cardProps, {
					hideNavigation: this.hideNavigation,
					onClose: () => this.handleBackOrCloseClick(),
				});
				break;
			case 'checklists':
			case 'checklistItem':
				Object.assign(cardProps, {
					onInnerObjectSelect: enhancedHandleCardInnerObjectSelect,
				});
				break;

			case '_blankCard':
				Object.assign(cardProps, {
					popInnerCardQueue: this.popInnerCardQueue,
					onInnerObjectSelect: enhancedHandleCardInnerObjectSelect,
					hideNavigation: this.hideNavigation,
				});
				break;
		}

		return {
			...sideObject,
			props: cardProps
		};
	};

	hideNavigation(bool) {
		this.setState({ hideNav: bool });
	}

	onCardRef = cardRef => {
		this.mainCardRef.current = cardRef
	};


	getSideCardProps = (state = this.state, props = this.props) => ({
		onEditModeChange: this.handleEditModeChange,
		editMode: state.isEditMode,
		isCreateMode: props.isCreateMode,
		viewType: props.mode,
		hideCancel: props.isCreateMode,
		isEditAllowed: props.isEditAllowed,
		isForceUpdateEnabled: props.isForceUpdateEnabled,
		showCloseButton:!props.hideNav && (props.mode === 'modal' || state.sideStack.length === 1),
		showBackButton: !props.hideNav && (props.mode === 'side' && state.sideStack.length > 1),
		onBackOrClose: () => this.handleBackOrCloseClick(),
	});

	getModalComponent = (state = this.state, props = this.props) => {
		const { sideStack, sideSize, mainSize } = state;
		const { mainCardStyles } = props;

		if (!(sideStack || []).length) return null;

		const extraProps = this.getSideCardProps(state, props);
		
		const stack = Array.isArray(sideStack) ? sideStack : [];
		let mainCardObject = stack[0];
		let sideCardObject = stack[Math.max(stack.length - 1, 1)];
		// TODO: remove index from `injectCardObjectProps`
		mainCardObject = this.injectCardObjectProps(0, mainCardObject);
		sideCardObject = this.injectCardObjectProps(Math.max(stack.length - 1, 1), sideCardObject);

		return (
			<Modal
				hideCloseButton
				style={{ zIndex: theme.zIndexes.splitViewModal }}
				open={Boolean(mainCardObject)}
				onClose={() => this.handleBackOrCloseClick()}
			>
				{Boolean(mainCardObject) && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: theme.popupHeight,
							width: theme.popupWidth,
							minWidth: 1200,
							maxWidth: '80vw',
							...mainCardStyles,
						}}
					>
						<SideCard isMain onRef={this.onCardRef} {...extraProps} sideObject={mainCardObject}>
							{({ header, cardBody: mainCardBody }) => {
								return (
									<>
										{header}
										<div style={{ display: 'flex', flex: 1 }}>
											<div
												style={{
													display: 'flex',
													flex: mainSize,
													boxShadow: BOX_SHADOW,
												}}
											>
												<Card style={{ height: '100%', flex: 1 }}>{mainCardBody}</Card>
											</div>
											{sideCardObject && (
												<div
													style={{
														display: 'flex',
														flex: sideSize,
														justifyContent: 'center',
														alignItems: 'center',
														overflow: 'hidden',
													}}
												>
													<SideCard {...extraProps} sideObject={sideCardObject}>
														{({ cardBody: secondaryCardBody, alert, tabs }) => (
															<Card style={{ height: '100%', flex: 1 }}>
																{alert}
																{tabs}
																{secondaryCardBody}
															</Card>
														)}
													</SideCard>
												</div>
											)}
										</div>
									</>
								);
							}}
						</SideCard>
					</div>
				)}
			</Modal>
		);
	};

	getSideCardComponent = (state = this.state, props = this.props) => {
		const { sideStack, sideSize } = state;
		const { rtl } = props;
		if (!(sideStack || []).length) return null;

		const extraProps = this.getSideCardProps(state, props);

		return (
			<>
				{sideStack.map((cardObject, index) => {
					cardObject = this.injectCardObjectProps(index, cardObject);
					if (!cardObject) return null;

					const isLastCard = index === sideStack.length - 1;
					return (
						<div
							key={cardObject?.props?.objectId}
							style={{
								display: !isLastCard ? 'none' : 'flex',
								zIndex: theme.zIndexes.splitViewCard,
								boxShadow: BOX_SHADOW,
								minWidth: '430px',
								maxWidth: '50vw',
								flex: sideSize,
								[rtl ? 'borderRight' : 'borderLeft']: theme.borderLineNeutralLight + '40',
							}}
						>
							<SideCard isMain onRef={isLastCard ? this.onCardRef : undefined} {...extraProps} sideObject={cardObject}>
								{({ header, alert, tabs, cardBody }) => (
									<Card>
										{header}
										{alert}
										{tabs}
										{cardBody}
									</Card>
								)}
							</SideCard>
						</div>
					);
				})}
			</>
		);
	};

	getSideComponent = (state = this.state, props = this.props) => {
		const { sideStack } = state;
		const { mode } = props;
		let cardComponent = null;

		if ((sideStack || []).length) {
			if (mode === 'side') cardComponent = this.getSideCardComponent(state, props);
			else if (mode === 'modal') cardComponent = this.getModalComponent(state, props);
		}

		return cardComponent;
	};

	render() {
		const { Main, withHorizontalScroll, hideSplitViewScrolls, onScrollFrame } = this.props;
		const { CurrentSideComponent, mainSize } = this.state;
		const SideComponent = CurrentSideComponent;
		const MainComponent = hideSplitViewScrolls ? (
			Main
		) : (
			<MenuScrollbar
				onScrollFrame={onScrollFrame}
				isSmooth={false}
				withHorizontalScroll={withHorizontalScroll}
				getScrollRef={this.getMainContainerScroll}
			>
				{Main}
			</MenuScrollbar>
		);
		return (
			<div style={{ height: 'inherit', display: 'flex', flex: 1 }}>
				<div
					style={{
						height: 'inherit',
						display: 'flex',
						flexDirection: 'column',
						overflow: 'auto',
						flex: mainSize,
					}}
					ref={this.getMainContainerRef}
				>
					{MainComponent}
				</div>
				<FocusManager.ContextProvider>{Boolean(SideComponent) && SideComponent}</FocusManager.ContextProvider>
			</div>
		);
	}
}

SplitViewPage = withRouterHOC(SplitViewPage);
const enhance = compose(
	connect(
		state => ({
			uiParams: state.ui.uiParams,
			inDraftMode: state.ui.inDraftMode,
		}),
		{},
	),
);
export default enhance(SplitViewPage);

const postCardStyle = {
	flex: 1,
	backgroundColor: theme.backgroundColorBright,
	height: "100%",
	boxShadow: "none",
};