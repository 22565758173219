import React, { useEffect } from 'react';
import useDailyReports from '../hooks/useDailyReports';

const PrevFormHandler = ({ onPrevForm, formReportDate, formType }) => {
  const { forms, getPrevForm, getFormInstancesMap } = useDailyReports(formType);

  useEffect(() => {
    if (Object.values(forms || {}).length) {
      const prevForm = getPrevForm(formReportDate);
      const prevPropData = getFormInstancesMap(prevForm?.id);
      onPrevForm?.(prevPropData);
    }
  }, [forms, getPrevForm, getFormInstancesMap, formReportDate, onPrevForm]);

  return null;
};

export default PrevFormHandler;