import reportsMessages from '../reports/reportsMessages';
import _ from 'lodash';
import { platformActions } from '../platformActions';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import { debugParams, getRoundedDate, removeEmpty, subscribeToLastUpdates } from '../lib/utils/utils';
import { getNewId, updateUsingFirebaseProxy } from '../lib/api';
import { hideLoading } from '../app/actions';
import { onError } from '../app/funcs';
import { envParams, getDispatch } from '../configureMiddleware';
import ExtraError from '../lib/errors/extraError';
import Form from './form';
import { checkAndUploadLocalObjects } from '../../native/app/funcs';
import serverSDK from '@cemento-sdk/server';
import * as permissionsFunc from '../permissions/funcs';
const excludeFields = [
  'projectId',
  'skipEmails',
  'targetEmails',
  'checklists', 
  'certifications',
  'title', 
  'attachments',
  'usersToNotify', 
  'url',
  'sections', 
  'formStartDate',
  'resendEmailTS',
  'createdAt',
  'tags'
];

export const fetchForms = async (queryParams, lastUpdateTS) => {
  
  if (lastUpdateTS) {
    queryParams.lastUpdateTS = lastUpdateTS;
  }
  let forms = await serverSDK.forms.getForms({ ...queryParams, type: queryParams.formType, 
    excludeFields
   });
  forms = forms.reduce((acc, form) => {
    acc[form.id] = {
      ...form,
      type: queryParams.formType,
      projectId: queryParams.projectId
    };
    return acc;
  }, {});

    return forms;
};

/**
 * Sorts an array of forms primarily by reportDate and secondarily by createdTS
 * @template T
 * @param {Array<T>} forms - Array of form objects to be sorted
 * @param {'asc' | 'desc'} [order='asc'] - Sort order, either 'asc' or 'desc'
 * @returns {Array<T>} Sorted array of forms
 * @example
 * // Sort forms in ascending order
 * const sortedForms = sortForms(forms, 'asc');
 * 
 * // Sort forms in descending order
 * const sortedForms = sortForms(forms, 'desc');
 */
export const sortForms = (forms, order = 'desc') => {
  const filteredForms = Object.values(forms || {});

  const compare = (a, b) => {
    if (typeof a !== 'number' || typeof b !== 'number') {
      return order === 'desc' ? b.localeCompare(a) : a.localeCompare(b); 
    } else {
      return order === 'desc' ? b - a : a - b; 
    }
  };

  const sortedForms = filteredForms.sort((formA, formB) => {
    const reportDateFormA = formA.reportDate || formA.updatedTS || 0;
    const reportDateFormB = formB.reportDate || formB.updatedTS || 0;
    const createdTSFormA = formA.createdTS || 0;
    const createdTSFormB = formB.createdTS || 0;
    
    return reportDateFormA === reportDateFormB 
      ? compare(createdTSFormA, createdTSFormB) 
      : compare(reportDateFormA, reportDateFormB); 
  });

  return sortedForms;
};


export const startFormsListener = (viewer, queryParams, onUpdate, immediateFetch) => {
  const { projectId, formType, locationId, formTemplateId, ids } = queryParams || {};

  let uniqueKey = null;
  if (ids?.length || locationId || formTemplateId) {
    uniqueKey = `formIdsListener_${ids?.join(',')}_${locationId}_${formTemplateId}`;
  }

  const saveFunc = async (_data, isRevoke) => {
    if (debugParams.disableFetchByTSSave) return;
    await saveFormsLocally(_data, projectId, formType, isRevoke);
    if (onUpdate) onUpdate?.(_data);
  };

  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    subject: `forms`,
    uniqueKey,
    queryParams,
    isObjectUseTransactionalDB: !uniqueKey,
    getData: (lastUpdatedTS) => fetchForms(queryParams, lastUpdatedTS),
    getLastUpdateTS: uniqueKey ? undefined : () => getLastUpdateTS(queryParams),
  };

  subscribeToLastUpdates(viewer, scopeParams, resourceParams, saveFunc, immediateFetch).then((data) => {
    if (immediateFetch) {
      saveFunc(data, false);
    }
  });

  return () => endFormsListener(projectId, queryParams, uniqueKey);
};

export const saveFormsLocally = async (forms, projectId, formType, cleanAll) => {
  const localDB = platformActions.localDB.getCementoDB();

  if (cleanAll) {
    const deleteQuery = getLocalDBFormQuery({ projectId, formType, includeDeleted: true });
    await localDB.unset('forms', deleteQuery);
  }

  if (_.size(forms)) {
    await localDB.set(
      'forms',
      _.map(forms, (f) => removeEmpty(new Form({ ...f, projectId })))
    );
  }
};

function getLastUpdateTS(queryParams) {
  let lastUpdateTS = 0;
  const forms = getLocalForms(queryParams);
  lastUpdateTS = _.maxBy(forms, 'updatedTS')?.updatedTS || 0;
  return lastUpdateTS;
}

export function endFormsListener(projectId, queryParams, uniqueKey) {
  ClientServerConnectivityManagerInstance.unregisterService({
    uniqueKey,
    scope: 'projects',
    scopeId: projectId,
    subject: 'forms',
    params: queryParams,
  });
}

const getRealmQuery = (query, includeDeleted) => {
  let realmQuery = '';
  realmQuery = Object.entries(_.omit(query, ['ids', 'id', 'locationId']))
    .map(([key, value]) => `${key} == "${value}"`)
    .join(' AND ');

  if (!includeDeleted) {
    realmQuery = realmQuery + ` AND isDeleted != true`;
  }

  if (query?.ids?.length > 1) {
    realmQuery = realmQuery + ` AND id IN (${query?.ids.map((id) => `"${id}"`).join(',')})`;
  } else if (query?.id) {
    realmQuery = realmQuery + ` AND id == "${query.id}"`;
  }

  if (query?.locationId) {
    realmQuery =
      realmQuery +
      ` AND (location.unitId == "${query.locationId}" OR location.floorId == "${query.locationId}" OR location.buildingId == "${query.locationId}")`;
  }

  return realmQuery;
};

const getLokiQuery = (query, includeDeleted) => {
  let lokiQuery = { ..._.omit(query, ['ids', 'id', 'locationId']) };

  if (!includeDeleted) {
    lokiQuery.isDeleted = { $ne: true };
  }

  if (query.locationId) {
    lokiQuery = {
      ...lokiQuery,
      ['$or']: [
        { 'location.unitId': query.locationId },
        { 'location.floorId': query.locationId },
        { 'location.buildingId': query.locationId },
      ],
    };
  }

  if (query.ids?.length > 1) {
    lokiQuery.id = { $in: query.ids };
  } else if (query.id) {
    lokiQuery.id = query.id;
  }

  return lokiQuery;
};

export const getLocalDBFormQuery = ({ projectId, formType, ids, formTemplateId, locationId, includeDeleted }) => {
  const isNative = platformActions.app.isNative();
  let queryParams = removeEmpty({
    projectId,
    type: formType,
    formTemplateId,
    id: ids?.length === 1 ? ids[0] : undefined,
    ids: ids?.length > 1 ? ids : undefined,
    locationId,
  });

  queryParams = isNative ? getRealmQuery(queryParams, includeDeleted) : getLokiQuery(queryParams, includeDeleted);

  return queryParams;
};

export const getLocalForms = (queryParams) => {
  const { projectId, formType, ids, formTemplateId, locationId } = queryParams;
  let forms = [];
  if (projectId && (formType || ids)) {
    const localDB = platformActions.localDB.getCementoDB();
    const query = getLocalDBFormQuery({ projectId, formType, ids, formTemplateId, locationId, includeDeleted: false });
    forms = localDB.get('forms', query);
  }
  return forms;
};

export async function deleteForm(projectId, form, type = 'general') {
  let updates = {};
  updates[`forms/${projectId}/full/${type}/${form.id}/isDeleted`] = true;
  await updateUsingFirebaseProxy({ projectId, type: `forms_${type}`, updates }); /// TODO: PUT through api server
  await saveFormsLocally([{ ...form, generator: { id: form.generator.id }, isDeleted: true }], projectId, form.type);
}

export const upsertForm = async (projectId, viewer, form, type = 'general', shouldAlertUser = true) => {
  const originalForm = getLocalForms({ projectId, formType: type, ids: [form.id] })[0];
  const generator = originalForm?.generator || { id: viewer.id, displayName: viewer.displayName };

  if (!type) throw new ExtraError('upsertForm error - form type missing', { form, type });

  const dispatch = getDispatch();

  let newForm = _.pick(form, [
    'parentId',
    'certifications',
    'formTemplateId',
    'id',
    'title',
    'uri',
    'owner',
    'status',
    'checklists',
    'location',
    'universalIds',
    'posts',
    'signatures',
    'reportDate',
    'targetEmails',
    'sections',
    'type',
    'usersToNotify',
    'isDocx',
    'createdTS',
    'parentId',
    'isDeleted',
    'subTitle'
  ]);

  if (!newForm.type) newForm.type = type;

  if (!newForm.createdTS) newForm.createdTS = Date.now();

  if (!newForm.reportDate) newForm.reportDate = getRoundedDate().timestamp;

  if (newForm.location) {
    const { unitId, buildingId, floorId } = newForm.location;
    const locationId = unitId || floorId || buildingId;

    if (locationId && platformActions.app.isNative()) {
      await checkAndUploadLocalObjects(projectId, locationId);
    }
  }

  newForm.id = newForm.id || getNewId();

  if (!newForm.parentId && newForm.type === 'dailyReport') newForm.parentId = newForm.id;

  const shouldRemoveOldUri = originalForm?.uri && (Object.keys(newForm.signatures || {}) !== Object.keys(originalForm?.signatures || {}));
  if (shouldRemoveOldUri) newForm.uri = null;

  newForm.updatedTS = Date.now();
  newForm.generator = generator;
  newForm = removeEmpty(newForm, 'upsertForm');

  saveFormsLocally([{ ...newForm, isLocal: true }], projectId, newForm.type);

  const timeout = 45 * 1000;
  let promise = async function (projectId, type, newForm) {
    return new Promise(function (resolve, reject) {
      if (!projectId || !newForm) reject('Missing projectId or form');

      setTimeout(() => {
        if (!didResolved) {
          didRejected = true;
          reject('Action canceled by timeout: Could not contact server in a reasonable amount of time');
        }
      }, timeout);

      let updates = {};
      updates['forms/' + projectId + '/full/' + type + '/' + newForm.id] = newForm;

      let didResolved = false;
      let didRejected = false;

      updateUsingFirebaseProxy({
        projectId,
        type: `forms`,
        updates,
        callback: (error) => {
          if (error) {
            didRejected = true;
            reject(error);
          }

          if (didRejected) return;
          didResolved = true;
          resolve(newForm);
        },
      });
    });
  };

  let upsertFormRes;
  let success = true;

  try {
    upsertFormRes = await promise(projectId, type, newForm);
    if (upsertFormRes) {
      upsertFormRes = _.omit(upsertFormRes, 'updatedTS');
    }
  } catch (error) {
    success = false;
    onError({
      errorMessage: 'Failed to upsert form',
      error,
      alertParams: !shouldAlertUser
        ? null
        : {
            title: reportsMessages.exportErrors.title,
            message: reportsMessages.exportErrors.content,
          },
      methodMetaData: {
        name: 'upsertForm',
        args: { projectId, viewer, form, type },
      },
    });
  } finally {
    dispatch(hideLoading());
  }

  return { projectId, reportId: newForm.id, form: upsertFormRes, success };
};

export const sendFormViaMailServer = async (projectId, formId, formType, targetEmails, subject, text) => {
  await platformActions.net.fetch(envParams.apiServer + '/v1/services/email/send/forms', {
    method: 'POST',
    body: JSON.stringify({
      projectId,
      formId,
      formType,
      targetEmails,
      subject,
      text,
    }),
  });

  return { projectId, formId, formType, targetEmails, subject, text };
};


const getCurrentSignature = (form, formTemplateSignatures = {}) => {
  return Object.values(formTemplateSignatures)
          .sort((sigA, sigB) => Number(sigA.ordinalNo) - Number(sigB.ordinalNo))
          .find(sig => !form?.signatures?.[sig.id]);
};

const didUserCouldSign = (form, formTemplateSignatures = {}) => {
  let permissionsObject = { permissions: { read: {
    title: [],
    groups: [],
    trades: []
  } } };

  Object.keys(form?.signatures || {}).forEach(sigId => {
    const signaturePermissions = formTemplateSignatures?.[sigId]?.permissions?.write;
    ['title', 'groups', 'trades'].forEach((key) => {  
      permissionsObject.permissions.read[key] = permissionsObject.permissions.read[key].concat(signaturePermissions?.[key] || []);
    })
  });

  return permissionsObject;
}

export const getFilteredForms = ({
  forms,
  permissionKey,
  permissionTargetType,
  viewer,
  selectedProjectId,
  configurations,
  formType,
}) => {
  const fullFormTypesArray = forms.map((form) => ({
    fullFormType: {
      id: form.id,
      type: form.type,
      generator: { id: form.generator?.id },
      permissions: configurations.getNested(['forms', form.formTemplateId, 'permissions'], {}),
      currentSignature: getCurrentSignature(
        form,
        configurations.getNested(['forms', form.formTemplateId, 'signatures'], {})
      ),
      didUserCouldSign: didUserCouldSign(
        form,
        configurations.getNested(['forms', form.formTemplateId, 'signatures'], {})
      ),
    },
  }));
  
  const formsPermitted = permissionsFunc.getFilteredMap(
    viewer,
    selectedProjectId,
    permissionKey,
    'read',
    fullFormTypesArray,
    permissionTargetType,
    viewer.adminMode,
    { formType }
  ).permitted;

    return formsPermitted;
};

/**
 * Populates checklists with their respective checklist items and locations.
 *
 * @param {Object} params - The input parameters.
 * @param {Object} params.checklistItems - A map of checklist items.
 * @param {Object} params.checklists - A map of checklists.
 * @returns {Object} - A structured map of checklists with their items and locations.
 */
const populateChecklistLocally = ({ checklistItems, checklists }) => {
  let structuredChecklists = {};
  _.forIn(checklists, (checklist, checklistId) => {
    if (checklist.locations) {
      structuredChecklists[checklistId] = {
        id: checklistId,
        items: {},
        locations: checklist.locations,
      };
    }
  });
  _.forIn(checklistItems, (checklistItem, checklistItemId) => {
    _.forIn(checklistItem.checklistIds, (checklist, checklistId) => {
      if (!checklists[checklistId]) return; // Ensure checklist exists
      if (!structuredChecklists[checklistId]) {
        structuredChecklists[checklistId] = {
          id: checklistId,
          items: {},
          locations: {},
        };
      }

      structuredChecklists[checklistId].items[checklistItemId] = { id: checklistItemId };

      _.forIn(checklistItem.onlyLocations, (currTypeLocations, locationType) => {
        structuredChecklists[checklistId].locations = {
          ...structuredChecklists[checklistId].locations,
          [locationType]: currTypeLocations,
        };
      });
    });
  });

  return structuredChecklists;
};

/**
 * Populates form templates with associated checklists and their locations.
 
 * @param {Object} params - The input parameters.
 * @param {Object} params.formTemplates - A map of form templates.
 * @param {Object} params.populatedChecklists - A structured checklist object containing items and locations.
 * @returns {Object} - A structured map of form templates with checklists and their locations.
 */
const populateFormTemplatesLocally = ({ formTemplates, populatedChecklists }) => {
  
  let structuredFormTemplates = {};

  _.forIn(formTemplates, (formTemplate) => {

    if (!structuredFormTemplates[formTemplate.id]) {
      structuredFormTemplates[formTemplate.id] = {
        ...formTemplate,
        checklists: {},
        locations: formTemplate.locations || {},
      };
    }

    _.forIn(formTemplate.exportableChecklists, (checklist, checklistId) => {
      let checklistData = populatedChecklists[checklistId];
      if (!checklistData && !formTemplate.locations) return;

      structuredFormTemplates[formTemplate.id].checklists[checklistId] = {
        id: checklistId,
        items: checklistData.items,
      };
      
      if (!formTemplate.locations) {
        _.forIn(checklistData.locations, (locations, locationType) => {
          structuredFormTemplates[formTemplate.id].locations = _.merge(
            structuredFormTemplates[formTemplate.id].locations,
            { [locationType]: locations }
          );
        });
      }
    });
  });

  return structuredFormTemplates;
};

/**
 * Main function that populates form templates with their corresponding checklists and locations.
 *
 * @param {Object} params - The input parameters.
 * @param {Object} [params.formTemplates={}] - A map of form templates.
 * @param {Object} [params.checklists={}] - A map of checklists.
 * @param {Object} [params.checklistItems={}] - A map of checklist items.
 * @returns {Object} - A structured map of populated form templates.
 */
export const populateFormTemplates = ({ formTemplates, checklists, checklistItems }) => {
  if (!formTemplates || !checklists || !checklistItems) {
    return {};
  }

  const locallyPopulatedChecklists = populateChecklistLocally({ checklistItems, checklists });
  const locallyPopulatedFormTemplates = populateFormTemplatesLocally({
    formTemplates,
    populatedChecklists: locallyPopulatedChecklists,
  });

  return locallyPopulatedFormTemplates;
};
