import theme from '../../assets/css/theme';
import Modal from './Modal';
import Text from './Text';
import Button from '../../app/standardComponents/Button';
import systemMessages from '../../../common/app/systemMessages';
import useIntl from '../../../common/intl/useIntl';
import { getDispatch } from '../../../common/configureMiddleware';
import { useSelector } from 'react-redux';
import { useMemo, useCallback } from 'react';
import { setDismissedModals } from '../../../common/app/actions';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import starsIcon from '../../assets/img/icons/stars.svg';

/**
 * FeatureModal Component
 * Displays a modal highlighting a new feature with a title, description, and an accept button.
 *
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the modal
 * @param {string} props.featureId - ID of the feature being displayed
 * @param {Function} props.onClose - Callback when the modal is closed
 * @returns {JSX.Element} - Rendered FeatureModal component
 */

const FeatureModal = ({ id, featureId, onClose }) => {
  const intl = useIntl();
  const dispatch = getDispatch();
  const dismissedModals = useSelector((state) => state.app.dismissedModals);
  const isDismissed = useMemo(() => Boolean(dismissedModals.getNested([id])), [dismissedModals, id]);

  const handleClose = useCallback(() => {
    dispatch(setDismissedModals({ modalId: id, isDismissed: true }));
    onClose?.();
  }, [id, dispatch, onClose]);

  const { title, description, howItWorks, extraDescription } = getFeatureMessages(featureId);

  return (
    <Modal onClose={handleClose} style={styles.modal} open={!isDismissed}>
      <div style={styles.container}>
        <section style={styles.sectionCentered}>
          <img style={styles.icon} src={starsIcon} alt='New feature icon' />
          <Text style={styles.newFeatureText}>{intl.formatMessage(systemMessages.newFeature)}</Text>
        </section>

        <section style={styles.sectionCenteredColumn}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.description}>{description}</Text>
          <Text style={styles.howItWorks}>{howItWorks}</Text>
          <Text style={styles.description}>{extraDescription}</Text>
        </section>

        <section style={styles.sectionCenteredColumn}>
          <Button
            onClick={handleClose}
            textStyle={styles.buttonText}
            title={intl.formatMessage(systemMessages.accept)}
            style={styles.button}
          />
        </section>
      </div>
    </Modal>
  );
};

/**
 * Retrieves messages for the given feature ID.
 * @param {string} featureId - The ID of the feature
 * @returns {Object} Object containing title, description, extraDescription, and howItWorks
 */
const getFeatureMessages = (featureId) => {
  switch (featureId) {
    case 'siteControlCompaniesCounter':
      return {
        title: propertiesMessages.ACModalTitle,
        description: propertiesMessages.ACModalDescription,
        extraDescription: propertiesMessages.ACModalExtraDescription,
        howItWorks: systemMessages.howDoesItWork,
      };
    default:
      return {};
  }
};

const styles = {
  modal: {
    zIndex: theme.zIndexes.FeatureModal,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 36,
    backgroundColor: 'white',
    minHeight: 300,
    minWidth: 400,
  },
  icon: {
    width: 30,
    height: 30,
    marginInlineEnd: theme.verticalMargin,
  },
  newFeatureText: {
    fontSize: theme.fontSizeH7,
    fontWeight: theme.strongBold,
  },
  title: {
    marginBottom: 10,
    fontWeight: theme.strongBold,
    fontSize: theme.fontSizeH5,
  },
  sectionCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionCenteredColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    width: '100%',
    textAlign: 'center',
    fontSize: theme.fontSizeH6,
  },
  howItWorks: {
    textAlign: 'center',
    fontSize: theme.fontSizeH6,
    fontWeight: 'bold',
    marginTop: theme.margin,
  },
  button: {
    backgroundColor: theme.brandPrimary,
    margin: 0,
  },
  buttonText: {
    color: 'white',
    fontSize: theme.fontSize,
  },
};

export default FeatureModal;
