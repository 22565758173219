import { startLoading, startToast } from '../app/actions';
import systemMessages from '../app/systemMessages';
import _ from 'lodash';
import { onError } from '../app/funcs';
import { track } from '../lib/reporting/actions';
import { propertiesTypesEvents } from './propertiesTypesEvents';
import serverSDK from '@cemento-sdk/server';

const checkValues = (values) => {
  let ret = {};
  if (!_.isArray(values)) ret.errorMessage = 'values is expected to be an array';
  else if (values.some((v) => !_.has(v, 'title'))) ret.errorMessage = 'some values are missing a title';
  ret.success = !Boolean(ret.errorMessage);
  return ret;
};

export function putSelectionListOptions({ subjectName, propId, values, projectId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(startLoading({ title: systemMessages.savingMessage, overlay: true, hideOnBackgroundPress: false }));
      let success = true;
      let resp;

      let check = checkValues(values);

      if (!check.success)
        onError({
          errorMessage: check.errorMessage,
          methodMetaData: {
            name: 'putSelectionListOptions',
            args: { subjectName, propId, values, projectId },
          },
        });

      try {
        resp = await serverSDK.properties.createSelectionList({
          scope: 'projects',
          scopeId: projectId,
          subject: subjectName,
          propId,
          values,
        });
      } catch (error) {
        success = false;
        onError({
          alertParams: {
            title: systemMessages.saveFailed,
          },
          errorMessage: 'putSelectionListOptions failed',
          error,
          methodMetaData: {
            name: 'putSelectionListOptions',
            args: { subjectName, propId, values, projectId },
          },
        });
      } finally {
        dispatch(track('DynamicSelectionList - create options', { subjectName, propId, values, projectId, success }));
        if (success) {
          dispatch(startToast({ title: systemMessages.savedSuccessfully }));
        }
        return { success, projectId, subjectName, propId, changedValues: _.get(resp, ['values']) };
      }
    };
    return {
      type: propertiesTypesEvents.PUT_SELECTION_LIST_OPTION,
      payload: getPromise(),
    };
  };
}

export function removeSelectionListOption({ subjectName, propId, valueId, projectId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(startLoading({ title: systemMessages.savingMessage, overlay: true, hideOnBackgroundPress: false }));
      let success = true;
      let resp;
      try {
        resp = await serverSDK.properties.markSelectionListValuesUnselectable({
          scope: 'projects',
          scopeId: projectId,
          valuesToDelete: [valueId],
        });
      } catch (error) {
        success = false;
        onError({
          alertParams: {
            title: systemMessages.saveFailed,
          },
          errorMessage: 'removeSelectionListOption failed',
          error,
          methodMetaData: {
            name: 'removeSelectionListOption',
            args: { subjectName, propId, valueId, projectId },
          },
        });
      } finally {
        dispatch(track('DynamicSelectionList - removed option', { subjectName, propId, valueId, projectId, success }));
        if (success) {
          dispatch(startToast({ title: systemMessages.deletedSuccessfully }));
        }
        return { success, projectId, subjectName, propId, changedValues: _.get(resp, ['values']) };
      }
    };
    return {
      type: propertiesTypesEvents.DELETE_SELECTION_LIST_OPTION,
      payload: getPromise(),
    };
  };
}